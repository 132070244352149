import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import api from '../../../apis/api.js';
import TableWidget from '../../widgets/TableWidget.js';
import WidgetTitle from '../../headers/WidgetTitle';
import { Link } from 'react-router-dom';
import { Grid, TextField, Typography, Button } from '@mui/material';
import matchFilter from '../../filters/functions/searchFilter.js';
import functions from '../../../functions/functions.js';
import { FileOpen, PlayCircle, Image, RestartAlt } from '@mui/icons-material';
import CustomSelect from '../../filters/CustomSelect.js';
import { updateFilter, clearFilter } from '../../../actions/index.js';

const tableHeader = [
  //{ id: 'host', label: 'Host' },
  { id: 'component', label: 'Component' },
  { id: 'manufacturer', label: 'Manufacturer' },
  { id: 'model', label: 'Model' },
  { id: 'repairType', label: 'Repair Type' },
  //{ id: 'repairLevel', label: 'Repair Level' },
  { id: 'repairLevelFormatted', label: 'Repair Level' },
  //{ id: 'title', label: 'Title' },
  //{ id: 'resourceType', label: 'Resource Type' },
  //{ id: 'link', label: 'Link' },
  { id: 'linkFormatted', label: 'Link' },
];

class Resources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      filterStr: '',
      changes: false,
      data: [],
      filteredData: [],
    };
    this.resetFilters = this.resetFilters.bind(this);
  }
  async componentDidMount() {
    let url = '/optimize/support/resources';
    this.setState({ loading: true });
    let response = await api.get(url);
    this.setState({ loading: false });
    this.setState({ data: response.data, filteredData: response.data });
    //console.log(response);
  }
  async resetFilters() {
    this.setState({ changes: true });
    await this.props.clearFilter();
    //this.multiSelectOnClose();
  }
  getComponents() {
    let dataArr = this.state.data;
    let unique = [];
    for (let i = 0; i < dataArr.length; i++) {
      if (unique.indexOf(dataArr[i].component) === -1) {
        unique.push(dataArr[i].component);
      }
    }
    return unique;
  }
  getManufacturers() {
    let dataArr = this.state.data;
    let unique = [];
    for (let i = 0; i < dataArr.length; i++) {
      if (unique.indexOf(dataArr[i].manufacturer) === -1) {
        unique.push(dataArr[i].manufacturer);
      }
    }
    return unique;
  }
  getRepairTypes() {
    let dataArr = this.state.data;
    let unique = [];
    for (let i = 0; i < dataArr.length; i++) {
      if (unique.indexOf(dataArr[i].repairType) === -1) {
        unique.push(dataArr[i].repairType);
      }
    }
    return unique;
  }
  getResourceTypes() {
    let dataArr = this.state.data;
    let unique = [];
    for (let i = 0; i < dataArr.length; i++) {
      if (unique.indexOf(dataArr[i].resourceType) === -1) {
        unique.push(dataArr[i].resourceType);
      }
    }
    return unique;
  }
  getRepairLevels() {
    let dataArr = this.state.data;
    let unique = [];
    for (let i = 0; i < dataArr.length; i++) {
      if (unique.indexOf(dataArr[i].repairLevel) === -1) {
        unique.push(dataArr[i].repairLevel);
      }
    }
    return unique;
  }
  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  filterData(data) {
    return data.filter((row) => {
      //console.log(this.props.sort.filter);
      let repairLevel = Math.floor(row.repairLevel);
      return (
        (!this.props.sort.filter.Component || this.props.sort.filter.Component.length === 0 || this.props.sort.filter.Component.indexOf(row.component) !== -1 || row.component === 'All') &&
        (!this.props.sort.filter.Manufacturer ||
          this.props.sort.filter.Manufacturer.length === 0 ||
          this.props.sort.filter.Manufacturer.indexOf(row.manufacturer) !== -1 ||
          row.manufacturer === 'All') &&
        (!this.props.sort.filter['Repair Type'] ||
          this.props.sort.filter['Repair Type'].length === 0 ||
          this.props.sort.filter['Repair Type'].indexOf(row.repairType) !== -1 ||
          row.repairType === 'All') &&
        (!this.props.sort.filter['Repair Level'] ||
          this.props.sort.filter['Repair Level'].length === 0 ||
          this.props.sort.filter['Repair Level'].indexOf(functions.numberToRepairLevel(repairLevel)) !== -1 ||
          row.repairLevel === 'All') &&
        (!this.props.sort.filter['Resource Type'] ||
          this.props.sort.filter['Resource Type'].length === 0 ||
          this.props.sort.filter['Resource Type'].indexOf(row.resourceType) !== -1 ||
          row.repairType === 'All')
      );
    });
  }
  render() {
    const { classes } = this.props;
    let dataArr = this.filterData(this.state.data);
    let data = [];
    if (dataArr !== undefined) {
      for (let i = 0; i < dataArr.length; i++) {
        data.push(dataArr[i]);
        let repairLevel = Math.floor(data[i].repairLevel);
        data[i].repairLevelFormatted = functions.numberToRepairLevel(repairLevel);
        data[i].linkFormatted = (
          <Link className={classes.textLink} to={{ pathname: data[i].link }} target='_blank'>
            <Grid container>
              <Grid item style={{ marginRight: 8 }} className={classes.grayText}>
                {data[i].resourceType === 'Video' ? (
                  <PlayCircle fontSize='small'></PlayCircle>
                ) : data[i].resourceType === 'Image' ? (
                  <Image fontSize='small'></Image>
                ) : data[i].resourceType === 'Document' ? (
                  <FileOpen fontSize='small'></FileOpen>
                ) : null}
              </Grid>
              <Grid item>
                <Typography variant='subtitle2'>{data[i].title}</Typography>
              </Grid>
            </Grid>
          </Link>
        );
      }
    }
    data = matchFilter(data, this.state.filterStr);
    //console.log(data);
    return (
      <React.Fragment>
        <Grid container justifyContent='space-between' style={{ marginTop: 16 }}>
          <Grid item>
            <CustomSelect
              id={null}
              label='Component'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.props.sort.filter.Component ? [] : this.props.sort.filter.Component}
              options={this.getComponents()}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Manufacturer'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.props.sort.filter.Manufacturer ? [] : this.props.sort.filter.Manufacturer}
              options={this.getManufacturers()}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Repair Type'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.props.sort.filter['Repair Type'] ? [] : this.props.sort.filter['Repair Type']}
              options={this.getRepairTypes()}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Repair Level'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.props.sort.filter['Repair Level'] ? [] : this.props.sort.filter['Repair Level']}
              options={['Field Adjustment', 'Parts Replacement', 'Body Repair', 'Note']}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              label='Resource Type'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.props.sort.filter['Resource Type'] ? [] : this.props.sort.filter['Resource Type']}
              options={this.getResourceTypes()}
              value={null}
              helperText={null}
            />
          </Grid>
          <Grid item>
            <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ float: 'right' }}>
              <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
            </Button>
          </Grid>
        </Grid>
        <WidgetTitle title='Resources' />
        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item>
            <TextField
              variant='outlined'
              InputProps={{ classes: { root: classes.inlineInput } }}
              className={classes.searchField}
              placeholder='Search'
              hiddenLabel
              onChange={(event) => {
                this.setState({ filterStr: event.target.value });
              }}
              value={this.state.filterStr}
            />
          </Grid>
        </Grid>
        <TableWidget
          //key={`table-${importance}`}
          defaultRows={25}
          data={data}
          header={tableHeader}
          loading={this.state.loading}
        ></TableWidget>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { sort: state.sort };
};

export default connect(mapStateToProps, { updateFilter, clearFilter })(withStyles(styles, { withTheme: true })(Resources));
