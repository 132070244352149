import { SET_MODULE } from '../actions/types';

const INITIAL_STATE = {
  currentModule: null,
};

const moduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_MODULE:
      return { ...state, currentModule: action.payload };
    default:
      return state;
  }
};

export default moduleReducer;
