import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
//import api from '../../apis/api';
import {
  Button,
  //Collapse,
  Checkbox,
  Grid,
  Input,
  Tooltip,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Typography,
  TableContainer,
  CircularProgress,
} from '@mui/material';
import DropdownMenu from '../menus/DropdownMenu';
import { styles } from '../styles';
// TODO SINGLE SELECTS ARE FOR DUMMY CODE SWAP LATER
import SingleSelect from '../filters/SingleSelect';
import TextInput from '../filters/TextInput';
import TextField from '@mui/material/TextField';
import TransferList from '../filters/TransferList';
import { Visibility } from '@mui/icons-material';
import { fetchDevicesSummary } from '../../actions/index';

/*let counter = 0;
function createData(name, concern, type) {
  counter += 1;
  return { id: counter, name, concern, type };
}*/

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = !array ? [] : array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class TableExpandableHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, selections, rowCount } = this.props;

    const numSelected = !selections ? 0 : selections.length;

    return (
      <TableHead>
        <TableRow>
          {!this.props.selectable ? null : (
            <TableCell padding='checkbox'>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ 'aria-label': 'select all' }}
                color='secondary'
              />
            </TableCell>
          )}
          {!this.props.header
            ? null
            : this.props.header.map(
                (row) => (
                  <TableCell style={{ paddingTop: '12px', paddingBottom: '12px' }} key={row.id} sortDirection={orderBy === row.id ? order : false}>
                    <Tooltip arrow placement='top' title='Sort' enterDelay={300}>
                      <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ),
                this
              )}
        </TableRow>
      </TableHead>
    );
  }
}

TableExpandableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};

class TableExpandable extends React.Component {
  state = {
    order: 'asc',
    orderBy: !this.props.orderBy ? 'concern' : this.props.orderBy,
    selected: [],
    //data: this.props.data,
    page: 0,
    rowsPerPage: this.props.rows,
    clickedRow: null,
    rowChange: {},
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  handleClick = (row, e) => {
    //console.log(e.target)
    if (!!this.props.handleClick) {
      this.props.handleClick(row, e);
      this.setState({ clickedRow: row });
    } else {
      console.log('Row Click Handler Not Defined!');
    }
  };

  renderChildRowContent = (row) => {
    if (!!this.props.renderChildRowContent) {
      return this.props.renderChildRowContent(row);
    } else {
      return null;
    }
  };

  renderChildRowContentOld() {
    //console.log();
    const { classes } = this.props;
    if (this.props.type === 'valveConfig') {
      return (
        <form
          onSubmit={(e) => {
            this.handleSubmit(e);
          }}
        >
          <Typography
            variant='subtitle1'
            color='primary'
            //className={classes.rowTitle}
          >
            Summary
          </Typography>
          <TextField
            className={classes.textField}
            label='Tag #'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.tag = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].name}
          />
          <TextField
            className={classes.textField}
            label='Serial #'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.serial = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].serial}
          />
          <TextField
            className={classes.textField}
            label='Site'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.site = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].site}
          />
          <TextField
            className={classes.textField}
            label='Application'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.application = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].application}
          />
          <TextField
            className={classes.textField}
            label='Description'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.description = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].description}
          />
          <TextField
            className={classes.textField}
            label='Size'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.size = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].bodySize}
          />
          <TextField
            className={classes.textField}
            label='Type'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.bodyType = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].bodyType}
          />
          <TextField
            className={classes.textField}
            label='Importance'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.importance = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].importance}
          />

          <div className={classes.dividerXl}></div>
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Performance
          </Typography>

          <TextField
            className={classes.textField}
            label='Resolution Target'
            type='number'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.resApp = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].resApp}
          />
          <TextField
            className={classes.textField}
            label='Sensitivity Target'
            type='number'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.senApp = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].senApp}
          />
          <TextField
            className={classes.textField}
            label='Stroke Speed Target'
            type='number'
            onChange={(event) => {
              let currentRow = this.state.rowChange;
              currentRow.strokeApp = event.target.value;
              this.setState(currentRow);
            }}
            defaultValue={!this.state.clickedRow ? null : this.props.devices[this.state.clickedRow].strokeApp}
          />

          {/*<Typography
            variant='subtitle1'
            color='primary'
            className={classes.rowTitle}
          >
            Performance
          </Typography>
          <Grid container spacing={4}>
            <Grid item>
              <Typography>
                What is the cost to the plant if the valve fails to meet its
                performance requirements or fails during operations?
              </Typography>
              <SingleSelect
                id={null}
                label='Cost'
                //type="sort"
                //handleChange={this.updateSort}
                options={['2 - Subsystem Shutdown or Sustained KPI Loss']}
                selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
                helperText={null}
              />
            </Grid>
            <Grid item>
              <Typography>Does this valve have a bypass?</Typography>
              <SingleSelect
                id={null}
                label='Bypass'
                //type="sort"
                //handleChange={this.updateSort}
                options={['Yes', 'No']}
                selectedOption={'No'}
                helperText={null}
              />
            </Grid>
          </Grid>
          <div className={classes.dividerXl}></div>
          <Typography
            variant='subtitle1'
            color='primary'
            className={classes.rowTitle}
          >
            Reliability
          </Typography>
          <Grid container spacing={4}>
            <Grid item>
              <Typography>
                Has this valve been tested for early mortality or metallurgy
                issues?
              </Typography>
              <SingleSelect
                id={null}
                label='Tested'
                //type="sort"
                //handleChange={this.updateSort}
                options={['Yes', 'No']}
                selectedOption={'No'}
                helperText={null}
              />
            </Grid>
            <Grid item>
              <Typography>
                Has the performance of this valve been suspect or caused a plant
                upset in the past?
              </Typography>
              <SingleSelect
                id={null}
                label='Bypass'
                //type="sort"
                //handleChange={this.updateSort}
                options={['Yes', 'No']}
                selectedOption={'No'}
                helperText={null}
              />
            </Grid>
          </Grid>*/}
          <Input type='submit' value='Submit' />
        </form>
      );
    } else if (this.props.type === 'userConfig') {
      return (
        <React.Fragment>
          <Grid item>
            <TextInput label='Username'></TextInput>
            <TextInput label='Password'></TextInput>
            <Button variant='outlined' size='medium' color='primary' className={classes.buttonFilterInline}>
              <Visibility className={classes.buttonIcon}></Visibility>View Password
            </Button>
            <TextInput label='Email'></TextInput>
            <TextInput label='First Name'></TextInput>
            <TextInput label='Last Name'></TextInput>
            <SingleSelect
              id={null}
              label='Permission Level'
              //type="sort"
              //handleChange={this.updateSort}
              options={['1']}
              selectedOption={'1'}
              helperText={null}
            />
            <SingleSelect
              id={null}
              label='Time Zone'
              //type="sort"
              //handleChange={this.updateSort}
              options={['EST']}
              selectedOption={'EST'}
              helperText={null}
            />
          </Grid>
        </React.Fragment>
      );
    } else if (this.props.type === 'deviceConfig') {
      return (
        <React.Fragment>
          <TextInput label='Device Name'></TextInput>
          <SingleSelect
            id={null}
            label='Device Type'
            //type="sort"
            //handleChange={this.updateSort}
            options={['Temperature']}
            selectedOption={'Temperature'}
            helperText={null}
          />
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Loop Shutdown Parameters
          </Typography>
          <SingleSelect
            id={null}
            label='Lower pH Limit'
            //type="sort"
            //handleChange={this.updateSort}
            options={['-1']}
            selectedOption={'-1'}
            helperText={null}
          />
          <SingleSelect
            id={null}
            label='Upper pH Limit'
            //type="sort"
            //handleChange={this.updateSort}
            options={['4']}
            selectedOption={'4'}
            helperText={null}
          />
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Device Valve Pairs
          </Typography>
          <TransferList list1Title='Unpaired Valves' list2Title='Paired Valves'></TransferList>
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Redundant Device Pairs
          </Typography>
          <TransferList list1Title='Unpaired' list2Title='Paired (Redundant)'></TransferList>
        </React.Fragment>
      );
    } else if (this.props.type === 'loopConfig') {
      return (
        <React.Fragment>
          <TextInput label='Loop Name'></TextInput>
          <SingleSelect
            id={null}
            label='Application Order'
            //type="sort"
            //handleChange={this.updateSort}
            options={['1']}
            selectedOption={'1'}
            helperText={null}
          />
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Device Pairs
          </Typography>
          <TransferList list1Title='Unpaired' list2Title='Paired Devices'></TransferList>
        </React.Fragment>
      );
    } else if (this.props.type === 'applicationConfig') {
      return (
        <React.Fragment>
          <TextInput label='Application Name'></TextInput>
          <SingleSelect
            id={null}
            label='Train'
            //type="sort"
            //handleChange={this.updateSort}
            options={['1']}
            selectedOption={'1'}
            helperText={null}
          />
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Paired Loops
          </Typography>
          <TransferList list1Title='Unpaired' list2Title='Paired Loops'></TransferList>
        </React.Fragment>
      );
    } else if (this.props.type === 'unitConfig') {
      return (
        <React.Fragment>
          <TextInput label='Unit Name'></TextInput>
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Paired Applications
          </Typography>
          <TransferList list1Title='Unpaired' list2Title='Paired Applications'></TransferList>
        </React.Fragment>
      );
    } else if (this.props.type === 'siteConfig') {
      return (
        <React.Fragment>
          <TextInput label='Site Name'></TextInput>
          <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
            Paired Units
          </Typography>
          <TransferList list1Title='Unpaired' list2Title='Paired Units'></TransferList>
        </React.Fragment>
      );
    } else if (this.props.type === 'issues') {
      <React.Fragment>issues</React.Fragment>;
    } else {
      return 'This table needs a type prop';
    }
  }

  render() {
    const data = this.props.data;
    const { classes } = this.props;
    const { /*data,*/ order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = !data ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const rowCount = !data ? 0 : data.length;
    if (this.props.loading && (!data || data.length === 0)) {
      return (
        <Typography style={{ padding: 16 }}>
          <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
          Loading...
        </Typography>
      );
    } else if (!data || data.length === 0) {
      return <Grid style={{ padding: 10 }}>No data found for this table.</Grid>;
    } else {
      //console.log(data);
      return (
        <TableContainer>
          {!this.props.selections || this.props.selections.length === 0 ? null : (
            <Grid container spacing={2}>
              <Grid item> {`${this.props.selections.length} Selected`}</Grid>
              <Grid item>
                <DropdownMenu tooltip='Actions' options={this.props.selectionActions} />
              </Grid>
            </Grid>
          )}
          <Table className={classes.table} aria-labelledby='tableTitle'>
            <TableExpandableHead
              selectable={this.props.selectable}
              onSelectAllClick={this.props.onSelectAllClick}
              selections={this.props.selections}
              selectionActions={this.props.selectionActions}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={rowCount}
              header={this.props.header}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = !this.props.selections ? false : this.props.selections.indexOf(row.id) !== -1;
                  //console.log(n);
                  return (
                    <React.Fragment key={`${row.id}_${index}`}>
                      <TableRow
                        hover
                        onClick={(e) => {
                          this.handleClick(row.id, e);
                        }}
                        //style={{ height: 34 }}
                        //selected={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}_${index}`}
                        className={`${classes.row} ${(this.state.clickedRow === row.id && !this.props.selectable) || (!!this.props.selectable && isItemSelected) ? classes.rowActive : null}`}
                      >
                        {!this.props.selectable ? null : (
                          <TableCell padding='checkbox'>
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                        )}
                        {this.props.header.map((header) => {
                          return (
                            <TableCell component='th' key={header.id} scope='row'>
                              {row[header.id] !== null ? row[header.id] : '-'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow className={this.state.clickedRow === row.id && this.props.rowOpen ? classes.rowOpen : null}>
                        <TableCell colSpan={this.props.header.length} className={classes.collapseCell} style={{ paddingLeft: 0 }}>
                          {this.renderChildRowContent(row)}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              {emptyRows > 0 && null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            onClick={(e) => e.preventDefault()}
          />
        </TableContainer>
      );
    }
  }
}

TableExpandable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { devices: state.devices.devices };
};

export default connect(mapStateToProps, { fetchDevicesSummary })(withStyles(styles)(TableExpandable));
