import {
  OPTIMIZE_SET_ACTIVE_TAB,
  OPTIMIZE_SET_ACTIVE_LOOP,
  OPTIMIZE_SET_ACTIVE_DEVICE,
  OPTIMIZE_SET_ACTIVE_TEST,
  OPTIMIZE_SET_COMPARE_FLAG,
  OPTIMIZE_SET_COMPARE_TEST,
  OPTIMIZE_SET_COMPARE_DEVICE,
} from '../actions/types';

const INITIAL_STATE = {
  activeTab: 'Summary',
  activeLoop: '',
  activeDevice: '',
  activeTest: '',
  compareTest: '',
  compareDevice: '',
  compareFlag: false,
};

const optimizeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPTIMIZE_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case OPTIMIZE_SET_ACTIVE_LOOP:
      return { ...state, activeLoop: action.payload };
    case OPTIMIZE_SET_ACTIVE_DEVICE:
      return { ...state, activeDevice: action.payload };
    case OPTIMIZE_SET_ACTIVE_TEST:
      return { ...state, activeTest: action.payload };
    case OPTIMIZE_SET_COMPARE_DEVICE:
      return { ...state, compareDevice: action.payload };
    case OPTIMIZE_SET_COMPARE_FLAG:
      return { ...state, compareFlag: action.payload };
    case OPTIMIZE_SET_COMPARE_TEST:
      return { ...state, compareTest: action.payload };
    default:
      return state;
  }
};

export default optimizeReducer;
