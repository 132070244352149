import React from 'react';
import { Typography, Button, TextField } from '@mui/material';
import TableWidget from '../../../widgets/TableWidget';
import Grid from '@mui/material/Grid';
import WidgetTitle from '../../../headers/WidgetTitle';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { connect } from 'react-redux';
import CustomSelect from '../../../filters/CustomSelect';
import matchFilter from '../../../filters/functions/searchFilter';
import { RestartAlt } from '@mui/icons-material';
import functions from '../../../../functions/functions.js';
import initializeTheme from '../../../theme.js';
import ReportChartWidget from '../../../widgets/ReportChartWidget';
import { Link } from '@mui/icons-material';
import {
  setLoopsSummaryStatus,
  fetchLoopsSummary,
  fetchLoops,
  updateSort,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  updateFilter,
  deleteLoops,
  deleteDevices,
  clearFilter,
} from '../../../../actions/index';

const headerArr = [
  //{ id: 'name', label: 'Name' },
  //{ id: 'location', label: 'Location' },
  { id: 'nameFormatted', label: 'Loop' },
  { id: 'applicationFormatted', label: 'Application' },
  //{ id: 'application', label: 'Application' },
  //{ id: 'order', label: 'App. Order' },
  { id: 'date', label: 'Date' },
  { id: 'concernFormatted', label: 'Concern' },
  //{ id: 'concern', label: 'Concern Score' },
  //{ id: 'concernShift', label: 'Concern Shift' },
  //{ id: 'concernMax', label: 'Max Concern Score' },
  { id: 'importanceFormatted', label: 'Importance' },
  //{ id: 'importance', label: 'Importance' },
  { id: 'issues', label: 'Open Issues' },
  { id: 'reportLink', label: 'View' },
];

class ConcernSummaryReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: '',
      orderBy: '',
      status: [],
      loading: true,
      changes: false,
      filterStr: '',
    };
    //this.resetFilters = this.resetFilters.bind(this);
  }
  async componentDidMount() {
    if (
      this.props.currentModule === 'Prioritize' ||
      this.props.reportModule === 'Prioritize' ||
      this.props.configModule === 'Prioritize' //&&
      //this.props.loopSummary === false //TODO add loop summary status check (see lines 80-81)
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      this.setState({ loading: true });
      this.props.updateSort('Concern');
      this.props.fetchSites();
      this.props.fetchApplications();
      this.props.fetchLocations();
      this.props.fetchDeviceTypes();
      this.props.fetchImportances();
      this.props.fetchIssueTypes();
      await this.props.fetchLoops(null, null, 'Concern');
      this.setState({ loading: false });
      //console.log('TODO: CALL FOR LOOP SUMMARY'); //TODO CALL FOR LOOP SUMMARY
    }
  }
  processConcern = (concern, sort) => {
    let name = 'Unknown';
    if (sort === 'Concern Shift') {
      if (concern < 0) {
        name = 'Good';
      } else if (concern >= 0 && concern <= 30) {
        name = 'Advisory';
      } else if (concern > 30 && concern <= 100) {
        name = 'Critical';
      }
    } else {
      if (concern >= 0 && concern < 30) {
        name = 'Good';
      } else if (concern >= 30 && concern < 60) {
        name = 'Advisory';
      } else if (concern >= 60 && concern <= 100) {
        name = 'Critical';
      }
    }
    return name;
  };

  processImportance(importance) {
    switch (importance) {
      case 5:
        return 'Extremely Important';
      case 4:
        return 'Very Important';
      case 3:
        return 'Important';
      case 2:
        return 'Standard';
      case 1:
        return 'Not Important';
      default:
        return 'Standard';
    }
  }
  getSort = () => {
    if (this.props.sort.sort === 'Concern Shift') {
      return 'concernShift';
    } else if (this.props.sort.sort === 'Concern Max') {
      return 'concernMax';
    } else {
      return 'concern';
    }
  };
  updateSort = async (value) => {
    this.props.updateSort(value);
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      Range: this.props.sort.filter.Range,
      analysisRange: this.props.sort.filter.AnalysisRange,
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: this.props.sort.filter.SpecificDevice,
      Concerns: JSON.stringify(this.props.sort.concern),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.analysisRange &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page

      //console.log(filters);
      //console.log(this.props.sort.filter);
      this.setState({ loading: true });
      //this.props.setTablePage(0);
      //}

      //console.log(filters);

      await this.props.fetchLoops(null, null, value, filters, this.props.sort.filter.Dataset).then(() => {
        this.setState({
          //page,
          //rowsPerPage,
          loading: false,
          changes: false,
        });
      });
      //this.props.fetchLoopsSummary();
    }
  };
  multiSelectOnClose = async (event, label, changesOverride) => {
    if (this.state.changes || changesOverride) {
      //99.9% SAME AS SETDEVICESPECIFIC
      //console.log('Closed');
      //console.log(this.props.sort);

      let filters = {
        Application: this.props.sort.filter.Application,
        Site: this.props.sort.filter.Site,
        Location: this.props.sort.filter.Location,
        Disruption: this.props.sort.filter.Disruption,
        Range: this.props.sort.filter.Range,
        analysisRange: this.props.sort.filter.AnalysisRange,
        Devicetypes: this.props.sort.filter['Device Types'],
        Issuetypes: this.props.sort.filter['Issue Types'],
        Importance: this.props.sort.filter.Importance,
        SpecificDevice: this.props.sort.filter.SpecificDevice,
        Concerns: JSON.stringify(this.props.sort.concern),
      };
      if (
        !(
          !filters.Application &&
          !filters.Disruption &&
          !filters.Devicetypes &&
          !filters.Issuetypes &&
          !filters.Importance &&
          !filters.Location &&
          !filters.analysisRange &&
          !filters.Site &&
          !filters.Concerns &&
          this.props.auth.require_filter
        )
      ) {
        this.props.deleteDevices();
        this.props.deleteLoops();
        //TODO Finish with update of page

        //console.log(filters);
        //console.log(this.props.sort.filter);
        this.setState({ loading: true });
        //this.props.setTablePage(0);
        //if (!this.props.locations || Object.entries(this.props.locations).length === 0) {
        if (label !== 'Location') {
          this.props.fetchLocations(null, filters);
        }
        //}

        //if (!this.props.applications || Object.entries(this.props.applications).length === 0) {
        if (label !== 'Application') {
          this.props.fetchApplications(null, filters);
        }
        //}

        if (label !== 'Importance') {
          this.props.fetchImportances(null, filters);
        }
        //}

        //if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
        if (label !== 'Site') {
          this.props.fetchSites(null, filters);
        }
        //}
        if (label !== 'Device Types') {
          this.props.fetchDeviceTypes(null, filters);
        }
        if (label !== 'Issue Types') {
          this.props.fetchIssueTypes(null, filters);
        }

        //console.log(filters);
        await this.props.fetchLoops(null, null, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
          this.setState({
            //page,
            //rowsPerPage,
            loading: false,
            changes: false,
          });
        });
        //this.props.fetchLoopsSummary();
      }
    }
  };
  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  changeAnalysisRange = async (obj) => {
    this.props.updateFilter(obj);
    this.setState({ changes: true });
  };
  resetFilters = async () => {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.props.updateSort('Concern');
    this.multiSelectOnClose();
  };
  processLoopData = (loopFlag) => {
    const { classes } = this.props;
    const theme = initializeTheme(this.props.colorMode);
    let colorsArr = [theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color];
    let tableData = [];
    let seriesData = [
      { name: 'Critical', color: colorsArr[0], data: [] },
      { name: 'Advisory', color: colorsArr[1], data: [] },
      { name: 'Good', color: colorsArr[2], data: [] },
    ];
    //let seriesDataCategories = [];
    let importanceSeries = [];
    let deviceTypeSeries = [];
    let deviceTypes = [];
    let importanceSeriesObject = {
      Critical: { 'Extremely Important': 0, 'Very Important': 0, Important: 0, Standard: 0, 'Not Important': 0 },
      Advisory: { 'Extremely Important': 0, 'Very Important': 0, Important: 0, Standard: 0, 'Not Important': 0 },
      Good: { 'Extremely Important': 0, 'Very Important': 0, Important: 0, Standard: 0, 'Not Important': 0 },
    };
    let deviceTypeSeriesObject = {
      Critical: {},
      Advisory: {},
      Good: {},
      Unknown: {},
    };
    let pieDataCount = {
      Critical: 0,
      Advisory: 0,
      Good: 0,
      Unknown: 0,
    };
    let loops = this.props.loops;
    let loopKeys = Object.keys(loops);
    for (let i = 0; i < loopKeys.length; i++) {
      let loop = loops[loopKeys[i]];

      let focusedConcern = Math.round(this.props.sort.sort === 'Concern Shift' ? loop.concernShift : this.props.sort.sort === 'Concern Max' ? loop.concernMax : loop.concern);

      const href =
        window.location.host === 'localhost:3000' ? `http://${window.location.host}/prioritize/${loop.loopId}/Workbench` : `https://${window.location.host}/prioritize/${loop.loopId}/Workbench`;
      let reportLink = (
        <Button variant='outlined' size='medium' color='primary' className={classes.button} href={href}>
          <Link className={classes.buttonIcon}></Link>Link
        </Button>
      );
      let nameFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{loop.processName}</Typography>
          <Typography variant='subtitle2'>{loop.location}</Typography>
        </React.Fragment>
      );
      let applicationFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>{loop.applicationType}</Typography>
          <Typography variant='subtitle2'>App. Order: {loop.applicationOrder}</Typography>
        </React.Fragment>
      );
      let concernFormatted = (
        <React.Fragment>
          <Typography variant='subtitle2'>
            <span className={classes.tableDataLabel}>Score: </span>
            <span className={loop.concern > 60 ? classes.redText : loop.concern > 30 ? classes.yellowText : classes.greenText}>{loop.concern}</span>
          </Typography>
          <Typography variant='subtitle2'>
            <span className={classes.tableDataLabel}>Max: </span>
            {loop.concernMax}
          </Typography>
          <Typography variant='subtitle2'>
            <span className={classes.tableDataLabel}>Shift: </span>
            {Math.round(loop.concernShift * 1000) / 1000}
          </Typography>
        </React.Fragment>
      );
      let issues = loop.issues;
      let issuesFormatted = !issues
        ? []
        : issues.map((issue) => {
            let issueName = issue.replace(loop.loopId, '');
            return (
              <Typography key={`${loop}_${issue}`} variant='subtitle2'>
                {issueName}
              </Typography>
            );
          });
      if (!!loop && !!loop.rowId) {
        tableData.push({
          id: i,
          nameFormatted: nameFormatted,
          applicationFormatted: applicationFormatted,
          name: loop.processName,
          location: loop.location,
          application: loop.applicationType,
          order: loop.applicationOrder,
          date: functions.dateToString(loop.dateRecent * 1000, 'date'),
          concernFormatted: concernFormatted,
          concern: loop.concern,
          concernMax: loop.concernMax,
          concernShift: Math.round(loop.concernShift * 1000) / 1000,
          importanceFormatted: this.processImportance(loop.importance_max),
          importance: loop.importance_max,
          issues: issuesFormatted,
          reportLink: reportLink,
        });
        let processedConcern = this.processConcern(focusedConcern, this.props.sort.sort);
        let concernIndex = processedConcern === 'Critical' ? 0 : processedConcern === 'Advisory' ? 1 : 2;
        for (let j = 0; j < 3; j++) {
          if (!!seriesData[j]) {
            seriesData[j].data.push({
              name: loop.processName,
              y: j === concernIndex ? focusedConcern : 0,
            });
          }
        }
        //seriesDataCategories.push(loop.processName);
        for (let j = 0; j < loop.deviceConcern.length; j++) {
          let deviceType = loop.deviceTypes[j];
          if (!deviceTypes.includes(deviceType)) {
            deviceTypes.push(deviceType);
          }
          let focusedDeviceConcern = Math.round(
            this.props.sort.sort === 'Concern Shift' ? loop.deviceConcernShift[j] : this.props.sort.sort === 'Concern Max' ? loop.deviceConcernMax[j] : loop.deviceConcern[j]
          );

          if (!deviceTypeSeriesObject[this.processConcern(focusedDeviceConcern, this.props.sort.sort)][deviceType]) {
            deviceTypeSeriesObject[this.processConcern(focusedDeviceConcern, this.props.sort.sort)][deviceType] = 0;
          }
          deviceTypeSeriesObject[this.processConcern(focusedDeviceConcern, this.props.sort.sort)][deviceType]++;
        }

        pieDataCount[this.processConcern(focusedConcern, this.props.sort.sort)]++;
        importanceSeriesObject[this.processConcern(focusedConcern, this.props.sort.sort)][functions.importanceToString(loop.importance_max)]++;
      }
    }
    let pieDataScoreDistribution = [
      { name: 'Critical', y: pieDataCount['Critical'] },
      { name: 'Advisory', y: pieDataCount['Advisory'] },
      { name: 'Good', y: pieDataCount['Good'] },
    ];
    if (pieDataCount.unknown > 0) {
      pieDataScoreDistribution.push({ name: 'Unknown', y: pieDataCount['Unknown'] });
    }
    tableData = matchFilter(tableData, this.state.filterStr);
    let importanceSeriesKeys = Object.keys(importanceSeriesObject);

    for (let j = 0; j < importanceSeriesKeys.length; j++) {
      let obj = importanceSeriesObject[importanceSeriesKeys[j]];
      let localArr = [obj['Extremely Important'], obj['Very Important'], obj['Important'], obj['Standard'], obj['Not Important']];
      let dataObj = { name: importanceSeriesKeys[j], data: localArr, color: colorsArr[j] };
      importanceSeries.push(dataObj);
    }
    let deviceTypeSeriesKeys = Object.keys(deviceTypeSeriesObject);
    deviceTypes.sort();
    for (let j = 0; j < deviceTypeSeriesKeys.length; j++) {
      let obj = deviceTypeSeriesObject[deviceTypeSeriesKeys[j]];
      let localArr = [];
      let localArrSum = 0;
      for (let k = 0; k < deviceTypes.length; k++) {
        localArr.push(!obj[deviceTypes[k]] ? 0 : obj[deviceTypes[k]]);

        localArrSum = localArrSum + (!obj[deviceTypes[k]] ? 0 : obj[deviceTypes[k]]);
      }
      //let localArr = [obj['Extremely Important'], obj['Very Important'], obj['Important'], obj['Standard'], obj['Not Important']];
      let dataObj = { name: deviceTypeSeriesKeys[j], data: localArr, color: colorsArr[j] };
      if (deviceTypeSeriesKeys[j] !== 'Unknown' || localArrSum !== 0) {
        deviceTypeSeries.push(dataObj);
      }
    }
    //localData.sort((a, b) => parseFloat(b.concern) - parseFloat(a.concern));
    //console.log(localData);
    //console.log(importanceSeriesObject);
    //console.log(importanceSeries);
    //console.log(deviceTypeSeriesObject);
    //console.log(deviceTypeSeries);
    //console.log(seriesData);
    //console.log(seriesDataCategories);
    return { tableData, seriesData, /* seriesDataCategories, */ pieDataScoreDistribution, importanceSeries, deviceTypeSeries, deviceTypes };
  };

  render() {
    const { classes } = this.props;
    const theme = initializeTheme(this.props.colorMode);
    let { tableData, seriesData, /* seriesDataCategories,*/ pieDataScoreDistribution, importanceSeries, deviceTypeSeries, deviceTypes } = this.processLoopData();
    let importanceTypes = ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important'];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    // console.log(tableData);
    // console.log(pieData);
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off'>
          <CustomSelect
            single
            id={null}
            label='Analysis Range'
            suffix=' Days'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              let obj = { ...this.props.sort.filter };
              let name = 'AnalysisRange';
              obj[name] = value;
              this.changeAnalysisRange(obj);
              //this.props.updateFilter(obj);
              //this.setState({ changes: true });
              //this.multiSelectOnClose(obj);
            }}
            //type='unit'
            values={!this.props.analysisRange ? '90' : this.props.analysisRange.toString()}
            options={['7', '14', '30', '90', '180', '365']}
            //value={null}
            helperText={null}
          />
          <CustomSelect
            single
            id={null}
            label='Sort'
            disabled={this.state.loading}
            //onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={(event, value) => {
              this.updateSort(value);
            }}
            //type='unit'
            values={this.props.sort.sort}
            options={['Concern', 'Concern Shift', 'Concern Max', 'Importance', 'Application Order']}
            //value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Site'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Site ? [] : this.props.sort.filter.Site}
            options={!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Location'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Location ? [] : this.props.sort.filter.Location}
            options={!this.props.locations || !this.props.locations.list ? [] : this.props.locations.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Application'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter.Application ? [] : this.props.sort.filter.Application}
            options={!this.props.applications || !this.props.applications.list ? [] : this.props.applications.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Importance'
            disabled={this.state.loading}
            values={!this.props.sort.filter.Importance ? [] : this.props.sort.filter.Importance}
            options={!this.props.importances || !this.props.importances.list ? [] : this.props.importances.list.sort(functions.sortImportance)}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Issue Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter['Issue Types'] ? [] : this.props.sort.filter['Issue Types']}
            options={!this.props.issuetypes || !this.props.issuetypes.list ? [] : this.props.issuetypes.list.sort()}
            value={null}
            helperText={null}
          />
          <CustomSelect
            id={null}
            label='Device Types'
            disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            //type='unit'
            values={!this.props.sort.filter['Device Types'] ? [] : this.props.sort.filter['Device Types']}
            options={!this.props.devicetypes || !this.props.devicetypes.list ? [] : this.props.devicetypes.list.sort()}
            value={null}
            helperText={null}
          />
          {/*<DatePicker
              label='Created After'
              disabled={this.state.loading}
              date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
              dateFunction={(startDate) => {
                this.updateDateRange(startDate, 'start');
              }}
            ></DatePicker>
            <DatePicker
              label='Created Before'
              disabled={this.state.loading}
              dateFunction={(endDate) => {
                this.updateDateRange(endDate, 'end');
              }}
            ></DatePicker>*/}
          <div style={{ float: 'right' }}>
            <Button
              variant='outlined'
              color='primary'
              aria-label='submit'
              className={`${classes.buttonFilterRight} ${classes.printHide}`}
              onClick={async () => {
                await this.setState({ loading: true, changes: true });
                this.multiSelectOnClose();
              }}
            >
              Submit
            </Button>
            <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ marginRight: 8 }}>
              <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
            </Button>
          </div>
        </form>
        {
          /*this.state.loading*/ false ? (
            'Loading...'
          ) : (
            <React.Fragment>
              <Grid container spacing={1}>
                {/*<WidgetTitle title='Concern Distribution' /><Grid item xs={12} sm={this.props.format === true ? 12 : 4}>
                <Grid item xs={12} className={classes.widgetMargin}>
                  <Paper className={classes.tilePaper} elevation={3}>
                    <Chart
                      type='pie'
                      colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
                      options={{
                        series: [
                          {
                            id: 1,
                            //showInLegend: false,
                            name: 'Concern',
                            data: pieDataScoreDistribution,
                          },
                        ],
                      }}
                    />
                  </Paper>
                </Grid>
                    </Grid>*/}
                <ReportChartWidget
                  name='Concern'
                  seriesData={seriesData}
                  colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
                  type={this.props.sort.sort === 'Concern Shift' ? 'concernShift' : 'concern'}
                  initialWidth={printStyles ? 12 : 8}
                  stacking='normal'
                  loading={this.state.loading}
                ></ReportChartWidget>
                <ReportChartWidget
                  name='Concern Distribution'
                  data={pieDataScoreDistribution}
                  colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
                  type='pie'
                  initialWidth={printStyles ? 12 : 4}
                  loading={this.state.loading}
                ></ReportChartWidget>
                {/*<Grid item xs={12} sm={this.props.format === true ? 12 : 8}>
                <Paper className={`${printStyles ? classes.printPaper : classes.paper} `} elevation={3}>
                  <Chart
                    type={this.props.sort.sort === 'Concern Shift' ? 'concernShift' : 'concern'}
                    options={{
                      series: seriesData,
                    }}
                  />
                </Paper>
              </Grid>*/}
              </Grid>
              <Grid container spacing={1} className={classes.pageBreak}>
                <ReportChartWidget
                  name='Concern By Importance'
                  seriesData={importanceSeries}
                  colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
                  type='stack'
                  categories={importanceTypes}
                  initialWidth={6}
                  loading={this.state.loading}
                ></ReportChartWidget>
                {/*<Grid item xs={12} sm={6}>
                <WidgetTitle title='Concern By Importance' />
                <Paper className={`${printStyles ? classes.printPaper : classes.paper} `} elevation={3}>
                  <Chart
                    type='stack'
                    categories={importanceTypes}
                    options={{
                      series: importanceSeries,
                    }}
                  />
                </Paper>
              </Grid>*/}
                <ReportChartWidget
                  name='Concern By Device Type'
                  seriesData={deviceTypeSeries}
                  colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
                  type='stack'
                  categories={deviceTypes}
                  initialWidth={6}
                  loading={this.state.loading}
                ></ReportChartWidget>
                {/*<Grid item xs={12} sm={6}>
                <WidgetTitle title='Concern By Device Type' />
                <Paper className={`${printStyles ? classes.printPaper : classes.paper} ` } elevation={3}>
                  <Chart
                    type='stack'
                    categories={deviceTypes}
                    options={{
                      series: deviceTypeSeries,
                    }}
                  />
                </Paper>
              </Grid>*/}
              </Grid>
              <WidgetTitle title='Concern Summary Table' />
              <Grid container justifyContent='space-between' className={classes.printHide}>
                <Grid item>
                  <TextField
                    variant='outlined'
                    InputProps={{ classes: { root: classes.inlineInput } }}
                    className={classes.searchField}
                    placeholder='Search'
                    hiddenLabel
                    onChange={(event) => {
                      this.setState({ filterStr: event.target.value });
                    }}
                    value={this.state.filterStr}
                  />
                </Grid>
              </Grid>
              <TableWidget
                defaultRows={25}
                rowsPerPageOptions={[5, 25, 100, { value: -1, label: 'All' }]}
                data={tableData}
                header={headerArr}
                orderBy={this.getSort()}
                order={'desc'}
                loading={this.state.loading}
              ></TableWidget>
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //activeDevice: state.optimize.activeDevice,
    auth: state.auth,
    //devices: state.devices.devices,
    loops: state.loops.loops,
    //deviceSummary: state.devices.summary,
    loopSummary: state.loops.summary,
    //deviceCount: state.devices.count,
    currentModule: state.currentModule.currentModule,
    //optimize: state.optimize,
    colorMode: state.colorMode.colorMode,
    reportModule: state.reports.activeTab,
    configModule: state.config.activeTab,
    applications: state.applications,
    importances: state.importances,
    issuetypes: state.issuetypes,
    locations: state.locations,
    loopCount: state.loops.count,
    devicetypes: state.devicetypes,
    sites: state.sites,
    sort: state.sort,
    analysisRange: state.sort.filter.AnalysisRange,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  setLoopsSummaryStatus,
  fetchLoopsSummary,
  fetchLoops,
  updateSort,
  updateFilter,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  deleteDevices,
  deleteLoops,
  clearFilter,
})(withStyles(styles)(ConcernSummaryReport));
