import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper, MenuItem, Checkbox, Typography, Tooltip, IconButton, Popper, ClickAwayListener } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import Presets from '../../../arrays/prioritize/presets';
import InfoPopover from '../../popup/InfoPopover.js';
import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import functions from '../../../functions/functions';
import {
  fetchLoop,
  fetchLoopDevices,
  fetchLoopEvents,
  fetchLoopVariable,
  setTestStatus,
  fetchLoopDiagnostics,
  prioritizeClearActiveVariables,
  updateAnalysisRange,
  updatePreset,
  fetchIssueTypes,
  clearChartSelections,
} from '../../../actions/index';
import { Settings, MoreVert } from '@mui/icons-material';
import ScheduleWidgets from '../reliability/ScheduleWidgets';
import MaintenanceWidgets from '../reliability/MaintenanceWidgets';
import DataWidgets from '../reliability/DataWidgets';
import PrintHeader from '../../headers/PrintHeader';
import AccordionWidgetFieldReport from '../../widgets/AccordionWidgetFieldReport.js';

class FieldWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issues: [],
      issuesCheck: [],
      shutdowns: true,
      events: false,
      scheduleChecked: false,
      scheduleToggle: false,
      maintenanceChecked: false,
      maintenanceToggle: false,
      dataChecked: false,
      dataToggle: false,
      selectMenu: false,
    };
  }
  async componentDidMount() {
    this.props.prioritizeClearActiveVariables();
    //console.log(this.props.test_status);
    //TODO PULL OUT DIAGNOSTICS FOR RENDER ISSUES
    let preset = {
      name: functions.getCookie('presetName'),
      value: functions.getCookie('presetValue'),
    }; // = `preset=${value}`;
    if (preset.name !== '' && preset.value !== '') {
      this.setState({ changes: true });
      await this.props.updatePreset(preset);
      let analysisRangePreset = !Presets[preset.name] ? null : Presets[preset.name].filter.AnalysisRange;
      if (!analysisRangePreset) {
        this.props.fetchLoopDiagnostics(this.props.activeLoop, analysisRangePreset, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      } else {
        this.props.fetchLoopDiagnostics(this.props.activeLoop, null, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      }
      if (!this.props.issuetypes || !this.props.issuetypes.list) {
        console.log('t');
        this.props.fetchIssueTypes();
      }

      //this.multiSelectOnClose();
    } else {
      if (
        !!this.props.activeLoop &&
        (!this.props.test_status ||
          !this.props.test_status['diagnostics'] ||
          !this.props.test_status['diagnostics'][this.props.activeLoop] ||
          !(this.props.test_status['diagnostics'][this.props.activeLoop] === 'loading' || this.props.test_status['diagnostics'][this.props.activeLoop] === 'loaded'))
      ) {
        //console.log(this.props.activeLoop);
        this.props.fetchLoopDiagnostics(this.props.activeLoop, this.props.analysisRange, this.props.dataset);
        this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
      }
    }
  }
  componentDidUpdate() {
    if (
      !!this.props.activeLoop &&
      !!this.props.loops &&
      (!this.props.test_status ||
        !this.props.test_status['diagnostics'] ||
        !this.props.test_status['diagnostics'][this.props.activeLoop] ||
        !(
          this.props.test_status['diagnostics'][this.props.activeLoop] === 'loading' ||
          (this.props.test_status['diagnostics'][this.props.activeLoop] === 'loaded' && typeof this.props.loops[this.props.activeLoop].diagnostics !== 'undefined')
        ))
    ) {
      this.props.fetchLoopDiagnostics(this.props.activeLoop, this.props.analysisRange, this.props.dataset);
      this.props.setTestStatus('diagnostics', this.props.activeLoop, 'loading');
    }
  }
  handleScheduleCheck = () => {
    this.setState({
      scheduleChecked: !this.state.scheduleChecked,
    });
  };
  handleMaintenanceCheck = () => {
    this.setState({
      maintenanceChecked: !this.state.maintenanceChecked,
    });
  };
  handleDataCheck = () => {
    this.setState({
      dataChecked: !this.state.dataChecked,
    });
  };
  renderScheduleWidgets() {
    const { classes } = this.props;
    if (this.state.scheduleToggle) {
      return (
        <div className={classes.pageBreak}>
          <ScheduleWidgets toggledWidget></ScheduleWidgets>
        </div>
      );
    }
  }
  renderMaintenanceWidgets() {
    const { classes } = this.props;
    if (this.state.maintenanceToggle) {
      return (
        <div className={classes.pageBreak}>
          <MaintenanceWidgets toggledWidget></MaintenanceWidgets>
        </div>
      );
    }
  }
  renderDataWidgets() {
    const { classes } = this.props;
    if (this.state.dataToggle) {
      return (
        <div className={classes.pageBreak}>
          <DataWidgets toggledWidget></DataWidgets>
        </div>
      );
    }
  }
  render() {
    const eventList = [];
    const eventKeys = Object.keys(this.props.events);
    const loop = this.props.loops[this.props.activeLoop];
    for (let i = 0; i < eventKeys.length; i++) {
      let eventName = this.props.events[eventKeys[i]].eventName;
      if (!eventList.includes(eventName)) {
        eventList.push(eventName);
      }
    }
    const { classes } = this.props;
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, scheduleToggle: this.state.scheduleChecked, maintenanceToggle: this.state.maintenanceChecked, dataToggle: this.state.dataChecked });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
        <Grid container justifyContent='space-between' style={{ marginBottom: -42 }} className={classes.printHide}>
          <Grid item></Grid>
          <Grid item>
            <Grid container spacing={1}>
              {!printStyles ? (
                <Grid item>
                  <InfoPopover
                    center
                    tooltip='Configure'
                    title='Configure'
                    titleIcon='Settings'
                    resize
                    style={{ marginBottom: -15 }}
                    icon={<Settings />}
                    type='prioritizeConfig'
                    usePrioritizeConfigTabs
                    loop={loop}
                    devices={['1', '2', '3']}
                  />
                </Grid>
              ) : null}
              <Grid item>
                <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                  <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                    <MoreVert />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
          <ClickAwayListener onClickAway={handleClose}>
            <Paper>
              <Typography variant='caption' style={{ padding: '10px 12px 6px 14px', display: 'block' }}>
                Select to show more info
              </Typography>
              <MenuItem onClick={this.handleMaintenanceCheck}>
                <Checkbox className={classes.menuCheckbox} checked={this.state.maintenanceChecked} color='secondary' />
                Maintenance
              </MenuItem>
              <MenuItem onClick={this.handleScheduleCheck}>
                <Checkbox className={classes.menuCheckbox} checked={this.state.scheduleChecked} color='secondary' />
                Schedule
              </MenuItem>
              <MenuItem onClick={this.handleDataCheck}>
                <Checkbox className={classes.menuCheckbox} checked={this.state.dataChecked} color='secondary' />
                Data & Alarms
              </MenuItem>
              <Grid container justifyContent='space-between'>
                <Grid item></Grid>
                <Grid item>
                  <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </ClickAwayListener>
        </Popper>
        <WidgetTitle className={classes.printHide} title={`Field Analysis - ${!loop || !loop.processName ? 'Loop Name Not Found' : loop.processName}`} />
        <AccordionWidgetFieldReport></AccordionWidgetFieldReport>
        {this.renderMaintenanceWidgets()}
        {this.renderScheduleWidgets()}
        {this.renderDataWidgets()}
        {!this.props.toggledWidget ? (
          <Grid className={classes.printHide} style={{ marginTop: 4 }}>
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                  <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
                    <MoreVert className={classes.buttonIcon}></MoreVert>Show More
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}
FieldWidgets.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeLoop: state.reliability.activeLoop,
    events: state.events,
    format: state.format.format,
    loops: state.loops.loops,
    test_status: state.tests.status,
    analysisRange: state.sort.filter.AnalysisRange,
    dataset: state.sort.filter.Dataset,
    issuetypes: state.issuetypes,
    chartSelections: state.chartSelections.selections,
  };
};

export default connect(mapStateToProps, {
  fetchLoop,
  fetchLoopEvents,
  fetchLoopDevices,
  fetchLoopVariable,
  setTestStatus,
  fetchLoopDiagnostics,
  prioritizeClearActiveVariables,
  updateAnalysisRange,
  updatePreset,
  fetchIssueTypes,
  clearChartSelections,
})(withStyles(styles)(FieldWidgets));
