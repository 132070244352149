import React from 'react';
import { styles } from '../styles';
import withStyles from '@mui/styles/withStyles';
import DiagnosticIcon from '../helpers/DiagnosticIcon';
import { IconButton, Grid, Tooltip, Typography, Paper, Dialog, DialogContent, DialogTitle, ToggleButton, ToggleButtonGroup, Button } from '@mui/material';
import { Info, Close, Fullscreen, FullscreenExit } from '@mui/icons-material';
import Draggable from 'react-draggable';
import PerformanceDetails from '../helpers/PerformanceDetails';
import DiagnosticDetailsDynamic from '../helpers/DiagnosticDetailsDynamic';
import DiagnosticGraphDetails from '../helpers/DiagnosticGraphDetails';
import PrioritizeDetails from '../helpers/PrioritizeDetails';
import ConfigDetails from '../helpers/ConfigDetails';
import PrioritizeConfigDetails from '../helpers/PrioritizeConfigDetails';
import prioritizeDetails from '../../arrays/prioritize/prioritizeDetails';
import diagnosticsDetails from '../../arrays/prioritize/prioritizeDetails';
import diagnosticsGraphDetails from '../../arrays/prioritize/prioritizeDetails';
import investigateDetails from '../../arrays/prioritize/prioritizeDetails';
import configDetails from '../../arrays/optimize/configDetails';
import performanceDetails from '../../arrays/prioritize/prioritizeDetails';

function PaperComponent(props) {
  const handleClick = (event) => {
    event.preventDefault();
  };
  if (window.innerWidth > 960) {
    return (
      <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper onClick={handleClick} {...props} />
      </Draggable>
    );
  } else {
    return <Paper onClick={handleClick} {...props} />;
  }
}
const InfoPopover = (props) => {
  const { classes } = props;
  const [open, setOpen] = React.useState(false);
  const [resize, setResize] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(props.type === 'prioritizeConfig' ? 'device' : props.defaultTab === 'investigate' ? 'investigate' : 'intro');
  const handleClickOpen = (event) => {
    event.preventDefault();
    if (!!props.onClick) {
      props.onClick();
    }
    setOpen(true);
  };
  const handleClose = (event) => {
    event.preventDefault();
    if (!!props.closeFunction) {
      props.closeFunction();
    }
    setOpen(false);
  };
  const handleResize = (event) => {
    event.preventDefault();
    setResize(!resize);
  };
  const handleTab = (event, value) => {
    event.preventDefault();
    if (value !== null) {
      setActiveTab(value);
    }
  };
  const processIssueName = (issueName) => {
    switch (issueName) {
      case 'OscillationAmplitude':
        return 'OscillationAmp';
      case 'SaturatedDevice':
        return 'Saturation';
      case 'SaturatedValve':
        return 'Saturation';
      case 'RedundantError':
        return 'RedundantErr';
      case 'RedundantNoise':
        return 'RedundantErr';
      case 'LoopError':
        return 'LoopErr';
      case 'LowDataResolution':
        return 'Quantization';
      case 'StuckDeviceValues':
        return 'Stuck';
      case 'DataValidity':
        return 'Validity';
      case 'Downtime':
        return 'Downtime';
      default:
        return issueName;
    }
  };
  const renderContent = () => {
    if (props.type === 'performance') {
      return <PerformanceDetails id={props.id} activeTab={activeTab} colorValue={props.colorValue} alignment={props.alignment} resize={resize}></PerformanceDetails>;
    } else if (props.type === 'diagnostics') {
      return (
        <DiagnosticDetailsDynamic
          id={props.id}
          alignment={props.alignment}
          activeTab={activeTab}
          colorValue={props.colorValue}
          issue={props.diagnosticIssue}
          showAll={props.showAll}
          resize={resize}
        ></DiagnosticDetailsDynamic>
      );
    } else if (props.type === 'diagnosticsGraph') {
      return <DiagnosticGraphDetails id={props.id} activeTab={activeTab} resize={resize}></DiagnosticGraphDetails>;
    } else if (props.type === 'prioritize') {
      let issueName = !props || !props.id ? '' : !props.id.issue ? props.id : props.id.issue.replaceAll(' ', '');
      issueName = processIssueName(issueName);
      return <PrioritizeDetails id={issueName} activeTab={activeTab} colorValue={props.colorValue} resize={resize}></PrioritizeDetails>;
    } else if (props.type === 'config') {
      return <ConfigDetails id={props.id} activeTab={activeTab}></ConfigDetails>;
    } else if (props.type === 'prioritizeConfig') {
      return <PrioritizeConfigDetails loop={props.loop} devices={props.devices} activeTab={activeTab}></PrioritizeConfigDetails>;
    } else {
      return props.content;
    }
  };
  const id = open ? 'simple-popover' : undefined;
  const details =
    props.type === 'performance'
      ? performanceDetails[props.id]
      : props.type === 'diagnostics'
      ? diagnosticsDetails[props.id]
      : props.type === 'diagnosticsGraph'
      ? diagnosticsGraphDetails[props.id]
      : props.type === 'prioritize'
      ? prioritizeDetails[props.id]
      : props.type === 'config'
      ? configDetails[props.id]
      : props.type === 'investigate'
      ? investigateDetails[props.id]
      : null;
  //let introDisable = !details ? false : !details.description;
  //TODO remove hardcoding
  let recDisable = props.title === 'Data Validity' || props.title === 'Validity' ? true : false;
  let recSpecificDisable = !details ? false : !details.recommendationsSpecific;
  let issuesDisable = !details ? false : !details.issues;
  let examplesDisable = !details ? false : !details.examples;
  //let investigateDisable = !details ? false : !details.investigate;
  //let importanceDisable = !details ? false : !details.importance;
  return (
    <React.Fragment>
      {!props.button ? (
        <Tooltip
          placement={!props.placement ? 'top' : props.placement}
          title={!!props.tooltip ? props.tooltip : !props.icon ? 'Click for More Information' : ''}
          aria-label={!!props.tooltip ? props.tooltip : !props.icon ? 'Click for More Information' : ''}
          arrow
        >
          <IconButton
            className={classes.checklistInfo}
            style={
              props.disableHover
                ? {
                    backgroundColor: 'transparent',
                  }
                : null
            }
            color='inherit'
            aria-label='information'
            onClick={handleClickOpen}
            size='large'
          >
            {!props.icon ? <Info fontSize='large' className={!props.appBar ? classes.checklistInfoIcon : classes.checklistInfoIconAppBar} /> : props.icon}
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant='outlined' size='medium' color='primary' onClick={handleClickOpen}>
          {props.icon}
          {props.tooltip}
        </Button>
      )}
      <Dialog
        id={id}
        open={open}
        onClose={handleClose}
        classes={{ paper: !resize ? null : classes.popoverResize }}
        scroll='paper'
        PaperComponent={PaperComponent}
        PaperProps={{
          classes: { root: props.center ? classes.centerDialog : null },
        }}
        maxWidth={!props.maxWidth ? 'md' : props.maxWidth}
        aria-labelledby='draggable-dialog-title'
      >
        <DialogTitle id='draggable-dialog-title' className={classes.dialogTitle}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Grid container>
                <Grid item>
                  <DiagnosticIcon icon={props.titleIcon}></DiagnosticIcon>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1' style={{ marginTop: 2 }}>
                    {!props.title ? 'Title Not Found' : props.title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {props.resize ? (
                <Tooltip arrow placement='top' className={classes.smHide} title={!resize ? 'Expand' : 'Collapse'} aria-label={!resize ? 'Expand' : 'Collapse'}>
                  <IconButton className={classes.textColor} onClick={handleResize} size='large'>
                    {!resize ? <Fullscreen></Fullscreen> : <FullscreenExit></FullscreenExit>}
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip arrow placement='top' title='Close' aria-label='Close'>
                <IconButton className={classes.textColor} onClick={handleClose} size='large'>
                  <Close></Close>
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        {!props.useOptimizeTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>
              <span className={classes.abbreviateMin}>Intro</span>
              <span className={classes.abbreviateMax}>Introduction</span>
            </ToggleButton>
            <ToggleButton value='recommendations' disabled={recDisable}>
              <span className={classes.abbreviateMin}>Rec</span>
              <span className={classes.abbreviateMax}>Recommendations</span>
            </ToggleButton>
            <ToggleButton value='investigate' disabled={recDisable}>
              Investigate
            </ToggleButton>
            <ToggleButton value='examples' disabled={examplesDisable}>
              Examples
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.usePerformanceTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>
              <span className={classes.abbreviateMin}>Intro</span>
              <span className={classes.abbreviateMax}>Introduction</span>
            </ToggleButton>
            <ToggleButton value='investigate' disabled={recDisable}>
              Investigate
            </ToggleButton>
            <ToggleButton value='examples' disabled={examplesDisable}>
              Examples
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.useOptimizeGraphTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>
              <span className={classes.abbreviateMin}>Intro</span>
              <span className={classes.abbreviateMax}>Introduction</span>
            </ToggleButton>
            <ToggleButton value='investigate' disabled={recDisable}>
              Investigate
            </ToggleButton>
            <ToggleButton value='issues' disabled={issuesDisable}>
              <span className={classes.abbreviateMin}>Issues</span>
              <span className={classes.abbreviateMax}>Related Issues</span>
            </ToggleButton>
            <ToggleButton value='examples' disabled={examplesDisable}>
              Examples
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.usePrioritizeTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>
              <span className={classes.abbreviateMin}>Intro</span>
              <span className={classes.abbreviateMax}>Introduction</span>
            </ToggleButton>
            <ToggleButton value='recommendations' disabled={recDisable}>
              <span className={classes.abbreviateMin}>General Rec</span>
              <span className={classes.abbreviateMax}>General Recommendations</span>
            </ToggleButton>
            <ToggleButton value='recommendationsSpecific' disabled={recSpecificDisable}>
              <span className={classes.abbreviateMin}>{!props.title ? 'Specific Rec' : props.title + ' Rec'}</span>
              <span className={classes.abbreviateMax}>{!props.title ? 'Specific Recommendations' : props.title + ' Recommendations'}</span>
            </ToggleButton>
            <ToggleButton value='examples' disabled={examplesDisable}>
              Examples
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.useConfigTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>
              <span className={classes.abbreviateMin}>Intro</span>
              <span className={classes.abbreviateMax}>Introduction</span>
            </ToggleButton>
            <ToggleButton value='importance'>Importance</ToggleButton>
            <ToggleButton value='recommendations' disabled={recDisable}>
              <span className={classes.abbreviateMin}>Rec</span>
              <span className={classes.abbreviateMax}>Recommendations</span>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.usePrioritizeConfigTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='device'>Devices</ToggleButton>
            <ToggleButton value='loop'>Loop</ToggleButton>
          </ToggleButtonGroup>
        )}
        {!props.useInvestigateTabs ? null : (
          <ToggleButtonGroup value={activeTab} exclusive onChange={(event, value) => handleTab(event, value)} className={classes.dialogToggleButtons}>
            <ToggleButton value='intro'>Investigate</ToggleButton>
            <ToggleButton value='examples' disabled={examplesDisable}>
              Examples
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <DialogContent style={{ paddingTop: 20 }}>{renderContent()}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default withStyles(styles)(InfoPopover);
