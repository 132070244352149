import React from 'react';
import {
  Button,
  Grid,
  //TextField,
  Typography,
  //IconButton,
} from '@mui/material';
import CustomSelect from '../../../filters/CustomSelect';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import api from '../../../../apis/api';

class OptimizeTestMerge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceTest: -1,
      targetTest: -1,
      status: '',
      statusMessage: '',
      loading: false,
    };
  }

  handleRowSubmit = async (event) => {
    event.preventDefault();
    try {
      const device = this.props.devices[this.props.activeDevice];
      const testOptions = !!device && !!device.datetimeHistory ? this.processTimestampArray(device.datetimeHistory, device.stateHistory) : [];
      const sourceTestIndex = testOptions.indexOf(this.state.sourceTest);
      const targetTestIndex = testOptions.indexOf(this.state.targetTest);
      const sourceTest = device.testIdHistory[sourceTestIndex];
      const targetTest = device.testIdHistory[targetTestIndex];
      const valveId = device.valveTestIdHistory[targetTestIndex];
      const url = `optimize/devices/${this.props.activeDevice}/test/merge`;
      this.setState({
        status: 'Success',
        statusMessage: 'Processing',
        loading: true,
      });
      //console.log(this.props.row.id);
      //console.log(this.state.rowChange);
      console.log({ sourceTest, targetTest, valveId });
      await api.post(url, { sourceTest, targetTest, valveId });

      this.setState({
        status: 'Success',
        statusMessage: 'Changes Saved',
        loading: false,
      });
      window.location.reload();
    } catch (err) {
      this.setState({
        status: 'Failure',
        statusMessage: 'Failure!',
        loading: false,
      });
      console.log(err);
    }
  };

  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }

  processTimestampArray(arr, stateArr) {
    let result = [];
    for (let i = 0; i < arr.length; i++) {
      const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();

      result.push(`${timestamp}${this.processState(stateArr[i])}`);
    }
    return result;
  }

  getSelectedTestTypes(index) {
    const device = this.props.devices[this.props.activeDevice];
    let str = '';
    if (device.resolutionIdHistory[index] !== 'NULL') {
      str += 'Resolution';
    }
    if (device.sensitivityIdHistory[index] !== 'NULL') {
      if (str !== '') {
        str += ', ';
      }
      str += 'Sensitivity';
    }
    if (device.strokeIdHistory[index] !== 'NULL') {
      if (str !== '') {
        str += ', ';
      }
      str += 'Stroke';
    }
    if (device.profileIdHistory[index] !== 'NULL') {
      if (str !== '') {
        str += ', ';
      }
      str += 'Profile';
    }
    if (str !== '') {
      str += ' found. ';
    } else {
      str = 'No Tests Found.';
    }
    return str;
  }

  dateDifference(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  render() {
    const { classes } = this.props;
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : [];
    return (
      <React.Fragment>
        {/*<Collapse
          in={
            this.props.rowOpen &&
            (this.props.clickedRow === null ||
              this.props.clickedRow.toString() === row.id.toString()) //type checking
          }
          unmountOnExit={true}
        >*/}
        <div className={!this.props.padding ? null : classes.collapseBody}>
          <form
            onSubmit={(e) => {
              //console.log('hi');
              //this.handleRowSubmit(e);
            }}
          >
            <Typography variant='subtitle1' color='primary' style={{ marginBottom: 10 }}>
              Please Select two tests to merge
            </Typography>
            <CustomSelect
              single
              id={null}
              label='Source Test'
              type='test'
              name='startTest'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={(event, value) => {
                if (value === 'Select a Test') {
                  this.setState({ sourceTest: -1 });
                } else {
                  this.setState({ sourceTest: value });
                }
              }}
              values={this.state.sourceTest === -1 ? 'Select a Test' : this.state.sourceTest}
              options={['Select a Test'].concat(testOptions)}
              //value={null}
              helperText={null}
            />

            {this.state.sourceTest !== -1 ? (
              <Typography
              //variant='subtitle1'
              //color={color}
              //style={{ marginLeft: 5, marginTop: 5 }}
              >
                {this.getSelectedTestTypes(testOptions.indexOf(this.state.sourceTest))}
              </Typography>
            ) : null}
            <br />
            <CustomSelect
              single
              id={null}
              label='Target Test'
              type='test'
              name='targetTest'
              disabled={this.state.loading}
              //onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={(event, value) => {
                if (value === 'Select a Test') {
                  this.setState({ targetTest: -1 });
                } else {
                  this.setState({ targetTest: value });
                }
              }}
              values={this.state.targetTest === -1 ? 'Select a Test' : this.state.targetTest}
              options={['Select a Test'].concat(testOptions)}
              //value={null}
              helperText={null}
            />

            {this.state.targetTest !== -1 ? (
              <Typography
              //variant='subtitle1'
              //color={color}
              //style={{ marginLeft: 5, marginTop: 5 }}
              >
                {this.getSelectedTestTypes(testOptions.indexOf(this.state.targetTest))}
              </Typography>
            ) : null}
            <br />
            <div className={classes.dividerMd}></div>

            <Grid container spacing={2}>
              <Grid item>
                <Button
                  disabled={
                    this.dateDifference(this.state.sourceTest, this.state.targetTest) >= 8 ||
                    this.state.sourceTest === -1 ||
                    this.state.targetTest === -1 ||
                    this.state.sourceTest === this.state.targetTest
                  }
                  onClick={(e) => {
                    this.handleRowSubmit(e);
                  }}
                  variant='outlined'
                  size='medium'
                  color='primary'
                  type='submit'
                  value='Submit'
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                {this.state.sourceTest === -1 || this.state.targetTest === -1 ? (
                  <Typography variant='subtitle1' color={'error'} style={{ marginTop: 5 }}>{`Please Select a source test and a target test.`}</Typography>
                ) : null}
              </Grid>
              <Grid item>
                {this.state.sourceTest === this.state.targetTest && this.state.sourceTest !== -1 ? (
                  <Typography
                    variant='subtitle1'
                    color={'error'}
                    //style={{ marginLeft: 20, marginTop: 5 }}
                  >{`Please ensure source test and target test are different.`}</Typography>
                ) : null}
              </Grid>
              <Grid item>
                {this.dateDifference(this.state.sourceTest, this.state.targetTest) >= 8 && this.state.sourceTest !== -1 && this.state.targetTest !== -1 ? (
                  <Typography
                    variant='subtitle1'
                    color={'error'}
                    //style={{ marginLeft: 20, marginTop: 5 }}
                  >{`Please ensure source test and target test less than a week apart.`}</Typography>
                ) : null}
              </Grid>
              <Grid item>{this.state.status !== '' ? <Typography variant='subtitle1' color={color} style={{ marginLeft: 20, marginTop: 5 }}>{`${this.state.statusMessage}`}</Typography> : null}</Grid>
            </Grid>
          </form>
        </div>
        {/*</Collapse>*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(OptimizeTestMerge));
