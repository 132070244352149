import validityEx1 from '../../images/screenshots_helpers/validityEx1.JPG';
import validityEx2 from '../../images/screenshots_helpers/validityEx2.JPG';
import validityEx3 from '../../images/screenshots_helpers/validityEx3.JPG';
import validityEx4 from '../../images/screenshots_helpers/validityEx4.jpg';
import validityEx5 from '../../images/screenshots_helpers/validityEx5.jpg';
import stuckEx1 from '../../images/screenshots_helpers/stuckEx1.JPG';
import stuckEx2 from '../../images/screenshots_helpers/stuckEx2.JPG';
import stuckEx3 from '../../images/screenshots_helpers/stuckEx3.JPG';
import quantizationEx1 from '../../images/screenshots_helpers/quantizationEx1.JPG';
import quantizationEx2 from '../../images/screenshots_helpers/quantizationEx2.JPG';
import saturationEx1 from '../../images/screenshots_helpers/saturationEx1.JPG';
import saturationEx2 from '../../images/screenshots_helpers/saturationEx2.JPG';
import saturationEx3 from '../../images/screenshots_helpers/saturationEx3.JPG';
import redundantErrEx1 from '../../images/screenshots_helpers/redundantErrEx1.JPG';
import redundantErrEx2 from '../../images/screenshots_helpers/redundantErrEx2.JPG';
import redundantErrEx3 from '../../images/screenshots_helpers/redundantErrEx3.JPG';
import redundantErrEx4 from '../../images/screenshots_helpers/redundantErrEx4.JPG';
import loopErrEx1 from '../../images/screenshots_helpers/loopErrEx1.JPG';
import loopErrEx2 from '../../images/screenshots_helpers/loopErrEx2.JPG';
import loopErrEx3 from '../../images/screenshots_helpers/loopErrEx3.JPG';
import loopErrEx4 from '../../images/screenshots_helpers/loopErrEx4.JPG';
import controlRangeEx1 from '../../images/screenshots_helpers/controlRangeEx1.JPG';
import controlRangeEx2 from '../../images/screenshots_helpers/controlRangeEx2.JPG';
import controlRangeEx3 from '../../images/screenshots_helpers/controlRangeEx3.JPG';
import controlRangeEx4 from '../../images/screenshots_helpers/controlRangeEx4.jpg';
import trendChangeEx1 from '../../images/screenshots_helpers/trendChangeEx1.JPG';
import trendChangeEx2 from '../../images/screenshots_helpers/trendChangeEx2.JPG';
import trendChangeEx3 from '../../images/screenshots_helpers/trendChangeEx3.JPG';
import trendChangeEx4 from '../../images/screenshots_helpers/trendChangeEx4.JPG';
import trendChangeEx5 from '../../images/screenshots_helpers/trendChangeEx5.jpg';
import trendChangeEx6 from '../../images/screenshots_helpers/trendChangeEx6.jpg';
import stabilityEx1 from '../../images/screenshots_helpers/trendChangeEx2.JPG';
import stabilityEx2 from '../../images/screenshots_helpers/stabilityEx2.jpg';
import stabilityEx3 from '../../images/screenshots_helpers/stabilityEx3.jpg';
import volatilityEx1 from '../../images/screenshots_helpers/volatilityEx1.JPG';
import volatilityEx2 from '../../images/screenshots_helpers/volatilityEx2.JPG';
import volatilityEx3 from '../../images/screenshots_helpers/volatilityEx3.JPG';
import volatilityEx4 from '../../images/screenshots_helpers/volatilityEx4.jpg';
import oscillationAmpEx1 from '../../images/screenshots_helpers/oscillationAmpEx1.jpg';
import oscillationAmpEx2 from '../../images/screenshots_helpers/oscillationAmpEx2.JPG';
import oscillationAmpEx3 from '../../images/screenshots_helpers/oscillationAmpEx3.JPG';
import oscillationAmpEx4 from '../../images/screenshots_helpers/oscillationAmpEx4.JPG';
import oscillationAmpEx5 from '../../images/screenshots_helpers/oscillationAmpEx5.jpg';
import oscillationAmpEx6 from '../../images/screenshots_helpers/oscillationAmpEx6.jpg';
import oscillationAmpEx7 from '../../images/screenshots_helpers/oscillationAmpEx7.jpg';
import signalInconsistencyEx1 from '../../images/screenshots_helpers/signalInconsistencyEx1.JPG';
import signalInconsistencyEx2 from '../../images/screenshots_helpers/signalInconsistencyEx2.JPG';
import signalAbnormalityEx1 from '../../images/screenshots_helpers/signalAbnormalityEx1.JPG';
import signalAbnormalityEx2 from '../../images/screenshots_helpers/signalAbnormalityEx2.JPG';
import concernScoreEx1 from '../../images/screenshots_helpers/concernScoreEx1.JPG';
import concernScoreEx2 from '../../images/screenshots_helpers/concernScoreEx2.JPG';
import concernScoreEx3 from '../../images/screenshots_helpers/concernScoreEx3.JPG';
import concern from '../../images/screenshots_helpers/concern.jpg';
import concernShift from '../../images/screenshots_helpers/concernShift.jpg';
import concernMax from '../../images/screenshots_helpers/concernMax.jpg';
import downtimeEx1 from '../../images/screenshots_helpers/downtimeEx1.jpg';
import downtimeEx2 from '../../images/screenshots_helpers/downtimeEx2.jpg';

const imagesArray = {
  validityEx1,
  validityEx2,
  validityEx3,
  validityEx4,
  validityEx5,
  stuckEx1,
  stuckEx2,
  stuckEx3,
  quantizationEx1,
  quantizationEx2,
  saturationEx1,
  saturationEx2,
  saturationEx3,
  redundantErrEx1,
  redundantErrEx2,
  redundantErrEx3,
  redundantErrEx4,
  loopErrEx1,
  loopErrEx2,
  loopErrEx3,
  loopErrEx4,
  controlRangeEx1,
  controlRangeEx2,
  controlRangeEx3,
  controlRangeEx4,
  trendChangeEx1,
  trendChangeEx2,
  trendChangeEx3,
  trendChangeEx4,
  trendChangeEx5,
  trendChangeEx6,
  stabilityEx1,
  stabilityEx2,
  stabilityEx3,
  volatilityEx1,
  volatilityEx2,
  volatilityEx3,
  volatilityEx4,
  oscillationAmpEx1,
  oscillationAmpEx2,
  oscillationAmpEx3,
  oscillationAmpEx4,
  oscillationAmpEx5,
  oscillationAmpEx6,
  oscillationAmpEx7,
  signalInconsistencyEx1,
  signalInconsistencyEx2,
  signalAbnormalityEx1,
  signalAbnormalityEx2,
  concernScoreEx1,
  concernScoreEx2,
  concernScoreEx3,
  concern,
  concernShift,
  concernMax,
  downtimeEx1,
  downtimeEx2,
};

export default imagesArray;
