import React from 'react';
import api from '../../apis/api';
import { client2 } from '../../apis/api';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import Dropzone from './Dropzone';
import Progress from './Progress';
import WidgetTitle from '../headers/WidgetTitle';
import ConfirmationTable from './ConfirmationTable';
import { Button, Grid, Typography, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { AddCircle, Cancel, CheckCircle, CloudCircle, CloudUpload, DeleteSweep, Delete, Error } from '@mui/icons-material';
import InfoPopover from '../popup/InfoPopover';
import UploadDetails from '../helpers/UploadDetails';
import functions from '../../functions/functions';
//import FastFieldImage from '../test/FastFieldImage';
//import { objectEach } from 'highcharts';

let responseCount = 0;
let responseTotal = 0;
let confirmationLoading = false;
let confirmationLoadingCount = 0;
class OptimizeUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      files: [],
      tests: {},
      confirmedTests: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      cleared: false,
      matches: [
        {
          name: '_Baseline_data',
          type: 'text/xml',
          nameMatch: '_Baseline_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_RES_data',
          type: 'text/xml',
          nameMatch: '_RES_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_SENS_data',
          type: 'text/xml',
          nameMatch: '_SENS_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_SR_data',
          type: 'text/xml',
          nameMatch: '_SR_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_SU_data',
          type: 'text/xml',
          nameMatch: '_SU_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_Stroking_data',
          type: 'text/xml',
          nameMatch: '_Stroking_Sub_0_data',
          typeMatch: 'text/xml',
        },
        {
          name: '_HDRL_data',
          type: 'text/xml',
          nameMatch: '_HDRL_Sub_0_data',
          typeMatch: 'text/xml',
        },
      ],
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.analyzeReponse = this.analyzeReponse.bind(this);
    this.processFiles = this.processFiles.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        {!this.state.confirmedTests || this.state.confirmedTests.length === 0 ? null : (
          <ConfirmationTable
            refreshFunction={() => {
              this.retrieveConfirmation(this.state.tests);
            }}
            data={this.state.confirmedTests}
          />
        )}
        <WidgetTitle
          title={
            <React.Fragment>
              <Grid container>
                <Grid item style={{ marginTop: -2 }}>
                  <InfoPopover
                    resize
                    title='I’ve done all my testing. How do I get my valve tests on to
              Optimize?'
                    content={<UploadDetails />}
                  />
                </Grid>
                <Grid item>Click to learn how to upload, or visit the FAQ</Grid>
                {/*<FastFieldImage file='227667_ef59041b-0bd6-40bb-a433-63a42d5fe2f8_894e18ff-ab18-4454-89b2-b0604f17df9b.jpeg' />*/}
              </Grid>
            </React.Fragment>
          }
        />
        <Grid container spacing={2}>
          <Grid item>
            <Dropzone id='optimize_upload_files_id' onFilesAdded={this.onFilesAdded} disabled={this.state.uploading || this.state.successfullUploaded} />
          </Grid>
          <Grid item>{this.renderActions()}</Grid>
          <Grid item>
            {this.state.uploading && !this.state.successfullUploaded ? (
              <Typography style={{ marginTop: 14 }}>
                Uploading tests... during the process please do not leave this page. Closing or leaving the page will result in tests failing to upload.
              </Typography>
            ) : null}
            {this.state.cleared && this.state.files.length === 0 ? <Typography style={{ marginTop: 14 }}>Files Cleared. </Typography> : null}
            {this.state.files.length > 0 && !this.state.uploading && !this.state.successfullUploaded && !this.state.error ? (
              <Typography style={{ marginTop: 14 }}>Click the upload button to begin the upload process. </Typography>
            ) : null}
          </Grid>
        </Grid>
        {this.state.files.map((file) => {
          return (
            <Grid key={file.name} container className={classes.uploadProgressWrapper}>
              <Grid item>{this.renderProgressCheck(file)}</Grid>
              <Grid item>
                <Delete
                  className={classes.uploadIcon}
                  onClick={(e) => {
                    this.removeFile(file);
                    this.processFiles();
                  }}
                />
              </Grid>
              <Grid item xs={8} key={file.name}>
                <Typography variant='subtitle2'>
                  {file.name}
                  {
                    //Message rendering if one exists
                    !!this.state.uploadProgress[file.name] && !!this.state.uploadProgress[file.name].message ? (
                      <i> - {this.state.uploadProgress[file.name].message}</i>
                    ) : //console.log(this.state.uploadProgress[file.name])
                    null
                  }
                </Typography>
                {this.renderProgress(file)}
              </Grid>
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    //if (this.state.uploading || this.state.successfullUploaded) {
    return (
      <React.Fragment>
        <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
      </React.Fragment>
    );
  }

  renderProgressCheck(file) {
    const { classes } = this.props;
    const uploadProgress = this.state.uploadProgress[file.name];
    if (!!uploadProgress && uploadProgress.state === 'error') {
      return (
        <React.Fragment>
          <Error className={`${classes.redText} ${classes.uploadProgressCheck}`}></Error>
        </React.Fragment>
      );
    }
    if (!!uploadProgress && uploadProgress.state === 'advisory') {
      return (
        <React.Fragment>
          <Error className={`${classes.grayText} ${classes.uploadProgressCheck}`}></Error>
        </React.Fragment>
      );
    } else if (this.state.uploading) {
      return (
        <React.Fragment>
          <CircularProgress className={classes.uploadProgressLoad} size={20} color='secondary' />
        </React.Fragment>
      );
    } else if (this.state.successfullUploaded) {
      return (
        <React.Fragment>
          <CloudCircle className={`${classes.blueText} ${classes.uploadProgressCheck}`}></CloudCircle>
        </React.Fragment>
      );
    } else if (this.state.successfullUploaded === 'false') {
      return (
        <React.Fragment>
          <Cancel className={`${classes.grayText} ${classes.uploadProgressCheck}`}></Cancel>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {uploadProgress && uploadProgress.state === 'done' ? (
            <CheckCircle className={`${classes.blueText} ${classes.uploadProgressCheck}`}></CheckCircle>
          ) : (
            <AddCircle className={`${classes.grayText} ${classes.uploadProgressCheck}`}></AddCircle>
          )}
        </React.Fragment>
      );
    }
  }
  appendFileMatch(file, name, type, pairedName, pairedType, issues) {
    /*let files = this.state.files;
    let currentIssues = issues;
    if (file.name.includes(name) && file.type === type) {
      let paired = false;
      for (let k = 0; k < files.length; k++) {
        if (files[k].name.includes(pairedName) && files[k].type === pairedType) {
          paired = true;
        }
      }
      if (!paired) {
        let message = `${!currentIssues[file.name] ? '' : `${currentIssues[file.name].message}, `} ${file.name} requires an additional file matching the following name structure XXXX_${pairedName}`;
        currentIssues[file.name] = {
          state: 'error',
          percentage: 0,
          message: message,
        };
      }
    }*/
    let files = this.state.files;
    let currentIssues = issues;
    let fileSplit = file.name.split(name);
    if (file.name.includes(name) && file.type === type) {
      let paired = false;
      for (let k = 0; k < files.length; k++) {
        let matchFile = files[k];
        let matchFileSplit = matchFile.name.split(pairedName);
        //console.log(fileSplit, matchFileSplit);
        if (matchFileSplit.length > 1 && fileSplit[1] === matchFileSplit[1] && files[k].type === pairedType) {
          paired = true;
        }
      }
      if (!paired) {
        let message = `${!currentIssues[file.name] ? '' : `${currentIssues[file.name].message}, `} ${file.name} requires an additional file matching the following name structure XXXX_${pairedName}${
          !fileSplit[1] ? `` : fileSplit[1]
        }`;
        currentIssues[file.name] = {
          state: 'error',
          percentage: 0,
          message: message,
        };
      }
    }
    return currentIssues;
  }
  checkFileMatch(file, name, type, pairedName, pairedType, issues) {
    //console.log(file, name, type, pairedName, pairedType, issues);
    let files = this.state.files;
    let currentIssues = issues;
    let fileSplit = file.name.split(name);
    if (file.name.includes(name) && file.type === type) {
      let paired = false;
      for (let k = 0; k < files.length; k++) {
        let matchFile = files[k];
        let matchFileSplit = matchFile.name.split(pairedName);
        //console.log(fileSplit, matchFileSplit);
        if (matchFileSplit.length > 1 && fileSplit[1] === matchFileSplit[1] && files[k].type === pairedType) {
          paired = true;
        }
      }
      if (!paired) {
        let message = `${!currentIssues[file.name] ? '' : `${currentIssues[file.name].message}, `} ${file.name} requires an additional file matching the following name structure XXXX_${pairedName}${
          !fileSplit[1] ? `` : fileSplit[1]
        }`;
        currentIssues[file.name] = {
          state: 'error',
          percentage: 0,
          message: message,
        };
      }
    }
    return currentIssues;
  }
  checkFileDuplicates(files, issues) {
    let currentIssues = issues;
    const counts = {};
    //console.log(files, issues);
    for (let i = 0; i < files.length; i++) {
      //files[i].willTest = 'Test';
      counts[files[i].name] = (counts[files[i].name] || 0) + 1; //1 or increment
    }
    //console.log(files);
    let keys = Object.keys(counts);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (counts[key] > 1) {
        currentIssues[key] = {
          state: 'error',
          percentage: 0,
          message: `Multiple files with the name ${key} detected, please rename or delete`,
        };
      }
    }
    return currentIssues;
  }
  processFiles() {
    let files = this.state.files;
    let matches = this.state.matches;
    let issues = {};
    issues = this.checkFileDuplicates(files, issues);
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      for (let j = 0; j < matches.length; j++) {
        let match = matches[j];
        //check A paired against B and B paired against A
        issues = this.checkFileMatch(file, match.name, match.type, match.nameMatch, match.typeMatch, issues);
        issues = this.checkFileMatch(file, match.nameMatch, match.typeMatch, match.name, match.type, issues);
      }
    }
    if (Object.keys(issues).length !== 0) {
      this.setState({
        error: true,
        uploadProgress: issues,
      });
    } else {
      this.setState({ error: false, uploadProgress: issues });
    }
    return null;
  }
  removeFile(file) {
    let localFiles = this.state.files;

    for (var i = 0; i < localFiles.length; i++) {
      if (localFiles[i] === file) {
        localFiles.splice(i, 1);
      }
    }
    this.setState((prevState) => ({
      files: localFiles,
      error: false,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      cleared: false,
    }));
  }

  async uploadFiles() {
    this.setState({ uploadProgress: {}, uploading: true });
    // const temp = [];
    const promises = [];
    const appended = [];
    let matches = this.state.matches;
    let files = this.state.files;
    //console.log(matches);
    //console.log(files);
    this.state.files.forEach(
      /*async*/ (file) => {
        //console.log(file);
        //console.log('APPEND');
        //console.log(appended);
        //console.log(file.name);
        //console.log(appended);
        if (!appended.includes(file.name)) {
          let uploadFiles = [];
          let noMatch = true;
          for (let j = 0; j < matches.length; j++) {
            let match = matches[j];
            let primeFile, secondFile;
            /*console.log('splits');
          console.log(file.name.split(match.name));
          console.log(file.name.split(match.nameMatch));
          console.log('break');*/
            //console.log(file.name);
            let fileSplitName = file.name.split(match.name);
            //let fileSplitNameMatch = file.name.split(match.nameMatch);
            if (file.name.includes(match.name) || file.name.includes(match.nameMatch)) {
              for (let k = 0; k < files.length; k++) {
                let matchFile = files[k];
                let splitNameMatch = matchFile.name.split(match.nameMatch);
                let splitName = matchFile.name.split(match.name);
                // console.log(matchFile.name);
                //console.log(fileSplitName, fileSplitNameMatch, splitNameMatch, splitName);
                /*if (matchFile.name.includes(match.nameMatch)) {
                noMatch = false;
                secondFile = matchFile;
                appended.push(matchFile.name);
              }
              if (matchFile.name.includes(match.name)) {
                noMatch = false;
                primeFile = matchFile;
                appended.push(matchFile.name);
              }*/

                if (splitNameMatch.length > 1 && splitNameMatch[1] === fileSplitName[1]) {
                  noMatch = false;
                  secondFile = matchFile;
                  appended.push(matchFile.name);
                }
                if (splitName.length > 1 && splitName[1] === fileSplitName[1]) {
                  noMatch = false;
                  primeFile = matchFile;
                  appended.push(matchFile.name);
                }
              }
              uploadFiles = [primeFile, secondFile];
              //console.log(uploadfiles);
              console.log('found');
            }
          }
          if (noMatch) {
            uploadFiles = [file];
          }

          //temp.push(uploadFiles);
          promises.push(this.sendRequest(uploadFiles));
        }
      }
    );
    //console.log(temp);
    //promises.push(this.sendRequest(this.state.files));
    try {
      await Promise.all(promises);
      //Code for if we continue on a failed individual upload
      if (this.state.error) {
        this.setState({ successfullUploaded: false, uploading: false });
      } else {
        this.setState({ successfullUploaded: true, uploading: false });
      }
    } catch (e) {
      //TODO Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  async analyzeReponse(type, filename, testID, retry) {
    let urlAnalyze = `https://www.ten-one.app/optimize/analyze/${type.toLowerCase()}?client=${this.props.auth.client}${!testID ? `` : `&testid=${testID}`}&engineid=${retry ? `INCOMPLETE` : `false`}`;
    if (typeof window !== 'undefined' && (window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://localhost:3000')) {
      urlAnalyze = `https://www.ten-one.io/optimize/analyze/${type.toLowerCase()}?client=${this.props.auth.client}${!testID ? `` : `&testid=${testID}`}&engineid=${retry ? `INCOMPLETE` : `false`}`;
    }
    if (
      typeof window !== 'undefined' &&
      (window.location.origin === 'http://ten-one.io' ||
        window.location.origin === 'https://ten-one.io' ||
        window.location.origin === 'http://www.ten-one.io' ||
        window.location.origin === 'https://www.ten-one.io')
    ) {
      urlAnalyze = `https://www.ten-one.io/optimize/analyze/${type.toLowerCase()}?client=${this.props.auth.client}${!testID ? `` : `&testid=${testID}`}&engineid=${retry ? `INCOMPLETE` : `false`}`;
    }
    //console.log('analyzeReponse!');
    let responseAnalyze;
    responseAnalyze = await client2.get(urlAnalyze);

    const analyzeCopy = { ...this.state.uploadProgress };

    if (responseAnalyze.data === -1 && retry === true) {
      //console.log(-1);

      analyzeCopy[filename] = {
        state: 'error',
        percentage: 90,
        message: `Processing Failed - Contact Admin`,
      };
      this.setState({ uploadProgress: analyzeCopy, error: true });
    } else if (responseAnalyze.data === -1 && retry === false) {
      //console.log(-1);
      analyzeCopy[filename] = {
        state: 'advisory',
        percentage: 90,
        message: `Processing Failed - Retrying...`,
      };
      this.setState({ uploadProgress: analyzeCopy, error: true });
    } else if (responseAnalyze.data === 0) {
      //console.log(0);
      //const copy = { ...this.state.uploadProgress };
      //TODO FIX 0 response to be no new data after driver fixes
      analyzeCopy[filename] = {
        state: 'done',
        percentage: 100,
        message: `Processing Complete`,
      };

      /*analyzeCopy[file.name] = {
        state: 'error',
        percentage: 90,
        message: `No New Data Detected - Contact Admin`,
      };*/
      this.setState({ uploadProgress: analyzeCopy, error: true });
    } else if (responseAnalyze.data === 1) {
      //console.log(1);
      //const copy = { ...this.state.uploadProgress };
      analyzeCopy[filename] = {
        state: 'done',
        percentage: 100,
        message: `Processing Complete`,
      };
      this.setState({ uploadProgress: analyzeCopy /*, error: false*/ });
    } else {
      analyzeCopy[filename] = {
        state: 'error',
        percentage: 100,
        message: `Unknown Error from Server`,
      };
    }

    //console.log(copy);

    // console.log(response);
    /*if (responseAnalyze) {
      analyzeCopy[file.name] = {
        state: 'done',
        percentage: 100,
        message: `Processing Complete`,
      };
      this.setState({ uploadProgress: analyzeCopy, error: false });
    }*/
    return responseAnalyze;
  }

  async sendRequest(fileArray) {
    /*try {
      const url = `http://68.129.207.55:8082/stroke?client=Olin&engineid=false`;
      //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
      // const url = `/devices`;
      const response = await api.get(url);
      console.log(response);
    } catch (e) {
      console.log(e);
    }*/
    //let file = fileArray[0]; //TODO TEMP FIX
    try {
      //const url = `http://68.129.207.55:8082/stroke?client=Olin&engineid=false`;
      //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
      // const url = `/devices`;
      //const response = await api.get(url);
      let baseURL = 'https://www.ten-one.app/optimize/upload/api/v1/upload-csv';
      if (typeof window !== 'undefined' && (window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://localhost:3000')) {
        //baseURL = 'https://localhost:9001/api/v1/optimize/upload-csv';
        baseURL = 'https://localhost:9001/api/v1/upload-csv';
      }
      if (
        typeof window !== 'undefined' &&
        (window.location.origin === 'http://ten-one.io' ||
          window.location.origin === 'https://ten-one.io' ||
          window.location.origin === 'http://www.ten-one.io' ||
          window.location.origin === 'https://www.ten-one.io')
      ) {
        //baseURL = 'https://www.ten-one.io/upload/api/v1/optimize/upload-csv'; //NEW
        baseURL = 'https://www.ten-one.io/optimize/upload/api/v1/upload-csv'; //OLD
      }
      const data = new FormData();
      let now = new Date();
      for (let i = 0; i < fileArray.length; i++) {
        data.append('file', fileArray[i]);

        console.log(now.toLocaleTimeString() + ' | starting upload for: ' + fileArray[i].name);
      }
      responseTotal++;
      const response = await api.post(
        baseURL,
        //'https://localhost:9001/api/v1/upload-csv',

        //'https://68.129.207.55:8082/optimize/upload/api/v1/upload-csv',
        //'https://www.ten-one.app/optimize/upload/api/v1/upload-csv',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data' /*,
            'Access-Control-Allow-Origin': '*',*/,
          },
          //timeout: 600000, //600s timeout
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            //console.log('onUploadProgress', totalLength);
            //console.log(Math.round((progressEvent.loaded * 100) / totalLength));
            if (totalLength !== null) {
              const copy = { ...this.state.uploadProgress };
              fileArray.forEach((file) => {
                if (progressEvent.loaded / progressEvent.total === 1) {
                  copy[file.name] = {
                    state: 'uploading',
                    percentage: 50,
                    message: `Upload Complete. Inserting Data...`,
                  };
                } else {
                  copy[file.name] = {
                    state: 'uploading',
                    percentage: (progressEvent.loaded / progressEvent.total) * 50,
                    message: `Uploading data...`,
                  };
                }
              });

              this.setState({ uploadProgress: copy });
              //this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
            }
            // receive two    parameter endpoint url ,form data
          },
        }
      );
      now = new Date();
      const copy = { ...this.state.uploadProgress };
      const testCopy = { ...this.state.tests };
      fileArray.forEach((file) => {
        console.log(now.toLocaleTimeString() + ' | upload response recieved for: ' + file.name);

        const messageFinal = file.name.toLowerCase().includes('_sub_0')
          ? 'Supplemental File Processed'
          : response.data.message === 'Duplicate File Detected'
          ? `${response.data.message}...Re-Running Analysis`
          : `${response.data.message}...Starting Analysis`;
        copy[file.name] = {
          state: 'done',
          percentage: file.name.toLowerCase().includes('_sub_0') ? 100 : 90,
          message: messageFinal,
        };
        if (!testCopy[response.data.type]) {
          testCopy[response.data.type] = [];
        }
        if (!testCopy[response.data.type].includes(response.data.testID)) {
          testCopy[response.data.type].push(response.data.testID);
        }
      });
      responseCount++;
      this.setState({ uploadProgress: copy, tests: testCopy });

      /*const urlAnalyze = `https://68.129.207.55:8082/optimize/analyze/${response.data.type.toLowerCase()}?client=${
        this.props.auth.client
      }&engineid=false`;*/

      //let responseAnalyze;
      /*if (response.data.type.toLowerCase() === 'profile') {
        setTimeout(async () => {
          responseAnalyze = await client2.get(urlAnalyze);
        }, 2000);
      } else {*/

      //setTimeout(async () => {
      //responseAnalyze = await client2.get(urlAnalyze);
      //}, 60000);

      // }
      //console.log(responseAnalyze);
      // console.log(response);
      //if(file)
      if (response.data.type.toLowerCase() === 'config' || response.data.message === 'Unsupported Test Type' || response.data.testID === 0) {
        const messageConfig =
          response.data.testID === 0 ? (response.data.message === 'Valid XML' ? `XML Upload Complete - Awaiting DAT` : `Data Upload Complete - Awaiting XML`) : `${response.data.message}`;
        fileArray.forEach((file) => {
          copy[file.name] = {
            //state: 'done',
            percentage: 100,
            message: messageConfig,
          };
        });
        this.setState({ uploadProgress: copy });
        return response;
      }
      now = new Date();
      fileArray.forEach((file) => {
        console.log(now.toLocaleTimeString() + ' | analyzeResponse for: ' + file.name);
      });
      //! TODO REVERT WHEN COMPLETE WITH TESTING (TURN ON ANALYSIS CALLS)
      let analysisResponse = await this.analyzeReponse(response.data.type, fileArray[0].name, response.data.testID, false);
      //console.log(analysisResponse);
      if (analysisResponse.data === -1) {
        //console.log(analysisResponse);
        fileArray.forEach((file) => {
          console.log(`Retrying analysis for ${file.name} after 2s delay`);
        });
        await functions.delay(2000); //DELAY 2s BEFORE CALLING RETRAY
        /*let analysisReponse2 = await*/ this.analyzeReponse(response.data.type, fileArray[0].name, response.data.testID, true);
      }
      if (responseCount === responseTotal && confirmationLoading === false) {
        //console.log(this.state);
        confirmationLoading = true;
        this.retrieveConfirmation();
      }

      return response;
      //resolve(req.response);
    } catch (e) {
      /*console.log(e);
      console.log(e.data);
      console.log(e.statusText);
      console.log(e.response);
      console.log(e.message);*/
      const copy = { ...this.state.uploadProgress };
      let message = 'Unknown Error from Server';
      //console.log(e);
      if (!!e.data && !!e.data.message) {
        message = e.data.message;
      } else if (!!e.statustext) {
        message = e.statustext;
      } else if (!!e.response && !!e.response.statusText) {
        message = e.response.statusText;
      } else if (!!e.message) {
        message = e.message;
      } else if (!!e.data.details) {
        message = e.data.details;
      }
      //console.log(message);
      //TODO REMOVE WHEN FIXED
      fileArray.forEach((file) => {
        copy[file.name] = {
          state: 'error',
          percentage: 0,
          message,
        };
        responseCount++;
      });
      this.setState({ uploadProgress: copy, error: true });
      //reject(req.response);
      //
      //IMMEDIATELY HALTS PROGRESS ON ERROR
      //return Promise.reject(e);
      //CONTINUE WITHOUT STOPPING
      //console.log(e);
      return e;
    }
    /*return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener('load', (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'done', percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener('error', (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'error', percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append('file', file, file.name);

      //TODO Start here to send to the correct spot on our server at :9001
      //req.open('POST', 'https://localhost:9001/upload-csv');

      //req.send(formData);
    });*/
  }

  async retrieveConfirmation(tests) {
    const url = `optimize/tests/summary/`;
    const data = !tests ? this.state.tests : tests;

    try {
      confirmationLoadingCount++;
      //console.log(confirmationLoadingCount);
      //console.log(data);
      const response = await api.post(url, data);
      //console.log(response.data);
      //console.log(response);
      this.setState({ confirmedTests: response.data.data });
    } catch (e) {
      //console.log(e);
      this.setState({ confirmationStatus: 'error' });
      confirmationLoading = false;
      if (confirmationLoadingCount <= 1) {
        this.retrieveConfirmation();
      }
    }
  }

  renderActions() {
    const { classes } = this.props;
    //console.log(this.state);
    if (this.state.successfullUploaded || this.state.error) {
      return (
        <Button
          variant='outlined'
          color='primary'
          style={{ marginTop: 8 }}
          onClick={() => {
            document.getElementById('optimize_upload_files_id').value = ''; // need to clear the data from the input stored in dropzone, thus we pass it an id up above and use that down here.
            responseCount = 0;
            responseTotal = 0;
            confirmationLoading = false;
            confirmationLoadingCount = 0;
            this.setState({
              files: [],
              tests: {},
              confirmedTests: [],
              uploading: false,
              uploadProgress: {},
              successfullUploaded: false,
              error: false,
              cleared: true,
            });
          }}
        >
          <DeleteSweep className={classes.buttonIcon}></DeleteSweep>Clear
        </Button>
      );
    } else {
      return (
        <Button
          variant='outlined'
          style={{ marginTop: 8 }}
          disabled={this.state.files.length === 0 || this.state.uploading}
          onClick={(e) => {
            this.setState({ cleared: false });
            this.uploadFiles(e);
          }}
        >
          <CloudUpload className={classes.buttonIcon}></CloudUpload> Upload
        </Button>
      );
    }
  }

  async onFilesAdded(files) {
    await this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
    this.processFiles();
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(OptimizeUpload));
