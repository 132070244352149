import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ConcernSummaryReport from './prioritize/ConcernSummaryReport';
import ActionReport from './prioritize/ActionReport';
import ImprovementReport from './prioritize/ImprovementReport';
import functions from '../../../functions/functions';
import { getPrioritizeSummaryReport, getImprovementsReport, reportsSetActiveReport } from '../../../actions/index';

class PrioritizeWidgets extends React.Component {
  componentDidMount() {
    let url = window.location.href.toLowerCase();
    url.includes('action') ? this.props.reportsSetActiveReport('action') : url.includes('improve') ? this.props.reportsSetActiveReport('improvement') : this.props.reportsSetActiveReport('concern');
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('concern');
    }
    //console.log(value);
  }
  //TODO fix wrapper buttons onclick
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activeReport ? 'concern' : this.props.activeReport}
          exclusive
          onChange={(event, value) => this.handleReport(event, value)}
          aria-label='text alignment'
          className={classes.toggleButtons}
          style={printStyles ? { display: 'none' } : null}
        >
          <ToggleButton value='concern' text='Concern Summary' component={Link} to={`/reports/Prioritize/Concern`}>
            Concern Summary
          </ToggleButton>
          <ToggleButton value='action' text='Action Items' component={Link} to={`/reports/Prioritize/Action`}>
            Action Items
          </ToggleButton>
          {/*<ToggleButton value='improvement' text='Application Improvement' component={Link} to={`/reports/Prioritize/Improvement`}>
            Application Improvement
          </ToggleButton>*/}
        </ToggleButtonGroup>
        <div className={classes.dividerXl}></div>
        {this.props.activeReport === 'concern' || this.props.activeReport === 'print' ? (
          <ConcernSummaryReport />
        ) : this.props.activeReport === 'action' ? (
          <ActionReport />
        ) : this.props.activeReport === 'improvement' ? (
          <ImprovementReport />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    activeReport: state.reports.activeReport,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  getPrioritizeSummaryReport,
  reportsSetActiveReport,
})(withStyles(styles)(PrioritizeWidgets));
