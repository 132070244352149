import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import functions from '../../functions/functions';
import { connect } from 'react-redux';
import { Grid, Tooltip, TableSortLabel, TableRow, TablePagination, TableHead, TableCell, TableBody, Table, TableContainer } from '@mui/material';
import { styles } from '../styles';

//import { Cancel, Check, Cached, CheckCircle } from '@mui/icons-material';

/*let counter = 0;
function createData(name, concern, type) {
  counter += 1;
  return { id: counter, name, concern, type };
}*/
// TODO - Allow for orderBy arrays to be submitted to allow for tiebreakers
function desc(a, b, orderBy) {
  //Override in place to swap any data column ID ending in 'Formatted' to its base value IF IT EXISTS
  let orderByKeyReplace = !a[orderBy.replace('Formatted', '')] ? orderBy : orderBy.replace('Formatted', '');
  //3rd
  if (b[orderByKeyReplace] < a[orderByKeyReplace]) {
    return -1;
  }
  if (b[orderByKeyReplace] > a[orderByKeyReplace]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  //2nd
  const stabilizedThis = !array ? [] : array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  //1st
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class TableComponentHead extends React.Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <TableHead>
        <TableRow>
          {!this.props.header
            ? null
            : this.props.header.map(
                (row, index) => (
                  <TableCell
                    className={classes.tableCell}
                    colSpan={row.id === 'comment' ? 2 : 1}
                    style={
                      printStyles && (row.id === 'menu' || row.id === 'reportLink')
                        ? {
                            paddingTop: '12px',
                            paddingBottom: '12px',
                            display: 'none',
                          }
                        : {
                            paddingTop: '12px',
                            paddingBottom: '12px',
                          }
                    }
                    key={`${row.id}_${index}`}
                    sortDirection={orderBy === row.id ? order : false}
                  >
                    <Tooltip arrow placement='top' title='Sort' enterDelay={300}>
                      <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                ),
                this
              )}
        </TableRow>
      </TableHead>
    );
  }
}
TableComponentHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
};
class TableComponent extends React.Component {
  state = {
    order: !this.props.order ? 'asc' : this.props.order,
    orderBy: !this.props.orderBy ? 'concern' : this.props.orderBy,
    selected: [],
    //data: this.props.data,
    page: 0,
    rowsPerPage: this.props.rows,
  };
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  render() {
    const data = this.props.data;
    const { classes } = this.props;
    const { /*data,*/ order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = !data ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
    const rowCount = !data ? 0 : data.length;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const TableHeader = withStyles(styles)(TableComponentHead);
    if (!data || data.length === 0) {
      if (this.props.type === 'improvement') {
        return <Grid style={{ padding: 10 }}>No Improvement Paths have been entered yet</Grid>;
      } else {
        return <Grid style={{ padding: 10 }}>No data found for this table.</Grid>;
      }
    } else {
      //console.log(data);
      return (
        <TableContainer className={!this.props.tileTable ? null : classes.tileTable}>
          <Table
            aria-labelledby='tableTitle'
            //style={{ tableLayout: 'fixed' }}
          >
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={rowCount}
              header={this.props.header}
              format={this.props.format}
              activeTab={this.props.activeTab}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  //console.log(n);
                  let rowStyle = !row.style ? {} : row.style;
                  let style =
                    index % 2 && !this.props.hasLink && this.props.colorMode === 'light'
                      ? { background: '#fbfbfb' }
                      : index % 2 && !this.props.hasLink && this.props.colorMode === 'dark'
                      ? { background: '#141414' }
                      : { background: null };
                  Object.assign(style, rowStyle);
                  return (
                    <TableRow tabIndex={-1} key={`${row.id}_${index}`} style={style}>
                      {this.props.header.map((header, index) => {
                        return (
                          <TableCell
                            className={classes.tableCell}
                            component='th'
                            key={`${header.id}_${index}`}
                            scope='row'
                            colSpan={header.id === 'comment' || header.id === 'linkFormatted' ? 2 : 1}
                            style={
                              printStyles && (header.id === 'menu' || header.id === 'reportLink')
                                ? {
                                    display: 'none',
                                  }
                                : null
                            }
                          >
                            {row[header.id] !== null ? row[header.id] : '-'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && null}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={!this.props.rowsPerPageOptions ? [5, 10, 25] : this.props.rowsPerPageOptions}
            component='div'
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            onClick={(e) => e.preventDefault()}
            className={!this.props.printHidePagination ? null : classes.printHide}
          />
        </TableContainer>
      );
    }
  }
}

TableComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    format: state.format.format,
    activeTab: state.optimize.activeTab,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(TableComponent));
