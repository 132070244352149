import React from 'react';
import RoadmapTabs from '../menus/RoadmapTabs';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import WidgetTitle from '../headers/WidgetTitle';
import { roadmapSetActiveTab } from '../../actions';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Redirect } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import functions from '../../functions/functions';
import roadmapDetails from '../../arrays/prioritize/roadmapDetails';

class Roadmap extends React.Component {
  renderPrioritizeRoadmap() {
    const { classes } = this.props;
    const roadmapIndex = Object.keys(roadmapDetails);
    return (
      <React.Fragment>
        <WidgetTitle title='Prioritize Roadmap' />
        <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
          Click any row to learn more about issues and view recommendations
        </Typography>
        {!roadmapIndex
          ? `Roadmap Not Found!`
          : roadmapIndex.map((roadmapKey) => {
              return (
                <Accordion key={roadmapDetails[roadmapKey]}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{roadmapDetails[roadmapKey].title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{roadmapDetails[roadmapKey].description}</Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
      </React.Fragment>
    );
  }
  renderOptimizeRoadmap() {
    //const { classes } = this.props;
    return <React.Fragment></React.Fragment>;
  }
  componentDidMount() {
    if (this.props.match.params.tab === 'null') {
      return <Redirect to='Prioritize' />;
    }
    this.props.roadmapSetActiveTab(this.props.match.params.tab);
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <RoadmapTabs />
        <div className={printStyles ? null : classes.details}>
          {this.props.activeTab === 'Prioritize' && this.renderPrioritizeRoadmap()}
          {this.props.activeTab === 'Optimize' && this.renderOptimizeRoadmap()}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeTab: state.roadmap.activeTab,
  };
};

export default connect(mapStateToProps, { roadmapSetActiveTab })(withStyles(styles, { withTheme: true })(Roadmap));
