import { SIGN_IN, SIGN_OUT, SET_AUTH_STATE, FETCH_USER_REALMS, FETCH_USER_PROFILE } from '../actions/types';

const INITIAL_STATE = {
  incorrect: true,
  isSignedIn: null,
  userId: null,
  current_realm: -1,
  full_name: '',
  prioritize: 0,
  optimize: 0,
  reliability: 0,
  fsr: 0,
  iba: 0,
  realms: [],
  primaryLogo: '',
  secondaryLogo: '',
  require_filter: 1,
  client_permission: 9999,
  fsr_type: 'default',
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      //console.log(action.payload);
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.username,
        full_name: action.payload.full_name,
        current_realm: action.payload.current_realm,
        prioritize: action.payload.prioritize,
        optimize: action.payload.optimize,
        reliability: action.payload.reliability,
        fsr: action.payload.fsr,
        iba: action.payload.iba,
        client: action.payload.client,
        view: action.payload.view,
        primaryLogo: action.payload.primaryLogo,
        secondaryLogo: action.payload.secondaryLogo,
        require_filter: action.payload.require_filter,
        client_permission: action.payload.client_permission,
        fsr_type: action.payload.fsr_type,
      };
    case SIGN_OUT:
      return { ...state, isSignedIn: false, userId: null, client: null };
    case SET_AUTH_STATE:
      return {
        ...state,
        incorrect: action.payload,
      };
    case FETCH_USER_REALMS:
      return {
        ...state,
        realms: action.payload,
      };
    case FETCH_USER_PROFILE:
      return {
        ...state,
        email: action.payload[0].email,
        first_name: action.payload[0].first_name,
        last_name: action.payload[0].last_name,
      };
    default:
      return state;
  }
};

export default authReducer;
