import api from './api.js';
import functions from '../functions/functions';

const getFastFieldAuth = async (override) => {
  let auth = functions.getCookie('fastFieldAuth');
  let authExpire = functions.getCookie('fastFieldAuthExpire');
  let nowDate = Date.now();
  //auth = '';
  //console.log(auth, authExpire, nowDate);
  if (auth === '' || authExpire === '' || authExpire < nowDate || override) {
    auth = requestFastFieldAuth();
    //console.log('new Auth: ', auth);
    console.log('New Auth Requested');
  }
  return auth;
};

const requestFastFieldAuth = async (count) => {
  let currentCount = !count ? 0 : count;
  let auth = '';
  try {
    const authUrl = 'iba/getAuth';
    const response = await api.get(authUrl);
    console.log(response.status === 429);
    //let sessionExpiration = '2024-03-02T02:25:24.684Z';
    //let sessionToken = 'b2db0d0708f34b6d8f65e6e473254ea5';
    functions.setCookie('fastFieldAuth', response.data.sessionToken, 1 / 4 /*1 / 3 */, true); //expires in 6 hours (auth is valid for 8)

    var today = new Date();
    today.setHours(today.getHours() + 6);
    console.log(today.getTime());
    functions.setCookie('fastFieldAuthExpire', today.getTime(), null, true); //expires in 6 hours (auth is valid for 8)
    auth = response.data.sessionToken;
    return auth;
  } catch (e) {
    //console.log(count, currentCount, currentCount + 1);
    if (currentCount < 2 && e.status === 429) {
      //allow 3 attempts to get auth
      setTimeout(function () {
        requestFastFieldAuth(currentCount + 1);
        // Something you want delayed.
      }, 2000);
    }
    console.log(e);
  }
  return null;
};

const fastfield = { getFastFieldAuth, requestFastFieldAuth };

export default fastfield;
