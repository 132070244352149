import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import TableWidget from '../widgets/TableWidget';
import functions from '../../functions/functions';
//import initializeTheme from '../theme.js';
import { Checkbox, Button, Typography, Grid } from '@mui/material';
import InfoPopover from '../popup/InfoPopover';
import { Settings } from '@mui/icons-material';
import OptimizeContent from '../widgetGroups/config/content/OptimizeContent';
import api from '../../apis/api';
import { connect } from 'react-redux';
import {
  //fetchDevices,
  //fetchDeviceByTest,
  //fetchDevicesSummary,
  fetchDevicesSummaryVerbose,
  //setDevicesSummaryStatus,
} from '../../actions/index';

class ConfirmationTable extends React.Component {
  constructor(props) {
    super(props);
    let data = this.props.data;
    let currentTestStates = {};
    for (let i = 0; i < data.length; i++) {
      currentTestStates[data[i].testID] = data[i].state;
    }
    this.state = {
      testStateChanges: currentTestStates,
      responseState: '',
    };
  }
  componentDidMount() {
    //this.props.fetchDevices(this.props.activeDevice);
    this.props.fetchDevicesSummaryVerbose();
  }

  renderStateBoxes(target, test_id) {
    //console.log(this.state);
    return (
      <React.Fragment>
        <Checkbox
          checked={target === this.state.testStateChanges[test_id]}
          onChange={(value) => {
            let currentState = this.state.testStateChanges;
            currentState[test_id] = target;
            this.setState({
              testStateChanges: currentState,
              responseState: '',
            });
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </React.Fragment>
    );
  }

  render() {
    //const theme = initializeTheme(this.props.colorMode);
    //const { classes } = this.props;
    let headerArr = [
      { id: 'visualID', label: 'Tag #' },
      { id: 'site', label: 'Site' },
      { id: 'location', label: 'Unit' },
      { id: 'applicationType', label: 'Application' },
      { id: 'datetime2', label: 'Date' },
      { id: 'state1', label: 'As Found' },
      { id: 'state2', label: 'Indeterminate' },
      { id: 'state3', label: 'As Left' },
      { id: 'resolutionApp2', label: 'Resolution Target' },
      { id: 'sensitivityApp2', label: 'Sensitivity Target' },
      { id: 'strokeApp2', label: 'Stroke Target' },
      { id: 'configure', label: 'Configure' },
    ];
    let data = this.props.data;
    for (let i = 0; i < data.length; i++) {
      data[i].id = i;
      data[i].datetime2 = functions.dateToString(data[i].datetime * 1000);
      data[i].resolutionApp2 = !data[i].resolutionApp ? 'Default' : data[i].resolutionApp + '%';
      data[i].sensitivityApp2 = !data[i].sensitivityApp ? 'Default' : data[i].sensitivityApp + '%';
      data[i].strokeApp2 = !data[i].strokeApp ? 'Default' : data[i].strokeApp + 's';
      data[i].state1 = this.renderStateBoxes(3, data[i].testID);
      data[i].state2 = this.renderStateBoxes(2, data[i].testID);
      data[i].state3 = this.renderStateBoxes(1, data[i].testID);

      //console.log(data[i].device_id);
      //console.log(this.props.devices);
      //console.log(this.props.devices[data[i].device_id]);
      const device = this.props.devices[data[i].device_id];
      const row = !device
        ? {}
        : {
            id: device.deviceId,
            name: device.name,
            serial: device.serial,
            site: device.site,
            application: device.application,
            description: device.description,
            bodySize: device.bodySize,
            bodyType: device.bodyType,
            importance: device.importance,
            resApp: !device.appRes ? null : device.appRes,
            senApp: !device.appSen ? null : device.appSen,
            strokeApp: !device.appStroke ? null : device.appStroke,
            effectiveTargetDate: new Date().getTime() / 1000,
            speedImportance: device.speedImportance,
            accuracyImportance: device.accuracyImportance,
            performanceSatisfaction: device.performanceSatisfaction,
            currentSerial: device.serial,
            deviceId: device.deviceId,
          };
      data[i].configure = !row ? null : (
        <React.Fragment>
          <InfoPopover
            center
            tooltip='Configure'
            title='Configure'
            titleIcon='Settings'
            resize
            style={{ marginBottom: -15 }}
            icon={<Settings />}
            closeFunction={this.props.refreshFunction}
            content={<OptimizeContent row={row} clickedRow={data[i].device_id} rowOpen={true} />}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <TableWidget xs={12} data={data} header={/*!this.props.devices[this.props.activeDevice] ? [] : */ headerArr} type='improvement' />
        <Grid container justifyContent='space-between'>
          <Grid item></Grid>
          <Grid>
            <Button
              variant='outlined'
              style={{ marginTop: 4 }}
              onClick={async (e) => {
                //console.log(this.state.testStateChanges);
                const url = `/optimize/tests/state`;
                try {
                  let response = await api.post(url, this.state.testStateChanges);
                  //console.log('t');
                  //console.log(response);
                  if (response.status === 200) {
                    console.log(1);
                    this.setState({ responseState: 'Success' });
                  } else {
                    console.log(2);
                    this.setState({ responseState: 'Error' });
                  }
                } catch (e) {
                  console.log(e);
                  this.setState({ responseState: 'Error' });
                }
              }}
            >
              Submit Information
            </Button>
          </Grid>
        </Grid>
        {this.state.responseState === '' ? null : <Typography color={this.state.responseState === 'Error' ? 'error' : 'secondary'}>{this.state.responseState}!</Typography>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
    devices: state.devices.devices,
  };
};

export default connect(mapStateToProps, { fetchDevicesSummaryVerbose })(withStyles(styles, { withTheme: true })(ConfirmationTable));
