import { SET_ACTIVE_NAV } from '../actions/types';

const INITIAL_STATE = {
  activeNav: null,
};

const navReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACTIVE_NAV:
      return { ...state, activeNav: action.payload };
    default:
      return state;
  }
};

export default navReducer;
