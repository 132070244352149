import { combineReducers } from 'redux';
//import { reducer as formReducer } from 'redux-form';
import actionReducer from './actionReducer';
import authReducer from './authReducer';
import applicationReducer from './applicationReducer';
import datasetReducer from './datasetReducer';
import locationReducer from './locationReducer';
import loopReducer from './loopReducer';
import eventReducer from './eventReducer';
import shutdownReducer from './shutdownReducer';
import errorReducer from './errorReducer';
import optimizeReducer from './optimizeReducer';
import prioritizeReducer from './prioritizeReducer';
import roadmapReducer from './roadmapReducer';
import reportsReducer from './reportsReducer';
import configReducer from './configReducer';
import supportReducer from './supportReducer';
import sortReducer from './sortReducer';
import tableReducer from './tableReducer';
import deviceReducer from './deviceReducer';
import moduleReducer from './moduleReducer';
import testReducer from './testReducer';
import navReducer from './navReducer';
import siteReducer from './siteReducer';
import clientReducer from './clientReducer';
import printReducer from './printReducer';
import formatReducer from './formatReducer';
import devicetypeReducer from './devicetypeReducer';
import importanceReducer from './importanceReducer';
import issuetypeReducer from './issuetypeReducer';
import uploadReducer from './uploadReducer';
import colorModeReducer from './colorModeReducer';
import reliabilityReducer from './reliabilityReducer';
import recommendationsReducer from './recommendationsReducer';
import chartSelectionsReducer from './chartSelectionsReducer';

export default combineReducers({
  auth: authReducer,
  actions: actionReducer,
  print: printReducer,
  format: formatReducer,
  //form: formReducer,
  issuetypes: issuetypeReducer,
  applications: applicationReducer,
  importances: importanceReducer,
  locations: locationReducer,
  clients: clientReducer,
  devices: deviceReducer,
  devicetypes: devicetypeReducer,
  datasets: datasetReducer,
  events: eventReducer,
  shutdowns: shutdownReducer,
  loops: loopReducer,
  error: errorReducer,
  optimize: optimizeReducer,
  prioritize: prioritizeReducer,
  roadmap: roadmapReducer,
  reliability: reliabilityReducer,
  reports: reportsReducer,
  config: configReducer,
  support: supportReducer,
  sites: siteReducer,
  sort: sortReducer,
  table: tableReducer,
  tests: testReducer,
  currentModule: moduleReducer,
  nav: navReducer,
  upload: uploadReducer,
  colorMode: colorModeReducer,
  recommendations: recommendationsReducer,
  chartSelections: chartSelectionsReducer,
});
