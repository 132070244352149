import React from 'react';
import Confirm from '../../../dialogs/Confirm';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import InputAdornment from '@mui/material/InputAdornment';
import CustomSelect from '../../../filters/CustomSelect';
import InfoPopover from '../../../popup/InfoPopover';
import configDetails from '../../../../arrays/optimize/configDetails';
import { Button, Collapse, Grid, TextField, Typography, Divider } from '@mui/material';
import DatePicker from '../../../filters/DatePicker';
import { fetchDevices, fetchDeviceByTest, fetchDevicesSummaryVerbose, setDevicesSummaryStatus } from '../../../../actions/index';
import api from '../../../../apis/api';
require('string_score'); // USE REQUIRE TO AVOID 'no use of declared item'. // TODO replace with better search package

class OptimizeContent extends React.Component {
  constructor(props) {
    super(props);

    const device = this.props.row.id !== -1 ? this.props.devices[this.props.row.id] : this.props.row;
    //console.log(this.props);
    //console.log(device);
    let effectiveTargetDate = new Date();
    effectiveTargetDate.setHours(0, 0, 0, 0);
    this.state = {
      order: 'asc',
      orderBy: 'concern',
      selected: [],
      //data: this.props.data,
      page: 0,
      rowsPerPage: this.props.rows,
      rowOpen: true,
      clickedRow: null,
      rowBorder: null,
      status: '',
      statusMessage: '',
      loading: false,
      rowChange: !device
        ? {}
        : {
            name: device.name,
            serial: device.serial,
            client: device.client,
            site: device.site,
            application: device.application,
            description: device.description,
            bodySize: device.bodySize,
            bodyType: device.bodyType,
            importance: device.importance,
            resApp: !device.processAppRes ? this.calculateDefaults('resolution') : device.processAppRes,
            senApp: !device.processAppSen ? this.calculateDefaults('sensitivity') : device.processAppSen,
            strokeApp: !device.processAppStroke ? this.calculateDefaults('stroke') : device.processAppStroke,
            effectiveTargetDate: effectiveTargetDate.getTime() / 1000,
            speedImportance: device.speedImportance,
            accuracyImportance: device.accuracyImportance,
            performanceSatisfaction: device.performanceSatisfaction,
            reliabilityImportance: device.reliabilityImportance,
            currentSerial: device.serial,
            deviceId: device.deviceId,
            bypass: device.bypass,
          },
      recConfirm: false,
      senRec: null,
      resRec: null,
      strokeRec: null,
      importanceRec: null,
    };
    this.recommendTargets = this.recommendTargets.bind(this);
    //this.onSearchChange = this.onSearchChange.bind(this);
    //this.onSearchSelect = this.onSearchSelect.bind(this);
  }

  handleRowSubmit = async (event) => {
    //console.log('t1');
    event.preventDefault();
    try {
      //console.log(this.props.row.id);
      const url = this.props.row.id === -1 ? `optimize/devices/${this.props.row.id}/create` : `optimize/devices/${this.props.row.id}/configure`;
      //console.log(this.state.rowChange);
      //console.log(url);
      this.setState({
        status: 'Success',
        statusMessage: 'Processing',
        loading: true,
      });
      //console.log(this.props.row.id);
      //console.log(this.state.rowChange);
      //console.log(this.state);
      //console.log(this.props);
      //console.log(this.props.devices[4094].activeTestId);
      await api.post(url, this.state.rowChange);
      //console.log(response);
      //this.props.fetchDevicesSummary();
      //this.props.fetchDevices(this.props.row.id);
      //console.log(this.props.row);
      //console.log(this.props.row.id !== -1 && !!this.props.devices[this.props.row.id].activeTestId, this.props.row.id, this.props.devices[this.props.row.id].activeTestId);
      if (this.props.row.id !== -1 && !!this.props.devices[this.props.row.id].activeTestId) {
        this.props.fetchDeviceByTest(this.props.row.id, this.props.devices[this.props.row.id].activeTestId);
      } else if (this.props.row.id !== -1 && !this.props.devices[this.props.row.id].activeTestId) {
        this.props.fetchDevices(this.props.row.id);
      } else if (this.props.row.id === -1) {
        this.props.fetchDevicesSummaryVerbose();
      }
      //console.log(this.props.row.id, this.props.devices[this.props.row.id].activeTestId);
      //this.setState((state) => ({ rowOpen: false }));
      this.setState({
        status: 'Success',
        statusMessage: 'Changes Saved',
        loading: false,
      });
      /*if (!!this.props.bubbleRowOpen) {
        this.props.bubbleRowOpen();
      }*/
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        loading: false,
      });
      console.log(e);
    }
  };
  calculateDefaults(type) {
    const device = this.props.row.id !== -1 ? this.props.devices[this.props.row.id] : this.props.row;

    const {
      //resApp,
      //senApp,
      //strokeApp,
      //performanceSatisfaction,

      accuracyImportance,
      speedImportance,
      bodySize,
    } = !this.state ? device : this.state.rowChange;
    const floatBodySize = Math.round(bodySize * 1000) / 1000;
    let resRec, senRec, strokeRec;
    switch (accuracyImportance) {
      case 5:
        resRec = 0.5;
        senRec = 0.25;
        break;
      case 4:
        resRec = 1;
        senRec = 0.5;
        break;
      case 3:
        resRec = 1;
        senRec = 1;
        break;
      case 2:
        resRec = 2;
        senRec = 1;
        break;
      case 1:
        resRec = 10;
        senRec = 5;
        break;
      default:
        resRec = 2;
        senRec = 1;
        break;
    }
    if (floatBodySize <= 6) {
      switch (speedImportance) {
        case 5:
          strokeRec = 2;
          break;
        case 4:
          strokeRec = 4;
          break;
        case 3:
          strokeRec = 8;
          break;
        case 2:
          strokeRec = 10;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 10;
          break;
      }
    } else if (floatBodySize <= 20) {
      switch (speedImportance) {
        case 5:
          strokeRec = 4;
          break;
        case 4:
          strokeRec = 6;
          break;
        case 3:
          strokeRec = 12;
          break;
        case 2:
          strokeRec = 20;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 20;
          break;
      }
    } else {
      switch (speedImportance) {
        case 5:
          strokeRec = 6;
          break;
        case 4:
          strokeRec = 8;
          break;
        case 3:
          strokeRec = 16;
          break;
        case 2:
          strokeRec = 30;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 30;
          break;
      }
    }
    if (type === 'stroke') {
      return strokeRec;
    }
    if (type === 'resolution') {
      return resRec;
    }
    if (type === 'sensitivity') {
      return senRec;
    }
  }
  recommendTargets(e) {
    e.preventDefault();
    //const currentSettings = this.state.rowChange;
    const {
      //resApp,
      //senApp,
      //strokeApp,
      //performanceSatisfaction,
      reliabilityImportance,
      accuracyImportance,
      speedImportance,
      bodySize,
    } = this.state.rowChange;

    const floatBodySize = Math.round(bodySize * 1000) / 1000;
    let resRec, senRec, strokeRec, importanceRec;
    switch (accuracyImportance) {
      case 5:
        resRec = 0.5;
        senRec = 0.25;
        break;
      case 4:
        resRec = 1;
        senRec = 0.5;
        break;
      case 3:
        resRec = 1;
        senRec = 1;
        break;
      case 2:
        resRec = 2;
        senRec = 1;
        break;
      case 1:
        resRec = 10;
        senRec = 5;
        break;
      default:
        resRec = 2;
        senRec = 1;
        break;
    }
    if (floatBodySize <= 6) {
      switch (speedImportance) {
        case 5:
          strokeRec = 2;
          break;
        case 4:
          strokeRec = 4;
          break;
        case 3:
          strokeRec = 8;
          break;
        case 2:
          strokeRec = 10;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 10;
          break;
      }
    } else if (floatBodySize <= 20) {
      switch (speedImportance) {
        case 5:
          strokeRec = 4;
          break;
        case 4:
          strokeRec = 6;
          break;
        case 3:
          strokeRec = 12;
          break;
        case 2:
          strokeRec = 20;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 20;
          break;
      }
    } else {
      switch (speedImportance) {
        case 5:
          strokeRec = 6;
          break;
        case 4:
          strokeRec = 8;
          break;
        case 3:
          strokeRec = 16;
          break;
        case 2:
          strokeRec = 30;
          break;
        case 1:
          strokeRec = 60;
          break;
        default:
          strokeRec = 30;
          break;
      }
    }
    importanceRec = Math.max(reliabilityImportance, accuracyImportance, speedImportance);
    //console.log(strokeRec, senRec, resRec);
    this.setState({
      recConfirm: true,
      strokeRec,
      senRec,
      resRec,
      importanceRec,
    });
    //console.log(currentSettings);
    //console.log(this.props.devices[this.props.clickedRow]);
  }

  convertBypass(value) {
    switch (value) {
      case 1:
        return 'Yes';
      case 0:
        return 'No';
      default:
        return 'Unknown';
    }
  }

  processSpeedAccuracy(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Important':
          return 5;
        case 'Very Important':
          return 4;
        case 'Important':
          return 3;
        case 'Standard':
          return 2;
        case 'Not Important':
          return 1;
        default:
          return 2;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Important';
        case 4:
          return 'Very Important';
        case 3:
          return 'Important';
        case 2:
          return 'Standard';
        case 1:
          return 'Not Important';
        default:
          return 'Standard';
      }
    }
  }
  processReliabilityImportance(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Important':
          return 5;
        case 'Very Important':
          return 4;
        case 'Important':
          return 3;
        case 'Standard':
          return 2;
        case 'Not Important':
          return 1;
        default:
          return 2;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Important';
        case 4:
          return 'Very Important';
        case 3:
          return 'Important';
        case 2:
          return 'Standard';
        case 1:
          return 'Not Important';
        default:
          return 'Standard';
      }
    }
  }
  processSatisfaction(value) {
    if (typeof value === 'string') {
      switch (value) {
        case 'Extremely Happy':
          return 5;
        case 'Happy':
          return 4;
        case 'Neutral':
          return 3;
        case 'Unhappy':
          return 2;
        case 'Very Unhappy':
          return 1;
        default:
          return 3;
      }
    } else {
      switch (value) {
        case 5:
          return 'Extremely Happy';
        case 4:
          return 'Happy';
        case 3:
          return 'Neutral';
        case 2:
          return 'Unhappy';
        case 1:
          return 'Very Unhappy';
        default:
          return 'Neutral';
      }
    }
  }

  checkDuplicate = (value, type, valveId, deviceId, currentSerialIndex) => {
    //console.log(value, type, valveId, deviceId, currentSerialIndex);
    const deviceKeys = Object.keys(this.props.devices);
    const devices = this.props.devices;

    for (let i = 0; i < deviceKeys.length; i++) {
      const localType = devices[deviceKeys[i]][type];

      //console.log(localType);
      if (type.includes('History')) {
        for (let j = 0; j < localType.length; j++) {
          if (localType[j] === value) {
            const localDeviceId = devices[deviceKeys[i]].deviceId;
            const localValveId = devices[deviceKeys[i]].valveIDHistory[j];
            if (valveId !== localValveId || deviceId !== localDeviceId) {
              return true;
            }
          }
        }
      } else {
        if (devices[deviceKeys[i]][type] === value) {
          const localDeviceId = devices[deviceKeys[i]].deviceId;
          //const localValveId = Math.parse(devices[deviceKeys[i]].valveIDHistory
          if (deviceId !== localDeviceId) {
            return true;
          }
        }
      }
    }
    return false;
  };

  renderChildRowContent = () => {
    //const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const row = this.props.row;
    const device = this.props.devices[this.props.row.id];
    const currentSerialIndex = !device || !device.serialHistory ? 0 : device.serialHistory.indexOf(this.state.rowChange.currentSerial);
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    let dd = tomorrow.getDate();
    let mm = tomorrow.getMonth() + 1;
    //const yyyy = tomorrow.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    const currentDay = new Date();
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    //console.log(this.state);
    //console.log(this.props.clickedRow, row.id);
    return (
      <React.Fragment>
        <Collapse
          in={
            this.props.rowOpen && (this.props.clickedRow === null || this.props.clickedRow.toString() === row.id.toString()) //type checking
          }
          unmountOnExit={true}
        >
          <div className={!this.props.padding ? null : classes.collapseBody}>
            <form
              onSubmit={(e) => {
                console.log('1');
                //this.handleRowSubmit(e);
              }}
            >
              {!this.props.addPadding ? null : <div style={{ paddingTop: 20 }}></div>}
              <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
                Valve Information
              </Typography>
              <TextField
                variant='standard'
                className={classes.textField}
                label='Tag #'
                onChange={(event) => {
                  //console.log(row.id);
                  let currentRow = this.state.rowChange;
                  currentRow.name = event.target.value;
                  this.setState({
                    changeRow: currentRow,
                    tagError: row.id === -1 ? false : this.checkDuplicate(event.target.value, 'name', device.valveIDHistory[currentSerialIndex], this.state.rowChange.deviceId, currentSerialIndex),
                  });
                }}
                defaultValue={
                  !this.props.clickedRow || this.state.rowChange.name === null || this.props.devices[this.props.clickedRow].name === null ? '' : this.props.devices[this.props.clickedRow].name
                }
              />

              {this.state.tagError ? <Typography color='error'>Duplicate Tag # / Serial Combination.</Typography> : null}
              <TextField
                variant='standard'
                className={classes.textField}
                label='Client'
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.client = event.target.value;
                  this.setState(currentRow);
                }}
                defaultValue={!this.props.clickedRow || !this.props.devices[this.props.clickedRow].client ? '' : this.props.devices[this.props.clickedRow].client}
              />
              <TextField
                variant='standard'
                className={classes.textField}
                label='Site'
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.site = event.target.value;
                  this.setState(currentRow);
                }}
                defaultValue={!this.props.clickedRow || this.state.rowChange.site === null ? '' : this.props.devices[this.props.clickedRow].site}
              />
              <TextField
                variant='standard'
                className={classes.textField}
                label='Application'
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.application = event.target.value;
                  this.setState(currentRow);
                }}
                defaultValue={!this.props.clickedRow || this.state.rowChange.application === null ? '' : this.props.devices[this.props.clickedRow].application}
              />
              <TextField
                variant='standard'
                className={classes.textField}
                label='Description'
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.description = event.target.value;
                  this.setState(currentRow);
                }}
                defaultValue={
                  !this.props.clickedRow || this.state.rowChange.description === null || !this.props.devices[this.props.clickedRow].description
                    ? ''
                    : this.props.devices[this.props.clickedRow].description
                }
              />

              {!!device && !!device.serialHistory && device.serialHistory.length > 1 ? (
                <CustomSelect
                  single
                  id={null}
                  label='Select Valve'
                  //type='sort'
                  disabled={this.state.loading}
                  //onCloseFunction={this.multiSelectOnClose}
                  onChangeFunction={(event, value) => {
                    //console.log(event);
                    const newSerialIndex = !device || !device.serialHistory ? 0 : device.serialHistory.indexOf(value);
                    let currentRow = this.state.rowChange;
                    currentRow.serial = value;
                    currentRow.currentSerial = value;
                    currentRow.bodySize = device.bodySizeHistory[newSerialIndex];
                    currentRow.bodyType = device.bodyTypeHistory[newSerialIndex];
                    //currentRow.valveId = device.valveIdHistory[newSerialIndex];
                    //console.log(currentRow);
                    this.setState(currentRow);
                  }}
                  values={!device.serialHistory ? [] : device.serialHistory[currentSerialIndex]}
                  options={!device.serialHistory ? [] : device.serialHistory}
                  //value={null}
                  helperText={null}
                />
              ) : null}

              <TextField
                variant='standard'
                className={classes.textField}
                label='Serial #'
                disabled={this.props.row.id === -1 || this.state.rowChange.serial === null}
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.serial = event.target.value;
                  this.setState({
                    rowChange: currentRow,
                    serialError:
                      row.id === -1
                        ? false
                        : this.checkDuplicate(
                            //bypass error check on new valve
                            event.target.value,
                            'serialHistory',
                            device.valveIDHistory[currentSerialIndex],
                            this.state.rowChange.deviceId,
                            currentSerialIndex
                          ),
                  });
                }}
                value={!this.state.rowChange.serial ? '' : this.state.rowChange.serial}
              />
              {this.state.serialError ? <Typography color='error'>Duplicate Serial #.</Typography> : null}
              <TextField
                variant='standard'
                className={classes.textField}
                label='Size'
                disabled={this.props.row.id === -1 || this.state.rowChange.bodySize === null}
                type='Number'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <Typography>in.</Typography>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.bodySize = event.target.value;
                  this.setState(currentRow);
                }}
                value={!this.state.rowChange.bodySize ? 0 : this.state.rowChange.bodySize}
              />
              <TextField
                variant='standard'
                className={classes.textField}
                label='Type'
                disabled={this.props.row.id === -1 || this.state.rowChange.bodyType === null}
                onChange={(event) => {
                  let currentRow = this.state.rowChange;
                  currentRow.bodyType = event.target.value;
                  this.setState(currentRow);
                }}
                value={!this.state.rowChange.bodyType ? '' : this.state.rowChange.bodyType}
              />
              <CustomSelect
                single
                id={null}
                label='Bypass'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  let currentRow = this.state.rowChange;
                  let finalValue = value === 'Yes' ? 1 : value === 'No' ? 0 : -1;
                  currentRow.bypass = finalValue;
                  this.setState(currentRow);
                }}
                values={this.convertBypass(this.state.rowChange.bypass)}
                options={['Unknown', 'Yes', 'No']}
                //value={null}
                helperText={null}
              />
              <Grid>
                {this.props.row.bodySize === null && this.props.row.bodyType === null && this.props.row.currentSerial === null && this.props.row.id !== -1 ? (
                  <Typography
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      fontStyle: 'italic',
                    }}
                    color='error'
                  >
                    {`Please upload a test to this device to enter a Serial, Size, and Type.`}
                  </Typography>
                ) : null}
              </Grid>
              <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
                  Recommendations
                </Typography>
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Grid container>
                      <Grid item style={{ marginTop: -1 }}>
                        <InfoPopover resize title={configDetails['accuracyImportance'].title} type='config' useConfigTabs id={'accuracyImportance'} />
                      </Grid>
                      <Grid item>
                        <Typography>How important is it that this valve moves accurately?</Typography>
                      </Grid>
                    </Grid>

                    <CustomSelect
                      single
                      id={null}
                      label='Importance'
                      //type='sort'
                      disabled={this.state.loading}
                      //onCloseFunction={this.multiSelectOnClose}
                      onChangeFunction={(event, value) => {
                        //console.log(event);
                        let currentRow = this.state.rowChange;
                        currentRow.accuracyImportance = this.processSpeedAccuracy(value);
                        this.setState(currentRow);
                      }}
                      values={this.processSpeedAccuracy(!this.state.rowChange ? null : this.state.rowChange.accuracyImportance)}
                      options={['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important', 'Unknown']}
                      //value={null}
                      helperText={null}
                    />
                  </Grid>

                  <Grid item sm={6}>
                    <Grid container>
                      <Grid item style={{ marginTop: -1 }}>
                        <InfoPopover resize title={configDetails['speedImportance'].title} type='config' useConfigTabs id={'speedImportance'} />
                      </Grid>
                      <Grid item>
                        <Typography>How important is it that this valve moves quickly?</Typography>
                      </Grid>
                    </Grid>
                    <CustomSelect
                      single
                      id={null}
                      label='Importance'
                      //type='sort'
                      disabled={this.state.loading}
                      //onCloseFunction={this.multiSelectOnClose}
                      onChangeFunction={(event, value) => {
                        //console.log(event);
                        let currentRow = this.state.rowChange;
                        currentRow.speedImportance = this.processSpeedAccuracy(value);
                        this.setState(currentRow);
                      }}
                      values={this.processSpeedAccuracy(!this.state.rowChange ? null : this.state.rowChange.speedImportance)}
                      options={['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important', 'Unknown']}
                      //value={null}
                      helperText={null}
                    />

                    {/*<Grid item>
                    <Typography>
                      How satisfied are you with the existing performance of the
                      valve when it comes to meeting the needs of the application?
                    </Typography>
                    <SingleSelect
                      id={null}
                      label='Cost'
                      //type="sort"
                      handleChange={(value) => {
                        //console.log(event);
                        let currentRow = this.state.rowChange;
                        currentRow.performanceSatisfaction = this.processSatisfaction(
                          value
                        );
                        console.log(currentRow);
                        this.setState(currentRow);
                      }}
                      options={[
                        'Very Unhappy',
                        'Unhappy',
                        'Neutral',
                        'Happy',
                        'Extremely Happy',
                      ]}
                      selectedOption={this.processSatisfaction(
                        !this.props.clickedRow
                          ? null
                          : this.props.devices[this.props.clickedRow]
                              .performanceSatisfaction
                      )}
                      helperText={null}
                    />
                      </Grid>*/}
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container>
                      <Grid item style={{ marginTop: -1 }}>
                        <InfoPopover resize title={configDetails['reliabilityImportance'].title} type='config' useConfigTabs id={'reliabilityImportance'} />
                      </Grid>
                      <Grid item>
                        <Typography>How important is it that this valve remains reliable?</Typography>
                      </Grid>
                    </Grid>

                    <CustomSelect
                      single
                      id={null}
                      label='Importance'
                      //type='sort'
                      disabled={this.state.loading}
                      //onCloseFunction={this.multiSelectOnClose}
                      onChangeFunction={(event, value) => {
                        //console.log(event);
                        let currentRow = this.state.rowChange;
                        currentRow.reliabilityImportance = this.processSpeedAccuracy(value);
                        this.setState(currentRow);
                      }}
                      values={this.processSpeedAccuracy(!this.state.rowChange ? null : this.state.rowChange.reliabilityImportance)}
                      options={['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important', 'Unknown']}
                      //value={null}
                      helperText={null}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Grid container>
                      <Grid item style={{ marginTop: -1 }}>
                        <InfoPopover resize title={configDetails['importance'].title} type='config' useConfigTabs id={'importance'} />
                      </Grid>
                      <Grid item>
                        <Typography>How important is this valve?</Typography>
                      </Grid>
                    </Grid>

                    <CustomSelect
                      single
                      id={null}
                      label='Importance'
                      //type='sort'
                      disabled={this.state.loading}
                      //onCloseFunction={this.multiSelectOnClose}
                      onChangeFunction={(event, value) => {
                        //console.log(event);
                        let currentRow = this.state.rowChange;
                        currentRow.importance = value;
                        this.setState(currentRow);
                      }}
                      values={!this.state.rowChange.importance ? 'Unknown' : this.state.rowChange.importance}
                      options={['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important', 'Unknown']}
                      //value={null}
                      helperText={null}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
                Targets
              </Typography>
              <Grid container style={{ marginBottom: 20 }}>
                <TextField
                  variant='standard'
                  className={classes.textField}
                  label='Resolution Target'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>%</Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  onChange={(event) => {
                    if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value < 100 && event.target.value > 0 && event.target.value.indexOf('e') === -1)) {
                      let currentRow = this.state.rowChange;
                      currentRow.resApp = event.target.value === '' ? this.calculateDefaults('resolution') : event.target.value;
                      this.setState(currentRow);
                    }
                  }}
                  value={!this.state.rowChange || !this.state.rowChange.resApp ? this.calculateDefaults('resolution') : this.state.rowChange.resApp}
                  helperText={
                    !this.state.rowChange || !this.state.rowChange.resApp || this.state.rowChange.resApp === this.calculateDefaults('resolution') ? (
                      <span className={classes.blueText}>(System Default)</span>
                    ) : (
                      ''
                    )
                  }
                />
                <TextField
                  variant='standard'
                  className={classes.textField}
                  label='Sensitivity Target'
                  type='number'
                  onChange={(event) => {
                    if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value < 100 && event.target.value > 0 && event.target.value.indexOf('e') === -1)) {
                      let currentRow = this.state.rowChange;
                      currentRow.senApp = event.target.value === '' ? this.calculateDefaults('sensitivity') : event.target.value;

                      this.setState(currentRow);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>%</Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  value={!this.state.rowChange || !this.state.rowChange.senApp ? this.calculateDefaults('sensitivity') : this.state.rowChange.senApp}
                  helperText={
                    !this.state.rowChange || !this.state.rowChange.senApp || this.state.rowChange.senApp === this.calculateDefaults('sensitivity') ? (
                      <span className={classes.blueText}>(System Default)</span>
                    ) : (
                      ''
                    )
                  }
                />
                <TextField
                  variant='standard'
                  className={classes.textField}
                  label='Stroke Speed Target'
                  type='number'
                  onChange={(event) => {
                    if (event.target.value === '' || (!isNaN(event.target.value) && event.target.value >= 0 && event.target.value.indexOf('e') === -1)) {
                      let currentRow = this.state.rowChange;
                      currentRow.strokeApp = event.target.value === '' ? this.calculateDefaults('stroke') : event.target.value;

                      this.setState(currentRow);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Typography>s</Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0 },
                  }}
                  value={!this.state.rowChange || !this.state.rowChange.strokeApp ? this.calculateDefaults('stroke') : this.state.rowChange.strokeApp}
                  helperText={
                    !this.state.rowChange || !this.state.rowChange.strokeApp || this.state.rowChange.strokeApp === this.calculateDefaults('stroke') ? (
                      <span className={classes.blueText}>(System Default)</span>
                    ) : (
                      ''
                    )
                  }
                />
                {/*<TextField
                  variant='standard'
                  id='date'
                  label='Change Effective'
                  type='date'
                  defaultValue={currentDay}
                  className={classes.textField}
                  onChange={(event) => {
                    let currentRow = this.state.rowChange;
                    currentRow.effectiveTargetDate = new Date(event.target.value).getTime() / 1000;
                    //console.log(currentRow);
                    this.setState(currentRow);
                  }}
                />*/}
                <DatePicker
                  label='Change Effective'
                  value={new Date(this.state.rowChange.effectiveTargetDate * 1000)}
                  date={currentDay}
                  dateFunction={(startDate) => {
                    let currentRow = this.state.rowChange;
                    currentRow.effectiveTargetDate = startDate.getTime() / 1000;
                    //console.log(currentRow);
                    this.setState(currentRow);
                  }}
                ></DatePicker>
                {/*<Typography
                variant='subtitle1'
                color='primary'
                className={classes.rowTitle}
              >
                Performance
              </Typography>
              <Grid container spacing={4}>
                <Grid item>
                  <Typography>
                    What is the cost to the plant if the valve fails to meet its
                    performance requirements or fails during operations?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Cost'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['2 - Subsystem Shutdown or Sustained KPI Loss']}
                    selectedOption={'2 - Subsystem Shutdown or Sustained KPI Loss'}
                    helperText={null}
                  />
                </Grid>
                <Grid item>
                  <Typography>Does this valve have a bypass?</Typography>
                  <SingleSelect
                    id={null}
                    label='Bypass'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
              </Grid>
              <div className={classes.dividerMd}></div>
              <Typography
                variant='subtitle1'
                color='primary'
                className={classes.rowTitle}
              >
                Reliability
              </Typography>
              <Grid container spacing={4}>
                <Grid item>
                  <Typography>
                    Has this valve been tested for early mortality or metallurgy
                    issues?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Tested'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
                <Grid item>
                  <Typography>
                    Has the performance of this valve been suspect or caused a plant
                    upset in the past?
                  </Typography>
                  <SingleSelect
                    id={null}
                    label='Bypass'
                    //type="sort"
                    //handleChange={this.updateSort}
                    options={['Yes', 'No']}
                    selectedOption={'No'}
                    helperText={null}
                  />
                </Grid>
              </Grid>*/}
              </Grid>
              {(!this.props.row.client && !this.state.rowChange.client) ||
              (!this.props.row.name && !this.state.rowChange.name) ||
              (!this.props.row.site && !this.state.rowChange.site) ||
              (!this.props.row.bodySize && this.props.row.bodySize !== null && !this.state.rowChange.bodySize && this.props.row.id !== -1) ? (
                <Typography className={classes.error} color='error'>
                  {`The following fields are required: ${!this.props.row.site && !this.state.rowChange.site ? `Site, ` : ''}${
                    !this.props.row.client && !this.state.rowChange.client ? `Client, ` : ''
                  }${!this.props.row.name && !this.state.rowChange.name ? `Tag, ` : ''}${
                    !this.props.row.bodySize && !this.state.rowChange.bodySize && this.props.row.bodySize !== null && this.props.row.id !== -1 ? `Body Size, ` : ''
                  }`.slice(0, -2)}
                </Typography>
              ) : null}
              <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
              <Grid container justifyContent='space-between' style={{ marginBottom: -8 }}>
                <Grid item>
                  {this.state.status !== '' ? (
                    <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                      {`${this.state.statusMessage}`}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item>
                  <Button type='text' color='secondary' onClick={this.recommendTargets} style={{ marginRight: 8 }}>
                    View Recommendations
                  </Button>
                  {!this.props.closeFunction ? null : (
                    <Button onClick={this.props.closeFunction} type='text' color='secondary' value='Close' style={{ marginRight: 8 }}>
                      Close
                    </Button>
                  )}
                  <Button
                    type='text'
                    onClick={(e) => {
                      //console.log('click');
                      this.handleRowSubmit(e);
                    }}
                    disabled={
                      this.state.loading ||
                      this.state.tagError ||
                      this.state.serialError ||
                      (!this.props.row.client && !this.state.rowChange.client) ||
                      (!this.props.row.name && !this.state.rowChange.name) ||
                      (!this.props.row.site && !this.state.rowChange.site) ||
                      (!this.props.row.bodySize && !this.state.rowChange.bodySize && this.props.row.bodySize !== null && this.props.row.id !== -1)
                    }
                    color='secondary'
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Collapse>
      </React.Fragment>
    );
  };

  render() {
    //console.log(this.props.row.id, this.props.clickedRow);

    return (
      <React.Fragment>
        <Confirm
          open={this.state.recConfirm}
          title={'Change Application Targets'}
          content={
            <React.Fragment>
              <Typography variant='subtitle1'>Would you like to use the following recommended targets?</Typography>
              <Typography style={{ marginTop: 10 }}>Resolution: {this.state.resRec}%</Typography>
              <Typography style={{ marginTop: 5 }}>Sensitivity: {this.state.senRec}%</Typography>
              <Typography style={{ marginTop: 5 }}>Stroke: {this.state.strokeRec}s</Typography>
              <Typography style={{ marginTop: 5 }}>Importance: {this.processSpeedAccuracy(this.state.importanceRec)}</Typography>
            </React.Fragment>
          }
          handleConfirm={() => {
            let currentRow = this.state.rowChange;
            currentRow.senApp = this.state.senRec;
            currentRow.resApp = this.state.resRec;
            currentRow.strokeApp = this.state.strokeRec;
            currentRow.importance = this.processSpeedAccuracy(this.state.importanceRec);
            //this.updateUser(false);
            //console.log(currentRow);
            this.setState({ recConfirm: false, rowChange: currentRow });
            //console.log(this.state.rowChange);
          }}
          handleClose={() => {
            this.setState({
              recConfirm: false,
              strokeRec: null,
              senRec: null,
              resRec: null,
              importanceRec: null,
            });
          }}
        />
        {this.renderChildRowContent()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { devices: state.devices.devices };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDeviceByTest,
  fetchDevicesSummaryVerbose,
  setDevicesSummaryStatus,
})(withStyles(styles)(OptimizeContent));
