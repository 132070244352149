import React from 'react';
//import Grid from '@mui/material/Grid';
import WidgetTitle from '../../../headers/WidgetTitle';
import {
  //Backdrop,
  Button,
  //CircularProgress,
  //Collapse,
  Grid,
  /*Input,
  Tooltip,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Table,*/
  //IconButton,
  TextField,
  Typography,
  //Typography,
} from '@mui/material';
//import Confirm from '../../../dialogs/Confirm';
//import DialogContentText from '@mui/material/DialogContentText';
/*import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';*/
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import CustomSelect from '../../../filters/CustomSelect';
import TableWidget from '../../../widgets/TableWidget';
import api from '../../../../apis/api';
import { fetchSites, fetchLocations, fetchApplications, fetchRealms } from '../../../../actions/index';
import matchFilter from '../../../filters/functions/searchFilter';
//import ExpandableSection from '../../../helpers/ExpandableSection';
import {
  // Delete,
  AddBox,
  // CheckCircle,
  //Undo,
  Info,
  Edit,
  //ThumbUpAlt,
  //ThumbDownAlt,
} from '@mui/icons-material';
import DropdownMenu from '../../../menus/DropdownMenu';
import CustomDialog from '../../../popup/CustomDialog';

class DemoConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignOpen: false,
      status: '',
      statusMessage: '',
      siteOpen: false,
      userList: [],
      deleteConfirm: false,
      changeConfirm: false,
      clickedRow: null,
      dialogOpen: false,
      rowOpen: false,
      userId: -1,
      username: '',
      email: '',
      first: '',
      last: '',
      password: '',
      confirmPassword: '',
      permission: 99,
      realms: [],
      sites: [],
      applications: [],
      locations: [],
      filterStr: '',
      loading: '',
      type: 'Demo',
      filterObj: { realms: '', sites: '', locations: '', applications: '' },
    };
    this.assignClose = this.assignClose.bind(this);
  }
  async componentDidMount() {
    if (this.state.userList.length === 0) {
      const url = `getDemoList`;
      const response = await api.get(url);
      //console.log(response);
      this.setState({ demoList: response.data });
    }
    if (this.state.userList.length === 0) {
      const url = `getUserList`;
      const response = await api.get(url);
      //console.log(response);
      this.setState({ userList: response.data });
    }
    if (!this.props.sites || Object.entries(this.props.sites).length === 0) {
      this.props.fetchSites();
      this.props.fetchLocations();
      this.props.fetchApplications();
    }
  }

  resetDemo = async (name, type, quickFlag) => {
    const url = `/resetDemo`;

    this.setState({ loading: 'Reseting Realm...this may take a while...' });
    const response = await api.post(url, { name: name, type: type, quickFlag: quickFlag }, { timeout: 2 * 60 * 1000 }); //set timeout to 2 minutes
    //console.log(response);
    if (response.status === 200) {
      this.setState({ loading: '' });
      /*const url = `getDemoList`;
      const response = await api.get(url);
      //console.log(response);
      this.setState({ demoList: response.data });
      //window.location.reload(false);*/
    }
  };

  releaseDemo = async (user, name) => {
    const url = `/releaseDemo`;
    this.setState({ loading: 'Releasing User...' });
    const response = await api.post(url, { user: user });
    console.log(response);
    if (response.status === 200) {
      this.setState({ loading: 'User Released. Reseting Realm...this may take a while...' });
      const url = `/resetDemo`;
      const response2 = await api.post(url, { name: name }, { timeout: 2 * 60 * 1000 }); //set timeout to 2 minutes
      console.log(response2);
      if (response2.status === 200) {
        this.setState({ loading: '' });
        const url = `getDemoList`;
        const response = await api.get(url);
        //console.log(response);
        this.setState({ demoList: response.data });
        this.props.fetchRealms();
      }
      //window.location.reload(false);
    }
  };

  assignDemo = async (user) => {
    //console.log(this.state);
    const url = `/assignDemo`;
    this.setState({ loading: 'Assigning Demo...' });
    const response = await api.post(url, {
      user: this.state.assignDemoUser,
      days: this.state.assignDemoLength,
      name: this.state.assignDemoId,
      type: this.state.type,
    });
    //console.log(response);
    if (response.status === 200) {
      this.setState({ loading: '' });
      const url = `getDemoList`;
      const response = await api.get(url);
      this.setState({ demoList: response.data, assignOpen: false });
      this.props.fetchRealms();
      //window.location.reload(false);
    }
  };

  showAssignForm() {
    const fullUserList = !this.state ? [] : this.state.userList.map((a) => a.username);
    const assignedUsers = !this.state || !this.state.demoList ? [] : this.state.demoList.map((a) => a.username);
    const availableUsers = fullUserList.filter((el) => !assignedUsers.includes(el));
    return (
      <CustomDialog
        center
        open={this.state.assignOpen}
        onClose={this.assignClose}
        title='Assign Demo'
        content={
          <React.Fragment>
            <form style={{ marginTop: 20 }}>
              <CustomSelect
                single
                id={null}
                label={`User`}
                //type='sort'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.setState({ assignDemoUser: value });
                }}
                values={assignedUsers.includes(this.state.assignDemoUser) ? availableUsers[0] : this.state.assignDemoUser}
                options={availableUsers}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id={null}
                label={`Type`}
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.setState({ type: value });
                }}
                values={this.state.type}
                options={['Demo', 'Training', 'Template']}
                //value={null}
                helperText={null}
              />
              <CustomSelect
                single
                id={null}
                label={`Rental Length (Days)`}
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={(event, value) => {
                  this.setState({ assignDemoLength: value });
                }}
                values={!this.state.assignDemoLength ? '1' : this.state.assignDemoLength.toString()}
                options={[/*-2,*/ '1', '2', '3', '4', '5', '6', '7']}
                //value={null}
                helperText={null}
              />
            </form>
            {this.state.status !== '' ? <Typography variant='subtitle1' color={this.state.status === 'Failure' ? 'error' : 'secondary'}>{`${this.state.statusMessage}`}</Typography> : null}
            {this.state.loading !== '' ? <Typography variant='subtitle1' color={'secondary'}>{`${this.state.loading}`}</Typography> : null}
          </React.Fragment>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.assignClose} color='secondary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.assignDemo();
              }}
              color='secondary'
            >
              Submit
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }

  assignOpen(demoId) {
    this.setState({
      assignDemoUser: this.props.userId,
      assignDemoId: demoId,
      assignDemoLength: 1,
      assignOpen: true,
    });
  }

  assignClose() {
    this.setState({
      assignDemoUser: this.props.userId,
      assignDemoId: -1,
      assignDemoLength: -1,
      assignOpen: false,
    });
  }

  //TODO

  render() {
    const { classes } = this.props;
    const headerArr = [
      { id: 'name', label: 'Demo Realm' },
      { id: 'username', label: 'Current Renter' },
      { id: 'demo_return2', label: 'Rental Ends' },
      { id: 'menu', label: 'Menu' },
    ];
    //console.log(this.state.filterStr);
    let data = !this.state.demoList ? [] : matchFilter(this.state.demoList, this.state.filterStr);

    //console.log(this.props);
    //console.log(data);
    /*let data = [
      {
        id: 1,
        realm_name: 'Demo One',
        current_loan: 'william.oconnell@trueanalytic.com',
        loan_end: '8/26/2021',
        menu: '',
      },
      {
        id: 2,
        realm_name: 'Demo Two',
        current_loan: '',
        loan_end: '',
        menu: '',
      },
    ];*/

    //USERS MAY NOT ASSIGN OR RELEASE A REALM FROM INSIDE OF THE REALM
    for (let i = 0; i < data.length; i++) {
      let menuOptions = [];

      if (data[i].demo_return === null && data[i].realmId !== this.props.current_realm) {
        menuOptions.push({
          id: data[i].id,
          title: 'Assign Realm',
          icon: Info,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.assignOpen(data[i].name);
          },
        });
      } else {
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Demo Configuration',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Demo');
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Training Configuration',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Training');
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Template Configuration',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Template');
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Demo Configuration (Quick)',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Demo', true);
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Training Configuration (Quick)',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Training', true);
          },
        });
        menuOptions.push({
          id: data[i].id,
          title: 'Reset to Template Configuration (Quick)',
          icon: Edit,
          onClick: () => {
            //console.log('More Info ' + data[i].id);
            this.resetDemo(data[i].name, 'Template', true);
          },
        });
        if (data[i].realmId !== this.props.current_realm) {
          menuOptions.push({
            id: data[i].id,
            title: 'Release Realm',
            icon: AddBox,
            onClick: () => {
              //console.log('More Info ' + data[i].id);
              this.releaseDemo(data[i].username, data[i].name);
            },
          });
        }
      }

      data[i].demo_return2 = data[i].demo_return === null ? '' : new Date(data[i].demo_return * 1000).toDateString();
      data[i].id = i;
      data[i].menu = <DropdownMenu disabled={this.state.loading !== ''} tooltip='Actions' options={menuOptions} />;
    }

    if (this.props.permission > 30) {
      //TODO Forcefull link to another section?
      return <React.Fragment>You do not have permission to view this module</React.Fragment>;
    }
    return (
      <React.Fragment>
        <WidgetTitle title='Demo List'></WidgetTitle>
        {this.showAssignForm()}

        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item>
            <TextField
              variant='outlined'
              InputProps={{ classes: { root: classes.inlineInput } }}
              className={classes.searchField}
              placeholder='Search'
              hiddenLabel
              onChange={(event) => {
                //console.log(event.target.value);
                this.setState({ filterStr: event.target.value });
              }}
              value={this.state.filterStr}
              /*defaultValue={
                  !this.props.clickedRow
                    ? null
                    : this.props.devices[this.props.clickedRow].application
                }*/
              InputLabelProps={{
                classes: {
                  root: classes.inputLabelFix,
                },
              }}
            />
          </Grid>
        </Grid>

        <TableWidget
          data={this.state.loading !== '' ? [{ id: 1, name: this.state.loading }] : data}
          header={headerArr}
          rows={25}
          type='userConfig'
          //handleClick={this.handleRowClick}
          //renderChildRowContent={this.renderChildRowContent}
        ></TableWidget>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    sites: state.sites,
    locations: state.locations,
    applications: state.applications,
  };
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
  fetchSites,
  fetchLocations,
  fetchApplications,
  fetchRealms,
})(withStyles(styles)(DemoConfig));
