import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { supportSetActiveTab } from '../../../actions';
import { supportSetActiveOptimize } from '../../../actions';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

class Contact extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginTop: 20, marginBottom: 10 }}>
          You can contact us at support@ten-one.app.
        </Typography>
        <Typography variant='body1'>
          Our hours are from 8 - 6 PM CST Monday through Friday, excluding
          holidays. You can expect we will respond within 24 hours, though
          evenings, weekends, and holidays may cause us to take a little bit
          longer. While you wait, be sure to read through our{' '}
          <Link
            onClick={() => {
              this.props.supportSetActiveOptimize('gettingstarted');
            }}
            to='/support/Optimize/Start'
            className={classes.textLink}
          >
            getting started
          </Link>{' '}
          guide and{' '}
          <Link
            onClick={() => {
              this.props.supportSetActiveOptimize('faq');
            }}
            to='/support/Optimize/FAQ'
            className={classes.textLink}
          >
            frequently asked questions(FAQ)
          </Link>
          . They might have the information you’re looking for.
        </Typography>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.support.activeTab,
    activeOptimize: state.support.activeOptimize,
  };
};

export default connect(mapStateToProps, {
  supportSetActiveTab,
  supportSetActiveOptimize,
})(withStyles(styles, { withTheme: true })(Contact));
