import _ from 'lodash';
import {
  FETCH_EVENTS,
  CHANGE_EVENT_SELECTIONS,
  CHANGE_EVENT_SELECTIONS_CONSOLIDATED /*,
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const eventReducer = (state = {}, action) => {
  switch (action.type) {
    /*case FETCH_LOCATION:
      return { ...state, [action.payload.eventId]: action.payload };*/
    case FETCH_EVENTS: {
      let payload = action.payload;
      for (let i = 0; i < payload.length; i++) {
        let eventLength = payload[i].incidentDevices.length;
        payload[i].selected = Array.from(Array(eventLength), (_, j) => j === eventLength - 1);
      } //add selected option to event based on number of incidents

      return { ...state, ..._.mapKeys(payload, 'id') };
    }
    case CHANGE_EVENT_SELECTIONS: {
      /*let devices = Object.assign({}, state.devices);
      devices[action.payload] = {};
      return {
        ...state,
        devices,
      };*/
      //console.log(action.payload);
      let newState = Object.assign({}, state);
      if (action.payload.indexes instanceof Array) {
        for (let i = 0; i < action.payload.indexes.length; i++) {
          newState[action.payload.id].selected[action.payload.indexes[i]] = action.payload.value;
        }
      } else {
        newState[action.payload.id].selected[action.payload.indexes] = action.payload.value;
      }

      return newState;
    }
    case CHANGE_EVENT_SELECTIONS_CONSOLIDATED: {
      /*let devices = Object.assign({}, state.devices);
      devices[action.payload] = {};
      return {
        ...state,
        devices,
      };*/
      //console.log(action.payload);
      let newState = Object.assign({}, state);
      newState[action.payload.id].selectedConsolidated = action.payload.selectedConsolidated;
      if (action.payload.indexes instanceof Array) {
        for (let i = 0; i < action.payload.indexes.length; i++) {
          newState[action.payload.id].selectedConsolidated[action.payload.indexes[i]] = action.payload.value;
        }
      } else {
        newState[action.payload.id].selectedConsolidated[action.payload.indexes] = action.payload.value;
      }

      return newState;
    }
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default eventReducer;
