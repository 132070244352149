import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Grid, IconButton, Tooltip, Paper } from '@mui/material';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import functions from '../../functions/functions';
import _ from 'lodash';

class MultiStockChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
      zoomCurrent: false,
    };
  }
  componentDidUpdate() {
    setTimeout(() => window.dispatchEvent(new Event('resize')));
    if (this.state.zoom !== this.state.zoomCurrent) {
      this.setState({ zoomCurrent: this.state.zoom });
    }
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let newOptions = {
      chart: {
        height: printStyles ? 220 : !this.state.zoom ? 280 : 500,
      },
    };
    let finalOptions = _.mergeWith(this.props.options, newOptions);
    //console.log(finalOptions);
    return (
      <Grid
        item
        sm={12}
        md={
          this.props.chartCount > 3 && !this.state.zoom && (this.props.embed || this.props.type === 'reliability')
            ? 6
            : this.props.chartCount > 2 && !this.state.zoom && !printStyles && (this.props.embed || this.props.type === 'reliability')
            ? 4
            : !this.state.zoom && !printStyles && this.props.embed
            ? 6
            : 12
        }
      >
        <Paper
          elevation={!this.props.embed ? 3 : 0}
          className={`${!this.props.embed ? null : classes.embedPaper} ${printStyles ? classes.printPaper : classes.paper} ${classes.avoidBreak}`}
          style={{ marginBottom: 4 }}
        >
          {!this.props.embed ? null : (
            <Grid container justifyContent='space-between'>
              <Grid item></Grid>
              <Grid item className={classes.chartButtonContainer} style={this.props.format ? { display: 'none' } : null}>
                {printStyles ? null : (
                  <Tooltip arrow placement='top' title={!!this.state.zoom ? 'Collapse' : 'Expand'} aria-label={!!this.state.zoom ? 'Collapse' : 'Expand'}>
                    <IconButton
                      className={classes.chartButton}
                      onClick={() =>
                        this.setState({
                          zoom: !this.state.zoom,
                        })
                      }
                      size='large'
                    >
                      {this.state.zoom ? <FullscreenExit></FullscreenExit> : <Fullscreen></Fullscreen>}
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          )}
          <HighchartsReact highcharts={this.props.highcharts} constructorType={'stockChart'} options={finalOptions} callback={this.props.callback} />
        </Paper>
      </Grid>
    );
  }
}
export default withStyles(styles)(MultiStockChart);
