import React from 'react';
import { Grid, Typography, Checkbox, Button } from '@mui/material';
import functions from '../../../../functions/functions';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { connect } from 'react-redux';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PrioritizeMultiStockChart from '../../../charts/wrappers/PrioritizeMultiStockChart';
import { changeEventSelectionsConsolidated } from '../../../../actions/index';

class EventContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }
  //!duplicate function from AccordianWidgetPrioritize.js
  consolidateEvents(events) {
    const eventList = !events.incidentTimes ? [] : events.incidentTimes;
    const eventDevices = !events.incidentDevices ? [] : events.incidentDevices;
    const eventDeviceNames = !events.incidentDeviceNames ? [] : events.incidentDeviceNames;
    const eventSelection = !events.selected ? [] : events.selected;
    let eventListCombined = [];
    let eventDevicesCombined = [];
    let eventDeviceNamesCombined = [];
    let selectedCombined = [];
    let tempEventStart = 0;
    let tempEventEnd = 0;
    let tempEventDevices = [];
    let tempEventDeviceNames = [];
    let tempSelected = false;

    for (let i = 0; i < eventList.length; i++) {
      if (tempEventStart === 0) {
        //first entry
        tempEventStart = eventList[i][0];
        tempEventEnd = eventList[i][1];
        tempEventDevices = eventDevices[i];
        tempEventDeviceNames = eventDeviceNames[i];
      } else if (tempEventEnd !== 0 && eventList[i][0] > tempEventEnd) {
        //push finished event
        eventListCombined.push([tempEventStart, tempEventEnd]);
        eventDevicesCombined.push(tempEventDevices);
        eventDeviceNamesCombined.push(tempEventDeviceNames);
        selectedCombined.push(tempSelected);
        //start new event
        tempEventStart = eventList[i][0];
        tempEventEnd = eventList[i][1];
        tempEventDevices = eventDevices[i];
        tempEventDeviceNames = eventDeviceNames[i];
        tempSelected = eventSelection[i];
      } else if (eventList[i][1] >= tempEventEnd) {
        //event continues or extends exisiting
        if (eventSelection[i] === true) {
          tempSelected = true;
        }
        tempEventEnd = eventList[i][1];
        for (let j = 0; j < eventDevices[i].length; j++) {
          if (!tempEventDevices.includes(eventDevices[i][j])) {
            tempEventDevices.push(eventDevices[i][j]);
            tempEventDeviceNames.push(eventDeviceNames[i][j]);
          }
        }
      } else if (eventList[i][0] >= tempEventStart) {
        //event occurs inside existing event
        if (eventSelection[i] === true) {
          tempSelected = true;
        }
        for (let j = 0; j < eventDevices[i].length; j++) {
          if (!tempEventDevices.includes(eventDevices[i][j])) {
            tempEventDevices.push(eventDevices[i][j]);
            tempEventDeviceNames.push(eventDeviceNames[i][j]);
          }
        }
      }
    }
    //push last event in buffer
    eventListCombined.push([tempEventStart, tempEventEnd]);
    eventDevicesCombined.push(tempEventDevices);
    eventDeviceNamesCombined.push(tempEventDeviceNames);
    if (eventSelection[eventList.length - 1] === true) {
      tempSelected = true;
    }
    selectedCombined.push(tempSelected);
    let tempEvents = Object.assign({}, events);
    tempEvents.incidentTimesConsolidated = eventListCombined;
    tempEvents.incidentDevicesConsolidated = eventDevicesCombined;
    tempEvents.incidentDeviceNamesConsolidated = eventDeviceNamesCombined;
    tempEvents.selectedConsolidated = !!events.selectedConsolidated ? events.selectedConsolidated : selectedCombined;
    tempEvents.eventCountConsolidated = eventListCombined.length;
    return tempEvents;
  }
  renderSelectedEvents(events) {
    const { classes } = this.props;
    //console.log(events);
    //const recentEvent = !event.incidentTimes ? [null, null] : event.incidentTimes[event.incidentTimes.length - 1];

    const eventList = !events.incidentTimesConsolidated ? [] : events.incidentTimesConsolidated;
    const eventDevice = !events.incidentDeviceNamesConsolidated ? [] : events.incidentDeviceNamesConsolidated;
    //const { eventList, eventDevice } = this.consolidateEvents(!events.incidentTimes ? [] : events.incidentTimes, !events.incidentDeviceNames ? [] : events.incidentDeviceNames);
    //console.log(consolidatedEvents);
    if (!events.selected[/*eventList.length - 1*/ 0]) {
      //this.props.changeEventSelections(events.id, /*eventList.length - 1*/ 0, true);
    }
    //console.log(eventList);
    //console.log(eventDevice);

    return eventList.map((event, index) =>
      events.selectedConsolidated[index] === false ? null : (
        <Grid container spacing={1} key={`${event.eventName}event_list_${index}`} style={{ marginBottom: 4 }}>
          <Grid item>
            <Checkbox
              key={`${event.eventName}event_list_box_${index}`}
              checked={events.selectedConsolidated[index]}
              onClick={(e) => {
                this.props.changeEventSelectionsConsolidated(events.id, index, !events.selectedConsolidated[index], events.selectedConsolidated);
              }}
              className={classes.checkbox}
              style={{ marginTop: -2 }}
              color='secondary'
            ></Checkbox>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>{!eventDevice[index] ? 'Unknown' : eventDevice[index].toString().replace(',', ', ')}</Typography>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>
              <span style={{ fontSize: 14 }}>Start:</span> {functions.dateToString(event[0] * 1000, 'date')}
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>
              <span style={{ fontSize: 14 }}>End:</span> {functions.dateToString(event[1] * 1000, 'date')}
            </Typography>
          </Grid>
        </Grid>
      )
    );
  }
  renderUnselectedEvents(events) {
    const { classes } = this.props;
    const eventList = !events.incidentTimesConsolidated ? [] : events.incidentTimesConsolidated;
    const eventDevice = !events.incidentDeviceNamesConsolidated ? [] : events.incidentDeviceNamesConsolidated;
    return eventList.map((event, index) =>
      events.selectedConsolidated[index] === false ? (
        <Grid container spacing={1} key={`${event.eventName}event_list_${index}`} style={{ marginBottom: 4 }}>
          <Grid item>
            <Checkbox
              key={`${event.eventName}event_list_box_${index}`}
              checked={events.selectedConsolidated[index]}
              onClick={(e) => {
                // console.log(events.id, index, !events.selectedConsolidated[index]);
                this.props.changeEventSelectionsConsolidated(events.id, index, !events.selectedConsolidated[index], events.selectedConsolidated);
              }}
              className={classes.checkbox}
              style={{ marginTop: -2 }}
              color='secondary'
            ></Checkbox>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>{!eventDevice[index] ? 'Unknown' : eventDevice[index].toString().replace(',', ', ')}</Typography>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>
              <span style={{ fontSize: 14 }}>Start:</span> {functions.dateToString(event[0] * 1000, 'date')}
            </Typography>
          </Grid>
          <Grid item style={{ marginRight: 16 }}>
            <Typography key={`${event.eventName}event_list_text_${index}`}>
              <span style={{ fontSize: 14 }}>End:</span> {functions.dateToString(event[1] * 1000, 'date')}
            </Typography>
          </Grid>
        </Grid>
      ) : null
    );
  }
  calculateEventPadding(recentEvent, issueType) {
    //let padding = (recentEvent[1] - recentEvent[0]) * 0.15;
    switch (issueType) {
      case 'Stuck Reading':
      case 'Redundant Error':
      case 'Control Range':
      case 'Volatility':
        return (60 * 60 * 24 * 7) / 2;
      case 'Data Resolution':
      case 'Saturation':
      case 'Loop Error':
      case 'Trend Change':
      case 'Stability':
      case 'Signal Inconsistency':
        return (60 * 60 * 24 * 30) / 2;
      default:
        return (recentEvent[1] - recentEvent[0]) * 0.15;
    }
  }
  renderIssueCharts(issueType, event) {
    //console.log(event.selected);
    //const { classes } = this.props;
    let recentEvent = [null, null];
    for (let i = 0; i < event.selected.length; i++) {
      if (event.selectedConsolidated[i] && !!event.incidentTimesConsolidated[i]) {
        if (recentEvent[0] === null || recentEvent[0] > parseInt(event.incidentTimesConsolidated[i][0])) {
          recentEvent[0] = parseInt(event.incidentTimesConsolidated[i][0]);
        }
        if (recentEvent[1] === null || recentEvent[1] < parseInt(event.incidentTimesConsolidated[i][1])) {
          recentEvent[1] = parseInt(event.incidentTimesConsolidated[i][1]);
        }
      }
    }
    //console.log(issueType);
    const recentEventWindowPadding = this.calculateEventPadding(recentEvent, issueType);
    //console.log(recentEvent);
    //console.log(issueType);

    //Fix for Split between Saturated Valve and Saturated Intrument
    let adjustedIssueType = issueType.toLowerCase().includes('saturated') ? 'Saturation' : issueType;
    return (
      <PrioritizeMultiStockChart
        // issueFlag={false}
        initialWidth={6}
        embed
        manualVariables={['Primary', adjustedIssueType]}
        //manualVariables={['Primary', 'Oscillation Amplitude', 'Volatility', 'Stability', 'Trend Change', 'Control Range']}
        //manualRange={'365'}
        minExtreme={!event.incidentTimes || event.incidentTimes.length === 0 ? recentEvent[0] : (parseInt(recentEvent[0]) - recentEventWindowPadding) * 1000}
        maxExtreme={!event.incidentTimes || event.incidentTimes.length === 0 ? recentEvent[1] : (parseInt(recentEvent[1]) + recentEventWindowPadding) * 1000}
        issueFilter={[issueType] /*filterIssues(this.state.issues)*/}
        shutdownFlag={true /*this.state.shutdowns*/}
      />
    );
  }
  renderShowMoreButton = () => {
    const { classes } = this.props;
    return (
      <Button onClick={this.handleEventToggle} style={{ marginLeft: -8, marginTop: 8, marginBottom: 8 }}>
        {this.state.showMore ? (
          <React.Fragment>
            <KeyboardArrowUp className={classes.buttonIcon}></KeyboardArrowUp>Hide Unselected Events
          </React.Fragment>
        ) : (
          <React.Fragment>
            <KeyboardArrowDown className={classes.buttonIcon}></KeyboardArrowDown>Show More Events
          </React.Fragment>
        )}
      </Button>
    );
  };
  handleEventToggle = () => {
    this.setState({ showMore: !this.state.showMore });
  };
  render() {
    const { classes } = this.props;
    const events = !this.props.events ? {} : this.props.events[`${this.props.activeLoop}${this.props.row.issue}`];

    //console.log(events);
    //console.log(!this.props.events ? {} : this.props.events[`${this.props.activeLoop}${this.props.row.issue}`].selected);
    //console.log(this.props.events);
    const consolidatedEvents = this.consolidateEvents(events);
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
          {events.incidentTimes.length > 0 ? `Events` : `No Events Found`}
        </Typography>

        {this.renderSelectedEvents(consolidatedEvents, this.props.rowIndex)}
        {consolidatedEvents.selectedConsolidated.includes(false) && events.incidentTimes.length > 0 ? this.renderShowMoreButton() : null}
        {!this.state.showMore ? null : this.renderUnselectedEvents(consolidatedEvents, this.props.rowIndex)}
        <div className={classes.dividerMd}></div>

        <Grid container spacing={1}>
          {this.renderIssueCharts(this.props.row.issue, consolidatedEvents)}
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activeLoop: state.prioritize.activeLoop,
    loops: state.loops.loops,
    devices: state.devices.devices,
    events: state.events,
  };
};

export default connect(mapStateToProps, { changeEventSelectionsConsolidated })(withStyles(styles)(EventContent));
