import React from 'react';
import { Button, Checkbox, Grid, TextField, IconButton, MenuItem, Tooltip, Typography, Paper, Popper, ClickAwayListener, Link } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OptimizeSpecs from '../../widgets/OptimizeSpecs';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import ValveChartWidget from '../../widgets/ValveChartWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import CustomSelect from '../../filters/CustomSelect';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  fetchTest,
  setTestStatus,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
} from '../../../actions/index';
import DiagnosticsWidgets from './DiagnosticsWidgets';
import ImprovementsWidgets from './ImprovementsWidgets';
import AlignmentWidgets from './AlignmentWidgets';
import InfoPopover from '../../popup/InfoPopover.js';
import { Merge, Update, Settings, MoreVert, Speed } from '@mui/icons-material';
import OptimizeContent from '../config/content/OptimizeContent';
import OptimizeTestMerge from '../config/content/OptimizeTestMerge';
import OptimizeTestState from '../config/content/OptimizeTestState';
import PrintHeader from '../../headers/PrintHeader';
import functions from '../../../functions/functions';
import CustomDialog from '../../popup/CustomDialog';

class PerformanceWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.compareTest = this.compareTest.bind(this);
  }
  state = {
    diagnosticsCheck: false,
    improvementsCheck: false,
    alignmentCheck: false,
    diagnosticsToggle: false,
    improvementsToggle: false,
    alignmentToggle: false,
    message: '',
    mergeFormOpen: false,
    selectMenu: false,
    selectMenuAnchor: null,
    pdfDialog: false,
  };
  isUndefined(value) {
    return typeof value === 'undefined';
  }
  componentDidMount() {
    if (!this.props.optimize.compareFlag) {
      this.props.optimizeSetCompareDevice(this.props.activeDevice); //defaults comparison to the current device
    }
    if (
      this.isUndefined(this.props.devices[this.props.activeDevice]) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].resolutionApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].sensitivityApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].strokeApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].spring) //taking place of specbool check //|| // TODO FIX SPECBOOL CHECK
      //!this.props.devices[this.props.activeDevice].specBool
    ) {
      this.props.fetchDevices(this.props.activeDevice);
      if (this.props.optimize.compareDevice !== this.props.activeDevice && this.props.optimize.compareDevice !== '') {
        this.props.fetchDevices(this.props.optimize.compareDevice);
      }
      if (this.props.deviceSummary === false) {
        this.props.setDevicesSummaryStatus(true);
        this.props.fetchDevicesSummary();
      }

      this.props.fetchDeviceSpecs(this.props.activeDevice);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  handleDiagnosticsCheck = () => {
    //let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.setState({
      diagnosticsCheck: !this.state.diagnosticsCheck,
    });
  };
  handleImprovementsCheck = () => {
    //let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.setState({
      improvementsCheck: !this.state.improvementsCheck,
    });
  };
  handleAlignmentCheck = () => {
    //let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    this.setState({
      alignmentCheck: !this.state.alignmentCheck,
    });
  };
  togglePdfDialog = () => {
    this.setState({
      pdfDialog: !this.state.pdfDialog,
    });
  };
  setList() {}
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    if (!!arr) {
      for (let i = 0; i < arr.length; i++) {
        const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();
        result.push(`${timestamp}${this.processState(stateArr[i])}`);
      }
    }
    return result;
  }

  compareTest(date) {
    //console.log(this);
    //console.log(this.props);
    //console.log(select);
    //console.log(this);
    this.setState({ message: '' });
    if (date === 'No Comparison') {
      this.props.optimizeSetCompareFlag(false);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      this.props.optimizeSetCompareTest('');
    } else {
      const timestampArr = !!this.props.devices[this.props.optimize.compareDevice] ? this.props.devices[this.props.optimize.compareDevice].datetimeHistory : [];
      const stateArr =
        !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].stateHistory
          ? this.props.devices[this.props.optimize.compareDevice].stateHistory
          : [];
      //let indexNum = -1;
      let newCompareTestId = -1;
      for (let i = 0; i < timestampArr.length; i++) {
        const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
        //result.push(timestamp);

        if (timestamp === date) {
          //indexNum = i;
          newCompareTestId = this.props.devices[this.props.optimize.compareDevice].testIdHistory[i];
          if (parseInt(newCompareTestId) !== this.props.devices[this.props.activeDevice].activeTestId || this.props.optimize.compareDevice.deviceId !== this.props.activeDevice) {
            // console.log('true');
            this.props.optimizeSetCompareFlag(true);
            //this.props.optimizeSetCompareDevice(this.props.activeDevice);
            this.props.optimizeSetCompareTest(newCompareTestId);
            this.props.fetchDevices(newCompareTestId);
          } else {
            //console.log('false');
            this.props.optimizeSetCompareFlag(false);
            //this.props.optimizeSetCompareDevice(this.props.);
            this.setState({ message: 'No Performance Data to Compare!' });
          }
        } else {
          /*this.props.optimizeSetCompareFlag(false);
        this.props.optimizeSetCompareDevice('');
        this.props.optimizeSetCompareTest('');*/
        }
      }
    }
    //this.props.fetchDeviceCompareTests(16, 30);
  }

  selectActiveTest = (date) => {
    //console.log(date);
    const timestampArr = !!this.props.devices[this.props.activeDevice] ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        //this.props.clearDeviceIssuesImprovements(this.props.activeDevice); //superflous added to fetchDeviceByTest
        this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
      }
    }

    /*this.setState({
      loading: true
    });
    this.props.setTablePageSize(event.target.value);
    this.props.setTableOffset(
      this.props.table.page * this.props.table.pageSize
    );
    const offset = this.props.table.page * this.props.table.pageSize;
    const rowsPerPage = event.target.value;
    const page = Math.floor(offset / rowsPerPage);
    this.props.setTablePage(page);
    this.refreshPageView(offset, rowsPerPage, page);*/
  };

  renderWidgets() {
    const { classes } = this.props;
    /*console.log(
      Object.keys(this.props.devices).map((key) => {
        return {
          name: this.props.devices[key].name,
          deviceId: this.props.devices[key].deviceId,
        };
      })
    );*/
    const device = this.props.devices[this.props.activeDevice];
    const compareDevice = this.props.devices[this.props.optimize.compareDevice];
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    //console.log(compareDevice);
    //Prevent comparison to self
    let adjustedDatetimeHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.datetimeHistory.slice();
    let adjustedStateHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.stateHistory.slice();
    //console.log(adjustedDatetimeHistory);
    //console.log(compareDevice);
    const index = !compareDevice || !compareDevice.datetimeHistory ? -1 : compareDevice.datetimeHistory.indexOf(device.dateTime.toString());
    //console.log(!device ? 'loading' : device.dateTime);
    //console.log(index);
    if (index !== -1) {
      adjustedDatetimeHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"

      adjustedStateHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"
    }
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(adjustedDatetimeHistory, adjustedStateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? adjustedDatetimeHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    //console.log(testOptionsCompare[testOptionsCompareIndex]);

    const printStyles = functions.setPrintStyles(this.props.activeTab);
    /*const compareIndex =
      !this.props.devices ||
      !this.props.optimize ||
      !this.props.devices[this.props.optimize.compareDevice]
        ? -1
        : this.props.devices[
            this.props.optimize.compareDevice
          ].testIdHistory.indexOf(this.props.optimize.compareTest);*/
    if (this.props.devices[this.props.activeDevice]) {
      return (
        <React.Fragment>
          {!!this.props.toggledWidget ? null : (
            <Grid container className={classes.testSelect}>
              <Grid item>
                {!!this.props.toggledWidget || !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
                  <CustomSelect
                    single
                    id={null}
                    label='Test'
                    type='Test'
                    disabled={this.state.loading}
                    //onCloseFunction={this.multiSelectOnClose}
                    onChangeFunction={(event, value) => {
                      this.selectActiveTest(value);
                    }}
                    //type='unit'
                    values={
                      !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                        ? 'Loading'
                        : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                    }
                    options={testOptions}
                    //value={null}
                    helperText={null}
                    width={260}
                  />
                )}
              </Grid>
              {!!this.props.toggledWidget ||
              !this.props.devices[this.props.activeDevice] ||
              !this.props.devices[this.props.activeDevice].datetimeHistory ||
              this.props.devices[this.props.activeDevice].datetimeHistory.length < 0 ? null : (
                <React.Fragment>
                  <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                    <Autocomplete
                      className={classes.formSelect}
                      id='combo-box-demo'
                      options={Object.keys(this.props.devices).map((key) => {
                        return {
                          name: this.props.devices[key].name,
                          deviceId: this.props.devices[key].deviceId,
                        };
                      })}
                      value={
                        !this.props.optimize.compareDevice || !this.props.devices[this.props.optimize.compareDevice]
                          ? null
                          : {
                              name: this.props.devices[this.props.optimize.compareDevice].name,
                              deviceId: this.props.devices[this.props.optimize.compareDevice].deviceId,
                            }
                      }
                      //value={this.props.optimize.compareDevice}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => {
                        //console.log(option);
                        //console.log(value);
                        return option.name === value.name && option.deviceId === value.deviceId;
                        //return false;
                      }}
                      disableClearable={true}
                      onChange={(e, value) => {
                        this.props.optimizeSetCompareFlag(false);
                        this.props.optimizeSetCompareTest('');
                        this.props.optimizeSetCompareDevice(value.deviceId);
                        this.props.fetchDevices(value.deviceId);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Comparison Device' /*variant="outlined"*/
                          /*InputLabelProps={{
                          className: classes.printHide,
                        }}*/
                          variant='standard'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                    <CustomSelect
                      single
                      id={null}
                      label='Compare to'
                      type='Compare'
                      disabled={this.state.loading || testOptionsCompare.length < 1}
                      onChangeFunction={(event, value) => {
                        this.compareTest(value);
                      }}
                      values={!testOptionsCompare || !testOptionsCompare[testOptionsCompareIndex] || !this.props.optimize.compareFlag ? 'No Comparison' : testOptionsCompare[testOptionsCompareIndex]}
                      options={['No Comparison'].concat(testOptionsCompare)}
                      helperText={null}
                      width={260}
                    />
                  </Grid>
                  {this.state.message !== '' ? (
                    <Grid item>
                      <Alert severity='warning'>{this.state.message}</Alert>
                    </Grid>
                  ) : null}
                </React.Fragment>
              )}
            </Grid>
          )}
          <Grid container className={classes.containerSpacingFix}>
            {!device.serial && !device.activeResolutionId ? (
              <Grid item xs={12} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Resolution</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={12} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeResolutionId} attribute={'Resolution'} />
            )}
            {!device.serial && !device.activeSensitivityId ? (
              <Grid item xs={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Sensitivity</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={6} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeSensitivityId} attribute={'Sensitivity'} />
            )}
            {!device.serial && !device.activeStrokeId ? (
              <Grid item xs={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Stroke</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={6} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeStrokeId} attribute={'Stroke'} />
            )}
            {/*this.props.tests_status['Resolution'][device.activeResolutionId] === 'loading' || this.props.tests_status['Resolution'][device.activeResolutionId] === 'loaded' ? null : (
              <ValveChartWidget initialWidth={12} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeResolutionId} attribute={'Resolution'} />
            )*/}
          </Grid>
        </React.Fragment>
      );
    }
  }
  renderPdfDialog() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.pdfDialog}
        onClose={this.togglePdfDialog}
        title='View Raw PDF'
        titleIcon='Folder'
        content={
          <div style={{ marginTop: 16 }}>
            <Link>View Valve Test 1</Link>
            <Link>Save as</Link>
          </div>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.togglePdfDialog} color='primary'>
              Cancel
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  renderDiagnosticsWidgets() {
    const { classes } = this.props;
    if (this.state.diagnosticsToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <DiagnosticsWidgets toggledWidget diagnosticSpecs></DiagnosticsWidgets>
        </div>
      );
    }
  }
  renderImprovementsWidgets() {
    const { classes } = this.props;
    if (this.state.improvementsToggle) {
      return (
        <div className={classes.pageBreak}>
          <ImprovementsWidgets toggledWidget></ImprovementsWidgets>
        </div>
      );
    }
  }
  renderAlignmentWidgets() {
    const { classes } = this.props;
    if (this.state.alignmentToggle) {
      return (
        <div className={classes.pageBreak}>
          <AlignmentWidgets toggledWidget></AlignmentWidgets>
        </div>
      );
    }
  }
  render() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    const row = !device
      ? {}
      : {
          id: device.deviceId,
          name: device.name,
          serial: device.serial,
          site: device.site,
          application: device.application,
          description: device.description,
          bodySize: device.bodySize,
          bodyType: device.bodyType,
          importance: device.importance,
          resApp: !device.appRes ? null : device.appRes,
          senApp: !device.appSen ? null : device.appSen,
          strokeApp: !device.appStroke ? null : device.appStroke,
          effectiveTargetDate: new Date().getTime() / 1000,
          speedImportance: device.speedImportance,
          accuracyImportance: device.accuracyImportance,
          performanceSatisfaction: device.performanceSatisfaction,
          currentSerial: device.serial,
          deviceId: device.deviceId,
        };
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, diagnosticsToggle: this.state.diagnosticsCheck, improvementsToggle: this.state.improvementsCheck, alignmentToggle: this.state.alignmentCheck });
    };
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.optimize.compareDevice].datetimeHistory, this.props.devices[this.props.optimize.compareDevice].stateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.props.devices[this.props.optimize.compareDevice].testIdHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    //let isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <Grid>
          <Grid container justifyContent='space-between' style={{ marginBottom: -42 }} className={classes.printHide}>
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {!printStyles ? (
                  <Grid item>
                    <Tooltip arrow placement='top' title='Create Performance Certificate in a New Tab' aria-label='Create Performance Certificate in a New Tab'>
                      <Link href={`/optimize/${this.props.activeDevice}/Certification`} target='_blank'>
                        <IconButton className={classes.textColor} aria-label='more' style={{ marginLeft: -10 }} size='large'>
                          <Speed />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </Grid>
                ) : null}
                {!printStyles ? (
                  <Grid item style={{ marginBottom: -15 }}>
                    <InfoPopover center tooltip='Set As Found/As Left' title='Set As Found/As Left' titleIcon='Update' large icon={<Update />} content={<OptimizeTestState />} maxWidth='sm' />
                  </Grid>
                ) : null}
                {!printStyles &&
                !!this.props.devices &&
                !!this.props.devices[this.props.activeDevice] &&
                !!this.props.devices[this.props.activeDevice].testIdHistory &&
                this.props.devices[this.props.activeDevice].testIdHistory.length > 1 ? (
                  <Grid item style={{ marginBottom: -15 }}>
                    <InfoPopover center tooltip='Merge Tests' title='Merge Tests' titleIcon='Merge' icon={<Merge />} content={<OptimizeTestMerge />} maxWidth='sm' />
                  </Grid>
                ) : null}
                <React.Fragment>
                  {!printStyles ? (
                    <Grid item>
                      <InfoPopover
                        center
                        tooltip='Configure'
                        title='Configure'
                        titleIcon='Settings'
                        resize
                        style={{ marginBottom: -15 }}
                        icon={<Settings />}
                        content={<OptimizeContent row={row} clickedRow={this.props.activeDevice} rowOpen={true} />}
                      />
                    </Grid>
                  ) : null}
                  {!this.props.toggledWidget ? (
                    <Grid item>
                      <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                        <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                      <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Paper>
                            <Typography
                              variant='caption'
                              style={{
                                padding: '10px 12px 6px 14px',
                                display: 'block',
                              }}
                            >
                              Select to show more info
                            </Typography>
                            {/*!isFirefox ? null : (
                              <Typography
                                variant='caption'
                                style={{
                                  padding: '0px 0px 6px 14px',
                                  display: 'block',
                                  fontSize: 10,
                                }}
                              >
                                *In Mozilla Firefox, <br></br> you may only select all or none <br></br> from this list
                              </Typography>
                              )*/}
                            <MenuItem onClick={this.handleImprovementsCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.improvementsCheck} color='secondary' />
                              Improvement Paths
                            </MenuItem>
                            <MenuItem onClick={this.handleDiagnosticsCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.diagnosticsCheck} color='secondary' />
                              Diagnostics
                            </MenuItem>
                            <MenuItem onClick={this.handleAlignmentCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.alignmentCheck} color='secondary' />
                              Alignment
                            </MenuItem>
                            <Grid container justifyContent='space-between'>
                              <Grid item></Grid>
                              <Grid item>
                                <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </ClickAwayListener>
                      </Popper>
                    </Grid>
                  ) : null}
                </React.Fragment>
              </Grid>
            </Grid>
          </Grid>
          <div>
            {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
            <WidgetTitle title={`Performance - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            <OptimizeSpecs
              device={this.props.devices[this.props.activeDevice]}
              compareDevice={this.props.devices[this.props.optimize.compareDevice]}
              compareTo={['No Comparison'].concat(testOptionsCompare)[testOptionsCompareIndex]}
              useTitle
              title='Device Details'
              moreSpecs
              diagnosticsSpecs={this.state.diagnosticsToggle}
            />
            {this.renderWidgets()}
          </div>
        </Grid>
        {this.renderPdfDialog()}
        {this.renderImprovementsWidgets()}
        {this.renderDiagnosticsWidgets()}
        {this.renderAlignmentWidgets()}
        {!this.props.toggledWidget ? (
          <Grid className={classes.printHide}>
            <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
              <MoreVert className={classes.buttonIcon}></MoreVert>Show More
            </Button>
          </Grid>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    optimize: state.optimize,
    format: state.format.format,
    tests_status: state.tests.status,
  };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  fetchTest,
  setTestStatus,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
  //fetchDeviceCompareTests /*, fetchDeviceEvents */,
})(withStyles(styles)(PerformanceWidgets));
