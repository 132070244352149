import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import UserWidgets from '../widgetGroups/config/UserWidgets';
import PrioritizeWidgets from '../widgetGroups/config/PrioritizeWidgets';
import OptimizeWidgets from '../widgetGroups/config/OptimizeWidgets';
import { connect } from 'react-redux';
import { configSetActiveTab, fetchDevicesSummary, setDevicesSummaryStatus, setModule } from '../../actions';
import { Redirect } from 'react-router-dom';
import functions from '../../functions/functions';
import ConfigTabs from '../menus/ConfigTabs';

class Config extends React.Component {
  componentDidMount() {
    if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null || !this.props.match.params.tab) {
      this.props.configSetActiveTab('Optimize');
      this.props.setModule('Optimize');
      //return <Redirect to='Optimize' />;
    } else {
      this.props.setModule(this.props.match.params.tab);
      this.props.configSetActiveTab(this.props.match.params.tab);
    }
    if (this.props.match.params.tab === 'Optimize') {
      if (this.props.deviceSummary === false) {
        this.props.setDevicesSummaryStatus(true);
        this.props.fetchDevicesSummary();
      }
    }
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.match.params.tab === 'null') {
      //this.props.configSetActiveTab('Optimize');
      return <Redirect to='Optimize' />;
    }
    return (
      <React.Fragment>
        {this.props.format ? null : <ConfigTabs></ConfigTabs>}
        <div className={printStyles ? null : classes.detailsMin}>
          {this.props.activeTab === 'Prioritize' && <PrioritizeWidgets></PrioritizeWidgets>}
          {(this.props.activeTab === 'Optimize' || this.props.activeTab === 'null') && <OptimizeWidgets></OptimizeWidgets>}
          {this.props.activeTab === 'User' && <UserWidgets></UserWidgets>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.config.activeTab,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  configSetActiveTab,
  fetchDevicesSummary,
  setDevicesSummaryStatus,
  setModule,
})(withStyles(styles, { withTheme: true })(Config));
