//import _ from 'lodash';
import {
  CLEAR_STATE,
  FETCH_LOCATION,
  FETCH_LOCATIONS /*,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/,
} from '../actions/types';

const locationReducer = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_STATE: {
      return {
        ...state,
        list: [],
      };
    }
    case FETCH_LOCATION:
      return { ...state, [action.payload.locationId]: action.payload };
    case FETCH_LOCATIONS:
      let locations = [];
      for (let i = 0; i < action.payload.length; i++) {
        locations.push(action.payload[i].location);
      }
      return { ...state, list: locations };
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default locationReducer;
