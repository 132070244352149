import React from 'react';
import { Grid, TextField, Typography, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OptimizeSpecs from '../../widgets/OptimizeSpecs';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import ValveChartWidget from '../../widgets/ValveChartWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import CustomSelect from '../../filters/CustomSelect';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import functions from '../../../functions/functions';
import {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  fetchTest,
  setTestStatus,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
  setColorMode,
} from '../../../actions/index';
import DiagnosticsWidgets from './DiagnosticsWidgets';
import ImprovementsWidgets from './ImprovementsWidgets';
import AlignmentWidgets from './AlignmentWidgets';

class CertWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.compareTest = this.compareTest.bind(this);
  }
  state = {
    message: '',
    mergeFormOpen: false,
  };
  isUndefined(value) {
    return typeof value === 'undefined';
  }
  componentDidMount() {
    if (!this.props.optimize.compareFlag) {
      this.props.optimizeSetCompareDevice(this.props.activeDevice); //defaults comparison to the current device
    }
    if (
      this.isUndefined(this.props.devices[this.props.activeDevice]) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].resolutionApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].sensitivityApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].strokeApp) ||
      this.isUndefined(this.props.devices[this.props.activeDevice].spring) //taking place of specbool check //|| // TODO FIX SPECBOOL CHECK
      //!this.props.devices[this.props.activeDevice].specBool
    ) {
      this.props.fetchDevices(this.props.activeDevice);
      if (this.props.optimize.compareDevice !== this.props.activeDevice && this.props.optimize.compareDevice !== '') {
        this.props.fetchDevices(this.props.optimize.compareDevice);
      }
      if (this.props.deviceSummary === false) {
        this.props.setDevicesSummaryStatus(true);
        this.props.fetchDevicesSummary();
      }

      this.props.fetchDeviceSpecs(this.props.activeDevice);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
    this.props.setColorMode('light');
  }
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    if (!!arr) {
      for (let i = 0; i < arr.length; i++) {
        const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();
        result.push(`${timestamp}${this.processState(stateArr[i])}`);
      }
    }
    return result;
  }

  compareTest(date) {
    this.setState({ message: '' });
    if (date === 'No Comparison') {
      this.props.optimizeSetCompareFlag(false);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      this.props.optimizeSetCompareTest('');
    } else {
      const timestampArr = !!this.props.devices[this.props.optimize.compareDevice] ? this.props.devices[this.props.optimize.compareDevice].datetimeHistory : [];
      const stateArr =
        !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].stateHistory
          ? this.props.devices[this.props.optimize.compareDevice].stateHistory
          : [];
      //let indexNum = -1;
      let newCompareTestId = -1;
      for (let i = 0; i < timestampArr.length; i++) {
        const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
        //result.push(timestamp);

        if (timestamp === date) {
          //indexNum = i;
          newCompareTestId = this.props.devices[this.props.optimize.compareDevice].testIdHistory[i];
          if (parseInt(newCompareTestId) !== this.props.devices[this.props.activeDevice].activeTestId || this.props.optimize.compareDevice.deviceId !== this.props.activeDevice) {
            // console.log('true');
            this.props.optimizeSetCompareFlag(true);
            //this.props.optimizeSetCompareDevice(this.props.activeDevice);
            this.props.optimizeSetCompareTest(newCompareTestId);
            this.props.fetchDevices(newCompareTestId);
          } else {
            //console.log('false');
            this.props.optimizeSetCompareFlag(false);
            //this.props.optimizeSetCompareDevice(this.props.);
            this.setState({ message: 'No Performance Data to Compare!' });
          }
        } else {
          /*this.props.optimizeSetCompareFlag(false);
        this.props.optimizeSetCompareDevice('');
        this.props.optimizeSetCompareTest('');*/
        }
      }
    }
    //this.props.fetchDeviceCompareTests(16, 30);
  }

  selectActiveTest = (date) => {
    //console.log(date);
    const timestampArr = !!this.props.devices[this.props.activeDevice] ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        this.props.clearDeviceIssuesImprovements(this.props.activeDevice);
        this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
      }
    }
  };

  renderWidgets() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    const compareDevice = this.props.devices[this.props.optimize.compareDevice];
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    //console.log(compareDevice);
    //Prevent comparison to self
    let adjustedDatetimeHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.datetimeHistory.slice();
    let adjustedStateHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.stateHistory.slice();
    //console.log(adjustedDatetimeHistory);
    //console.log(compareDevice);
    const index = !compareDevice || !compareDevice.datetimeHistory ? -1 : compareDevice.datetimeHistory.indexOf(device.dateTime.toString());
    //console.log(!device ? 'loading' : device.dateTime);
    //console.log(index);
    if (index !== -1) {
      adjustedDatetimeHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"

      adjustedStateHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"
    }
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(adjustedDatetimeHistory, adjustedStateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? adjustedDatetimeHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    //console.log(testOptionsCompare[testOptionsCompareIndex]);
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    if (this.props.devices[this.props.activeDevice]) {
      return (
        <React.Fragment>
          <Grid container className={classes.printTestSelect}>
            <Grid item>
              {!!this.props.toggledWidget || !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
                <CustomSelect
                  single
                  id={null}
                  label='Test'
                  type='Test'
                  disabled={this.state.loading}
                  //onCloseFunction={this.multiSelectOnClose}
                  onChangeFunction={(event, value) => {
                    this.selectActiveTest(value);
                  }}
                  //type='unit'
                  values={
                    !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                      ? 'Loading'
                      : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                  }
                  options={testOptions}
                  //value={null}
                  helperText={null}
                  width={260}
                />
              )}
            </Grid>
            {!!this.props.toggledWidget ||
            !this.props.devices[this.props.activeDevice] ||
            !this.props.devices[this.props.activeDevice].datetimeHistory ||
            this.props.devices[this.props.activeDevice].datetimeHistory.length < 0 ? null : (
              <React.Fragment>
                <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                  <Autocomplete
                    className={classes.formSelect}
                    id='combo-box-demo'
                    options={Object.keys(this.props.devices).map((key) => {
                      return {
                        name: this.props.devices[key].name,
                        deviceId: this.props.devices[key].deviceId,
                      };
                    })}
                    value={
                      !this.props.optimize.compareDevice || !this.props.devices[this.props.optimize.compareDevice]
                        ? null
                        : {
                            name: this.props.devices[this.props.optimize.compareDevice].name,
                            deviceId: this.props.devices[this.props.optimize.compareDevice].deviceId,
                          }
                    }
                    //value={this.props.optimize.compareDevice}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => {
                      //console.log(option);
                      //console.log(value);
                      return option.name === value.name && option.deviceId === value.deviceId;
                      //return false;
                    }}
                    disableClearable={true}
                    onChange={(e, value) => {
                      this.props.optimizeSetCompareFlag(false);
                      this.props.optimizeSetCompareTest('');
                      this.props.optimizeSetCompareDevice(value.deviceId);
                      this.props.fetchDevices(value.deviceId);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Comparison Device' /*variant="outlined"*/
                        /*InputLabelProps={{
                          className: classes.printHide,
                        }}*/
                        variant='standard'
                      />
                    )}
                  />
                </Grid>
                <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                  <CustomSelect
                    single
                    id={null}
                    label='Compare to'
                    type='Compare'
                    disabled={this.state.loading || testOptionsCompare.length < 1}
                    onChangeFunction={(event, value) => {
                      this.compareTest(value);
                    }}
                    values={!testOptionsCompare || !testOptionsCompare[testOptionsCompareIndex] || !this.props.optimize.compareFlag ? 'No Comparison' : testOptionsCompare[testOptionsCompareIndex]}
                    options={['No Comparison'].concat(testOptionsCompare)}
                    helperText={null}
                    width={260}
                  />
                </Grid>
                {this.state.message !== '' ? (
                  <Grid item>
                    <Alert severity='warning'>{this.state.message}</Alert>
                  </Grid>
                ) : null}
              </React.Fragment>
            )}
          </Grid>
          <Grid container className={classes.containerSpacingFix}>
            {!device.serial && !device.activeResolutionId ? (
              <Grid item xs={12} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Resolution</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={12} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeResolutionId} attribute={'Resolution'} />
            )}
            {!device.serial && !device.activeSensitivityId ? (
              <Grid item xs={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Sensitivity</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={6} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeSensitivityId} attribute={'Sensitivity'} />
            )}
            {!device.serial && !device.activeStrokeId ? (
              <Grid item xs={6} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <Typography variant='subtitle2'>No Test Found for Stroke</Typography>
                </Paper>
              </Grid>
            ) : (
              <ValveChartWidget initialWidth={6} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeStrokeId} attribute={'Stroke'} />
            )}
            {/*this.props.tests_status['Resolution'][device.activeResolutionId] === 'loading' || this.props.tests_status['Resolution'][device.activeResolutionId] === 'loaded' ? null : (
              <ValveChartWidget initialWidth={12} type='valve' valveColumns={1} progress={1} testId={this.props.devices[this.props.activeDevice].activeResolutionId} attribute={'Resolution'} />
            )*/}
          </Grid>
        </React.Fragment>
      );
    }
  }
  renderDiagnosticsWidgets() {
    const { classes } = this.props;
    return (
      <div className={`${classes.pageBreak} ${classes.avoidBreak} ${classes.printWidgetGroup}`}>
        <DiagnosticsWidgets toggledWidget diagnosticSpecs></DiagnosticsWidgets>
      </div>
    );
  }
  renderImprovementsWidgets() {
    const { classes } = this.props;
    return (
      <div className={`${classes.pageBreak} ${classes.printWidgetGroup}`}>
        <ImprovementsWidgets toggledWidget hideButton></ImprovementsWidgets>
      </div>
    );
  }
  renderAlignmentWidgets() {
    const { classes } = this.props;
    return (
      <div className={`${classes.pageBreak} ${classes.printWidgetGroup}`}>
        <AlignmentWidgets toggledWidget></AlignmentWidgets>
      </div>
    );
  }
  render() {
    //const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.optimize.compareDevice].datetimeHistory, this.props.devices[this.props.optimize.compareDevice].stateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.props.devices[this.props.optimize.compareDevice].testIdHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    return (
      <React.Fragment>
        <Grid>
          <div>
            <WidgetTitle title={`Performance - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            <OptimizeSpecs
              device={this.props.devices[this.props.activeDevice]}
              compareDevice={this.props.devices[this.props.optimize.compareDevice]}
              compareTo={['No Comparison'].concat(testOptionsCompare)[testOptionsCompareIndex]}
              useTitle
              title='Device Details'
              diagnosticSpecs
              moreSpecs
              diagnosticsSpecs
              cert
            />
            {this.renderWidgets()}
          </div>
        </Grid>
        {this.renderImprovementsWidgets()}
        {this.renderDiagnosticsWidgets()}
        {this.renderAlignmentWidgets()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    optimize: state.optimize,
    format: state.format.format,
    tests_status: state.tests.status,
  };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setFormat,
  fetchTest,
  setTestStatus,
  setDevicesSummaryStatus,
  clearDeviceIssuesImprovements,
  setColorMode,
  //fetchDeviceCompareTests /*, fetchDeviceEvents */,
})(withStyles(styles)(CertWidgets));
