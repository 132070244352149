import React from 'react';
import HC_exporting from 'highcharts/modules/exporting';
import Stockcharts from 'highcharts/highstock';
import { connect } from 'react-redux';
import { styles } from '../styles';
import withStyles from '@mui/styles/withStyles';
import initializeTheme from '../theme.js';
import { addChartSelections, replaceChartSelections, updateActionRange } from '../../actions';
import MultiStockChart from './MultiStockChart';

// init the module
HC_exporting(Stockcharts);

class MultiStockChartWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      format_current: false,
      dragMode: 'zoom',
    };
    Stockcharts.seriesTypes.line.prototype.drawLegendSymbol = Stockcharts.seriesTypes.area.prototype.drawLegendSymbol; //Sets default legend to circle
    this.internalCharts = {};
    this.internalTimeRange = {};
    this.internalTimeRangeCount = 0;
    this.internalMinRange = 0;
    this.internalColors = { currentIndex: 0 };
    this.colors = ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1']; //available colors for the chart
    this.getSmallestDataIncrement = this.getSmallestDataIncrement.bind(this);
    this.registerChart = this.registerChart.bind(this);
    this.registerColors = this.registerColors.bind(this);
    this.alterSelectMethod = this.alterSelectMethod.bind(this);
    this.unselectByClick = this.unselectByClick.bind(this);
    this.selectedPoints = this.selectedPoints.bind(this);
    this.selectedPointsByDrag = this.selectedPointsByDrag.bind(this);
  }

  componentDidMount() {
    this.internalColors.currentIndex = 0;
  }

  unselectByClick() {
    console.log('unselect');
  }

  selectedPoints(e) {
    console.log(e);
    //console.log(e.points.length);
  }

  selectedPointsByDrag(e) {
    //console.log(this);
    //console.log(e);
    //console.log();
    //console.log(e.originalEvent.shiftKey); //! DETECTION OF SHIFT DURING HIGHLIGHT
    //console.log(e.xAxis[0].min, e.xAxis[0].max);
    let shiftFlag = e.originalEvent.shiftKey;
    if (shiftFlag) {
      this.props.addChartSelections([e.xAxis[0].min, e.xAxis[0].max]);
    } else {
      this.props.replaceChartSelections([e.xAxis[0].min, e.xAxis[0].max]);
    }
    //console.log(this.props.chartSelections);
    //console.log();
    //this.selectedPoints(e);
    // Fire a custom event DOES NOT WORK
    //Stockcharts.fireEvent(this, 'selectedpoints', { points: [1, 2, 3] });

    return false; // Don't zoom
  }

  alterSelectMethod(charts, param) {
    //const charts = this.internalCharts;
    //console.log(this.state);
    //console.log(charts);
    //console.log(param);
    this.setState({ dragMode: param });
    /*if (param === 'zoom') {
      this.setState({ dragMode: 'zoom' });
      for (const key in charts) {
        charts[key].update({
          chart: {
            panning: {
              enabled: false,
              type: 'x',
            },
            events: {
              selection: undefined,
              selectedpoints: undefined,
              click: undefined,
            },
            zoomType: 'x',
          },
          plotOptions: {
            series: {
              allowPointSelect: false,
            },
          },
        });
      }
    } else if (param === 'pan') {
      this.setState({ dragMode: 'pan' });
    } else if (param === 'select') {
      this.setState({ dragMode: 'select' });
    }*/
  }
  registerColors(devices) {
    const sortedDevices = devices.sort();
    let colors = [];
    for (let i = 0; i < sortedDevices.length; i++) {
      if (sortedDevices[i] === '') {
        //do nothing
      } else if (sortedDevices[i] in this.internalColors) {
        colors.push(this.internalColors[sortedDevices[i]]); //device already registered, push a assigned color
      } else {
        const newColor = this.colors[this.internalColors.currentIndex % this.colors.length]; //register color
        this.internalColors[sortedDevices[i]] = newColor;
        this.internalColors.currentIndex++;
        colors.push(newColor);
      }
    }
    return colors;
  }

  getMin(arr) {
    let len = arr.length;
    let min = Infinity;

    while (len--) {
      min = arr[len] < min ? arr[len] : min;
    }
    return min;
  }
  closest(num, arr) {
    var mid;
    var lo = 0;
    var hi = arr.length - 1;
    while (hi - lo > 1) {
      mid = Math.floor((lo + hi) / 2);
      if (arr[mid] < num) {
        lo = mid;
      } else {
        hi = mid;
      }
    }
    if (num - arr[lo] <= arr[hi] - num) {
      return arr[lo];
    }
    return arr[hi];
  }

  setAllExtremes(e, index) {
    const charts = this.internalCharts;
    const chartKeys = Object.keys(charts);

    //console.log(this.internalTimeRange);
    let min = Math.round(e.min / 1000);
    let max = Math.round(e.max / 1000);

    if ((this.internalTimeRange.start !== min || this.internalTimeRange.end !== max) && !!e.trigger) {
      //MUST CHECK FOR TRIGGER TO PREVENT RUNAWAY EVENT
      this.internalTimeRangeCount++;
      this.internalTimeRange.start = min;
      this.internalTimeRange.end = max;
      this.props.updateActionRange({ start: min, end: max });
    }
    //TODO CHECK THAT SET EXTREMES DOESN'T CALL ITSELF AGAIN ON TRIGGER - (Even if it does 'nothing' because it is already moved)
    chartKeys.forEach(function (key, i) {
      const chart = charts[key];
      if (i !== index && !!chart.xAxis) {
        chart.xAxis[0].setExtremes(e.min, e.max);
      }
    });
  }

  getSmallestDataIncrement(seriesData) {
    let increment = this.internalMinRange;
    //check all ranges in upcoming chart addition
    for (let i = 0; i < seriesData.length; i++) {
      let gap = !seriesData[i].data || seriesData[i].data.length === 0 ? 0 : seriesData[i].data[1][0] - seriesData[i].data[0][0];
      increment = increment === 0 || gap < increment ? gap : increment;
    }
    if ((this.internalMinRange === 0 || increment < this.internalMinRange) && increment !== 0) {
      this.internalMinRange = increment * 10; //update master value
      //update all existing charts
      const charts = this.internalCharts;
      const chartsKeys = Object.keys(charts);

      for (let i = 0; i < chartsKeys.length; i++) {
        charts[chartsKeys[i]].update({
          xAxis: {
            minRange: this.internalMinRange,
          },
        });
      }
    }
    return this.internalMinRange;
  }

  processAnalysisRange(analysisRange) {
    if (!analysisRange) {
      return analysisRange; //default behavior if not set
    }
    switch (analysisRange) {
      case '7':
        return 0;
      case '14':
        return 1;
      case '30':
        return 2;
      case '90':
        return 3;
      case '180':
        return 4;
      case '365':
        return 5;
      default:
        return 3; // 90 days
    }
    //return analysisRange;
  }

  getChartOptions(seriesData, index, plotBands) {
    const theme = initializeTheme(this.props.colorMode);
    const charts = this.internalCharts;
    //const minFunction = this.getMin;
    const getClosest = this.closest;
    //const chartHeight = 225;
    const chartMarginTop = index === 0 ? 35 : 10;
    const deviceList = seriesData.map((a) => a.name);
    const colorArray = this.registerColors(deviceList);
    const dataIncrement = this.getSmallestDataIncrement(seriesData);
    const alterSelectMethod = this.alterSelectMethod;
    let dataMinTime = 0;
    let dataMaxTime = 0;

    for (let i = 0; i < seriesData.length; i++) {
      let dataLength = !seriesData[i].data ? 1 : seriesData[i].data.length;
      if (!!seriesData[i].data && !!seriesData[i].data[0] && dataMinTime < seriesData[i].data[0][0]) {
        dataMinTime = seriesData[i].data[0][0];
      }
      if (!!seriesData[i].data && !!seriesData[i].data[dataLength - 1] && dataMaxTime < seriesData[i].data[dataLength - 1][0]) {
        dataMaxTime = seriesData[i].data[dataLength - 1][0];
      }
    }
    //console.log(dataMaxTime);
    //console.log(this.state.dragMode);
    //console.log(this.processAnalysisRange(this.props.analysisRange));
    return {
      colors: colorArray,
      chart: {
        animation: false,
        //height: null,
        spacingTop: chartMarginTop,
        zoomType: this.state.dragMode === 'zoom' || this.state.dragMode === 'select' ? 'x' : '',
        panning:
          this.state.dragMode === 'pan'
            ? {
                enabled: true,
                type: 'x',
              }
            : { enabled: false },
        events: {
          selection: this.state.dragMode === 'select' ? this.selectedPointsByDrag : undefined,
          selectedpoints: this.state.dragMode === 'select' ? this.selectedPoints : undefined,
          click: this.state.dragMode === 'select' ? this.unselectByClick : undefined,
        },
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        spacingBottom: 15,
        marginRight: 15,
      },
      exporting:
        index === 0
          ? {
              enabled: true,
              buttons: {
                contextButton: {
                  symbolStroke: this.props.colorMode === 'dark' ? '#6fc2ff' : '#1879C0',
                  symbolStrokeWidth: 2,
                  theme: {
                    fill: 'none',
                    states: {
                      hover: {
                        fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
                      },
                      select: {
                        fill: this.props.colorMode === 'dark' ? '#444' : '#e5e5e5',
                      },
                    },
                  },
                  menuItems: [
                    {
                      text: 'Select',
                      onclick: function () {
                        alterSelectMethod(charts, 'select');
                        //console.log(charts);
                        //console.log(this); // refers to charts
                        //TODO set alterSelect as merely a state change, have the configuration moved into the chart object with if-then to reduce redraws (see example on line 259)

                        /*for (const key in charts) {
                          charts[key].update({
                      chart: {
                        inverted: false,
                        polar: false,
                      },
                     
                    });
                        }*/
                      },
                    },
                    {
                      text: 'Zoom',
                      onclick: function () {
                        alterSelectMethod(charts, 'zoom');
                      },
                    },
                    {
                      text: 'Pan',
                      onclick: function () {
                        alterSelectMethod(charts, 'pan');
                      },
                    },
                  ],
                },
              },
            }
          : { enabled: false },
      plotOptions: {
        series: {
          pointInterval: 24 * 3600 * 1000, // one day
          gapSize: 10,
          connectNulls: false,
          dataGrouping: {
            groupPixelWidth: 0.4,
            //enabled: false,
          },
          allowPointSelect: this.state.dragMode === 'select',
          //enableMouseTracking: false,
        },
      },
      credits: { enabled: false },
      labels: {
        style: { color: this.props.colorMode === 'dark' ? '#fff' : '#333' },
      },
      legend: {
        align: 'left',
        enabled: true,
        squareSymbol: false,
        margin: 0,
        symbolHeight: 8,
        symbolWidth: 8,
        symbolRadius: 4,
        verticalAlign: 'top',
        useHTML: true,
        x: -6,
        y: -6,
        itemStyle: {
          color: theme.palette.text.primary,
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '18px',
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      navigation: {
        menuItemStyle: {
          fill: 'none',
          states: {
            hover: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
            },
            select: {
              fill: this.props.colorMode === 'dark' ? '#444' : '#e5e5e5',
            },
          },
        },
      },
      navigator: {
        adaptToUpdatedData: false,
        height: 12,
        margin: 8,
        outlineWidth: 0,
        series: { data: [] },
        xAxis: { gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee', labels: { enabled: false } },
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        text: seriesData[0].title,
        align: 'left',
        margin: 0,
        style: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#333',
          fontWeight: 500,
          fontSize: '15px',
        },
      },
      tooltip: {
        //animation: false,
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
        outside: true,
        split: false,
        useHTML: true,
        //shared: true,
        formatter: function () {
          //return 'test';
          let uniqueType = [];
          let tooltipDate = new Date(this.x); //Set the date
          let s = '<b>' + tooltipDate.toLocaleString() + '</b>';

          let chartKeys = Object.keys(charts); //grab all the charts in the component
          if (!!plotBands) {
            let eventCount = 0;
            let eventString = '</br></br>Events: ';
            for (let i = 0; i < plotBands.length; i++) {
              const plotBand = plotBands[i];
              const start = plotBand.from;
              const end = plotBand.to;
              const type = plotBand.type;
              if (start <= this.x && this.x <= end && uniqueType.indexOf(type) === -1) {
                eventString += plotBand.type + ', ';
                eventCount++;
                uniqueType.push(type);
              }
            }
            s += eventCount > 0 ? eventString.substring(0, eventString.length - 2) : '';
          }

          for (let i = 0; i < chartKeys.length; i++) {
            let chart = charts[chartKeys[i]];
            const title = !chart || !chart.title ? '' : chart.title.textStr;
            s += `<br/><br/><b>${title}</b>`; //add the title of this chart

            if (!!chart.series) {
              for (let j = 0; j < chart.series.length; j++) {
                //for each series in each chart
                let series = chart.series[j];
                let seriesDevice = series.options.name;
                let seriesColor = series.color;
                const label = series.userOptions.label;
                const suffix = !label || label === 'Score' ? `` : `${label}`;

                if (series.name.indexOf('Navigator') === -1) {
                  //ignore Navigators
                  //Grab the x point in the data that is closest to the moused over X and return the Y value at that point
                  const needle = this.x;
                  //swapped for higher performance version
                  /* const diffArr = series.xData.map((x) => Math.abs(needle - x));
                  const minNumber = minFunction(diffArr);
                  const index = diffArr.findIndex((x) => x === minNumber);*/
                  const index = series.xData.indexOf(getClosest(needle, series.xData));
                  const delta = Math.abs(needle - series.xData[index]);

                  const yDataPoint = Math.round(series.yData[index] * 100) / 100;

                  // If gap between closest data point and current time is > 3 days show 'No Recent Data';
                  const finalPoint = delta > 1000 * 60 * 60 * 24 * 3 ? 'No Recent Data' : `${yDataPoint} ${suffix}`;
                  s += `<br/><span  style="color:${seriesColor}">${seriesDevice}:</span> ${finalPoint}`;
                }
              }
            }
          }
          return s;
        },
      },
      rangeSelector: {
        selected: !!this.props.minExtreme && !!this.props.maxExtreme ? null : !this.props.analysisRange ? 3 : this.processAnalysisRange(this.props.analysisRange), //leave off so refresh doesn't reset view.
        //enabled: index === 0,
        buttons: [
          {
            type: 'day',
            count: 7,
            text: '7d',
            title: 'View 7 days',
          },
          {
            type: 'day',
            count: 14,
            text: '14d',
            title: 'View 14 days',
          },
          {
            type: 'month',
            count: 1,
            text: '1m',
            title: 'View 1 month',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
            title: 'View 3 months',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
            title: 'View 6 months',
            enabled: this.props.analysisRange === 365,
          },
          /*{
            type: 'ytd',
            text: 'YTD',
            title: 'View year to date',
          },*/
          /*{
            type: 'year',
            count: 1,
            text: '1y',
            title: 'View 1 year',
          },*/
          {
            type: 'all',
            text: 'All',
            title: 'View all',
          },
        ],
        height: 2,
        y: -50,
        buttonTheme: {
          fill: 'none',
          style: {
            color: theme.palette.text.primary,
          },
          states: {
            hover: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
              style: {
                color: this.props.colorMode === 'dark' ? '#fff' : '#000',
              },
            },
            select: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
              style: {
                color: this.props.colorMode === 'dark' ? '#fff' : '#333',
              },
            },
            // disabled: { ... }
          },
        },
        inputStyle: {
          color: this.props.colorMode === 'dark' ? '#6fc2ff' : '#1879C0',
        },
        labelStyle: {
          color: '#999',
        },
      },
      xAxis: {
        type: 'datetime',
        ordinal: false,
        offset: 18,
        events: {
          afterSetExtremes: (e) => {
            this.setAllExtremes(e, index);
          },
        },
        minRange: dataIncrement,
        plotBands: !plotBands ? [] : plotBands,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        min: !!this.props.minExtreme && this.props.minExtreme < dataMinTime ? dataMinTime : this.props.minExtreme, //Date.UTC(2017, 9, 6),
        max: !!this.props.maxExtreme && this.props.maxExtreme > dataMaxTime ? dataMaxTime : this.props.maxExtreme, //Date.UTC(2017, 10, 30),
      },
      yAxis: {
        min: seriesData[0].nameType !== 'Primary' ? 0 : null,
        max: seriesData[0].nameType !== 'Primary' ? 100 : null,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
          x: -4,
          text: !seriesData[0].label ? null : seriesData[0].label,
          //text: seriesData[0].title === 'Valve' ? '% Open' : seriesData[0].title === 'Flow' ? 'GPH' : seriesData[0].title === 'Pressure' ? 'PSI' : seriesData[0].title === 'Level' ? '%' :seriesData[0].title === 'Analyzer' ? '': 'Concern Score',*/
        },
        //id: seriesData[0].name,
      },

      series: seriesData,
    };
  }

  registerChart(chart, key) {
    if (key !== 'Primary') {
      this.internalCharts[key] = chart;
    }
    //this.setState((prevState) => ({ charts: [...prevState.charts, chart] })); // ACCOUNTS FOR ASYNCHONOUS UPDATES
  }

  render() {
    const chartList = this.props.chartList;
    const plotBands = this.props.plotBands;
    const chartKeys = Object.keys(chartList);
    const existingCharts = Object.keys(this.internalCharts);
    //console.log(this.internalCharts);
    //console.log(this.props.chartSelections);
    //console.log(plotBands);
    //if a previously existing item has been removed, strike it from the registered charts
    for (let i = 0; i < existingCharts.length; i++) {
      if (!chartKeys.includes(existingCharts[i])) {
        delete this.internalCharts[existingCharts[i]];
      } else if (!!this.props.minExtreme && !!this.props.maxExtreme) {
        //!IMPORTANT EXAMINE THIS FOR PARTIAL SOURCE OF REDRAW ISSUES
        this.setAllExtremes({ min: this.props.minExtreme, max: this.props.maxExtreme }, -1);
      } else {
        // resizes when the format has been triggered
        //!IMPORTANT EXAMINE THIS FOR PARTIAL SOURCE OF REDRAW ISSUES
        this.internalCharts[existingCharts[i]].setSize();
      }
    }
    return (
      <React.Fragment>
        {chartKeys.map((key, index) => {
          // console.log(this.internalCharts[key]);
          let chartCount = !chartKeys.length === undefined ? null : chartKeys.length;
          return (
            <MultiStockChart
              type={this.props.type}
              embed={this.props.embed}
              chartCount={chartCount}
              key={key}
              ref={key}
              highcharts={Stockcharts}
              constructorType={'stockChart'}
              options={this.getChartOptions(chartList[key], index, plotBands)}
              callback={(chart) => {
                this.registerChart(chart, key);
              }}
            ></MultiStockChart>
          );
        })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
    chartSelections: state.chartSelections,
    //ActionRange: state.sort.filter.ActionRange,
  };
};

export default connect(mapStateToProps, {
  addChartSelections,
  replaceChartSelections,
  updateActionRange,
})(withStyles(styles)(MultiStockChartWrapper));
