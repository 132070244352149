const prioritizeDetails = {
  Validity: {
    title: `Data Validity`,
    description: `Efficient operation of a process depends on valid data. Most devices are designed to output data within specific ranges for it to be considered valid. If a device is functioning incorrectly, some values may be reported that would otherwise be considered impossible (e.g., valve readback of -200%, pH of 18, etc.). This invalid data typically means there is an issue with the device, wiring, or configuration in the DCS. When data is invalid, process engineers may have trouble understanding the real situation, resulting in suboptimal or manual control of the process.`,
    alarms: {
      Black: `The data is labeled as ‘shutdown’ during the analyzed time period. `,
      Gray: `This check was not conducted during the time period analyzed because the valid data ranges are not set. Please configure these ranges in the configuration section.`,
      Green: `The data appears to be valid throughout the analyzed time period.`,
      Yellow: `A variable appears to be invalid, possibly due to a poorly calibrated device (e.g., negative ‘zero’).`,
      Red: `More than 5% of a variable was invalid on at least one day during the analyzed time period. It is important to review this alarm and resolve any outstanding issues as they may negatively affect site performance. 
      Purple: Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but no further action items are likely required.`,
    },
    recommendationsSpecific: {
      Title1: `Check Device Configuration`,
      Rec1: `Ensure that the device type and data limits are correctly entered in the Prioritize configuration. Check that the data uploaded is correct and consistent with previous uploads. Confirm the device tag is assigned in the configuration and ensure there is no invalid data.`,
      Title2: `Check for Shutdowns`,
      Rec2: `Set the shutdown dates in Prioritize to cover the right time frames. For example, if the device was unpowered because the plant was shutdown, set the shutdown dates in Prioritize to cover those time windows. Inspect the data that is invalid during shutdown windows. If the device is reporting slightly invalid values during a shutdown, then it may need to be recalibratedor reconfigured. If one device is reporting values while another is in shutdown, there may be calibration issues or there may be a leak present.`,
      Title3: `Confirming Device Repairs`,
      Rec3: `If the most recent device readings have returned to normal, confirm that the issue was fixed. Fixed issues may be acknowledged and do not require an action item.`,
      Title4: `Address Ongoing Device Issues`,
      Rec4: `Check the data historian and confirm that the control system is receiving the correct information from the device. Consider issues with the device or control system settings, as well as wiring failure, grounding fault, or electrical interference.`,
      Rec5: `If these are not the causes of the issues, the device may need to be re-ranged, reconfigured, recalibrated, or repaired. Conduct onboard diagnostics or use in-line diagnostic tools to determine the exact nature of repairs.`,
    },
    examples: {
      example1: {
        src: 'validityEx1',
        title: `Impossible Device Readings`,
        caption:
          'This is an example of a flow device that has failed. Typically, negative flow readings and level readings, much like pH readings above 16, are impossible. This sensor may have failed.',
        size: 'small',
      },
      example2: {
        src: 'validityEx2',
        title: `Misconfigured Device`,
        caption:
          'This is an example of a level device that is misconfigured, so it reads negative values during a shutdown. When this type of reading occurs but the readings are not stuck, the device should be inspected for performance and reconfigured or recalibrated if necessary.',
        size: 'small',
      },
      example3: {
        src: 'validityEx3',
        title: `Early Signs of Failure`,
        caption:
          'This is an example of why it is important to check validity issues, even if temporary. This level device showed early warning signs of failure, and then failed permanently several months later.',
        size: 'small',
      },
      example4: {
        src: 'validityEx4',
        title: `Acceptable Negative Values`,
        caption:
          'This is an example of a temperature device that is reading negative temperature, but is doing so correctly. In these instances, this alarm can be safely acknowledged. In general, the data devices that operate outside normal parameters should be inspected by hand. This is to ensure they are working correctly, as the data from the devices may not be obviously wrong if they fail.',
        size: 'small',
      },
      example5: {
        src: 'validityEx5',
        title: `Detecting a Leak`,
        caption:
          'This is an example of devices in shutdown that are still reading positive values. In this case, flow was still occuring past a valve earlier in line. Always check with the plant to determine if data like this is indicative of an issue or if this is intended plant operation.',
        size: 'small',
      },
    },
  },
  Stuck: {
    title: `Stuck Device Values`,
    description: `When a device reports identical values for a significant period of time, the data is considered Stuck. It is usually a sign of a failure in the device, its wiring, or its configuration in the DCS. In some cases, it can also be a sign of a saturated device input. Invalid data from Stuck Device Values can force processes into sub-optimal or manual control, and process engineers will have difficulty understanding the true situation inside the process.`,
    alarms: {
      Black: `This data is from a valve/control element or the data is labeled as ‘shutdown’ during the analyzed time period. If a valve is designated as a vent valve, this check will be disabled for that valve.`,
      Gray: `This check was not performed during the time period analyzed.`,
      Green: `The data is varying over the analyzed time period.`,
      Red: `A variable has not changed for at least seven consecutive days during the analyzed time period.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned. Confirm readings at measurement sources match those found in the DCS and the Historian. `,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active over the same periods as the stuck alarm, then address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered.`,
      Title3: `Confirm the Need to Take Action`,
      Rec3: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title4: `Acknowledge Overlapping Issues`,
      Rec4: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Stuck Reading concern scores for periods of time where data was considered stuck. Diagnose these time periods individually. Looking at short timeframes and long timeframes of the data may be necessary to make conclusions.`,
      Title2: `Check Device Status`,
      Rec2: `Confirm that the device isn’t in manual or simulation mode. Check if the device is unpowered, has a wiring failure, or has a communication failure. For devices in a manual hold, determine the underlying cause of the need to control in manual and identify ways to address the root cause.`,
      Title3: `Address Saturation Issues`,
      Rec3: `If data is valid but stuck, check if the device is saturated. Confirm the device is ranged properly and in the correct units. You may need to resize the device or address process issues that cause saturation.`,
      Title4: `Address Process Issues`,
      Rec4: `Check for process issues like blockages, buildup, and shutoffs that can cause static readings. Static readings of zero or very low readings are common in cases of extreme process buildup or fouling. Clean the device if process buildup or fouling is present. Consider incorporating mitigating elements, such as separators, filters, different piping, regular maintenance, or different instrumentation.`,
      Title5: `Address Device Issues`,
      Rec5: `The device may need to be re-ranged, reconfigured, recalibrated, re-tuned or repaired. Use onboard diagnostics or use in-line diagnostic tools to determine the nature of repairs.`,
    },
    examples: {
      example1: {
        src: 'stuckEx1',
        title: `Stuck Reading`,
        caption:
          'This is an example of a stuck reading that is compared to its redundant counterparts. Stuck readings, such as those from device AI_6104, will look flat and unresponsive. This behavior is usually due to a broken device that needs repairs/replacement.',
        size: 'small',
      },
      example2: {
        src: 'stuckEx2',
        title: `Repeating Values`,
        caption:
          'If shutdown parameters are not set in the prioritize configuration, there is a chance that during shutdown that some devices may read the same values repeatedly. For example, a flow meter may read 0 flow during a shutdown because there is truly no flow. If this is the expected behavior, the stuck value issue can be safely acknowledged. Alternatively, you can set shutdown periods in the prioritize configuration settings so the system will automatically recognize these values as shutdown.',
        size: 'small',
      },
      example3: {
        src: 'stuckEx3',
        title: `Process Buildup`,
        caption:
          'Process buildup can ultimately lead to a complete loss of readings from a device. This can either be due to buildup that prevents readings (ex: certain level devices) or buildup that prevents flow (as in this case in this example). In either case, the device will need to be cleaned or repaired. This may be possible using in-process cleaning systems or by shutting down the area of the site to clean or replace devices and pipes manually.',
        size: 'small',
      },
    },
  },
  Quantization: {
    title: `Data Resolution`,
    description: `Low data resolution can occur when there are not enough unique readings from the device to accurately measure the process. This can be caused by a large device span, an inaccurate sensor, or failures in grounding. This issue should be addressed as soon as possible to capture process dynamics and optimize control.`,
    alarms: {
      Black: `This alarm is labeled as ‘shutdown’ or is close to shutdown periods during the analyzed time period.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The data appears to have numerous unique readings.`,
      Red: `A variable has insufficient unique readings over a thirty day period during the analyzed time period.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Confirm the Need to Take Action`,
      Rec1: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title2: `Acknowledge Overlapping Issues`,
      Rec2: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Data Resolution concern scores for periods of time where data was considered to have a low resolution. Diagnose these time periods individually. Typically, looking at short timeframes and long timeframes of the data will be necessary to make conclusions.`,
      Title2: `Check Data Configuration`,
      Rec2: `Verify that data is not being truncated or over-approximated by checking DCS and historian settings. Check the data reporting, data storage, and deadband settings. Adjust the historian’s settings if it shows significantly less granular data than the DCS.`,
      Title3: `Check Device Resolution`,
      Rec3: `Check the device's resolution and accuracy against the expected data range. If resolution is low, re-range the device or use a different device. Adjust deadband settings if needed.`,
      Title4: `Check Device Connection`,
      Rec4: `Check for connection issues between the instrument and the control system, such as wiring failure or grounding fault. Compare readings at the device and control system to confirm.`,
      Title5: `Address Device Issues`,
      Rec5: `The device may need repair. Use onboard diagnostics or use in-line diagnostic tools to determine the nature of repairs.`,
    },
    examples: {
      example1: {
        src: 'quantizationEx1',
        title: `Insufficient Resolution`,
        caption: `This is an example of sensors with insufficient resolution to accurately measure a process. Changes of level below 5% of the process's typical level are not captured. To address this issue, first ensure that the control system and historian are storing data at suitable resolutions. If greater accuracy is needed, consider re-ranging or replacing the devices.`,
        size: 'small',
      },
      example2: {
        src: 'quantizationEx2',
        title: `False Positives`,
        caption:
          'There are some cases where data from a device does not move often. Though rare, this can sometimes be flagged as a potential issue of low data resolution. If the device appears to be behaving as expected, the issue can safely be acknowledged.',
        size: 'small',
      },
    },
  },
  Saturation: {
    title: `Saturation`,
    description: `When a device reaches the limit of its measurement range, it can no longer provide accurate measurement values. Instead, the device will only provide its maximum or minimum values until process conditions are back within its measurement range. To avoid this issue, devices should be ranged properly to cover the full set of process dynamics or replaced with devices that can measure those dynamics. When data is inaccurate, the process will likely have suboptimal or manual control only.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `This variable has an acceptable number of readings at extreme values.`,
      Yellow: `The data reports near its maximum values a significant portion of the time when compared to similar variables in the application.  If the variable is a valve, values below 15% or above 85% of travel are considered to be near maximum values. This check is one-sided if the valve is a vent valve, where values are expected to be near one maximum of travel the majority of the time. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if it is reasonable to do so.`,
      Red: `The data reports near its maximum values the most often compared to similar variables in this application. If the variable is a valve, values below 15% or above 85% of travel are considered to be near maximum values. This check is one-sided if the valve is a vent valve, where values are expected to be near one maximum of travel the majority of the time.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are required.`,
    },
    recommendations: {
      Title1: `Check Data Validity`,
      Rec1: `Confirm the validity of the data and the status of the device responsible for the variable. Address any validity alarms and ensure that the device is powered.`,
      Title2: `Confirm the Need to Take Action`,
      Rec2: `If the latest device readings have returned to normal, verify that the issue was fixed.`,
      Title3: `Check Data Configuration`,
      Rec3: `Check the DCS and historian settings and ensure that the device value ranges are properly configured. Adjust the settings if necessary to match the readings at the device.`,
      Title4: `Acknowledge Overlapping Issues`,
      Rec4: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Saturation concern scores for periods of time where the concern score was high. Diagnose these time periods individually. Looking at short timeframes and long timeframes of the data may be necessary to make conclusions.`,
      Title2: `Identify Upstream/Downstream Dynamics`,
      Rec2: `Consider if upstream or downstream process issues are causing the instrument to be at maximum readings or the valve to be more or less open than intended.`,
      Title3: `Review Control Tuning and Response`,
      Rec3: `Inspect the tuning of the loop and see if different tuning parameters or control schemes would alleviate the saturation issue. Loops with high response times between the set point and primary variable may require changes beyond tuning (ex: device locations).`,
      Title4: `Address Process Buildup`,
      Rec4: `Extreme coating or buildup in the loop can cause a valve to become fully open or closed, leading to control issues. Determine if those issues are due to physical phenomenon, such as a temperature that is too high or a flow rate that is too low. Consider incorporating mitigating elements in the process, such as separators, filters, different piping, regular maintenance, or instrumentation designed for the process issues.`,
      Title5: `Address Device Ranging`,
      Rec5: `Re-ranging devices or resizing valves/piping may be necessary. This is particularly important if the limitation affects a KPI such as throughput. Alternatively, consider using a "gap and trim" or "big and small" valve approach to control the process more effectively.`,
      Title6: `Review Process Information`,
      Rec6: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'saturationEx1',
        title: `Typical Example of Saturation`,
        caption:
          'This is an example of a valve that is saturating. Over time, the valve is controlling at increasingly higher percentages of its span. As the valve reaches the ends of its travel, it is increasingly unable to meet the needs of this service and its control performance will be worse than if the valve was controlling in the middle of its travel. This could be due to performance degradation, process buildup, or changes in process design (increased throughput). Valves that are reaching their limitations should usually be investigated and repaired/resized if appropriate.',
        size: 'small',
      },
      example2: {
        src: 'saturationEx2',
        title: `Limited Reading Range`,
        caption:
          'This is an example of a saturated reading on an instrument. The flow meter does not read more than 50 GPM despite the valve continuing to open. This is an example of a flow meter that needs to be re-ranged to capture the full range of process values.',
        size: 'small',
      },
      example3: {
        src: 'saturationEx3',
        title: `Seasonal Trends`,
        caption:
          'Despite the process issues at the beginning of this chart. The main cause of saturation in this application is seasonal. This cooling unit is undersized and saturates because it cannot provide enough cooling for the process to run optimally. Always consider the process application and what it implies. In this case, the issue was not the valve that was reporting saturation but the cooling system as a whole.',
        size: 'small',
      },
    },
  },
  RedundantErr: {
    title: `Redundant Error`,
    description: `Devices that are redundant should report similar values. If these devices report significantly different values, it is usually an indication that at least one of the devices is experiencing problems. Inaccurate data can lead to suboptimal control and compromised process performance.`,
    alarms: {
      Black: `No devices are assigned as redundant in the configuration or the data is ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The redundant variable pairs report similar readings.`,
      Yellow: `Two redundant variables have a higher percentage of error compared to similar redundant pairs in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if it is reasonable to do so.`,
      Red: `Two redundant variables have the highest percentage of error among similar redundant pairs in the application. All redundant errors above 10% will always report as a ‘concern score’ of 10.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Review Configuration Settings`,
      Rec1: `Check if the devices are correctly configured as redundant in Prioritize. Check the DCS and historian settings and value ranges. Verify that the device readings match the DCS/historian readings.`,
      Title2: `Confirm the Need to Take Action`,
      Rec2: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title3: `Acknowledge Overlapping Issues`,
      Rec3: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Saturation concern scores for periods of time where data was considered stuck. Diagnose these time periods individually. Typically, looking at short timeframes and long timeframes of the data will be necessary to make conclusions.`,
      Title2: `Confirm Device Functionality`,
      Rec2: `Check that all devices are operational and not in simulation mode. Review advanced diagnostics. Calibrate, repair or replace the device if diagnostics indicate to do so.`,
      Title3: `Confirm Redundancy`,
      Rec3: `Ensure that the devices are truly redundant and there are no differences in measurement principles, process differences, or separate pipes. If any differences exist, identify and mitigate the issues.`,
      Title4: `Check Environment Factors`,
      Rec4: `Verify that there are no environmental factors affecting one device and not the other, such as sun exposure or electrical issues.`,
      Title5: `Calibrate or Replace Devices`,
      Rec5: `If none of the above solutions resolve the issue, calibrate or replace the sensors. Attempt calibration first before opting for a replacement. Multiple devices may need calibration or replacement if the root cause is not obvious.`,
    },
    examples: {
      example1: {
        src: 'redundantErrEx1',
        title: `Unresponsive Device`,
        caption: `This is an example of a redundant pair where one device has become unresponsive (AI_6233). Typical causes of non-response areprocess buildup or an issue that needs to be fixed or replaced.`,
        size: 'small',
      },
      example2: {
        src: 'redundantErrEx2',
        title: `Unusual Behavior`,
        caption: `This is an example of a device that consistently behaves differently compared to its redundant pair, even after calibrations or repairs (AI_6104). Typically, this is caused by a systemic problem related to processes or design. Check the helper recommendations for suggestions. Sometimes, simple issues like the amount of sunlight a device is exposed to can make a difference.`,
        size: 'small',
      },
      example3: {
        src: 'redundantErrEx3',
        title: `Poor Calibration`,
        caption:
          'Redundant errors occur when devices are miscalibrated or when their performance has changed since the initial calibration, leading to a degradation in accuracy. This example shows a pair of devices that are misaligned. When sustained redundant errors are present, it is important to promptly check and calibrate the devices.',
        size: 'small',
      },
      example4: {
        src: 'redundantErrEx4',
        title: `Process Issues`,
        caption:
          'In certain cases, there may be a gradual change or difference between redundant variables over time. For devices like pH probes that are considered "consumable," such errors indicate the need for calibration or replacement. However, for sturdier devices like flow meters or level meters, such discrepancies typically point to a process-related problem like buildup. If a process issue affects the measurement, it may be necessary to address that issue or employ an alternative measurement method to resolve the issue.',
        size: 'small',
      },
    },
  },
  LoopErr: {
    title: `Loop Error`,
    description: `Instrument and valve pairs that operate in a control loop should behave similarly. In closed control, the ‘gain’ of the valve to the instrument should be linear across travel and as close to a value of 1 as possible. In open control, the signals between the valve and the instrument should be in general agreement. For instance, if a valve closes, the flow measured downstream should decrease immediately. However, if the pattern of the valve and instrument disagree, it indicates a loss of control. High loop error could result from issues with either the instrument or the valve, or it could stem from problems with the process such as erosion, coating, or solids. Ignoring these issues could lead to a complete loss of control or shutdown of site operations. It is essential to review the performance of loops with degraded control and address financially significant issues.`,
    alarms: {
      Black: `No devices are assigned as in a loop or the data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `These variables report similar signal patterns.`,
      Yellow: `Two loop variables have signal patterns that are significantly different from each other among similar loop pairs in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if it is reasonable to do so.`,
      Red: `Two loop variables have signal patterns that are the most different from each other among similar loop pairs in the application.
      Purple: Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are required.`,
    },
    recommendations: {
      Title1: `Review Configuration Settings`,
      Rec1: `Ensure that devices are correctly set up as a loop in the configuration.`,
      Title2: `Verify Loop Type`,
      Rec2: `Confirm that the devices are in closed or open control based on the intended function of the loop. In closed control, a valve attempts to keep the device readings close to a certain value. In open control, the device values move proportionally to the movements of a valve. Verify the loop type is integrated or normal based on the intended function of the loop. For example, a level loop is integrated because a change in a valve changes the rate of change of a level device. A normal loop has a proportional change.`,
      Title3: `Confirm Data Validity`,
      Rec3: `Check that the data uploaded was the correct data and that the units of measure are consistent with any previous uploads. Confirm that neither device in a loop is in simulation mode, manual control, or a similar hold.`,
      Title4: `Confirm the Need to Take Action`,
      Rec4: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title5: `Determine Financial Impact`,
      Rec5: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title6: `Acknowledge Overlapping Issues`,
      Rec6: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Loop Error concern scores for periods of time where the concern score was high. If there are specific time periods where the loop error score was high, diagnose these time periods individually. Whether loop error is high consistently or in specific instances, looking at short timeframes and long timeframes of the data will be necessary to make conclusions.`,
      Title2: `Check Device Issues`,
      Rec2: `Review the validity, stuck, data resolution, and saturated issues of the device and its paired instrument. Determine if these issues are related or are affecting the measurement of Loop Error.  Review any diagnostic recommendations and perform necessary repairs.`,
      Title3: `Identify Discrepancies`,
      Rec3: `Consistently high loop errors can indicate a changing relationship between the signals or indicate consistently unrelated signals.  High loop errors over specific periods should be reviewed individually. Example issues can be leak-by of a closed valve, changes in process gain, and non-response. `,
      Title4: `Identify Feed-Forward or Cascade Dynamics`,
      Rec4: `Determine if the issue is affected or caused by an attempt to control upstream/downstream dynamics. Check nearby loops to see if there are similar issues. If the change in the variable fully accounts for the upstream/downstream variability, this alarm can be acknowledged. The variability would typically be considered accounted for if the instrumentation remains consistent despite the control element having more variability. Otherwise, a review can be conducted to see if variability can be reduced further.`,
      Title5: `Identify Uncontrolled Upstream/Downstream Dynamics`,
      Rec5: `Determine if the issue is affected or caused by uncontrolled upstream/downstream dynamics. Check nearby loops to see if there are similar issues. Another approach is to put the loop in manual to see if the active issue resolves itself. Issues that resolve themselves are local to the loop. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      Title6: `Check for Process Issues`,
      Rec6: `Consider process issues such as buildup, blockages, erosion, and solids that may affect loop performance. Look for changes in the noise of the measurements or recorded measurements. With solids and buildup, the measurements may decrease given the same signal from the control element. With erosion, they may increase.  Mitigate any issues with process composition that may contribute to the issue.`,
      Title7: `Review Tuning and Control Scheme`,
      Rec7: `Review tuning and control scheme for extremely large oscillations or variability (greater than 10%). Valves with tuning issues may also have valve performance issues. Loops with high response times between the set point and primary variable may require changes beyond tuning (ex: device locations).`,
      Rec8: `If the loop is controlled via closed control, then large shifts in loop error may indicate a non-linear gain. If this cannot be improved via control tuning, then this could be because the installed characteristic curve is significantly non-linear. Review the valve specifications and piping to determine if a more optimal flow characteristic can be achieved.`,
      Title8: `Review Valve Performance`,
      Rec89: `If valve control appears to be poor and not caused by other issues, perform an in-line valve diagnostic test. Complete any necessary valve repairs. Saw-tooth/square-wave pattern or oscillations are signs of valve issues.`,
      Title9: `Review Loop Dynamics`,
      Rec10: `If the correct action items are not clear, review the purpose of the loop/devices and the device relationships with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'loopErrEx1',
        title: `Expected Loop Behavior`,
        caption:
          'Loop error can be leveraged for any device pair where we expect a change in response of one device to affect the shape of the response in the other. Ideally, changes in one variable are reflected in the changes in another.  We can set the system to work on setpoint and/or process variables, rather than just setpoint and valve readback.',
        size: 'small',
      },
      example2: {
        src: 'loopErrEx2',
        title: `Upstream Issues`,
        caption: `Loop error alarms may be triggered when there are times where a response is anticipated but not observed. In this scenario, we can observe that the valve experiences significant changes in the requested output without affecting the process pressure (Nov 4th and Nov 15th). A change like this might occur due to attempts to control upstream or downstream dynamics and may be acceptable to the plant. Additionally, we notice that the pressure changes while the valve remains closed, indicating a possible occurrence of 'leak-by' or another aspect of the process influencing this loop. This would be an example of an uncontrolled change that should be explored further.`,
        size: 'small',
      },
      example3: {
        src: 'loopErrEx3',
        title: `Stiction`,
        caption:
          'This is an example of a sawtooth/square-wave pattern. The setpoint increases without a material shift in the process variable level. Then, suddenly, the variable shifts suddenly and the setpoint reverses direction. This happens because the DCS (Distributed Control System) tries to move the valve, but due to  ‘stiction’, the valve requires a minimum step size to generate enough force for movement. When this occurs, the valve needs to be repaired in order to function properly.',
        size: 'small',
      },
      example4: {
        src: 'loopErrEx4',
        title: `Process Buildup`,
        caption: 'Buildups of solids over time will typically show as a slowly changing difference in the response of the valve or its instrument when compared to each other.',
        size: 'small',
      },
    },
  },
  ControlRange: {
    title: `Control Range`,
    description: `Many loops are designed to keep variation to a minimum. Larger ranges of data within a loop are indicative of sub-optimal control, sub-optimal valve performance, cyclical variations, and/or process upsets that should be eliminated.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The data reported a reasonable range of data for all seven-day periods during the analyzed time period.`,
      Yellow: `A variable has a larger Y-axis range over a seven day period compared to similar variables in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, then any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable has the largest Y-axis ranges over a seven day period compared to similar variables in the application. This score is calculated as a percentage of the typical variable values. All control ranges above 100% of typical variable values will always report as a ‘concern score’ of 10.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned, and that there isn’t invalid data in your upload.`,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active, address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered.`,
      Title3: `Consider Device Maintenance`,
      Rec3: `If the issue is due to device failure, determine if the device(s) have been repaired/replaced. If those devices have been repaired/replaced, this issue can be acknowledged. If failures are due to process wear or occur on a regular interval, consider replacing the parts with different components, such as different measurement technologies or different metals. Adjusting maintenance intervals or performing preventative maintenance can also reduce process upsets due to device failure.`,
      Title4: `Confirm the Need to Take Action`,
      Rec4: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title5: `Determine Financial Impact`,
      Rec5: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title6: `Acknowledge Overlapping Issues`,
      Rec6: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Normal Performance`,
      Rec1: `If the device operates in a variable system, such as an on-off system or a batch system, then the device may be expected to have wide control ranges. You can acknowledge this alarm if performance is within normal parameters.`,
      Title2: `Review Affected Data`,
      Rec2: `Review the Control Range concern scores for periods of time where the concern score was high. Diagnose these time periods individually. Looking at short timeframes and long timeframes of the data may be necessary to make conclusions.`,
      Title3: `Identify Upstream/Downstream Dynamics`,
      Rec3: `Determine if the issue is affected or caused by upstream/downstream dynamics. Check nearby loops to see if there are similar issues. Another approach is to put the loop in manual to see if the active issue resolves itself.  Issues that resolve themselves are local to the loop. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      Title4: `Identify the Control Range Issue Types`,
      Rec4: `Issues with control ranges can arise in various forms, including consistently high variability, changes in underlying variables over time, and occasional or consistent process upsets. Determine which are the best descriptions for the control range issues in the data, then review the appropriate recommendations below.`,
      Title5: `Sustained Variability`,
      Rec5: `If the issue is due to high and sustained variability, then the variable will have a large control range throughout the data. The stability and volatility alarms will likely be active. For an example of high sustained variability, refer to the first example later in this Helper.`,
      LearnMore1: {
        Title1: `Repeating Behavior`,
        Rec1: `Check if the variability has a structure to it, such as a repeating or oscillating behavior. If it does, diagnose this alarm as if the oscillation amplitude alarm is active.`,
        Title2: `Issues Due to Upstream/Feedstock Upsets`,
        Rec2: `Check that the issues are not due to issues upstream. Attempt to address the issues at the source. This may include adjustments to valve performance/tuning or a review of contractual obligations regarding feedstocks. If this is not possible, attempt to migrate those issues through adjustments to valve performance or control tuning. Review control system settings to determine if loops can receive information on a faster basis (ex: Cascade/Feedforward). Repositioning or installation of new devices may also be effective.`,
        Title3: `Process Dynamics`,
        Rec3: `Check if the process environment is causing the issues. Solids, corrosion, and coating are potential culprits. If your process has these issues, see if the anomalies align with these features. For example, a process that has the potential to coat a flow meter may cause levels in a tank to drop over time. Address any issues with the process environment. Recalibrate or repair instruments if necessary, and try to reduce the amount of time spent in sub-optimal process ranges. Consider instrumentation and piping that mitigate process impacts.`,
        Title4: `Issues due to Secondary Variables`,
        Rec4: `Make sure that secondary device variables like temperature, pressure, viscosity, density, and conductivity are within normal parameters. If your devices or control schemes use compensation, check that these variables are not causing the variability. Changes to the process may be needed to address these issues, such as a properly sized separator for density or the right heat exchangers or steam valves for temperature/humidity.`,
        Title5: `Issues due to Plant Conditions`,
        Rec5: `Check maintenance records or control system records for causes that coincide with changes in variability. Address those causes if appropriate.`,
        Title6: `Review Process Gain`,
        Rec6: `Confirm the process gain isn’t excessive. The process gain should usually be between 0.5 and 2. Process gain means that on a percent span basis the PV responds 0.5 times to 2 times as much as the control element commands. If the process gain is low, then the primary variable may have too large of a control range. If it is high, then re-tuning the loop or changing the control element engineering may be necessary. Process gain should also be as linear as possible over the control range, with the maximum gain being no more than twice the minimum gain over the control range. In processes where large swings must be accounted for and fine-control is required then unique engineering or gap-and-trim control may be needed to control the process fully.`,
      },
      Title6: `Changes Over Time`,
      Rec6: `If the issue is due to changes over time, then the variable will have a larger control range over time. The trend change alarm might be active. For an example of changes over time, refer to the second example later in this Helper.`,
      LearnMore2: {
        Title1: `Normal Behavior`,
        Rec1: `Confirm whether the variable is intended to change over this time period. If so, this alarm may be acknowledged.`,
        Title2: `Process Dynamics`,
        Rec2: `Check if the process environment is causing the issues. Solids, corrosion, and coating are potential culprits. If your process has these issues, see if the anomalies align with these features. For example, a process that has the potential to coat a flow meter may cause levels in a tank to drop over time. Another example is a process that runs too hot, causing corrosion. Address any issues with the process environment. Recalibrate or repair instruments if necessary, and try to reduce the amount of time spent in sub-optimal process ranges. Consider instrumentation and piping that mitigate process impacts.`,
        Title3: `Secondary Variables`,
        Rec3: `Make sure that secondary device variables like temperature, pressure, viscosity, density, and conductivity are within normal parameters. If your devices or control schemes use compensation, check that these variables are not causing the variability. Changes to the process may be needed to address these issues, such as a properly sized separator for density or the right heat exchangers or steam valves for temperature/humidity.`,
      },
      Title7: `Seasonal or Cyclical Factors`,
      Rec7: `If the issue is due to seasonal or cyclical factors, determine the frequency and/or times of occurrence, then correlate them with potential causes. For instance, if the issue cycles every 24 hours, it may be due to day/night cycles. The issue could also be changes in parts like pH probes, or process changes like batch runs. Once you establish a cause, identify approaches to mitigate effects. For example, fluctuations in temperature can be mitigated by insulation, shading of sensors, and improvements to heating/cooling systems.`,
      Title8: `Process Upsets`,
      Rec8: `If the issue is due to process upsets, then the control range will be large for periods of time and then smaller for the rest of the data. The volatility alarm might activate. In cases of consistently repeating upsets, the stability alarm might also activate. For an example of process upsets, refer to the third example later in this section.`,
      LearnMore3: {
        Title1: `Identify the Root Cause of the Upset`,
        Rec1: `You can review maintenance and site records to find valuable information. Look for changes to control or maintenance issues for the device and related devices. Correlate the records with the dates and times of the upsets.`,
        Title2: `Issues due to Upstream/Feedstock Upsets`,
        Rec2: `Attempt to address the issues at the source. This may include adjustments to valve performance/tuning or a review of contractual obligations regarding feedstocks. If this is not possible, attempt to migrate those issues through adjustments to valve performance or control tuning. Review control system settings to determine if loops can receive information on a faster basis (ex: Cascade or Feedforward). Repositioning or installation of new devices may also be effective.`,
        Title3: `Issue due to Solids/Corrosion/Blockage`,
        Rec3: `Cleaning or repair/replacement of the devices may be necessary. Long term improvements should be considered to prevent future issues. Determine if those issues are due to physical phenomenon, such as a temperature that is too high or a flow rate that is too low.  If possible, adjust the control of those parameters to reduce issues. Consider incorporating mitigating elements in the process, such as separators, filters, different piping, regular maintenance, or instrumentation designed for the process issues.`,
      },
      Title9: `Control Tuning and Low Valve Performance`,
      Rec9: `If none of the above options are the root cause, the issues may be due to control tuning and/or low valve performance. To isolate whether the issue is due to control performance or control tuning, you can perform an online or offline diagnostic test. If the valve(s) fail to meet the desired performance, repair or replace them. Then re-tune the loop. If the valve meets performance, re-tune the loop with the known valve performance in mind. If the loop continues to have issues, consider advanced tuning approaches like APC and MPC.`,
      Title10: `Review Process Information`,
      Rec10: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'controlRangeEx1',
        title: `Consistent Data`,
        caption: 'This is an example of a device that would have a low concern score for control range. This is because the data is consistent and has a low range in the Y-axis.',
        size: 'small',
      },
      example2: {
        src: 'controlRangeEx2',
        title: `Process Upset`,
        caption:
          'This is an example where the y-axis range increases a significant degree compared to the normal day-to-day noise.  This kind of variability occurred over an intermittent period of time (e.g. a process upset).',
        size: 'small',
      },
      example3: {
        src: 'controlRangeEx3',
        title: `Consistent Poor Performance`,
        caption:
          'Extremely high variability can adversely impact process performance. We can see here that this variable is moving a significant amount, but the overall trendline over these minutes is relatively constant. These variations tend to adversely impact quality, yield, or throughput.',
        size: 'small',
      },
      example4: {
        src: 'controlRangeEx4',
        title: `Changing Performance`,
        caption: 'Trend changes in variable output can also cause control ranges to expand. These issues may be indicative of a reliability or process issue.',
        size: 'small',
      },
    },
  },
  TrendChange: {
    title: `Trend Change`,
    description: `A change in the values of a process over a long period of time may mean that the loop's performance is changing. This can happen because of different reasons like changes in weather, erosion, loss of valve or instrument performance, etc. Keeping process parameters consistent can lead to better quality, yield, efficiency, and throughput. Fixing components that are worn out can also result in better uptime and overall throughput.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The data reported stable process data between the start and end of each sixty-day period during the analyzed time period.`,
      Yellow: `A variable reports a significant change in process values between the start and end of a sixty-day period among similar variables in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable reports the largest percent change in process values between the start and end of a sixty-day period among similar variables in the application.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are required.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned, and that there isn’t invalid data in your upload.`,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active, address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered.`,
      Title3: `Normal Performance`,
      Rec3: `If the device is supposed to change over this time period, the issue can be acknowledged.`,
      Title4: `Confirm the Need to Take Action`,
      Rec4: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title5: `Determine Financial Impact`,
      Rec5: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title6: `Acknowledge Overlapping Issues`,
      Rec6: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Check for Control Range Issues`,
      Rec1: `Significant process noise or changes in control range may affect the stability of the slope alarm measurement. For example, large sustained process upsets may affect the Trend Change reading. Before determining sustained changes in data, elimination of control range issues may be necessary.`,
      Title2: `Review Affected Data`,
      Rec2: `Review the Trend Change concern scores for periods of time where the concern score was high. Diagnose these time periods individually. Look for changes caused by trend lines or a sudden and sustained change in operating level.`,
      Title3: `Identify Upstream/Downstream Dynamics`,
      Rec3: `Determine if the issue is affected or caused by upstream/downstream dynamics. Check nearby loops to see if there are similar issues. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      Title4: `Review Process Dynamics`,
      Rec4: `Check if the process environment is causing the issues. Solids, corrosion, and coating are potential culprits. If your process has these issues, see if the anomalies align with these features. For example, a process that has the potential to coat a flow meter may cause levels in a tank to drop over time. Another example is a process that runs too hot, causing corrosion. Address any issues with the process environment. Recalibrate or repair instruments if necessary, and try to reduce the amount of time spent in sub-optimal process ranges. Consider instrumentation, piping, and other changes that mitigate process impacts.`,
      Title5: `Review Secondary Variables`,
      Rec5: `Make sure that secondary device variables like temperature, pressure, viscosity, density, and conductivity are within normal parameters. If your devices or control schemes use compensation, check that these variables are not causing the variability. Changes to the process may be needed to address these issues, such as a properly sized separator for density or the right heat exchangers or steam valves for temperature/humidity.`,
      Title6: `Seasonal, Cyclical, or Time Factors`,
      Rec6: `Identify the frequency and times of occurrence. Correlate with potential causes such as the change in seasons, changes in parts, or process changes. Once identified, mitigate the effects. For example, fluctuations in temperature can be mitigated by insulation, shading of sensors, and improvements to heating/cooling systems.`,
      Title7: `Address Instrumentation`,
      Rec7: `Recalibrate or repair instruments if necessary. Consider replacing parts with different components, technologies, or metals if measurement failures are due to process wear or occur on a regular interval. Adjust maintenance intervals or perform preventative maintenance to reduce process upsets. Make sure to compare redundant instruments and identify any differences in trend changes that might affect the recommended action items.`,
      Title8: `Review Process Information`,
      Rec8: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'trendChangeEx1',
        title: `Stable Trend vs Moving Trend`,
        caption:
          'This is an example of a device that would have low concern vs one with higher concern when it comes to Trend Change. AI_6195 (blue) is stable over this time period. On the other hand, AI_6168 (orange) begins to shift slowly but surely. Process data that does not change its median values tends to be preferable to data that is changing over time.',
        size: 'small',
      },
      example2: {
        src: 'trendChangeEx2',
        title: `Trend Behavior`,
        caption:
          'Trend changes will appear to be slow shifts over time, such as two of these three pH values. These three redundant pH sensors should be reporting the same values. However, these sensors are wearing out, so there is a trend in the individual variables while the process controls around the ‘middle’ value. Trends in redundant error are a key sign that it is time to re-calibrate or replace the pH sensors.',
        size: 'small',
      },
      example3: {
        src: 'trendChangeEx3',
        title: `Process Buildup`,
        caption:
          'Solids/buildup will typically appear as a slow change over time. In the case of a flow meter, we would expect the readings to drift towards zero if throughput was constrained. With a level or pressure device readings may increase, decrease, or stay the same depending on the location of the buildup. With Temperature, pH, or similar devices, the readings are likely to exhibit static readings that reflect values of the substance coating the device.',
        size: 'small',
      },
      example4: {
        src: 'trendChangeEx4',
        title: `Seasonal Trends`,
        caption:
          'Changes in process can also be due to season trends, such as these motors. They operate fans to maintain a constant temperature. They work harder in the warmer months than the colder months.',
        size: 'small',
      },
      example5: {
        src: 'trendChangeEx5',
        title: `Inconsistent Process`,
        caption:
          'Processes with high variability may mask trends that are occurring underneath. This particular process ‘wanders’ on any given sixty-day basis, and should be reviewed for opportunities to improve process consistency.',
        size: 'small',
      },
      example6: {
        src: 'trendChangeEx6',
        title: `Normal Process Shifts`,
        caption: 'If the process is a batch process, then changes in process levels may occur on a semi-regular or regular basis. If all shifts appear to be expected, this alarm can be acknowledged.',
        size: 'small',
      },
    },
  },
  Stability: {
    title: `Stability`,
    description: `High long-term variability in a loop can indicate that there are sustained issues like oscillations, process dynamics, or erosion. Addressing long-term stability can lead to improvements in quality, yield, efficiency, and throughput.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed, or there are not enough non-shutdown values during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The data reported stable process data for all continuous thirty-day periods during the analyzed time period.`,
      Yellow: `A variable reports larger volatility during a thirty-day period compared to similar variables in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable reports the largest volatility during a thirty-day period among similar variables in the application.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Check Device Status`,
      Rec1: `Confirm that the device isn’t in manual or simulation mode. Check if the device is unpowered, has a wiring failure, or has a communication failure.`,
      Title2: `Confirm the Need to Take Action`,
      Rec2: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title3: `Determine Financial Impact`,
      Rec3: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title4: `Acknowledge Overlapping Issues`,
      Rec4: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Stability concern scores for periods of time where the concern score was high. Diagnose these time periods individually. Sometimes the area of interest can be where there was a sustained improvement in stability.`,
      Title2: `Identify Upstream/Downstream Dynamics`,
      Rec2: `Determine if the issue is affected or caused by upstream/downstream dynamics. Check nearby loops to see if there are similar issues. Another approach is to put the loop in manual to see if the active oscillation resolves itself. Issues that resolve themselves are local to the loop. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      LearnMore1: {
        Title1: `Address the Issues`,
        Rec1: `Attempt to address the issues at the source. This may include adjustments to valve performance/tuning or a review of contractual obligations regarding feedstocks. If this is not possible, attempt to migrate those issues through adjustments to valve performance or control tuning. Review control system settings to determine if loops can receive information on a faster basis (ex: Cascade/Feedforward). Repositioning or installation of new devices may also be effective.`,
      },
      Title3: `Issues due to Plant Conditions`,
      Rec3: `Check maintenance records or control system records for causes that coincide with changes in variability. Address those causes if appropriate.`,
      Title4: `Identify Oscillations`,
      Rec4: `If the behavior is repetitive, highly structured, or oscillatory, then diagnose this alarm as if the oscillation amplitude alarm is active.`,
      Title5: `Identify Process Issues`,
      Rec5: `Consider process issues such as buildup, blockages, erosion, and solids that may affect volatility.  With solids and buildup, they may decrease. With erosion, they may increase.  Determine if those issues are due to physical phenomenon, such as a temperature that is too high or a flow rate that is too low.  If possible, adjust the control of those parameters to reduce issues. Consider incorporating/inspecting mitigating elements in the process, such as separators, filters, different piping, regular maintenance, or instrumentation designed for the process issues.`,
      Title6: `Consider Device Maintenance`,
      Rec6: `If the issue is due to device failure, determine if the device(s) have been repaired/replaced. If those devices have been repaired/replaced, this issue can be acknowledged. If failures are due to process wear or occur on a regular interval, consider replacing the parts with different components, such as different measurement technologies or different metals. Adjusting maintenance intervals or performing preventative maintenance can also reduce process upsets due to device failure.`,
      Title7: `Secondary Variables`,
      Rec7: `Make sure that secondary device variables like temperature, pressure, viscosity, density, and conductivity are within normal parameters. If your devices or control schemes use compensation, check that these variables are not causing the variability. Changes to the process may be needed to address these issues, such as a properly sized separator for density or the right heat exchangers or steam valves for temperature/humidity.`,
      Title8: `Review Process Gain`,
      Rec8: `Confirm the process gain isn’t excessive. The process gain should usually be between 0.5 and 2. Process gain means that on a percent span basis the PV responds 0.5 times to 2 times as much as the control element commands. If the process gain is low, then the primary variable may have too large of a control range. If it is high, then re-tuning the loop or changing the control element engineering may be necessary. Process gain should also be as linear as possible over the control range, with the maximum gain being no more than twice the minimum gain over the control range. In processes where large swings must be accounted for and fine-control is required then unique engineering or gap-and-trim control may be needed to control the process fully.`,
      Title9: `Eliminate Uncommon Causes`,
      Rec9: `Issues with electrical wiring, grounding fields, and electrical boards can cause significant volatility. Check for differences between instrumentation and control system readings. Validate theoretical process readings against determined process readings. Look for sudden significant jumps in slow variables.`,
      Title10: `Control Tuning and Low Valve Performance`,
      Rec10: `If none of the above options are the root cause, the issues may be due to control tuning and/or low valve performance. To isolate whether the issue is due to control performance or control tuning, you can perform an online or offline diagnostic test. If the valve(s) fail to meet the desired performance, repair or replace them. Then re-tune the loop. If the valve meets performance, re-tune the loop with the known valve performance in mind. If the loop continues to have issues, consider advanced tuning approaches like cascade control, feedforward control, APC, and MPC. Loops with high response times between the set point and primary variable may require changes beyond tuning (ex: device locations).`,
      Title11: `Review Process Information`,
      Rec11: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'stabilityEx1',
        title: `Stable Performance`,
        caption: 'Loops with minimal variation and noise are generally better for achieving process objectives. This is an example of the kind of device that has stable readings.',
        size: 'small',
      },
      example2: {
        src: 'stabilityEx2',
        title: `Poor Performance`,
        caption:
          'Stability is an excellent metric to identify loops that are poorly controlled. It can be thought of as similar to standard deviation over a thirty day period. This particular flow device is consistently varying by 50% of its typical values. In many instances, that much instability is undesirable for high control. However, some processes are naturally noisy - either by design or because there is minimal value in tighter control. When evaluating loops for improvement, be sure to confirm that improvement is not only possible but that it is valuable. If so, look to improve the control elements, look to reduce upstream variability, and look to mitigate process issues (ex: solids).',
        size: 'small',
      },
      example3: {
        src: 'stabilityEx3',
        title: `Upstream Issues`,
        caption:
          'In this example, the upper graph represents noisy flow data, and the lower graph represents the (less noisy) data from one year later. In this situation, a poorly performing valve was located upstream in the application, and the poor performance continued to affect downstream components. Once the upstream performance was improved, the performance of this flow improved as well. When investigating loop errors, it is crucial to verify whether the causes originate upstream or downstream to ensure that the appropriate devices are addressed.',
        size: 'small',
      },
    },
  },
  Volatility: {
    title: `Volatility`,
    description: `High short-term volatility in a loop indicates intermittent or transient issues, such as unstable process dynamics or process upsets. Addressing short-term volatility can improve uptime and overall throughput.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed, or there are not enough non-shutdown values during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `This data reported stable process data during the time range that was analyzed.`,
      Yellow: `A variable reports larger volatility during a one-day period among similar variables in the application.If this variable is ‘very important’ or ‘extremely important’ to site performance then any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable reports the largest volatility during a one-day period among similar variables in the application.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are required.`,
    },
    recommendations: {
      Title1: `Check Device Status`,
      Rec1: `Confirm that the device isn’t in manual or simulation mode. Check if the device is unpowered, has a wiring failure, or has a communication failure.`,
      Title2: `Confirm the Need to Take Action`,
      Rec2: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title3: `Determine Financial Impact`,
      Rec3: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title4: `Acknowledge Overlapping Issues`,
      Rec4: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Check for Stability Issues`,
      Rec1: `Sustained process noise may affect the ability to detect short changes in process noise. If there are stability issues, try to specifically look for large shifts in volatility readings rather than focus on the overall level of volatility.`,
      Title2: `Review Affected Data`,
      Rec2: `Identify the periods of high volatility using the volatility variables in the charts. Diagnose each time period individually. Compare periods of high volatility to periods of low volatility.`,
      Title3: `Identify Upstream/Downstream Dynamics`,
      Rec3: `Determine if the issue is affected or caused by upstream/downstream dynamics. Check nearby loops to see if there are similar issues. Another approach is to put the loop in manual to see if the active oscillation resolves itself. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      LearnMore1: {
        Title1: `Address the Issues`,
        Rec1: `Attempt to address the issues at the source. This may include adjustments to valve performance/tuning or a review of contractual obligations regarding feedstocks. If this is not possible, attempt to migrate those issues through adjustments to valve performance or control tuning. Review control system settings to determine if loops can receive information on a faster basis (ex: Cascade/Feedforward). Repositioning or installation of new devices may also be effective.`,
      },
      Title4: `Issues due to Plant Conditions`,
      Rec4: `Check maintenance records or control system records for causes that coincide with changes in variability. Address those causes if appropriate.`,
      Title5: `Identify Oscillations`,
      Rec5: `If the behavior is oscillatory, then diagnose this alarm as if the oscillation amplitude alarm is active.`,
      Title6: `Seasonal or Cyclical Factors`,
      Rec6: `If the issue is due to seasonal or cyclical factors, determine the frequency and/or times of occurrence, then correlate them with potential causes. For instance, if the issue cycles every 24 hours, it may be due to day/night cycles. The issue could also be changes in parts like pH probes, or process changes like batch runs. Once you establish a cause, identify approaches to mitigate effects. For example, fluctuations in temperature can be mitigated by insulation, shading of sensors, and improvements to heating/cooling systems.`,
      Title7: `Identify Process Issues`,
      Rec7: `Consider process issues such as buildup, blockages, erosion, and solids that may affect volatility.  With solids and buildup, they may decrease. With erosion, they may increase.  Determine if those issues are due to physical phenomenon, such as a temperature that is too high or a flow rate that is too low.  If possible, adjust the control of those parameters to reduce issues. Consider incorporating/inspecting mitigating elements in the process, such as separators, filters, different piping, regular maintenance, or instrumentation designed for the process issues.`,
      Title8: `Consider Device Maintenance`,
      Rec8: `If the issue is due to device failure, determine if the device(s) have been repaired/replaced. If those devices have been repaired/replaced, this issue can be acknowledged. If failures are due to process wear or occur on a regular interval, consider replacing the parts with different components, such as different measurement technologies or different metals. Adjusting maintenance intervals or performing preventative maintenance can also reduce process upsets due to device failure.`,
      Title9: `Secondary Variables`,
      Rec9: `Make sure that secondary device variables like temperature, pressure, viscosity, density, and conductivity are within normal parameters. If your devices or control schemes use compensation, check that these variables are not causing the variability. Changes to the process may be needed to address these issues, such as a properly sized separator for density or the right heat exchangers or steam valves for temperature/humidity.`,
      Title10: `Review Process Gain`,
      Rec10: `Confirm the process gain isn’t excessive. The process gain should usually be between 0.5 and 2. Process gain means that on a percent span basis the PV responds 0.5 times to 2 times as much as the control element commands. If the process gain is low, then the primary variable may have too large of a control range. If it is high, then re-tuning the loop or changing the control element engineering may be necessary. Process gain should also be as linear as possible over the control range, with the maximum gain being no more than twice the minimum gain over the control range. In processes where large swings must be accounted for and fine-control is required then unique engineering or gap-and-trim control may be needed to control the process fully.`,
      Title11: `Eliminate Uncommon Causes`,
      Rec11: `Issues with electrical wiring, grounding fields, and electrical boards can cause significant volatility. Check for differences between instrumentation and control system readings. Validate theoretical process readings against determined process readings. Look for sudden significant jumps in slow variables.`,
      Title12: `Control Tuning and Low Valve Performance`,
      Rec12: `If none of the above options are the root cause, the issues may be due to control tuning and/or low valve performance. To isolate whether the issue is due to control performance or control tuning, you can perform an online or offline diagnostic test. If the valve(s) fail to meet the desired performance, repair or replace them. Then re-tune the loop. If the valve meets performance, re-tune the loop with the known valve performance in mind. If the loop continues to have issues, consider advanced tuning approaches like cascade control, feedforward control, APC, and MPC. Loops with high response times between the set point and primary variable may require changes beyond tuning (ex: device locations).`,
      Title13: `Review Process Information`,
      Rec13: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'volatilityEx1',
        title: `Poor Performance`,
        caption:
          'Extremely high variability can adversely impact process performance. We can see here that this variable is moving a significant amount, but the overall trendline over these minutes is relatively constant. These variations tend to adversely impact quality, yield, or throughput.',
        size: 'small',
      },
      example2: {
        src: 'volatilityEx2',
        title: `Process Upsets`,
        caption:
          'Variability can also occur over an intermittent period of time, such as during a process upset. We can see here that the overall variability of the process is quite low, but during upsets the process swings significantly.',
        size: 'small',
      },
      example3: {
        src: 'volatilityEx3',
        title: `Other Events`,
        caption:
          'Changes in volatility over time are indicative of an issue that can be correlated with other events. These events may be maintenance actions, operation actions (changes in motors, pumps, or systems), changes in secondary variables (ex: density), or seasonal factors (day/night factors). Review your systems for potential factors that may have changed at similar times to the changes in process volatility.',
        size: 'small',
      },
      example4: {
        src: 'volatilityEx4',
        title: `Re-Tuning`,
        caption:
          'Once sources of variability have been identified and eliminated, loops should be re-tuned to optimize performance. If no sources can be identified, then tuning may be able to make the best of a bad situation. This is an example of a loop that has been re-tuned after variability has been eliminated. The upper graph, pre-tuning, varied by a significant degree. The lower graph, after tuning, now varies by a few percent of the target value.',
        size: 'small',
      },
    },
  },
  OscillationAmp: {
    title: `Oscillation Amplitude`,
    description: `Oscillations are wave-like fluctuations in process control that can negatively impact system performance, throughput, yield, and quality. Certain oscillations like cavitation and vibration can also contribute to excess valve/instrument wear, which can lead  to costly replacements.  Common causes of oscillations are process dynamics, tuning, poor valve performance, electrical issues, and instrumentation.`,
    alarms: {
      Gray: `This check was not conducted during the time period analyzed.`,
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Green: `The data has no oscillations, limited oscillations, or small oscillations compared to similar variables in the application.`,
      Yellow: `A variable has significant oscillation amplitudes compared to similar variables in the application. If this variable is ‘very important’ or ‘extremely important’ to site performance, then any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable has the largest oscillation amplitudes among similar variables in the application.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Title1: `Confirm Instrument Readings`,
      Rec1: `Check the validity of instrument readings. Confirm readings at measurement sources match those found in the DCS and the Historian. Ensure that readings are not affected by electrical disturbances. Check for diagnostic alarms that might affect measurement or cause an oscillation.`,
      Title2: `Confirm the Need to Take Action`,
      Rec2: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title3: `Determine Financial Impact`,
      Rec3: `Determine if the issue is large enough to cause financial problems. Oscillations that are significantly smaller than the overall noise of the variable may be less important to address than other causes of noise. You may also use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title4: `Acknowledge Overlapping Issues`,
      Rec4: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Review Affected Data`,
      Rec1: `Review the Oscillation concern scores for periods of time where the concern score was high. Diagnose these time periods individually. Typically, looking at short timeframes and long timeframes of the data will be necessary to make conclusions. This is because the detected oscillations may be intermittently active, rather than constantly active.`,
      Title2: `Review the Loop`,
      Rec2: `If this device is part of a group of devices, each device should be inspected over the same time frames as the active oscillation(s).  This is because what is a large oscillation in one device may be caused by a small (but important) oscillation in another device.`,
      Title3: `Check for Control Range Issues`,
      Rec3: `Significant process noise or changes in control range can affect the oscillation measurement. For example, significant and sustained variability may affect the oscillation reading. Before addressing oscillations, ensure there are no relevant control range issues.`,
      Title4: `Identify Upstream/Downstream Dynamics`,
      Rec4: `Determine if the issue is affected or caused by upstream/downstream dynamics. Check nearby loops to see if there are similar issues. Another approach is to put the loop in manual to see if the active oscillation resolves itself. Issues that resolve themselves are local to the loop. If you find upstream/downstream issues, then address upstream/downstream issues first.`,
      Title5: `Issues due to Plant Conditions`,
      Rec5: `If the oscillation is not consistently present then check maintenance records or control system records for causes that coincide with changes in variability.`,
      Title6: `Confirm No Process-Based Cause`,
      Rec6: `Confirm there is no known process-based reason for the loop to oscillate. For example, consider interactions between loops, cyclical behavior, or accumulation and breakup of solids. Address these issues accordingly.`,
      LearnMore1: {
        Title1: `Loop Interactions`,
        Rec1: `Interactions between loops can often lead to natural oscillations. For example, the level in a series of tanks where fluid from one tank increases the fluid in another will typically oscillate. Tuning changes, such as feed-forward or cascade control, can help isolate or reduce the impact of these interactions. It is important to note that poor valve performance can worsen these issues, so make sure that valve performance is adequate.`,
        Title2: `Cyclical Behavior`,
        Rec2: `Cyclical behavior is another common cause of oscillations. For example, liquid storage tanks that fill up and empty out on a daily basis. Temperature and regeneration systems may also have day-night cycles that cause oscillations. If these oscillations are undesired, try to mitigate the issue through methods such as insulation or shade covering, or anticipate and counteract the cycle elsewhere in the process.`,
        Title3: `Process Issues`,
        Rec3: `In certain cases, the rapid accumulation and breakup of solids can cause oscillations in the loop. To remedy this, it is necessary to repair or improve straining devices. Alternatively, valves and devices that are unaffected by slurries or similar processes must be used.`,
      },
      Title7: `Check Setpoint and Valve System`,
      Rec7: `Confirm if the setpoint in the control system matches the relative position of the valve system. Review digital positioner data or directly inspect the valve and its gauges. Communication between someone in the control room and someone watching the valve stem is helpful to identify lags or discrepancies. If any discrepancies are detected, review any onboard diagnostics and/or perform an offline diagnostic test as soon as possible. A 'square-wave/sawtooth' pattern in the valve setpoint and measurement value may indicate valve performance issues.`,
      Title8: `Review Process Gain`,
      Rec8: `Confirm the process gain isn’t excessive. The process gain should usually be between 0.5 and 2. Process gain means that on a percent span basis the PV responds 0.5 times to 2 times as much as the control element commands. If the process gain is low, then the primary variable may have too large of a control range. If it is high, then re-tuning the loop or changing the control element engineering may be necessary. Process gain should also be as linear as possible over the control range, with the maximum gain being no more than twice the minimum gain over the control range. In processes where large swings must be accounted for and fine-control is required then unique engineering or gap-and-trim control may be needed to control the process fully.`,
      Title9: `Review Process Tuning and Control Schemes`,
      Rec9: `If the instrument and valve performance is sufficient, then a review of process tuning and control schemes may be appropriate. Implementation of a feed-forward design or similar mitigation may be needed to eliminate the oscillation. Conduct an offline diagnostic test if the control group is unsure of valve performance. Loops with high response times between the set point and primary variable may require changes beyond tuning (ex: device locations).`,
      Title10: `Review Process Information`,
      Rec10: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'oscillationAmpEx1',
        title: `Oscillation Example`,
        caption:
          'An oscillation is a wave-like pattern that dominates the process variability.  Oscillations may not be the only noise in the system, so waves may not be perfectly symmetric or consistent.',
        size: 'small',
      },
      example2: {
        src: 'oscillationAmpEx2',
        title: `Upstream Factors`,
        caption:
          'Oscillations should always involve a review of upstream/downstream systems to identify potential causes. In this case, the upstream input (J_AI_0449) did  not oscillate, so diagnosis was able to begin on the oscillating instrument (J_AI_0438).',
        size: 'small',
      },
      example3: {
        src: 'oscillationAmpEx3',
        title: `Additional Issues`,
        caption:
          'Changes in the amplitude of oscillation over time can suggest a problem that may be linked to other issues. These issues can include maintenance actions, operational changes such as alterations in motors, pumps, or systems, variations in secondary variables like density, or seasonal factors like day-night cycles. Check your systems for potential factors that might have changed around the same time as the fluctuations in oscillation amplitude.',
        size: 'small',
      },
      example4: {
        src: 'oscillationAmpEx4',
        title: `Stiction`,
        caption:
          'There are two active oscillations in the pressure readings. One is a longer frequency (longer periods between repeat waves),, and the other is smaller (faster periods between repeat waves). The larger oscillation in this particular pair is a classic example of a sawtooth or square-wave pattern. The oscillation is triggered by the valve. The setpoint increases without causing a significant change in the process variable level. Then, suddenly, the variable shifts and the setpoint changes direction. This happens because the DCS (Distributed Control System) tries to move the valve, but due to "stiction," the valve requires a minimum step size to generate enough force for movement. When this occurs, the valve needs to be repaired in order to function correctly.',
        size: 'small',
      },
      example5: {
        src: 'oscillationAmpEx5',
        title: `Cyclical Behavior`,
        caption:
          'Sometimes cyclical patterns that are unusual can be due to grounding or other anomalies. If there are concerns about the grounding in this area of the process, always check that electrical issues are not the root cause of cyclical behavior.',
        size: 'small',
      },
      example6: {
        src: 'oscillationAmpEx6',
        title: `Reviewing the Whole Loop`,
        caption:
          'If an oscillating device is part of a loop, then each device  in the loop should be inspected.  This is because a large oscillation in one device may be caused by a small (but important) oscillation in another device in the loop. In this example, the level oscillations were the largest in the application and were flagged as very important to address. However, the oscillations in the valve were smaller than oscillations in other locations in the application. As a result, the valve reported a lower score for oscillations.',
        size: 'small',
      },
      example7: {
        src: 'oscillationAmpEx7',
        title: `Weak Oscillations`,
        caption:
          'If Prioritize is analyzing low resolution data then oscillations may be detected that are not obvious when inspecting the data.  If an oscillation is detected on a fast-responding loop (ex: a flow loop), ask the facility if this device does indeed oscillate.  In this example, an oscillation is detected on the flow, but the resolution of the data is once every ten minutes. The valve shows clearer signs of oscillation, so it is worth reviewing this data with the facility to see the true extent of any oscillations.',
        size: 'small',
      },
    },
  },
  SignalInconsistency: {
    title: `Signal Inconsistency`,
    description: `A properly controlled and stable process should have predictable performance, even if there is some random noise. Changes in the patterns of random noise in the data over time can identify early signs of changing performance. For example, changes in frequency, amplitude, or the existence of an oscillation are noteworthy. Highly random data should be comparable across different time periods and look consistent. Changes in performance can indicate wear and tear, seasonal or time-based dynamics, new issues (such as process upsets or electrical interference), and other factors that should be addressed.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `This data reported consistent data during the time range that was analyzed.`,
      Yellow: `Data from a variable has a significantly different consistency compared to data from the same variable 30 days ago. If this variable is ‘very important’ or ‘extremely important’ to site performance, then any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `Data from a variable has a very different consistency than data from the same variable 30 days ago.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are required.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned, and that there isn’t invalid data in your upload.`,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active, address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered. `,
      Title3: `Confirm the Need to Take Action`,
      Rec3: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title4: `Determine Financial Impact`,
      Rec4: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title5: `Acknowledge Overlapping Issues`,
      Rec5: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Normal Behavior`,
      Rec1: `Confirm whether the variable is intended to change over this time period. Sometimes, inconsistency is expected behavior, such as when a loop is placed into manual or simulation mode. If so, this alarm may be acknowledged.`,
      Title2: `Review Affected Data`,
      Rec2: `Identify the periods of high inconsistency using the inconsistency variables in the charts. Compare them to similar periods approximately 30 days before. Check if inconsistency is persistently high due to a lack of control.`,
      Title3: `Investigate Changes`,
      Rec3: `Check for any changes in the process or nearby processes that could have caused the inconsistency to shift over time. Identify the starting point for any trends.`,
      Title4: `Check for Overlapping Issues`,
      Rec4: `Determine if the periods of high or growing inconsistency correspond to issues already identified in other checklist alarms. Acknowledge this alarm if all periods are already addressed by other solutions.`,
      Title5: `Identify Upstream/Downstream Issues`,
      Rec5: `Determine if remaining periods of high inconsistency are affected or caused by upstream/downstream dynamics. One approach is to place the loop into manual while the inconsistency is occuring. If the issue resolves itself, then the issue is contained within the loop. Another approach is to inspect data from nearby loops to see if there are similar issues. Address upstream/downstream issues accordingly first.`,
      Title6: `Identify the Root Cause`,
      Rec6: `Identify the root cause for each remaining event. Typical root causes are process upsets, time-based issues, changes in tuning, changes in process dynamics, or changes in mechanical components (wear and tear, replacement, or a new measuring methodology). Identify corrective actions to address each issue. If root causes cannot be found, investigate maintenance records or control system records for causes that coincide with changes.`,
      Title7: `Review Process Information`,
      Rec7: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'signalInconsistencyEx1',
        title: `Consistent Patterns`,
        caption: 'Data with regular patterns in its movement is more ‘consistent’ than data without. In this example, this flow has the same repeating patterns.',
        size: 'small',
      },
      example2: {
        src: 'signalInconsistencyEx2',
        title: `Inconsistent Patterns`,
        caption: 'For contrast, this data does not show similar repeating patterns, and is less ‘consistent’.',
        size: 'small',
      },
    },
  },
  SignalAbnormality: {
    title: `Signal Abnormality`,
    description: `Fluctuations in the ideal process variable should ideally be composed of largely random noise. Process control should compensate for extreme positive or negative fluctuations to bring the process back to the desired operating parameters. Significant auto-correlation (positive feedback loop) implies a process that is out-of-control or sluggish. Significant anti-correlation (extreme negative feedback loop) implies too much structure to our noise, such as oscillations and volatility. These can be equally undesirable. This diagnostic checks for extreme feedback loops and that our process noise closely approximates random noise.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `The variation in the data is randomly distributed data during the analyzed time period.`,
      Yellow: `A variable has significant auto- or anti-correlation among similar variables in the application. This alarm should be reviewed. If this variable is ‘very important’ or ‘extremely important’ to site performance, any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `A variable has the most auto- or anti-correlation among similar variables in the application.`,
      Purple: `Potential issues found by this check have been reviewed and acknowledged as acceptable. This alarm should be reviewed, but it is likely no further action items are likely required.`,
    },
    recommendations: {
      Rec1: `Check the data uploaded was the correct data and that units of measure are consistent with any previous uploads. Confirm the configuration of Prioritize has the correct device tag assigned and that there isn’t invalid data in your upload.`,
      Rec2: `Confirm the data is valid. If the validity alarm is active, address those issues.`,
      Rec3: `Confirm the process isn’t in shutdown and the device responsible for this variable is powered.`,
      Rec4: `If the variable has returned to desired operating parameters, confirm corrective action was taken to resolve the issue. If not, determine if the issue is likely to repeat itself. If so, take corrective action. If not, proceed as if the issue is still ‘current’, as repeat events may happen in the future.`,
      Rec5: `Determine if the issue is large enough to cause financial issues. Utilize the importance system inside Prioritize to help make a determination. If the device/variable is not important or impact to KPIs does not cause significant financial loss, you can acknowledge this issue.`,
      Rec6: `Determine if the issue is affected or caused by upstream/downstream dynamics. One approach is to place the loop into manual. If the issue resolves itself, then the issue is contained within the loop. Another approach is to inspect data from nearby loops to see if there are similar issues. If they begin earlier chronologically, then the root cause of the issue is likely upstream/downstream. Address upstream/downstream issues accordingly first, then return to this variable.`,
      Rec7: `Determine if the data is highly auto-correlated (positive feedback loop) or anti-correlated (extreme negative feedback loop) over the time period in question. Auto-correlation will look like a positive feedback loop - changes beget more changes. This would be common in an out of control process. Extreme negative feedback, or anti-correlation, will look like highly structured data, such as highly volatile or oscillating data.`,
      Rec8: `If the data experiences high amounts of undesirable anti-correlation, this can be due to process upsets, oscillations due to valve performance, control tuning, or electrical disturbances.`,
      Indent1: `•	If the issue is due to process upsets, Identify the root cause(s) of the upset. A review of maintenance/site records may provide valuable information. Common sources of issues include feedstock/upstream upsets, solids/corrosion/blockage, device failure and sub-optimal control tuning.`,
      Indent2: `•	If the issue is due to upstream/feedstock upsets, there are several effective approaches for resolution. First, one can attempt to address those issues at the source. This may include adjustments to valve performance and tuning, or a review of contractual obligations from outside sites and vendors. Second, one can attempt to mitigate those issues downstream. This may include adjustments to valve performance and tuning. If reviewing valve performance and tuning, determine what performance is desired and have valve testing/repairs performed to bring valves into alignment. Do not forget to review control system settings to determine if control loops can receive information on a more timely basis. Repositioning or installation of new devices can also be effective.`,
      Indent3: `•	If the issue is due to process issues (solids/corrosion/blockages), determine if those issues are due to physical phenomena. For example, perhaps the temperature of the process is too high or too low.`,
      Sub1: `○ If possible, attempt to adjust the control of those parameters to reduce solids/corrosion/blockages.`,
      Sub2: `○ If those issues are inherent or permanent in the system, review existing mitigating elements to confirm they are functioning properly. For example, any systems designed to filter solids should be reviewed if solids are present downstream of those systems. Additionally, consider incorporating additional mitigating elements in the process. Separators, filters, and correct piping/devices for service can have a large impact on required maintenance intervals.`,
      Indent4: `•	Confirm the setpoint in the control system matches the relative position of the valve system. This can be done with a review of digital positioner data or direction inspection of the valve and its gauges. Communication from someone watching the valve stem with someone in the control room is a good way to identify lags or discrepancies between the time a new command is issued to the valve and the response of the valve. If there isn’t a match, the loop error alarm might also be active.`,
      Sub3: `○ Review any onboard diagnostics and conduct an offline diagnostic test at the soonest opportunity. Repair any deficiencies in valve performance and confirm the valve meets application performance requirements. A common pattern that indicates valve performance issues is the ‘square-wave/sawtooth’ pattern in the valve setpoint and the measurement value the valve is intended to control.`,
      Indent5: `•	If the issues are due to control tuning, confirm that the issues are due to control tuning and not due to a degradation or shift in expected valve performance. This can be done via online or offline diagnostic testing. If the valves do not meet desired performance, repair/replace valves and re-tune the loop with the new performance. If the valve meets performance, re-tune the loop with the known valve performance in mind. If the loop continues to have issues, consider advanced tuning approaches such as APC and MPC.`,
      Indent6: `•	In uncommon cases, issues with electrical wiring, grounding fields, and electrical boards can cause significant volatility. One way to check for electrical issues is to check for issues between instrumentation and control system readings. Another way is to validate theoretical process readings against determined process readings. Sudden significant jumps in temperature for just a few seconds at a time would be an example of unlikely process readings that warrant inspection for electrical issues.`,
      Rec9: `If the data experiences high amounts of undesirable auto-correlation or sluggish response, this can be due to process dynamics, process upsets, and control tuning.`,
      Indent7: `•	Loop type and process dynamics are a common cause for sluggish loops. As an example, level and pressure will respond slower than flow or pressure. Large tanks or vessels may take significant time to change compared to small tanks or vessels. Inherently, integrated processes will show more autocorrelation than other loops.`,
      Indent8: `•	Process upsets can overwhelm the loop’s ability to control the process. If possible, consider feedforward or cascade control to improve disturbance rejection. Feed-forward can be used to ‘predict’ an upstream effect and compensate. Cascade control can be used to improve disturbance rejection from certain sources. Be aware that poor valve performance tends to greatly exacerbate issues with loops that interact, so be sure to confirm valve performance is sufficient as well.`,
      Indent9: `•	Confirm that there are no deadband settings active on the loop. Excessive deadband might also show up as an intermittent response from the loop to control requests. As necessary, adjust deadband settings. If deadband settings do not account for intermittent response, then valve diagnostic testing or valve repair may be warranted.`,
      Indent10: `• If the issues are due to control tuning, confirm that the issues are due to control tuning and not due to a degradation or shift in expected valve performance. This can be done via online or offline diagnostic testing. If the valves do not meet desired performance, repair/replace valves and re-tune the loop with the new performance. If the valve meets performance, re-tune the loop with the known valve performance in mind. Confirm the filter settings on inputs and outputs are ideal for the process. If the loop continues to have issues, consider advanced tuning approaches such as APC and MPC.`,
    },
    examples: {
      example1: {
        src: 'signalAbnormalityEx1',
        title: `Signal Abnormality Example 1`,
        caption:
          'Anti-correlated data will be mean-reverting and structured - either bouncing back and forth around a mean or oscillating. For example images of similar behavior, review the examples found in the volatility check and the oscillation amplitude check.',
        size: 'small',
      },
      example2: {
        src: 'signalAbnormalityEx2',
        title: `Signal Abnormality Example 2`,
        caption: 'Autocorrelated data is ‘sluggish’ or out of control. This process does not have significant noise, and when it varies it takes significant time to go back to established control.',
        size: 'small',
      },
    },
  },
  Downtime: {
    title: `Process Downtime`,
    description: `Efficient operation of a process depends on the process being available when needed. If parts of the process are unavailable and shutdown for significant portions of time, the causes for that unavailability should be addressed.`,
    alarms: {
      Gray: `This check was not conducted during the time period analyzed.`,
      Green: `This data had the highest uptime out of similar devices during the time period analyzed.`,
      Yellow: `Data from a variable has a significantly higher amount of time in shutdown compared to data from similar devices. If this variable is ‘very important’ or ‘extremely important’ to site performance, then any outstanding issues should be resolved. Otherwise, this alarm should be resolved if reasonable.`,
      Red: `Data from a variable has the highest amount of time in shutdown compared to data from similar devices.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned, and that there isn’t invalid data in your upload.`,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active, address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered.`,
      Title3: `Confirm the Need to Take Action`,
      Rec3: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title4: `Determine Financial Impact`,
      Rec4: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
      Title5: `Acknowledge Overlapping Issues`,
      Rec5: `If the issues are already addressed by the action items from another issue, then this issue may be acknowledged. Acknowledged issues do not require an action item.`,
    },
    recommendationsSpecific: {
      Title1: `Normal Behavior`,
      Rec1: `Confirm when the variable is intended to be in shutdown over the analyzed time period. Sometimes, shutdowns are expected behavior, such as when a plant conducts a turnaround. If all shutdowns are expected behavior, this alarm may be acknowledged.`,
      Title2: `Review Affected Data`,
      Rec2: `Identify the periods of shutdown using the uptime variables in the charts.`,
      Title3: `'Investigate Changes`,
      Rec3: `Check for any changes in the process or nearby processes that could have caused the process downtime to occur. `,
      Title4: `Check for Overlapping Issues`,
      Rec4: `Determine if the periods of process downtime correspond to issues already identified in other checklist alarms. Acknowledge this alarm if all periods are already addressed by other solutions.`,
      Title5: `Identify the Root Cause`,
      Rec5: `Identify the root cause for each event. Typical root causes are process upsets, time-based issues, changes in process dynamics, or changes in mechanical components (wear and tear, replacement, or a new measuring methodology). Identify corrective actions to address each issue. If root causes cannot be found, investigate maintenance records or control system records for causes that coincide with changes.`,
      Title6: `Review Process Information`,
      Rec6: `If the correct action items are not clear, review the purpose of the loop/devices and the process environment with someone knowledgeable about the application. Then review the affected periods of data.`,
    },
    examples: {
      example1: {
        src: 'downtimeEx1',
        title: `Process Upsets`,
        caption:
          'Process upsets are a common cause of plant downtime. In this case, a valve did not respond with the speed the plant required, causing the plant to shutdown.  Note that in many cases Prioritize will not track the data at the resolution required to detect the cause of sudden shutdowns. Please use the information gathered in the onboarding process or in subsequent reviews with the plant to determine the root cause.',
        size: 'small',
      },
      example2: {
        src: 'downtimeEx2',
        title: `Process Degradation`,
        caption: 'This process had a buildup of solids that eventually shutdown the plant. This is why it is important to proactively mitigate process trends and variability.',
        size: 'small',
      },
    },
  },
  ConcernScore: {
    title: `High Concern Score`,
    description: `Ideally, process data should be stable with minimal random noise. Deviations from this ideal are usually caused by issues on the site, which can be identified and addressed. Prioritizing these issues is crucial due to limited resources. The concern score summarizes the issues and provides an overall health of a variable compared to others on the site. This helps determine which critical variables should be reviewed first. The identified issues can then be reviewed to determine the root cause and maximize site profitability.`,
    alarms: {
      Black: `The data is designated as ‘shutdown’ during the time period analyzed.`,
      Gray: `The data analyses were not conducted during the time period analyzed.`,
      Green: `The variable’s overall performance is less likely to negatively impact the process compared to similar variables in the application.`,
      Yellow: `A variable is likely to have at least one or two issues detected. The variable’s overall performance is more likely to negatively impact the process when compared to similar variables in the application.`,
      Red: `A variable has many detected issues. It is extremely likely that these issues are negatively impacting the process when compared to similar variables in the application.`,
      Purple: `The concern score has been reviewed and acknowledged as acceptable.`,
    },
    recommendations: {
      Title1: `Confirm Data Upload`,
      Rec1: `Check that the data uploaded was the correct data and that the units of measurement are consistent with any previous uploads. Confirm that the configuration of Prioritize has the correct device tag and device type assigned, and that there isn’t invalid data in your upload.`,
      Title2: `Confirm Data Validity`,
      Rec2: `Confirm that the data is valid. If the validity alarm is active, address those issues. Confirm that the process wasn’t in shutdown and the device responsible for this variable is powered.`,
      Title3: `Confirm the Need to Take Action`,
      Rec3: `If more recent data has returned to desirable operating parameters, confirm whether corrective action was already taken. If the issue is likely to repeat, take corrective action.`,
      Title4: `Determine Financial Impact`,
      Rec4: `Determine if the issue is large enough to cause financial problems. Use the importance system in Prioritize to help make a determination. If the device/variable is not important or the impact on KPIs is not significant, acknowledge the issue.`,
    },
    recommendationsSpecific: {
      Title1: `Review Workbench Tab`,
      Rec1: `Use the diagnostic checklist in the loop workbench and address each issue from top to bottom. Adjust the issue coverage as you address each issue (add/remove shutdowns, events, etc.). Refer to the information helpers if you are unsure how to diagnose an issue. Identify action items based on the source of noise. Note that the devices which require action may not be tied to the variables with the highest concern. For example, a damaged valve may be causing a pressure reading to no longer be controlled. This might mean a high concern for the pressure and a lower concern for the valve, even though the valve is the device to address.`,
      Title2: `Use Action Item Tab`,
      Rec2: `Add action items for each issue based on the workbench diagnoses.  If another action item from a different issue addresses the current issue, acknowledge the current issue.`,
      Title3: `Follow-Up and Completion Instructions`,
      Rec3: `Go through the action item list with key stakeholders. Review the data with them so they are well-informed about what's going on, why it's important, the value of correction, and any relevant action items. Accept or reject proposed action items as appropriate. Follow up on all action items and complete them to resolve the issues.`,
    },
    examples: {
      example1: {
        src: 'concernScoreEx1',
        title: `Low Concern`,
        caption: 'This is an example of a low-concern device. The pressure is tightly controlled and stable, the noise is random.',
        size: 'small',
      },
      example2: {
        src: 'concernScoreEx2',
        title: `Advisory Concern`,
        caption: 'This is an example of an emerging-concern device. The pressures do not read the same measurement, the process is cyclical, but it is controlled.',
        size: 'small',
      },
      example3: {
        src: 'concernScoreEx3',
        title: `Critical Concern`,
        caption: 'This is an example of a high-concern device. The pressures do not read the same measurement, the process is noisy, and does not appear controlled.',
        size: 'small',
      },
    },
  },
  Concern: {
    title: `Concern`,
    description: `A metric that helps you prioritize data based on issues in a loop or device, and their importance to process improvement.`,
    types: {
      title1: `Concern Score`,
      ConcernScore: `A score from 0 to 100 that rates a device or loop based on issues and importance.`,
      screenshot1: `concern`,
      title2: `Concern Shift`,
      ConcernShift: `The change in concern over the listed period of time.`,
      screenshot2: `concernShift`,
      title3: `Concern Max`,
      ConcernMax: `The maximum concern that has occurred over the listed period of time.`,
      screenshot3: `concernMax`,
    },
    examples: {
      example1: {
        src: 'concern',
        title: `Concern Example`,
        caption: 'This is an example of concern',
        size: 'xsmall',
      },
      example2: {
        src: 'concernShift',
        title: `Concern Shift Example`,
        caption: 'The change in concern over the past 30 days',
        size: 'xsmall',
      },
      example3: {
        src: 'concernMax',
        title: `Concern Max Example`,
        caption: 'The maximum concern that has occurred in the past 90 days',
        size: 'xsmall',
      },
    },
    recommendations: {
      title1: `Data Entry`,
      Rec1: `Check the data uploaded was the correct data and that units of measure are consistent with any previous uploads. Confirm the configuration of Prioritize has the correct device tag assigned and that there isn’t invalid data in your upload.`,
      title2: `Check Data Validity`,
      Rec2: `Confirm the data is valid. If the validity alarm is active, address those issues.`,
      title3: `Check Status`,
      Rec3: `Confirm the process isn’t in shutdown and the device responsible for this variable is powered.`,
      title4: `Confirm Resolved Issues`,
      Rec4: `If the variable has returned to desired operating parameters, confirm corrective action was taken to resolve the issue(s). If not, determine if the issue(s) are likely to repeat itself. If so, take corrective action. If not, proceed as if the issue(s) are still ‘current’, as repeat events may happen in the future.`,
      title5: `Analyze Financial Impact`,
      Rec5: `Determine if the issues found are large enough to cause financial issues. Utilize the importance system inside Prioritize to help make a determination. If the device/variable is not important or impact to KPIs does not cause significant financial loss, you can acknowledge this issue.`,
    },
    recommendationsSpecific: {
      title1: `Review Diagnostic Checklist`,
      Rec1: `Review the diagnostic checklist available in the loop workbench. Go through each issue top to bottom. If you are unfamiliar with how to diagnose the issue, refer to the helpers for a diagnostic process.`,
      title2: `Manage Events`,
      Rec2: `As you go through each issue, adjust the event coverage to appropriately reflect what is happening with the variable (shutdowns, add/remove events, etc.).`,
      title3: `Add Action Items`,
      Rec3: `Add action items at each juncture based on the tooltips. If a higher priority item addresses the issue, you do not need to add additional action items for that issue and may acknowledge it. Note that the devices which require action may not be the devices with the highest concern. For example, a stuck valve causing a pressure reading to no longer be controlled might have a high concern for the pressure and a lower concern for the valve. The key is that by reviewing the source of noise, we identify which issues (in this case a stuck valve) which will maximize overall site control.`,
      title4: `Review`,
      Rec4: `Go through the action item list with key stakeholders. Review data with them as appropriate so they are well informed on what is going on, why it is important, the value of correction, and action items. Accept/reject proposed action items as appropriate.`,
      title5: `Follow Up`,
      Rec5: `Follow up and complete all action items.`,
    },
  },
  Importance: {
    title: `Importance`,
    description: `A loop or device’s importance is an estimate of its need to function accurately, quickly, and reliably. Usually, a device's importance is based on the most important function of a device. For instance, a valve that moves slowly and has a bypass is still very important if it needs to be very accurate for the whole plant to work well.`,
    types: {
      title1: `Extremely Important`,
      ExtremelyImportant: `This device has accuracy, speed, or reliability consequences that are extremely important (site outage possible, safety issue, or millions of dollars lost).`,
      title2: `Very Important`,
      VeryImportant: `This device has accuracy, speed, or reliability consequences that are very important (application/sub-unit outage possible, close to a million dollars lost).`,
      title3: `Important`,
      Important: `This device has accuracy, speed, or reliability consequences that are important (significant dollars lost).`,
      title4: `Standard`,
      Standard: `This device has no accuracy, speed, or reliability consequences that are important (ex: non-essential device where functional and/or sub-optimal performance is acceptable).`,
      title5: `Not Important`,
      NotImportant: `Not Important - This device has no measured criteria for accuracy, speed, or reliability consequences (ex: pH probe to a ditch, on-off valve w/ bypass, etc.).`,
    },
    recommendations: {
      title1: `Set the Application Importance in the Configuration`,
      Rec1: `Application importance helps determine where actions items are most effective for the facility.`,
    },
  },
  Issues: {
    title: `Open Issues`,
    description: `Open issues are problems that are impacting a device or devices in a loop.  Issues can be found on the action items tab once you have selected a loop. Once you have selected a loop, you can also click the ‘show events’ button on the workbench tab to see the issues.`,
    recommendations: {
      title1: `Review Device Index Page`,
      Rec1: `On the Prioritize Loop Selection page, you can find loops that have open issues that need to be addressed. You can use filters at the top of the page to sort the loops by how many open issues they have.`,
      title2: `Review Open Issues`,
      Rec2: `Once you have selected a loop and are on the Workbench page, you can click a button called 'show issues' to see the times when the loop had  certain problems. You can then use the checklist and helpers to figure out what is causing the issues.`,
      title3: `Propose Action Items`,
      Rec3: `If you want to see recommended action items for each issue, you can click the 'show more' button or go to the issues tab.  If these suggestions address the issue, you can use them to save time. If they don't, then you can create your own Actions Items tab.`,
    },
  },
  Actions: {
    title: `Action Items`,
    description: `Action items are resolutions to issues that are impacting a device or devices in a loop. Action items should be addressed based on importance and the ability to act upon them.`,
    types: {
      title1: `Investigate`,
      Investigate: `This action item requires investigation. Typically such action items result in further action items upon completion.`,
      title2: `Online Improvement`,
      OnlineImprovement: `This action item can be implemented while the loop remains in operation.`,
      title3: `Loop Outage Required`,
      OutageRequired: `An outage of this loop is required in order to implement this action item.`,
      title4: `Application Outage Required`,
      ApplicationOutageRequired: `An application outage is required in order to implement this action item.`,
      title5: `Plant Shutdown Required`,
      PlantShutdownRequired: `A plant shutdown or turnaround is required in order to implement this action item.`,
      title6: `To Be Determined`,
      ToBeDeterminded: `Additional information is required in order to determine the actionability of this action item.`,
      title7: `Note`,
      Note: `This action item is a record or notice for future reference.`,
    },
    recommendations: {
      title1: `Investigate Appropriate Action Items`,
      Rec1: `You can investigate what action items are appropriate based on the data in the workbench tab and the active open issues.`,
      title2: `Use Pre-Generated Action Items`,
      Rec2: `Consider using pre-generated action items for each open issue. These can be found by opening an issue on the issues tab. Pre-generated action items may be faster than writing action items manually. If pre-generated action items don't meet your needs, you can enter action items manually at the bottom of the action items tab.`,
      title3: `Enter Action Item Type and Priority`,
      Rec3: `When entering action items, consider the correct action item type and priority. Start with the highest priority of the affected devices. If the issue only partially affects plant objectives, consider reducing importance by one level. Priority can be edited later if new information becomes available. For action item type, consider the steps needed to complete the action item. Some can be done while the loop is online, while others require the plant or a section of the plant to be offline. If you're not sure, leave the action item type as 'to be determined' and adjust it after consultation with the appropriate facility personnel.`,
      title4: `Enter Comments`,
      Rec4: `Use comments to explain the issue and how it may be affecting the plant. Explain what can be done to address the issue. These comments serve as a historical record of the conclusions found and why taking the action item is valuable.`,
      title5: `Present Action Items`,
      Rec5: `Present action items to relevant facility personnel for approval/rejection. This can be done in the action items tab or in the action items report. Follow-up should be conducted to ensure completion. To ensure clear lines of responsibility, contact information of the individual accepting, rejecting, or completing action items can be entered at each stage.`,
    },
    importance: {
      title1: `Extremely Important`,
      ExtremelyImportant: `Completion of this action item results in improvements that are extremely important (future site outages avoided, elimination of a potential safety issue, significant dollars gained per day).`,
      title2: `Very Important`,
      VeryImportant: `Completion of this action item results in improvements that are very important (future application/sub-unit outage avoided, significant dollars gained per month).`,
      title3: `Important`,
      Important: `Completion of this action item results in improvements that are important (significant dollars gained).`,
      title4: `Standard`,
      Standard: `Completion of this action item is not urgent but beneficial to plant performance (ex: non-essential device where sub-optimal performance can be tolerated)`,
      title5: `Not Important`,
      NotImportant: `Completion of this action item is not urgent or important (ex: pH probe to a ditch, on-off valve w/ bypass, etc.).`,
    },
  },
};

export default prioritizeDetails;
