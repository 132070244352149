import React from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import CustomSelect from '../../../filters/CustomSelect';
import { fetchSites, fetchLocations, fetchApplications, fetchClients, fetchLoopsSummary } from '../../../../actions/index';
//import TableComponent from '../../../charts/Table';
import functions from '../../../../functions/functions';
import api from '../../../../apis/api';

class LoopContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clickedRow: !this.props.row ? null : this.props.row.id,
      rowChange: {},
    };
  }
  componentDidMount() {
    let loop = !this.props.row ? {} : this.props.loops[this.props.row.id];
    this.setState((state) => ({
      rowChange: {
        id: loop.loopId,
        name: loop.processName,
        site: loop.site,
        application: loop.applicationType,
        importance: !loop.importance ? 'Standard' : functions.importanceToString(loop.importance_max),
        applicationOrder: !loop.importance ? 1 : loop.applicationOrder,
      },
    }));
  }
  validateLoopEntry = (data) => {
    // TODO SET UP TO USE LOOP ENTRIES
    let valid = true;
    let emptyStr = '';
    if (!data.application || data.application === '') {
      emptyStr += 'Application';
      valid = false;
    }
    /* if (!data.client || data.client === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Client';
    }*/
    if (!data.site || data.site === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Site';
      valid = false;
    }

    if (!data.importance || data.importance === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Importance';
      valid = false;
    }
    if (!data.name || data.name === '') {
      if (emptyStr !== '') {
        emptyStr += ', ';
      }
      emptyStr += 'Loop Name';
      valid = false;
    }
    let nameFlag = this.validateLoopName(data);
    if (nameFlag) {
      valid = false;
    }

    let errorStr = 'Error: '; // TODO GET RID OF 'ERROR:' WHEN SETTING VALID TO BE FUNCTION
    errorStr += !nameFlag ? '' : `Loop Name ${data.name} is already taken. `;
    errorStr += emptyStr === '' ? '' : `${emptyStr} is blank. `;

    return { valid: valid, message: errorStr };
  };
  validateLoopName = (data) => {
    let loopKeys = Object.keys(this.props.loops);
    let nameFlag = false;
    for (let i = 0; i < loopKeys.length; i++) {
      let loop = this.props.loops[loopKeys[i]];
      if (loop.processName === data.name && loop.loopId !== data.id) {
        nameFlag = true;
      }
    }
    return nameFlag;
  };
  handleSubmit = async () => {
    try {
      //console.log(this.state.rowChange);
      // put in status checks, error messages, ect (ref user config)
      const userData = this.state.rowChange;
      const url = `prioritize/loops/${this.state.rowChange.id}/configure`; //` //TODO Loop Config URL
      //console.log(userData);
      //console.log('submit'); //await api.post(url, userData);
      const validate = this.validateLoopEntry(userData);
      //console.log(validate);
      if (validate.valid) {
        console.log(userData);
        this.setState({ status: 'Executing', statusMessage: 'Executing...' });
        const response = await api.post(url, userData);
        //console.log(response);
        //const response = { data: { changedRows: 1 } };
        if (response.data.changedRows > 0) {
          const params = {
            analysisRange: 90,
          };
          await this.props.fetchLoopsSummary('prioritize', params);
          this.setState({ status: 'Success', statusMessage: 'Success!' });
        }
      } else {
        this.setState({
          status: 'Failure',
          statusMessage: validate.message,
          loading: false,
        });
      }
    } catch (e) {
      this.setState({
        status: 'Failure',
        statusMessage: !e.data || !e.data.error ? (!e.statusText ? 'Failure!' : `Error: ${e.statusText}`) : e.data.error,
        loading: false,
      });
      console.log(e);
    }
  };
  render() {
    const { classes } = this.props;
    //let row = this.props.row;
    /*const shutdownHeaderArr = [
      { id: 'startDate', label: 'Start Date' },
      { id: 'endDate', label: 'End Date' },
      { id: 'duration', label: 'Duration' },
      { id: 'note', label: 'Note' },
      { id: 'menu', label: '' },
    ];
    let shutdownData = [
      {
        id: 1,
        startDate: 'Tim',
        endDate: 'Tim',
        duration: 'Tim',
        note: 'Tim',
        menu: 'Menu',
      },
      {
        id: 2,
        startDate: 'Tim2',
        endDate: 'Tim2',
        duration: 'Tim2',
        note: 'Tim2',
        menu: 'Menu',
      },
    ];*/
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';

    return (
      <form
      /* onSubmit={(e) => {
          
          //this.setState({ changeConfirm: true });
        }}*/
      >
        <Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
          Loop Configuration
        </Typography>
        <TextField
          variant='standard'
          className={classes.textField}
          label='Loop Name'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.name = event.target.value;
          }}
          defaultValue={
            !this.state.clickedRow || this.state.rowChange.name === null || this.props.loops[this.state.clickedRow].processName === null ? '' : this.props.loops[this.state.clickedRow].processName
          }
        />
        {this.state.tagError ? <Typography color='error'>Duplicate Tag # / Serial Combination.</Typography> : null}
        <TextField
          variant='standard'
          className={classes.textField}
          label='Site'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.site = event.target.value;
            this.setState(currentRow);
          }}
          defaultValue={!this.state.clickedRow || this.state.rowChange.site === null ? '' : this.props.loops[this.state.clickedRow].site}
        />
        <TextField
          variant='standard'
          className={classes.textField}
          label='Application'
          onChange={(event) => {
            let currentRow = this.state.rowChange;
            currentRow.application = event.target.value;
            this.setState(currentRow);
          }}
          defaultValue={!this.state.clickedRow || this.state.rowChange.applicationType === null ? '' : this.props.loops[this.state.clickedRow].applicationType}
        />
        <CustomSelect
          single
          id={null}
          label='Importance'
          disabled={this.state.loading}
          onChangeFunction={(event, value) => {
            //console.log(event);
            let currentRow = this.state.rowChange;
            currentRow.importance = value;
            this.setState(currentRow);
          }}
          options={
            this.state.rowChange.importance === null || !this.state.rowChange.importance ? ['Unknown', 'Standard'] : ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important']
          }
          values={!this.state.clickedRow || this.state.rowChange.importance === null ? 'Standard' : functions.importanceToString(this.props.loops[this.state.clickedRow].importance_max)}
          //value={null}
          helperText={null}
        />
        <CustomSelect
          single
          id={null}
          label='Application Order'
          disabled={this.state.loading}
          //onCloseFunction={this.multiSelectOnClose}
          onChangeFunction={(event, value) => {
            //console.log(event);
            let currentRow = this.state.rowChange;
            currentRow.applicationOrder = parseInt(value);
            //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
            //console.log(currentRow);
            this.setState(currentRow);
          }}
          options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']}
          values={this.state.rowChange.applicationOrder === null || !this.state.rowChange.applicationOrder ? '1' : this.state.rowChange.applicationOrder.toString()}
          //value={null}
          helperText={null}
        />
        {/* TODO Prio_1.0*/}{' '}
        <CustomSelect
          single
          id={null}
          label='Open or Closed Loop'
          disabled={this.state.loading}
          //onCloseFunction={this.multiSelectOnClose}
          onChangeFunction={(event, value) => {
            //console.log(event);
            //!FINISH
            let currentRow = this.state.rowChange;
            currentRow.loopOpen = value;
            //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
            //console.log(currentRow);
            this.setState(currentRow);
          }}
          options={['Open', 'Closed', 'Unknown']}
          values={!this.state.rowChange.loopOpen ? 'Unknown' : this.state.rowChange.loopOpen}
          helperText={null}
        />
        <CustomSelect
          single
          id={null}
          label='Integrated Loop'
          disabled={this.state.loading}
          //onCloseFunction={this.multiSelectOnClose}
          onChangeFunction={(event, value) => {
            //console.log(event);
            //!FINISH, do we allow multiples, BUILD RETRIEVE DEVICENAMES FUNCTIONS
            let currentRow = this.state.rowChange;
            currentRow.loopIntegration = value;
            //currentRow.accuracyImportance = this.processSpeedAccuracy(value);
            //console.log(currentRow);
            this.setState(currentRow);
          }}
          options={['Integrated', 'Not Integrated', 'Unknown']}
          values={!this.state.rowChange.loopIntegration ? 'Unknown' : this.state.rowChange.loopIntegration}
          helperText={null}
        />
        {/*<Typography variant='subtitle1' color='primary' className={classes.rowTitle}>
          Shutdown Information
        </Typography>
        <Grid item xs={12}>
          <TableComponent data={shutdownData} header={shutdownHeaderArr} rows={25} tileTable printHidePagination></TableComponent>
        </Grid>*/}
        <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`}></Divider>
        <Grid container justifyContent='space-between' style={{ marginBottom: -8 }}>
          <Grid item>
            {this.state.status !== '' ? (
              <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                {this.state.statusMessage}
              </Typography>
            ) : null}
          </Grid>
          <Grid item>
            {!this.props.closeFunction ? null : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  this.props.closeFunction();
                }}
                type='text'
                color='secondary'
                value='Close'
                style={{ marginRight: 8 }}
              >
                Close
              </Button>
            )}
            <Button
              color='secondary'
              variant='text'
              type='submit'
              onClick={(e) => {
                e.preventDefault();
                console.log('submitted');
                this.handleSubmit();
              }}
              className={classes.buttonFilter}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    loops: state.loops.loops,
    sites: state.sites,
    locations: state.locations,
    clients: state.clients,
    applications: state.applications,
  };
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
  fetchSites,
  fetchLocations,
  fetchClients,
  fetchApplications,
  fetchLoopsSummary,
})(withStyles(styles)(LoopContent));
