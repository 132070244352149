import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, Typography } from '@mui/material';
import initializeTheme from '../theme';
import { connect } from 'react-redux';
import { styles } from '../styles';
import functions from '../../functions/functions';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import InfoPopover from '../popup/InfoPopover';

class PrioritizeProgress extends React.Component {
  state = {
    completed: this.props.concern,
    buffer: 0,
  };
  processProgress(props) {
    if (!props.concern) {
      return {
        concern: 0,
        completed: 0,
        buffer: 0,
      };
    } else if (props.concernShift < 0) {
      return {
        concern: props.concern,
        completed: props.concern,
        buffer: props.concern - props.concernShift,
      };
    } else {
      return {
        concern: props.concern,
        completed: props.concern - props.concernShift,
        buffer: props.concern,
      };
    }
  }
  renderConcern() {
    //const { classes } = this.props;
    return (
      <Typography variant='subtitle1' style={{ marginTop: -10 }}>
        {!this.props.concern ? 'Concern Not Found' : this.props.concern}{' '}
        <span style={{ fontSize: 14 }}>
          {!!this.props.concernShift ? '(' : null}
          {!!this.props.concernShift && this.props.concernShift > 0 ? '+' : null}
          {!this.props.concernShift ? null : `${this.props.concernShift}`}
          {!!this.props.concernShift ? ')' : '(No Shift)'}
        </span>
      </Typography>
    );
  }
  renderShift() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    if (this.props.concernShift > 0) {
      return (
        <Grid container>
          <Grid>
            <Typography variant='subtitle2' style={theme.palette.blue}>
              ({this.props.concernShift})
            </Typography>
          </Grid>
          <Grid>
            <TrendingUp
              className={classes.shiftArrow}
              //style={theme.palette.blue}
            ></TrendingUp>
          </Grid>
        </Grid>
      );
    } else if (this.props.concernShift < 0) {
      return (
        <Grid container>
          <Grid>
            <Typography variant='subtitle2' style={theme.palette.pink}>
              ({this.props.concernShift})
            </Typography>
          </Grid>
          <Grid>
            <TrendingDown
              className={classes.shiftArrow}
              //style={theme.palette.pink}
            ></TrendingDown>
          </Grid>
        </Grid>
      );
    } else if (this.props.concernShift === 0) {
      return <Typography variant='subtitle2'>(No Shift)</Typography>;
    } else return;
  }

  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    const { completed, buffer } = this.processProgress(this.props);
    let barColorPrimary = classes[`${functions.concernToColorClass(this.props.concern)}BG`];
    //let colorPrimary;

    /*if (this.props.concernShift > 0) {
      colorPrimary = classes.blueBGLight;
    } else {
      colorPrimary = classes.pinkBG;
    }*/
    return (
      <div className={classes.progressWrapper}>
        <Grid container>
          <Grid item style={{ marginTop: -2 }}>
            <InfoPopover resize title='Concern' id='Concern' type='prioritize' />
            {/* 
            <Tooltip
              arrow
              placement='top'
              title={
                <React.Fragment>
                  <Grid>
                    <Typography variant='caption'> This loop contains historical data that may be over 2 years old.</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant='caption' style={{ marginTop: 4 }}>
                      Last tested on ___
                    </Typography>
                  </Grid>
                </React.Fragment>
              }
              enterDelay={300}
            >
              <IconButton
                className={classes.checklistInfo}
                style={
                  this.props.disableHover
                    ? {
                        backgroundColor: 'transparent',
                      }
                    : null
                }
                color='inherit'
                aria-label='information'
                size='large'
              >
                <History color='inherit' style={{ height: 18, width: 18 }}></History>
              </IconButton>
            </Tooltip>*/}
          </Grid>
          <Grid item>
            <Typography style={theme.palette.blue} className={classes.progressLabelPrioritize} variant='subtitle2'>
              {this.props.label}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>{this.renderConcern()}</Grid>
          {/*<Grid item className={classes.shift}>
            {this.renderShift()}
          </Grid>*/}
        </Grid>
        <LinearProgress
          value={completed}
          variant='buffer'
          valueBuffer={buffer}
          classes={{
            bar1Buffer: classes.marker,
            bar2Buffer: classes.marker,
            barColorPrimary: barColorPrimary,
            colorPrimary: barColorPrimary,
          }}
        />
        <div className={classes.guide} />
      </div>
    );
  }
}

PrioritizeProgress.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(PrioritizeProgress));
