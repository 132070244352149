import React from 'react';
import * as Sentry from '@sentry/react';
import ResponsiveDrawer from './drawer/ResponsiveDrawer';
import { connect } from 'react-redux';
import { verifyAuth, setPrint, setFormat, setColorMode } from '../actions';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import initializeTheme from '../components/theme';
import fastfield from '../apis/fastfield';

//Sentry Dialog
//const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
class App extends React.Component {
  componentDidMount() {
    Sentry.configureScope(function (scope) {
      scope.setTag('version', '0.1.1');
    });
    if (!this.props.isSignedIn) {
      this.props.verifyAuth();
      /*let ff = */ fastfield.getFastFieldAuth();
      //console.log(ff);
    }
    /*var beforePrint = () => {
      //console.log('Functionality to run before printing.');
      this.props.setPrint(true);
    };
    var afterPrint = () => {
      //console.log('Functionality to run after printing');
      this.props.setPrint(false);
    };*/
    /*if (window.matchMedia) {
      var mediaQueryList = window.matchMedia('print');
      mediaQueryList.addListener(function (mql) {
        if (mql.matches) {
          beforePrint();
        } else {
          afterPrint();
        }
      });
    }
    window.onbeforeprint = beforePrint;
    window.onafterprint = afterPrint;*/
  }
  render() {
    const currentUrl = window.location.href;
    const printFlag = currentUrl.slice(-6);
    if (printFlag.toLowerCase() === '/print') {
      //PRINT FORMAT FROM URL FUNCTIONALITY
      this.props.setFormat(true);
      this.props.setColorMode('light');
    } else {
      this.props.setFormat(false);
    }
    let username = this.props.userId;
    let theme = initializeTheme(this.props.colorMode, this.props.activeTab);
    /*console.log('theme udpate');
    console.log(theme);
    console.log(this.props.colorMode);*/
    Sentry.configureScope(function (scope) {
      scope.setUser({ username: !username ? 'Not_Signed_In' : username });
    });
    //<ColorModeContext.Provider value={'dark'}>
    //     </ColorModeContext.Provider>
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ResponsiveDrawer />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userId: state.auth.userId,
    locations: state.locations,
    errors: state.errors,
    print: state.print,
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, { verifyAuth, setPrint, setFormat, setColorMode })(Sentry.withProfiler(App));
//export default App;
