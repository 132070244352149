import { UPLOAD_SET_ACTIVE_TAB } from '../actions/types';

const INITIAL_STATE = {
  activeTab: 'Optimize',
};

const uploadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPLOAD_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
};

export default uploadReducer;
