require('string_score');
function matchFilter(options, needle, setKeys, limit) {
  //let values = this.state.value.split(' ');
  let values = needle.split(' ');
  //console.log(options);
  // Remove "" values from array as result of hitting space for a new search term
  const index = values.indexOf('');
  if (index > -1) {
    values.splice(index, 1);
  }
  // search threshold
  const threshold = 0.2; //Math.min(0.1 + 0.1 * this.state.value.length, 0.5);
  let finalOptions = [];
  //search all key->value pairs in the option for value matches based on each search term, if higher than the threshold, pass it.
  for (let a = 0; a < options.length; a++) {
    let option = options[a];
    let keys = !setKeys ? Object.keys(option) : setKeys;

    let pass = 0;
    for (let i = 0; i < keys.length; i++) {
      for (let j = 0; j < values.length; j++) {
        if (
          //toString in case the key is a number
          !!option[keys[i]] &&
          ((option[keys[i]] !== null &&
            option[keys[i]].toString().score(values[j]) > threshold) ||
            values[j] === '')
        ) {
          pass++;
        }
      }
    }
    // if the number of passed key/value pairs is >= number of search terms, return
    if (pass >= values.length) {
      finalOptions.push(option);
    }
  }
  if (!!limit && finalOptions.length >= limit) {
    finalOptions = finalOptions.slice(0, limit);
    finalOptions.push({
      name: `More than ${limit} results found, please refine search.`,
    });
  }
  return finalOptions;
}

export default matchFilter;
