//import _ from 'lodash';
import {
  //DELETE_LOOPS,
  //FETCH_LOOP,
  //FETCH_DEVICES,
  FETCH_TEST,
  SET_TEST_STATUS,
  FETCH_DIAGNOSTICS,
  //UPDATE_DEVICE_COUNT
  /*,
  FETCH_LOCATIONS,
  CREATE_LOCATION,
  DELETE_LOCATION,
  EDIT_LOCATION*/
} from '../actions/types';

const INITIAL_STATE = { tests: {}, status: {} };

const processTest = (state, tests, payloadObj) => {
  //console.log(payloadObj);
  //console.log(tests);
  //no test data
  if (payloadObj.testId === null) {
    return tests;
  }
  let testsLocal = tests;

  const testType = payloadObj.type === 'IP' ? 'i/p' : payloadObj.type.toLowerCase();
  if (!testsLocal[testType]) {
    //console.log('new type');
    //test type doesn't exist yet
    testsLocal[testType] = {};
    //test doesn't exist yet, add
    testsLocal[testType] = payloadObj[testType];
  } else if (!testsLocal[testType][payloadObj.testId]) {
    //console.log('new test');
    //test doesn't exist yet, add
    testsLocal[testType][payloadObj.testId] = payloadObj[testType][payloadObj.testId];
  } else {
    //console.log('merge');
    //test exists, merge data
    testsLocal[testType][payloadObj.testId] = Object.assign(testsLocal[testType][payloadObj.testId], payloadObj[testType][payloadObj.testId]);
  }
  //console.log(testsLocal);
  return testsLocal;
};

const processTestStatus = (state, testStatus, payloadObj) => {
  //let testStatus = Object.create(state.status);
  //if no data
  if (payloadObj.testId === null) {
    //console.log('f');
    return testStatus;
  }
  //adjustment for I/P slash handling
  let testStatusLocal = testStatus;
  let type = payloadObj.type === 'IP' ? 'I/P' : payloadObj.type;
  let status = payloadObj.status;
  let testId = payloadObj.testId;
  //console.log('testing!');
  if (!testStatusLocal[type]) {
    //console.log('new test type');
    //test type doesn't exist yet
    testStatusLocal[type] = {};
    //test doesn't exist yet, add
    testStatusLocal[type] = { [testId]: status };
  } else if (!testStatusLocal[type][testId]) {
    //console.log('new test status');
    //test doesn't exist yet, add
    testStatusLocal[type][testId] = status;
  } else {
    //console.log('merge');
    //test exists, merge data
    testStatusLocal[type][testId] = status;
  }
  //console.log('t');
  return testStatusLocal;
};

const testReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TEST: {
      let tests = JSON.parse(JSON.stringify(state.tests));
      //let tests = Object.create(state.tests);
      //console.log(action.payload);
      if (Array.isArray(action.payload)) {
        for (let index in action.payload) {
          tests = processTest(state, tests, action.payload[index]);
        }
      } else {
        tests = processTest(state, tests, action.payload);
      }

      return {
        ...state,
        tests,
      };
    }
    case SET_TEST_STATUS: {
      let testStatus = JSON.parse(JSON.stringify(state.status));
      //console.log(action.payload);
      if (Array.isArray(action.payload)) {
        for (let index in action.payload) {
          testStatus = processTestStatus(state, testStatus, action.payload[index]);
        }
      } else {
        testStatus = processTestStatus(state, testStatus, action.payload);
      }
      return {
        ...state,
        status: testStatus,
      };
    }
    case FETCH_DIAGNOSTICS: {
      // do I need this to be seperate given the result is the same as the fetch test?
      let tests = JSON.parse(JSON.stringify(state.tests));
      //let tests = Object.create(state.tests);
      if (action.payload === null || action.payload === 'No Data Found') {
        return state;
      }
      const { specs, diagnostics } = action.payload;
      //console.log(action.payload);
      //console.log(diagnostics);
      if (!state.tests['diagnostics']) {
        tests['diagnostics'] = { [diagnostics.profileId]: diagnostics };
        tests['diagnosticsSpecs'] = !specs || !specs.profileId ? {} : { [specs.profileId]: specs };
      } else if (!state.tests['diagnostics'][diagnostics.profileId]) {
        //test doesn't exist yet, add
        tests['diagnostics'][diagnostics.profileId] = diagnostics;
        if (!!specs && !!specs.profileId) {
          tests['diagnosticsSpecs'][specs.profileId] = specs;
        }
      } else {
        //test exists, merge data
        tests['diagnostics'][diagnostics.profileId] = Object.assign(state.tests['diagnostics'][diagnostics.profileId], diagnostics);
        if (!!specs && !!specs.profileId) {
          tests['diagnosticsSpecs'][specs.profileId] = Object.assign(state.tests['diagnosticsSpecs'][specs.profileId], specs);
        }
      }
      return {
        ...state,
        tests,
      };
    }
    /*case DELETE_LOOPS:
      return {
        ...state,
        loops: {}
      };
    case FETCH_LOOP:
      return {
        ...state,
        loops: { ...state.loops, ..._.mapKeys(action.payload, 'loopId') }
      };*/
    /*case FETCH_DEVICES:
      return {
        ...state,
        devices: { ...state.devices, ..._.mapKeys(action.payload, 'deviceId') }
      };
    case UPDATE_DEVICE_COUNT:
      return { ...state, count: action.payload };*/
    /*case EDIT_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case CREATE_LOCATION:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_LOCATION:
      return _.omit(state, action.payload);*/
    default:
      return state;
  }
};

export default testReducer;
