import _ from 'lodash';
import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles.js';
import { connect } from 'react-redux';
import { Link } from '@mui/icons-material';
import { /*Checkbox,*/ Typography, /*ListItem, Tooltip, /*TablePagination, */ Button /*, Paper*/ } from '@mui/material';
import IBATile from '../tiles/IBATile';
import CustomSelect from '../filters/CustomSelect';
import { RestartAlt } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import functions from '../../functions/functions';
import {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
} from '../../actions/index';

import { CircularProgress } from '@mui/material';
import WidgetTitle from '../headers/WidgetTitle';
import ReportChartWidget from '../widgets/ReportChartWidget';
import Highcharts from 'highcharts';
import initializeTheme from '../theme.js';
//import TableWidget from '../widgets/TableWidget.js';
//import fastfield from '../../apis/fastfield.js';
//import api from '../../apis/api.js';
//import FastFieldImage from '../../components/test/FastFieldImage.js';

class IBA extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: {} };
    this.processData = this.processData.bind(this);
  }
  componentDidMount() {
    if (this.state.currentModule !== 'IBA') {
      this.props.setModule('IBA');
    }
    //this.props.fetchDevices('3');
    this.props.fetchDevicesSummary();
    // this.props.fetchOptimizeDevices(0, 24);
  }

  renderLoading() {
    const { classes } = this.props;
    if (this.state.loading) {
      return (
        <div>
          <Typography variant='subtitle2' style={{ marginTop: 12 }}>
            <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
            Loading...
          </Typography>
        </div>
      );
    } else {
      return;
    }
  }

  resetFilters = async () => {
    this.setState({ changes: true });
    await this.props.clearFilter();
    this.props.updateSort('Importance');
    this.multiSelectOnClose();
  };
  handleSort = async (event, value, type) => {
    return 'fix';
    // this.props.updateSort(value);
    // // TODO FINISH THIS WHEN TABLE FULLY IN REDUX
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   this.props.deleteDevices();
    //   this.props.deleteLoops();
    //   //TODO Finish with update of page

    //   //console.log(filters);
    //   //console.log(this.props.sort.filter);
    //   this.setState({ loading: true });
    //   this.props.setTablePage(0);
    //   //}

    //   //console.log(filters);

    //   await this.props.fetchLoops(0, this.props.table.pageSize, value, filters, this.props.sort.filter.Dataset).then(() => {
    //     this.setState({
    //       //page,
    //       //rowsPerPage,
    //       loading: false,
    //       changes: false,
    //     });
    //   });
    //   //this.props.fetchLoopsSummary();
    // }
  };
  renderTotals() {
    return 'fix';
    // const { classes } = this.props;
    // let issueKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].issues.length;
    // });
    // let totalIssues = 0;
    // issueKeys.forEach((issue) => {
    //   totalIssues += issue;
    // });
    // let actionKeys = Object.keys(this.props.loops).map((key) => {
    //   return this.props.loops[key].actions.length;
    // });
    // let totalActions = 0;
    // actionKeys.forEach((action) => {
    //   totalActions += action;
    // });
    // return (
    //   <Grid container className={classes.totalsContainer} spacing={2}>
    //     <Grid item>{<Typography variant='subtitle2'>Total Issues: {!totalIssues ? 0 : totalIssues}</Typography>}</Grid>
    //     <Grid item>{<Typography variant='subtitle2'>Total Action Items: {!totalActions ? 0 : totalActions}</Typography>}</Grid>
    //   </Grid>
    // );
  }

  multiSelectOnClose = async (event, label, changesOverride) => {
    let obj = { ...this.props.sort.filter };
    this.setState({ filter: obj });
  };

  multiSelectOnChange = async (event, value, type) => {
    let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    obj[name] = value;
    this.props.updateFilter(obj);
  };

  refreshPageView = (offset, rowsPerPage, expectedPage) => {
    return 'fix';
    // let filters = {
    //   Application: this.props.sort.filter.Application,
    //   Site: this.props.sort.filter.Site,
    //   Location: this.props.sort.filter.Location,
    //   Disruption: this.props.sort.filter.Disruption,
    //   startDate: this.props.sort.filter.Range.startDate,
    //   endDate: this.props.sort.filter.Range.endDate,
    //   analysisRange: this.props.sort.filter.analysisRange,
    //   Devicetypes: this.props.sort.filter['Device Types'],
    //   Issuetypes: this.props.sort.filter['Issue Types'],
    //   Importance: this.props.sort.filter.Importance,
    //   SpecificDevice: this.props.sort.filter.SpecificDevice,
    //   Concerns: JSON.stringify(this.props.sort.concern),
    // };
    // if (
    //   !(
    //     !filters.Application &&
    //     !filters.Disruption &&
    //     !filters.Devicetypes &&
    //     !filters.Issuetypes &&
    //     !filters.Importance &&
    //     !filters.Location &&
    //     !filters.Site &&
    //     !filters.Concerns &&
    //     this.props.auth.require_filter
    //   )
    // ) {
    //   //Check to see if we already pulled the row data, if so don't bother the API again
    //   //console.log(this.props.sort);
    //   let rowsExist = true;
    //   const page = Math.floor(offset / rowsPerPage); // correct the page number for changing the page size
    //   //const rowList = this.props.loops.map(loop => loop.rowId);
    //   const rowList = _.map(this.props.loops, (loop) => loop.rowId);
    //   for (let i = offset; i < offset + rowsPerPage; i++) {
    //     if (!rowList.includes(i + 1)) {
    //       rowsExist = false;
    //     }
    //   }
    //   if (rowsExist) {
    //     this.setState({
    //       page,
    //       rowsPerPage,
    //       loading: false,
    //     });
    //   } else {
    //     this.props.fetchLoops(offset, rowsPerPage, this.props.sort.sort, filters, this.props.sort.filter.Dataset).then(() => {
    //       this.setState({
    //         page,
    //         rowsPerPage,
    //         loading: false,
    //       });
    //     });
    //   }
    // }
  };

  handleChangePage = (event, page) => {
    return 'fix';
    // this.setState({
    //   loading: true,
    // });
    // this.props.setTableOffset(this.props.table.pageSize * page);
    // this.props.setTablePage(page);
    // const offset = this.props.table.pageSize * page;
    // const rowsPerPage = this.props.table.pageSize;
    // this.refreshPageView(offset, rowsPerPage, page);
  };
  handleChangeRowsPerPage = (event) => {
    return 'fix';
    // this.setState({
    //   loading: true,
    // });
    // this.props.setTablePageSize(event.target.value);
    // this.props.setTableOffset(this.props.table.page * this.props.table.pageSize);
    // const offset = this.props.table.page * this.props.table.pageSize;
    // const rowsPerPage = event.target.value;
    // const page = Math.floor(offset / rowsPerPage);
    // this.props.setTablePage(page);
    // this.refreshPageView(offset, rowsPerPage, page);
  };

  //Look through filters and rows of the data at the designated points and return all the match, using the orArray to set wether a particular column allows AND vs OR checks
  processData = () => {
    /*let data = _.map(this.props.loops, (loop) => {
      let loopAdj = loop;
      loopAdj.meterCheck = 'TRUE';
      loopAdj.actionCheck = 'TRUE';
      //return loopAdj;
    });*/
    const filters = this.state.filter;
    //const report_link_function = this.getFastFieldReport;
    let filterList = ['Plant', 'Unit', 'Application', 'Line', 'Manufacturer', 'Device Type', 'Year', 'Status', 'Importance', 'Recommendations'];
    let filterListRow = [
      'plant_name',
      'plant_location',
      'device_location',
      'vessel_line_location',
      'manufacturer',
      'asset_description',
      'year_manufactured',
      'meterCheck',
      'criticality',
      'recommendation_type',
    ];
    let orArray = [false, false, false, false, false, false, false, false, false, true]; //sets column check to and vs or (default and);
    //console.log(filters);
    let outerThis = this;
    const theme = initializeTheme(this.props.colorMode);
    let data = _.reduce(
      this.props.loops,
      function (acc, obj) {
        //acc stands for accumlation, [] is the accumulator
        let match = true;
        for (let i = 0; i < filterList.length; i++) {
          let filterListArr = !filters[filterList[i]] ? [] : filters[filterList[i]];
          //if ((!filters[filterList[i]] || filters[filterList[i]].length === 0 || filters[filterList[i]].indexOf(obj[filterListRow[i]]) !== -1) && match) {
          let orMatch = filterListArr.length === 0 && match ? true : false; //convert check to an OR check from an AND check
          for (let j = 0; j < filterListArr.length; j++) {
            if (((orMatch && orArray[i]) || !filterListArr[j] || filterListArr[j].length === 0 || (!!obj[filterListRow[i]] && obj[filterListRow[i]].indexOf(filterListArr[j]) !== -1)) && match) {
              orMatch = true;
            } else if (!orArray[i]) {
              //console.log('hi');
              match = false;
            } /*&& true*/
          }
          match = orMatch;
        }

        //console.log(ignore);
        if (match) {
          obj.meterCheck = outerThis.checkDeviceOperatingStatus(obj);
          obj.actionCheck = 'TRUE';
          /*obj.report_link = (
            <Button variant='outlined' onClick={() => report_link_function(obj.submission_id)}>
              Get Report
            </Button>
          );*/
          /*let lat = obj.location_gps_lat;
          let lat_suff = lat > 0 ? 'N' : 'S';
          let long = obj.location_gps_long;
          let long_suff = long > 0 ? 'E' : 'W';
          obj.map_link = <a href={`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`}>Map Link</a>;
          console.log(`https://www.google.com/maps/place/${Math.abs(lat)}${lat_suff}+${Math.abs(long)}${long_suff}`);*/
          obj.report_link = obj.deviceId; //<FastFieldImage file={obj.asset_photos[0]} />;
          obj.status = outerThis.checkDeviceOperatingStatus(obj);
          //off, recommended action, no issue
          //TODO Consider alternative coloration
          let color = obj.status === 'Off' ? '#888' : obj.status === 'Recommended Action' ? theme.palette.blue.color : '#308D3B';
          //console.log(color);
          obj.style = { borderLeft: '8px solid ' + color };
          acc.push(obj);
        }
        //console.log(acc);
        return acc;
      },
      []
    );
    //console.log(data);
    return data;
  };

  createRecommendationPie = (data) => {
    let recommendation_types = {};

    for (let i = 0; i < data.length; i++) {
      let recommendation_type_split = !data[i].recommendation_type ? [] : data[i].recommendation_type.split(',');
      for (let j = 0; j < recommendation_type_split.length; j++) {
        if (!recommendation_types[recommendation_type_split[j]]) {
          recommendation_types[recommendation_type_split[j]] = 1;
        } else {
          recommendation_types[recommendation_type_split[j]]++;
        }
      }
    }

    let obj_keys = Object.keys(recommendation_types);
    //console.log(obj_keys);
    let pie_data = [];
    for (let i = 0; i < obj_keys.length; i++) {
      pie_data.push({
        name: obj_keys[i],
        y: recommendation_types[obj_keys[i]],
      });
    }
    return pie_data;
  };

  checkDeviceOperatingStatus = (device) => {
    if (!device) {
      return 'Error';
    } else if (!!device.meter_power_check && device.meter_power_check.toLowerCase() === 'false') {
      return 'Off';
    } /* else if (device.meter_measurement_check === '0') {
      return 'Incorrect';
    }*/ else if (device.recommendation_type !== null) {
      return 'Recommended Action';
    } else {
      return 'No Issue';
    }
  };

  createDeviceOperationColumn = (data, xCategory) => {
    let operationColumnObj = {};
    const theme = initializeTheme(this.props.colorMode);
    for (let i = 0; i < data.length; i++) {
      let xAxisType = data[i][xCategory] === null ? 'Unknown' : data[i][xCategory];
      if (!operationColumnObj[xAxisType]) {
        operationColumnObj[xAxisType] = { 'No Issue': 0, Off: 0, Incorrect: 0, 'Recommended Action': 0 };
      }
      //console.log(xAxisType, this.checkDeviceOperatingStatus(data[i]));
      operationColumnObj[xAxisType][data[i].status]++;
    }
    //console.log(operationColumnObj);
    let types = ['Off', /* 'Incorrect',*/ 'Recommended Action', 'No Issue'];
    let colorTypes = ['#888', theme.palette.blue.color, /* '#e3a61b',*/ '#308D3B'];
    let obj_keys = Object.keys(operationColumnObj);
    let stacking_data = [];
    //console.log(operationColumnObj);
    for (let i = 0; i < types.length; i++) {
      let dataArr = [];
      for (let j = 0; j < obj_keys.length; j++) {
        dataArr.push(operationColumnObj[obj_keys[j]][types[i]]);
      }
      //console.log(obj_keys[i]);
      //console.log(dataArr);

      stacking_data.push({
        name: types[i],
        data: dataArr,
        color: colorTypes[i],
      });
    }
    //console.log(stacking_data);
    //console.log({ importanceTypes: obj_keys, stackingData: stacking_data });
    return { importanceTypes: obj_keys, stackingData: stacking_data };
    /* let importanceTypes = ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Not Important'];
    let stackingData = [
      {
        name: 'Incorrect Measurement',
        data: [1, 2, 1, 0, 0],
        color: '#D3001B',
      },
      {
        name: 'Off',
        data: [4, 3, 3, 0, 0],
        color: '#e3a61b',
      },
      {
        name: 'On',
        data: [6, 7, 2, 1, 0],
        color: '#308D3B',
      },
    ];
    return { importanceTypes, stackingData };*/
  };

  createPieData = (data) => {
    let on_count = 0;
    let off_count = 0;
    let incorrect_count = 0;

    for (let i = 0; i < data.length; i++) {
      //console.log(data[i].meter_measurement_check, data[i].meter_power_check);
      if (data[i].meter_power_check.toLowerCase() === 'false') {
        off_count++;
      } else if (data[i].meter_measurement_check === '0') {
        incorrect_count++;
      } else {
        on_count++;
      }
    }
    return [
      {
        name: 'On',
        y: on_count,
      },
      {
        name: 'Off',
        y: off_count,
      },
      {
        name: 'Incorrect Measurement',
        y: incorrect_count,
      },
    ];
  };

  getUniqueValues = (data, prop, csv) => {
    let uniqueValues = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i][prop]) {
        console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
      } else {
        let values = !csv ? [data[i][prop]] : data[i][prop].split(',');
        for (let j = 0; j < values.length; j++) {
          if (uniqueValues.indexOf(values[j]) === -1 && !!values[j]) {
            uniqueValues.push(values[j].toString());
          } else if (!values[j]) {
            console.log(`Error: Undefined in getUniqueValues() for ${prop}`);
          }
        }
      }
    }
    return uniqueValues;
  };

  customSelectFactory = (label, values) => {
    return (
      <CustomSelect
        id={null}
        label={label}
        disabled={this.state.loading}
        onCloseFunction={this.multiSelectOnClose}
        onChangeFunction={this.multiSelectOnChange}
        //type='unit'
        values={!this.props.sort.filter[label] ? [] : this.props.sort.filter[label]}
        options={values /*!this.props.sites || !this.props.sites.list ? [] : this.props.sites.list.sort()*/}
        value={null}
        helperText={null}
      />
    );
  };

  render() {
    const { classes } = this.props;
    let data = this.processData();
    const { importanceTypes, stackingData } = this.createDeviceOperationColumn(data, 'manufacturer');
    const pieData = this.createPieData(data);
    const pie_recommendation_data = this.createRecommendationPie(data);
    let plant_names = this.getUniqueValues(data, 'plant_name');
    let unit_names = this.getUniqueValues(data, 'plant_location');
    let application_names = this.getUniqueValues(data, 'device_location');
    let line_names = this.getUniqueValues(data, 'vessel_line_location');
    let device_type_names = this.getUniqueValues(data, 'asset_type');
    let manufacturer_names = this.getUniqueValues(data, 'manufacturer');
    //let year_names = this.getUniqueValues(data, 'year_manufactured');
    let status_names = this.getUniqueValues(data, 'meterCheck');
    let importance_names = this.getUniqueValues(data, 'criticality');
    //let recommendations_names = this.getUniqueValues(data, 'actionCheck');
    let recommendation_types = this.getUniqueValues(data, 'recommendation_type', true);

    /*let headerArr = [
      //{ id: 'tag', label: 'Device' },
      //{ id: 'report_link', label: 'Link' },
      { id: 'tag', label: 'Device' },
      { id: 'asset_type', label: 'Device Type' },
      { id: 'plant_name', label: 'Plant' },
      { id: 'plant_location', label: 'Unit' },
      { id: 'device_location', label: 'Application' },
      { id: 'vessel_line_location', label: 'Line' },
      { id: 'manufacturer', label: 'Manuf.' },
      //{ id: 'order_code', label: 'order_code' },
      // { id: 'year_manufactured', label: 'Year Manuf.' },
      //{ id: 'meterCheck', label: 'Meter Status' },
      { id: 'criticality', label: 'Importance' },
      //{ id: 'actionCheck', label: 'Recommendations' },
      { id: 'recommendation_type', label: 'Recommendation Types' },
      { id: 'link_formatted', label: 'Report' },

      //{ id: '', label: '' },
    ];*/

    for (let i = 0; i < data.length; i++) {
      data[i].link_formatted = (
        <React.Fragment>
          {/*<Typography>{data[i].tag}</Typography>*/}
          <Button variant='outlined' size='medium' color='primary' className={classes.button} href={`IBA/detail/${data[i].report_link}`} style={{ marginTop: 8, marginBottom: 8 }}>
            <Link className={classes.buttonIcon}></Link>View Device
          </Button>
        </React.Fragment>
      );
    }

    const theme = initializeTheme(this.props.colorMode);
    const monoColors = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[0])
        .brighten((i - 6) / 16)
        .get()
    );
    const monoColors2 = Highcharts.getOptions().colors.map((c, i) =>
      // Start out with a darkened base color (negative brighten), and end
      // up with a much brighter color
      Highcharts.color(Highcharts.getOptions().colors[7])
        .brighten((i - 3) / 12)
        .get()
    );
    //console.log(this.props.loops);
    if (!this.props.auth) {
      return <div>Loading...</div>;
    }
    if (!!this.props.auth && this.props.auth.prioritize === 0) {
      return <div>You do not have permission to access this module</div>;
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //console.log(this.props);
    return (
      <React.Fragment>
        <form className={classes.form} autoComplete='off' style={{ marginTop: 10 }}>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <CustomSelect
                single
                id={null}
                label='Sort'
                type='sort'
                disabled={this.state.loading}
                //onCloseFunction={this.multiSelectOnClose}
                onChangeFunction={this.handleSort}
                values={!this.props.sort.sort ? 'Importance' : this.props.sort.sort}
                options={
                  /*!this.props.sort.sort
                ? ''
                :*/ ['Concern', 'Concern Max', 'Concern Shift', 'Importance', 'Name', 'Application Order' /*'Priority'*/]
                }
                //value={null}
                helperText={null}
              />
              {this.customSelectFactory('Plant', plant_names)}
              {this.customSelectFactory('Unit', unit_names)}
              {this.customSelectFactory('Application', application_names)}
              {this.customSelectFactory('Line', line_names)}
              {this.customSelectFactory('Device Type', device_type_names)}
              {this.customSelectFactory('Manufacturer', manufacturer_names)}
              {/*this.customSelectFactory('Year', year_names)*/}
              {this.customSelectFactory('Status', status_names)}
              {this.customSelectFactory('Importance', importance_names)}
              {/*this.customSelectFactory('Recommendations', recommendations_names)*/}
              {this.customSelectFactory('Recommendations', recommendation_types)}
            </Grid>
            <Grid item>
              <Button className={`${classes.buttonFilterRight} ${classes.printHide}`} variant='outlined' onClick={this.resetFilters} style={{ float: 'right', marginTop: 14 }}>
                <RestartAlt className={classes.buttonIcon}></RestartAlt>Clear All Filters
              </Button>
              {/*<Button
                className={`${classes.buttonFilterRight} ${classes.printHide}`}
                variant='outlined'
                component={Link}
                to={'/support/Prioritize'}
                style={{ float: 'right', marginLeft: 0, marginTop: 14 }}
                onClick={() => this.props.setActiveNav(7)}
              >
                <Directions className={classes.buttonIcon}></Directions>View Roadmaps
                      </Button>*/}
            </Grid>
          </Grid>
          {/*<DatePicker
            label='Start Date'
            disabled={this.state.loading}
            date={`${new Date().getFullYear() - 2}-01-01T12:00:00`}
            dateFunction={(startDate) => {
              this.updateDateRange(startDate, 'start');
            }}
          ></DatePicker>
          <DatePicker
            label='End Date'
            disabled={this.state.loading}
            dateFunction={(endDate) => {
              this.updateDateRange(endDate, 'end');
            }}
          ></DatePicker>*/}
        </form>
        <Grid container justifyContent='space-between'>
          <Grid item>{/*this.renderTotals()*/}</Grid>
        </Grid>
        <Grid container spacing={1}>
          <ReportChartWidget name='Measurement Breakdown' data={pieData} type='pie' colors={monoColors} initialWidth={printStyles ? 12 : 4} loading={this.state.loading}></ReportChartWidget>
          <ReportChartWidget
            name='Meter Status'
            colors={[theme.palette.red.color, theme.palette.yellow.color, theme.palette.green.color]}
            type='stack'
            seriesData={stackingData}
            categories={importanceTypes}
            initialWidth={printStyles ? 6 : 4}
            loading={this.state.loading}
          ></ReportChartWidget>
          <ReportChartWidget name='Recommendations' data={pie_recommendation_data} type='pie' colors={monoColors2} initialWidth={printStyles ? 6 : 4} loading={this.state.loading}></ReportChartWidget>
        </Grid>
        <div className={classes.pageBreak}></div>
        <WidgetTitle title={`Installed Base Analysis`} />
        {data.map((device, index) => {
          return <IBATile key={`iba_tile_${index}`} device={device} hasBorder index={index} />;
        })}
        {/*<TableWidget header={headerArr} data={data} defaultRows={25} loading={this.state.loading}></TableWidget>*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    applications: state.applications,
    importances: state.importances,
    locations: state.locations,
    loops: state.devices.devices,
    loopCount: state.devices.count,
    format: state.format.format,
    sort: state.sort,
    table: state.table,
    sites: state.sites,
    issueTypes: state.issuetypes,
    currentModule: state.currentModule,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  fetchOptimizeDevices,
  fetchDevicesSummary,
  fetchDevices,
  fetchEvents,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  setModule,
  updateSort,
  updateDateRange,
  fetchLocations,
  fetchApplications,
  fetchImportances,
  fetchSites,
  fetchIssueTypes,
  deleteLoops,
  deleteDevices,
  fetchSort,
  fetchLoops,
  updateFilter,
  clearFilter,
})(withStyles(styles)(IBA));
