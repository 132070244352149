import React from 'react';
import Grid from '@mui/material/Grid';
import TableWidget from '../../widgets/TableWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import DatePicker from '../../filters/DatePicker';

class HistoryWidgets extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <DatePicker></DatePicker>
        <DatePicker></DatePicker>
        <div className={classes.dividerXl}></div>
        <WidgetTitle title='History' />
        <Grid container spacing={1}>
          <TableWidget
            xs={12}
            //data={data}
            //header={
            // !this.props.devices[this.props.activeDevice] ? [] : headerArr
            //}
          />
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
})(withStyles(styles)(HistoryWidgets));
