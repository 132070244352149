import React /*{ useState }*/ from 'react';
import { Typography, Grid, Divider, ListItem, ListItemText } from '@mui/material';
import { styles } from '../styles';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { fetchDeviceIssuesByName, fetchActions, fetchInvestigations, fetchIssueDetails, fetchDevices, fetchDeviceSpecs, fetchDiagnostics, setTestStatus } from '../../actions/index';
import functions from '../../functions/functions';
import ChecklistIndicator from '../filters/ChecklistIndicator';

let requestedInvestigations = [];
let requestedActions = [];
let requestedIssues = [];
let recommendationsCurrent = {};
//let inactiveState = {};

function getProbabilityColor(props, probability) {
  const { classes } = props;
  switch (probability) {
    case 'Very Likely':
      return classes.greenText;
    case 'Likely':
      return classes.greenText;
    case 'Possible':
      return classes.yellowText;
    case 'Unlikely':
      return classes.redText;
    default:
      return classes.grayText;
  }
}
/*function setInactiveState(issueId) {
  if (!inactiveState[issueId]) {
    inactiveState[issueId] = true;
  } else {
    inactiveState[issueId] = !inactiveState[issueId];
  }
}*/

function RenderIssueConcise(propsParent) {
  let props = propsParent.props;
  let issue = propsParent.issue;
  //const [inactiveOpen, setInactiveOpen] = useState(false);
  const { classes } = props;
  const issueRecommendations = !issue.recommendation_list_active ? [] : issue.recommendation_list_active;
  const issueRecommendationsInactive = !issue.recommendation_list_inactive ? [] : issue.recommendation_list_inactive;
  const issueInvestigations = !issue.investigation_list_active ? [] : issue.investigation_list_active;
  const issueInvestigationsInactive = !issue.investigation_list_inactive ? [] : issue.investigation_list_inactive;
  const combinedRecommendations = issueRecommendations.concat(issueRecommendationsInactive);
  //only ask for things we do not have yet
  let missingRecommendations = [];
  for (let i = 0; i < combinedRecommendations.length; i++) {
    if (!props.recommendations.actions[combinedRecommendations[i]] && !requestedActions.includes(combinedRecommendations[i])) {
      requestedActions.push(combinedRecommendations[i]);
      missingRecommendations.push(combinedRecommendations[i]);
    }
  }
  const combinedInvestigations = issueInvestigations.concat(issueInvestigationsInactive);
  let missingInvestigations = [];
  for (let i = 0; i < combinedInvestigations.length; i++) {
    if (!props.recommendations.investigations[combinedInvestigations[i]] && !requestedInvestigations.includes(combinedInvestigations[i])) {
      requestedInvestigations.push(combinedInvestigations[i]);
      missingInvestigations.push(combinedInvestigations[i]);
    }
  }
  if (!props.recommendations.issues[issue.issue] && !requestedIssues.includes(issue.issue)) {
    requestedIssues.push(issue.issue);
    props.fetchIssueDetails([issue.issue]);
  }
  if (missingInvestigations.length !== 0) {
    props.fetchInvestigations(missingInvestigations);
  }
  if (missingRecommendations.length !== 0) {
    props.fetchActions(missingRecommendations);
  }

  return (
    <React.Fragment>
      <React.Fragment>
        <Typography variant='subtitle1' className={classes.bodyHeader}>
          Recommendations
        </Typography>
        {issue.recommendation_list_active.length < 1 ? <Typography>No recommendations found!</Typography> : renderRecommendations(props, issue, issue.recommendation_list_active)}
        {/*issue.recommendation_list_inactive.length < 1 ? null : (
          <React.Fragment>
            <Typography
              variant='subtitle1'
              color='secondary'
              onClick={() => {
                setInactiveOpen(!inactiveOpen);
              }}
              style={{ marginBottom: 5 }}
            >
              Inactive Recommendations
            </Typography>
            <Collapse in={inactiveOpen}>{renderRecommendations(props, issue, issue.recommendation_list_inactive, true)}</Collapse>
          </React.Fragment>
            )*/}
      </React.Fragment>
    </React.Fragment>
  );
}

function renderRecommendations(props, issueId, recommendations, inactive = false) {
  const { classes } = props;
  //console.log(recommendations);
  let recommendationDetails = props.recommendations.actions;
  //console.log(recommendationDetails);
  return (
    <React.Fragment>
      {recommendations.map((recommendation) => {
        let rowActionId = recommendation;
        if (!recommendationDetails[recommendation]) {
          return <Typography key={`recommendation_row_id${rowActionId}`}>Loading Recommendation...</Typography>;
        }
        if (inactive) {
          return (
            <React.Fragment key={`${recommendation}_recommendation_item`}>
              <ListItem role={undefined} dense style={{ paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant='subtitle2'>
                      <Grid container>
                        <Grid item style={{ marginRight: 40 }}>
                          {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                        </Grid>
                        {/* <Grid item style={{ marginRight: 40 }}>
                          Likelihood -{' '}
                          <span className={getProbabilityColor(props, recommendationDetails[recommendation].probability)}>
                            {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                          </span>
                        </Grid>
                  <Grid item>Repair Level - {recommendationsCurrent[issueId][rowActionId].level}</Grid>*/}
                      </Grid>
                    </Typography>
                  }
                  classes={props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                />
              </ListItem>
              <div style={{ marginBottom: 16 }}>
                <Typography
                  variant='body1'
                  style={{
                    marginBottom: 16,
                  }}
                  className={classes.body}
                >
                  {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                </Typography>
              </div>
            </React.Fragment>
          );
        } else {
          if (typeof recommendationsCurrent[issueId] === 'undefined') {
            recommendationsCurrent[issueId] = {};
          }
          if (typeof recommendationsCurrent[issueId][rowActionId] === 'undefined') {
            recommendationsCurrent[issueId][rowActionId] = {
              checked: false,
              level: recommendationDetails[recommendation].actionType,
            };
          }
          return (
            <React.Fragment key={`${recommendation}_recommendation_item`}>
              <ListItem role={undefined} dense style={{ paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant='subtitle2'>
                      <Grid container>
                        <Grid item style={{ marginRight: 40 }}>
                          {!recommendationDetails[recommendation].action ? 'Recommendation not found!' : recommendationDetails[recommendation].action}
                        </Grid>
                        <Grid item style={{ marginRight: 40 }}>
                          Likelihood -{' '}
                          <span className={getProbabilityColor(props, recommendationDetails[recommendation].probability)}>
                            {!recommendationDetails[recommendation].probability ? 'Likelihood not found!' : recommendationDetails[recommendation].probability}
                          </span>
                        </Grid>
                        <Grid item>Repair Level - {recommendationsCurrent[issueId][rowActionId].level}</Grid>
                      </Grid>
                    </Typography>
                  }
                  classes={props.format ? { primary: classes.listPrimaryFormatted } : { primary: classes.listPrimary }}
                />
              </ListItem>
              <div style={{ marginBottom: 16 }}>
                <Typography
                  variant='body1'
                  style={{
                    marginBottom: 16,
                  }}
                  className={classes.body}
                >
                  {!recommendationDetails[recommendation].comment ? 'Recommendation description not found!' : recommendationDetails[recommendation].comment}
                </Typography>
              </div>
            </React.Fragment>
          );
        }
      })}
    </React.Fragment>
  );
}
function DiagnosticDetailsConcise(props) {
  const { classes } = props;

  //const id = props.id;
  //const alarm = props.checklistColor;
  //const showRecommendations = props.showRecommendations;
  //console.log(id);
  const device = props.devices[props.activeDevice];
  //console.log(device);
  //console.log(!device.issues ? null : device.issues.Technician);
  let issueMatch =
    !device.issues || !device.issues.Technician
      ? null
      : device.issues.Technician.find((obj) => {
          return obj.issue_name === props.issue_name;
        });
  let adjIssueName = props.issue_name.replace('/', '_');

  if (
    !!device &&
    !!props.devices[props.activeDevice].activeTestId &&
    !props.devices[props.activeDevice].issues /* || props.devices[props.activeDevice].activeTestId.toString() !== device.testIdHistory[device.testIdHistory.length - 1]*/ &&
    !issueMatch &&
    (!props.device_issue_load ||
      !props.device_issue_load[props.activeDevice] ||
      !props.device_issue_load[props.activeDevice]['Technician'] ||
      !props.device_issue_load[props.activeDevice]['Technician'][adjIssueName] ||
      props.device_issue_load[props.activeDevice]['Technician'][adjIssueName] !== 'empty') /*&& !issueLoad*/ /* || loadedTest !== props.devices[props.activeDevice].activeTestId*/
  ) {
    //issueLoad = true;
    //loadedTest = props.devices[props.activeDevice].activeTestId;
    props.fetchDeviceIssuesByName(props.activeDevice, props.devices[props.activeDevice].activeTestId, !props.alignment ? 'Technician' : 'Control', adjIssueName);
  }
  if (!issueMatch) {
    if (
      !props.device_issue_load[props.activeDevice] ||
      !props.device_issue_load[props.activeDevice][!props.alignment ? 'Technician' : 'Control'] ||
      (props.device_issue_load[props.activeDevice][!props.alignment ? 'Technician' : 'Control'][adjIssueName] !== 'loaded' &&
        props.device_issue_load[props.activeDevice][!props.alignment ? 'Technician' : 'Control'][adjIssueName] !== 'empty')
    ) {
      return <Typography style={{ marginRight: 40 }}>{`Loading ${props.issue_name}...`}</Typography>;
    } else {
      return <Typography style={{ marginRight: 40 }}>{`${props.issue_name} failed to load!`}</Typography>;
    }
  } else {
    let content = (
      <div style={{ marginBottom: 50 }}>
        <Grid container>
          <Grid item>
            <Typography variant='h6' className={classes.recommendationTitle}>
              {!issueMatch.issue_name ? `Title Not Found!` : issueMatch.issue_name}
            </Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            marginBottom: 4,
            marginTop: 10,
          }}
          className={classes.printHide}
        />
        <div
          className={classes.helperSection}
          style={{
            marginTop: 20,
          }}
        >
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Description
            </Typography>
            <Typography variant='body1' className={classes.body}>
              {!issueMatch || !issueMatch.description ? `Description Not Found!` : issueMatch.description}
            </Typography>
          </div>
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Concern Level
            </Typography>
            {!issueMatch || !issueMatch.priority_description ? (
              `Concern Level not listed for this item!`
            ) : (
              <React.Fragment>
                <div key={`${issueMatch.priority}_priority_item`} style={{ marginBottom: 15 }}>
                  <ChecklistIndicator color={functions.numberToColor(issueMatch.priority)} bodyCheckbox></ChecklistIndicator>
                  <Typography style={{ width: '100%' }} className={classes.body}>
                    <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>{functions.numberToColor(issueMatch.priority)}</span>
                    <br></br>
                    {issueMatch.priority_description}
                  </Typography>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className={classes.helperSection}>
          <RenderIssueConcise props={props} issue={issueMatch} />
          {/*renderIssueConcise(props, issueMatch)*/}
        </div>
      </div>
    );
    return content;
  }
}

const mapStateToProps = (state) => ({
  activeDevice: state.optimize.activeDevice,
  devices: state.devices.devices,
  device_issue_load: state.devices.device_issue_load,
  recommendations: state.recommendations,
});

export default connect(mapStateToProps, {
  fetchDeviceIssuesByName,
  fetchActions,
  fetchInvestigations,
  fetchIssueDetails,
  fetchDevices,
  fetchDeviceSpecs,
  fetchDiagnostics,
  setTestStatus,
})(withStyles(styles)(DiagnosticDetailsConcise));
