import React from 'react';

import api from '../../apis/api';
//import { client2 } from '../../apis/api';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import Dropzone from './Dropzone';
import Progress from './Progress';
import WidgetTitle from '../headers/WidgetTitle';
import { Button, Grid, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { AddCircle, Cancel, CheckCircle, CloudCircle, CloudUpload, Delete, DeleteSweep, Error } from '@mui/icons-material';
import InfoPopover from '../popup/InfoPopover';
import UploadDetails from '../helpers/UploadDetails';

//import { Buffer } from 'buffer';
//window.Buffer = window.Buffer || require('buffer').Buffer;

class PrioritizeUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      cleared: false,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.analyzeReponse = this.analyzeReponse.bind(this);
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <WidgetTitle
          title={
            <React.Fragment>
              <Grid container>
                <Grid item style={{ marginTop: -2 }}>
                  <InfoPopover
                    resize
                    title='I’ve done all my testing. How do I get my valve tests on to
              Optimize?'
                    content={<UploadDetails />}
                  />
                </Grid>
                <Grid item>Click to learn how to upload, or visit the FAQ</Grid>
              </Grid>
            </React.Fragment>
          }
        />

        <Grid container spacing={2}>
          <Grid item>
            <Dropzone id='optimize_upload_files_id' onFilesAdded={this.onFilesAdded} disabled={this.state.uploading || this.state.successfullUploaded} />
          </Grid>
          <Grid item>{this.renderActions()}</Grid>
        </Grid>
        <Typography>
          Please provide the following: <br />
          For every data import file, a matching configuration file.
          {/*`Please
        click HERE for template files. CLEAN UP WITH REAL STUFF`*/}
        </Typography>
        {this.state.error && !this.state.errorMessage !== '' ? <Typography color={'error'}>{`${this.state.errorMessage}`}</Typography> : null}
        {this.state.uploading && !this.state.successfullUploaded ? (
          <Typography>
            Uploading tests... during the process please do not leave this page. <br />
            Closing or leaving the page will result in tests failing to upload.
          </Typography>
        ) : null}
        {this.state.cleared && this.state.files.length === 0 ? <Typography>Files Cleared. </Typography> : null}
        {this.state.files.length > 0 && !this.state.uploading && !this.state.successfullUploaded && !this.state.error ? (
          <Typography>Click the upload button to begin the upload process. </Typography>
        ) : null}
        {this.state.files.map((file) => {
          return (
            <Grid key={file.name} container className={classes.uploadProgressWrapper}>
              <Grid item>{this.renderProgressCheck(file)}</Grid>
              <Grid item>
                <Delete
                  className={classes.uploadIcon}
                  onClick={(e) => {
                    this.removeFile(file);
                  }}
                />
              </Grid>
              <Grid item xs={8} key={file.name}>
                <Typography variant='subtitle2' color={!!this.state.uploadProgress[file.name] && this.state.uploadProgress[file.name].state === 'error' ? 'error' : null}>
                  {file.name}
                  {
                    //Message rendering if one exists
                    !!this.state.uploadProgress[file.name] && !!this.state.uploadProgress[file.name].message ? (
                      <i> - {this.state.uploadProgress[file.name].message}</i>
                    ) : //console.log(this.state.uploadProgress[file.name])
                    null
                  }
                </Typography>
                {this.renderProgress(file)}
              </Grid>
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    //if (this.state.uploading || this.state.successfullUploaded) {
    return (
      <React.Fragment>
        <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
      </React.Fragment>
    );
  }

  renderProgressCheck(file) {
    const { classes } = this.props;
    const uploadProgress = this.state.uploadProgress[file.name];
    if (!!uploadProgress && uploadProgress.state === 'error') {
      return (
        <React.Fragment>
          <Error
            className={classes.uploadProgressCheck}
            color='error'
            style={{
              opacity: 1,
            }}
          ></Error>
        </React.Fragment>
      );
    } else if (this.state.uploading) {
      return (
        <React.Fragment>
          <CloudCircle
            className={classes.uploadProgressCheck}
            style={{
              opacity: uploadProgress && uploadProgress.state === 'done' ? 1 : 0.7,
            }}
          ></CloudCircle>
        </React.Fragment>
      );
    } else if (this.state.successfullUploaded) {
      return (
        <React.Fragment>
          <CheckCircle
            className={classes.uploadProgressCheck}
            style={{
              opacity: uploadProgress && uploadProgress.state === 'done' ? 1 : 0.7,
            }}
          ></CheckCircle>
        </React.Fragment>
      );
    } else if (this.state.successfullUploaded === 'false') {
      return (
        <React.Fragment>
          <Cancel
            className={classes.uploadProgressCheck}
            style={{
              opacity: uploadProgress && uploadProgress.state === 'done' ? 1 : 0.7,
            }}
          ></Cancel>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <AddCircle
            className={classes.uploadProgressCheck}
            style={{
              opacity: uploadProgress && uploadProgress.state === 'done' ? 1 : 0.7,
            }}
          ></AddCircle>
        </React.Fragment>
      );
    }
  }
  removeFile(file) {
    let localFiles = this.state.files;

    for (var i = 0; i < localFiles.length; i++) {
      if (localFiles[i] === file) {
        localFiles.splice(i, 1);
      }
    }
    this.setState((prevState) => ({
      files: localFiles,
      error: false,
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      cleared: false,
    }));
  }

  verifyFiles(files) {
    //TODO REPLACE WITH REAL CHECKS
    //SHOULD SHOW WHICH FILES ARE ACTUALLY FAILING IF A SPECIFIC FILE IS FAILING
    let flag = true;

    for (let i = 0; i < files.length; i++) {
      let message = '';
      let localFlag = true;
      if (!files[i].name.toLowerCase().includes('prioritize')) {
        localFlag = false;
        message = 'Invalid File Naming, ';
      }
      if (files[i].type !== 'text/csv') {
        localFlag = false;
        message += 'Invalid File Type, ';
      }
      if (localFlag === false) {
        flag = false;
        this.setState((state) => ({
          ...state,
          uploadProgress: {
            ...state.uploadProgress,
            [files[i].name]: {
              state: 'error',
              //percentage: 90,
              message: message.slice(0, -2),
            },
          },
        }));
      }
    }

    //flag = false; // REMOVE WHEN DONE WITH CSV VALIDATION
    return { flag, message: 'Error' };
  }
  async uploadFiles() {
    this.setState({ error: false, errorMessage: '' });
    const valid = this.verifyFiles(this.state.files);
    if (valid.flag) {
      this.setState({ uploadProgress: {}, uploading: true });
      //REPLACE WITH PROMISES FOR API (SEE OPTIMIZE UPLOAD)
      const promises = [];
      this.state.files.forEach((file) => {
        promises.push(this.sendRequest(file));
      });
      try {
        await Promise.all(promises);
        // console.log('finished');
        //Code for if we continue on a failed individual upload
        if (this.state.error) {
          this.setState({ successfullUploaded: false, uploading: false });
        } else {
          this.setState({ successfullUploaded: true, uploading: false });
        }
      } catch (e) {
        //TODO Not Production ready! Do some error handling here instead...
        this.setState({ successfullUploaded: true, uploading: false });
      }
    } else {
      //TODO ADD ADJUSTMENTS FOR FILE REMOVAL, ECT
      this.setState({
        error: true,
        errorMessage: `Error: Please correct the files below before attemping upload again.`,
        /*cleared: true,
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,*/
      });
    }
  }

  setFileState(state, props) {
    return { ...state };
  }
  async sendRequest(file) {
    try {
      //const url = `http://68.129.207.55:8082/stroke?client=Olin&engineid=false`;
      //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
      // const url = `/devices`;
      //const response = await api.get(url);
      let baseURL = 'https://www.ten-one.app/upload/api/v1/prioritize/upload-csv';
      if (typeof window !== 'undefined' && (window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://localhost:3000')) {
        baseURL = 'https://localhost:9001/api/v1/prioritize/upload-csv';
      }
      if (
        typeof window !== 'undefined' &&
        (window.location.origin === 'http://ten-one.io' ||
          window.location.origin === 'https://ten-one.io' ||
          window.location.origin === 'http://www.ten-one.io' ||
          window.location.origin === 'https://www.ten-one.io')
      ) {
        baseURL = 'https://www.ten-one.io/upload/api/v1/prioritze/upload-csv';
      }
      const data = new FormData();
      data.append('file', file);
      let now = new Date();
      console.log(now.toLocaleTimeString() + ' | starting upload for: ' + file.name);
      const response = await api.post(
        baseURL,
        //'https://localhost:9001/api/v1/upload-csv',

        //'https://68.129.207.55:8082/optimize/upload/api/v1/upload-csv',
        //'https://www.ten-one.app/optimize/upload/api/v1/upload-csv',
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data' /*,
            'Access-Control-Allow-Origin': '*',*/,
          },
          //timeout: 600000, //600s timeout
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            //console.log('onUploadProgress', totalLength);
            //console.log(Math.round((progressEvent.loaded * 100) / totalLength));
            if (totalLength !== null) {
              const copy = { ...this.state.uploadProgress };
              if (progressEvent.loaded / progressEvent.total === 1) {
                copy[file.name] = {
                  state: 'uploading',
                  percentage: 50,
                  message: `Upload Complete. Inserting Data...`,
                };
              } else {
                copy[file.name] = {
                  state: 'uploading',
                  percentage: (progressEvent.loaded / progressEvent.total) * 50,
                  message: `Uploading data...`,
                };
              }
              this.setState({ uploadProgress: copy });
              //this.updateProgressBarValue(Math.round( (progressEvent.loaded * 100) / totalLength ));
            }
            // receive two    parameter endpoint url ,form data
          },
        }
      );
      now = new Date();
      console.log(now.toLocaleTimeString() + ' | upload response recieved for: ' + file.name);
      const copy = { ...this.state.uploadProgress };
      const messageFinal = response.data.message === 'Duplicate File Detected' ? `${response.data.message}...Re-Running Analysis` : `${response.data.message}...Starting Analysis`;
      copy[file.name] = {
        //state: 'done',
        percentage: 90,
        message: messageFinal,
      };
      this.setState({ uploadProgress: copy });

      /*const urlAnalyze = `https://68.129.207.55:8082/optimize/analyze/${response.data.type.toLowerCase()}?client=${
        this.props.auth.client
      }&engineid=false`;*/

      //let responseAnalyze;
      /*if (response.data.type.toLowerCase() === 'profile') {
        setTimeout(async () => {
          responseAnalyze = await client2.get(urlAnalyze);
        }, 2000);
      } else {*/

      //setTimeout(async () => {
      //responseAnalyze = await client2.get(urlAnalyze);
      //}, 60000);

      // }
      //console.log(responseAnalyze);
      // console.log(response);
      if (response.data.type.toLowerCase() === 'config') {
        const messageConfig = `${response.data.message}`;
        copy[file.name] = {
          //state: 'done',
          percentage: 100,
          message: messageConfig,
        };
        this.setState({ uploadProgress: copy });
        return response;
      }
      now = new Date();
      console.log(now.toLocaleTimeString() + ' | analyzeResponse for: ' + file.name);
      //this.analyzeReponse(response.data.type, file.name);

      return response;
      //resolve(req.response);
    } catch (e) {
      /*console.log(e);
      console.log(e.data);
      console.log(e.statusText);
      console.log(e.response);
      console.log(e.message);*/
      const copy = { ...this.state.uploadProgress };
      let message = 'Unknown Error from Server';

      if (!!e.data && !!e.data.message) {
        message = e.data.message;
      } else if (!!e.statustext) {
        message = e.statustext;
      } else if (!!e.response && !!e.response.statusText) {
        message = e.response.statusText;
      } else if (!!e.message) {
        message = e.message;
      }
      //console.log(message);
      //TODO REMOVE WHEN FIXED
      copy[file.name] = {
        state: 'error',
        percentage: 0,
        message,
      };
      this.setState({ uploadProgress: copy, error: true });
      //reject(req.response);
      //
      //IMMEDIATELY HALTS PROGRESS ON ERROR
      //return Promise.reject(e);
      //CONTINUE WITHOUT STOPPING
      //console.log(e);
      return e;
    }
    /*return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: 'pending',
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener('load', (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'done', percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener('error', (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: 'error', percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      const formData = new FormData();
      formData.append('file', file, file.name);

      //TODO Start here to send to the correct spot on our server at :9001
      //req.open('POST', 'https://localhost:9001/upload-csv');

      //req.send(formData);
    });*/
  }
  async sendRequestOld(file) {
    try {
      const messageFinal = `Forced Complete Accepted for ${file.name}.`;

      this.setState((state) => ({
        ...state,
        uploadProgress: {
          ...state.uploadProgress,
          [file.name]: {
            //state: 'done',
            percentage: 90,
            message: messageFinal,
          },
        },
      }));
      let response = { data: { type: 'prioritize_dummy' } };
      this.analyzeReponse(response.data.type, file.name);
      return response;
    } catch (e) {
      const copy = { ...this.state.uploadProgress };
      let message = 'Unknown Error from Server';

      if (!!e.data && !!e.data.message) {
        message = e.data.message;
      } else if (!!e.statustext) {
        message = e.statustext;
      } else if (!!e.response && !!e.response.statusText) {
        message = e.response.statusText;
      } else if (!!e.message) {
        message = e.message;
      }
      //TODO REMOVE WHEN FIXED
      copy[file.name] = {
        state: 'error',
        percentage: 0,
        message,
      };
      this.setState({ uploadProgress: copy, error: true });
      return e;
    }
  }

  async analyzeReponse(type, filename) {
    this.setState((state) => ({
      ...state,
      uploadProgress: {
        ...state.uploadProgress,
        [filename]: {
          state: 'done',
          percentage: 100,
          message: `Processing Complete`,
        },
      },
    }));

    return true; // responseAnalyze;
  }

  renderActions() {
    const { classes } = this.props;
    //console.log(this.state);
    if (this.state.successfullUploaded || this.state.error) {
      return (
        <Button
          variant='outlined'
          color='primary'
          style={{ marginTop: 8 }}
          onClick={() => {
            document.getElementById('optimize_upload_files_id').value = ''; // need to clear the data from the input stored in dropzone, thus we pass it an id up above and use that down here.
            this.setState({
              files: [],
              uploading: false,
              uploadProgress: {},
              successfullUploaded: false,
              error: false,
              cleared: true,
            });
          }}
        >
          <DeleteSweep className={classes.buttonIcon}></DeleteSweep>Clear
        </Button>
      );
    } else {
      return (
        <Button
          style={{ marginTop: 8 }}
          variant='outlined'
          disabled={this.state.files.length === 0 || this.state.uploading}
          onClick={(e) => {
            this.setState({ cleared: false });
            this.uploadFiles(e);
          }}
        >
          <CloudUpload className={classes.buttonIcon}></CloudUpload> Upload
        </Button>
      );
    }
  }

  onFilesAdded(files) {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(PrioritizeUpload));
