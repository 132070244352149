import { SET_FORMAT } from '../actions/types';

const INITIAL_STATE = {
  format: false,
};

const formatReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FORMAT:
      return { ...state, format: action.payload };
    default:
      return state;
  }
};

export default formatReducer;
