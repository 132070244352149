import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Paper, Typography, CircularProgress } from '@mui/material';
import TableComponent from '../charts/Table';
import { styles } from '../styles';
import { connect } from 'react-redux';
import functions from '../../functions/functions';

class TableWidget extends React.Component {
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} style={{ marginBottom: 4, marginTop: this.props.marginTop }}>
        <Paper elevation={3} className={printStyles ? classes.printTablePaper : classes.tablePaper}>
          {!this.props.loading ? (
            <TableComponent
              rows={!this.props.defaultRows ? 5 : this.props.defaultRows}
              rowsPerPageOptions={!this.props.rowsPerPageOptions ? [5, 10, 25] : this.props.rowsPerPageOptions}
              data={this.props.data}
              header={this.props.header}
              type={this.props.type}
              order={this.props.order}
              orderBy={this.props.orderBy}
            />
          ) : (
            <Typography variant='subtitle2' style={{ padding: 16 }}>
              <CircularProgress className={classes.loadIcon} size={15} color='secondary' />
              Loading Table...
            </Typography>
          )}
        </Paper>
      </Grid>
    );
  }
}

TableWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { activeTab: state.optimize.activeTab };
};

export default connect(mapStateToProps, {})(withStyles(styles)(TableWidget));
