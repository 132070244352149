import _ from 'lodash';
import React from 'react';
import Highcharts from 'highcharts';
import Stockcharts from 'highcharts/highstock';
import HC_more from 'highcharts/highcharts-more'; //module
import HighchartsReact from 'highcharts-react-official';
import initializeTheme from '../theme.js';
import { connect } from 'react-redux';
import functions from '../../functions/functions.js';
HC_more(Highcharts); //init module

function customizer(objValue, srcValue) {
  //console.log(objValue, srcValue);
  if (_.isArray(objValue)) {
    return objValue.concat(srcValue);
  }
}

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      format_current: false,
    };
    this.chartCreated = this.chartCreated.bind(this);
    this.processCallback = this.processCallback.bind(this);
    this.internalChart = {};
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!this.refs[this.props.attribute]) {
      const chart = this.refs[this.props.attribute].chart;
      //console.log(chart);
      if (this.props.format !== this.state.format_current) {
        // this sets it so it only resizes when the format has been triggered, not every redraw so as to preserve performance
        chart.setSize();
        this.setState({ format_current: this.props.format });
      }
    }
    //console.log(this.refs);
  }
  chartCreated(chart) {
    this.internalChart = chart;
  }
  processCallback = (chart) => {
    const callback = !this.props.callback ? null : this.props.callback(chart);
    this.chartCreated(chart);
    return callback;
  };
  render() {
    const theme = initializeTheme(this.props.colorMode);
    //console.log(this.internalChart);
    let defaultColors = ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'];
    if (!!this.internalChart.series) {
      this.internalChart.setSize();
    }
    const options = {
      time: {
        useUTC: false,
      },
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        marginTop: 20,
        panning: true,
        panKey: 'shift',
        height: !this.props.chartHeight ? 300 : this.props.chartHeight,
      },
      colors: ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'],
      credits: { enabled: false },
      title: {
        text: '',
        align: 'left',
        style: {
          fontSize: '14px',
          color: theme.palette.text.primary,
        },
      },
      yAxis: { title: { enabled: false } },
    };
    const prioritizeOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        //height: 240,
        marginLeft: 42,
        yAxis: {
          width: 200,
        },
      },
      colors: ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'],
      credits: { enabled: false },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        margin: 8,
        padding: 5,
        width: '100%',
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
            fontFamily: 'roboto',
          },
        },
        itemStyle: { fontWeight: 500 },
      },
      title: { text: '' },
    };
    const valveOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        height: 240,
        marginLeft: 42,
      },
      colors: ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'],
      credits: { enabled: false },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        margin: 8,
        padding: 5,
        width: '100%',
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
            fontFamily: 'roboto',
          },
        },
        itemStyle: {
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      title: { text: '' },
      tooltip: _.merge(this.props.options.tooltip, {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
      }),
      xAxis: _.merge(this.props.options.xAxis, {
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
          //text: 'test',
        },
      }),
      yAxis: _.merge(this.props.options.yAxis, {
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
          //text: 'test',
        },
      }),
    };
    const barOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        height: this.props.chartHeight,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        type: 'column',
      },
      legend: {
        itemStyle: {
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      tooltip: {
        valueSuffix: this.props.suffix,
        headerFormat: '<b>{point.key}</b></br>',
        pointFormat: '<b>{series.name}: <br>{point.y}</b>',
      },
      //colors: ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'],
      credits: { enabled: false },
      title: {
        text: this.props.title,
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 500,
          color: theme.palette.primary.main,
        },
      },
      xAxis: {
        categories: ['Extremely Important', 'Very Important', 'Important', 'Standard', 'Unimportant', 'Unknown'],
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          text: this.props.titleX,
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        stackLabels: {
          //enabled: true,
          align: 'center',
          style: {
            textOutline: 0,
            textShadow: false,
            fontSize: 14,
          },
        },
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
          x: -4,
          format: '{text}' + this.props.suffix,
          text: this.props.titleY,
        },
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}' + this.props.suffix,
            style: {
              textOutline: 0,
              textShadow: false,
              fontSize: 14,
              fontWeight: 500,
              color: theme.palette.text.primary,
            },
          },
          dataGrouping: {
            //enabled: false,
          },
        },
      },
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {},
          },
        ],
      },
    };
    const stackOptions = {
      chart: {
        backgroundColor: '',
        type: 'column',
        marginTop: 35,
        height: this.props.chartHeight,
        style: {
          fontFamily: 'roboto',
        },
        colors: this.props.colors,
      },
      colors: !this.props.colors ? defaultColors : this.props.colors,
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
      },
      xAxis: {
        type: 'category',
        categories: this.props.categories,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          text: 'Number of Devices',
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        column: {
          stacking: 'normal',
          borderColor: theme.palette.tile.primary,
          dataLabels: {
            enabled: false, //true,
            formatter: function () {
              if (this.y > 1) {
                return this.y;
              } else {
                return null;
              }
            },
            style: {
              textOutline: 0,
              textShadow: false,
              fontSize: 14,
              fontWeight: 500,
              color: this.props.colorMode === 'dark' ? '#000' : '#fff',
            },
          },
        },
      },
      legend: {
        verticalAlign: 'top',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        margin: 8,
        padding: 5,
        y: -10,
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
            fontFamily: 'roboto',
          },
        },
        itemStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      credits: { enabled: false },
      title: {
        text: undefined,
      },
    };
    const concernOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        height: this.props.chartHeight,
        type: 'column',
        plotBackgroundColor: null,
      },
      legend: {
        verticalAlign: 'top',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        margin: 8,
        padding: 5,
        y: -10,
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
            fontFamily: 'roboto',
          },
        },
        itemStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      credits: { enabled: false },
      title: {
        text: '',
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        column: {
          borderWidth: 0,
          stacking: this.props.stacking,
          zones: [
            {
              value: 30,
              color: theme.palette.green.color,
            },
            {
              value: 60,
              color: theme.palette.yellow.color,
            },
            {
              color: theme.palette.red.color,
            },
          ],
        },
      },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
      },
      xAxis: {
        type: 'category',
        categories: this.props.categories,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        max: 100,
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          text: 'Concern Score',
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
    };
    const concernShiftOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        height: this.props.chartHeight,
        type: 'column',
        plotBackgroundColor: null,
      },
      legend: {
        verticalAlign: 'top',
        itemMarginBottom: 2,
        itemDistance: 10,
        maxHeight: 60,
        margin: 8,
        padding: 5,
        y: -10,
        alignColumns: false,
        style: {
          '@media print': {
            fontSize: 10,
            fontFamily: 'roboto',
          },
        },
        itemStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
          fontWeight: 500,
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
      },
      xAxis: {
        type: 'category',
        categories: this.props.categories,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      yAxis: {
        max: 100,
        min: -100,
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          text: 'Concern Score',
          style: {
            color: theme.palette.text.primary,
          },
        },
      },
      credits: { enabled: false },
      title: {
        text: '',
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        column: {
          borderWidth: 0,
          //cursor: 'pointer',
          stacking: this.props.stacking,
          /*zones: [
            {
              value: 0,
              color: '#56b260',
            },
            {
              value: 16,
              color: '#e3a61b',
            },
            {
              color: '#cc4848',
            },
          ],*/
        },
      },
    };
    const pieOptions = {
      chart: {
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        height: !this.props.chartHeight ? 300 : this.props.chartHeight,
        type: 'pie',
        plotBackgroundColor: null,
      },
      tooltip: {
        backgroundColor: this.props.colorMode === 'dark' ? '#333' : '#fff',
        style: {
          color: theme.palette.text.primary,
        },
        // headerFormat: '<b>{point.key}</b></br>',
        formatter: function () {
          if (this.point.name === '') {
            return 'Unknown<br>' + Math.round(this.point.percentage) + '%';
          } else {
            return this.point.name + '<br>' + Math.round(this.point.percentage) + '%';
          }
        },
        //pointFormat: '<b>{point.name}: <br>{point.percentage:.1f}%</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderColor: theme.palette.tile.primary,
          innerSize: '50%',
          borderWidth: 2,
          //borderRadius: 5,
          dataLabels: {
            padding: 2,
            style: {
              textOutline: 'none',
              color: theme.palette.text.primary,
            },
            enabled: true,
            formatter: function () {
              if (this.point.name === '') {
                return 'Unknown<br>' + Math.round(this.point.percentage) + '%';
              } else {
                return this.point.name + '<br>' + Math.round(this.point.percentage) + '%';
              }
            },
            // format: ':<br>{point.percentage:.1f}%',
          },
        },
      },
      colors: !this.props.colors ? defaultColors : this.props.colors,
      credits: { enabled: false },
      title: {
        text: this.props.title,
        align: 'left',
        style: {
          fontSize: '14px',
          fontWeight: 500,
          color: theme.palette.primary.main,
        },
      },
    };
    //const [key /*, setKey*/] = React.useState(1);
    //const refresh = () => setKey(key + 1);
    const key = 1;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    let finalOptions = {};
    let newOptions = !this.props.options
      ? {
          series: [
            {
              name: 'Series 1',
              data: [
                [Date.UTC(1970, 10, 25), 0],
                [Date.UTC(1970, 11, 6), 0.25],
                [Date.UTC(1970, 11, 20), 1.41],
                [Date.UTC(1970, 11, 25), 1.64],
                [Date.UTC(1971, 0, 4), 1.6],
                [Date.UTC(1971, 0, 17), 2.55],
                [Date.UTC(1971, 0, 24), 2.62],
                [Date.UTC(1971, 1, 4), 2.5],
                [Date.UTC(1971, 1, 14), 2.42],
                [Date.UTC(1971, 2, 6), 2.74],
                [Date.UTC(1971, 2, 14), 2.62],
                [Date.UTC(1971, 2, 24), 2.6],
                [Date.UTC(1971, 3, 1), 2.81],
                [Date.UTC(1971, 3, 11), 2.63],
                [Date.UTC(1971, 3, 27), 2.77],
                [Date.UTC(1971, 4, 4), 2.68],
                [Date.UTC(1971, 4, 9), 2.56],
                [Date.UTC(1971, 4, 14), 2.39],
                [Date.UTC(1971, 4, 19), 2.3],
                [Date.UTC(1971, 5, 4), 2],
                [Date.UTC(1971, 5, 9), 1.85],
                [Date.UTC(1971, 5, 14), 1.49],
                [Date.UTC(1971, 5, 19), 1.27],
                [Date.UTC(1971, 5, 24), 0.99],
                [Date.UTC(1971, 5, 29), 0.67],
                [Date.UTC(1971, 6, 3), 0.18],
                [Date.UTC(1971, 6, 4), 0],
              ],
            },
          ],
        }
      : this.props.options;
    //let newOptions2 = Object.assign({}, newOptions);

    //console.log(props.options);
    //console.log(newOptions);

    if (this.props.type === 'sparkline') {
      finalOptions = _.mergeWith(options, newOptions, customizer);
    } else if (this.props.type === 'bar') {
      finalOptions = Object.assign(newOptions, barOptions);
    } else if (this.props.type === 'stack') {
      finalOptions = Object.assign(newOptions, stackOptions);
    } else if (this.props.type === 'concern') {
      finalOptions = Object.assign(newOptions, concernOptions);
    } else if (this.props.type === 'concernShift') {
      finalOptions = Object.assign(newOptions, concernShiftOptions);
    } else if (this.props.type === 'pie') {
      finalOptions = Object.assign(newOptions, pieOptions);
    } else if (this.props.type === 'prioritize') {
      let prioritizeOptionsTemp = Object.assign({}, prioritizeOptions); //protect the defaults
      let newOptionsTemp = Object.assign({}, newOptions);
      finalOptions = Object.assign(newOptionsTemp, prioritizeOptionsTemp); //_.mergeWith(newOptions, valveOptions);
      if (this.props.chartHeight !== null) {
        finalOptions.chart.height = this.props.chartHeight;
        finalOptions.chart.width = null;
      }
      //console.log(finalOptions);
      /*finalOptions = Object.assign(newOptions, {
        chart: {
          backgroundColor: '',
          style: {
            fontFamily: 'roboto',
          },
          height: 300,
          type: 'stockChart',
          plotBackgroundColor: null,
        },
      });*/
    } else if (this.props.type === 'valve') {
      let valveOptionsTemp = Object.assign({}, valveOptions); //protect the defaults
      let newOptionsTemp = Object.assign({}, newOptions);

      finalOptions = Object.assign(newOptionsTemp, valveOptionsTemp); //_.mergeWith(newOptions, valveOptions);
      //console.log(finalOptions);
      if (window.innerWidth < 400) {
        finalOptions.chart.height = 350;
      } else if (this.props.chartHeight !== null) {
        finalOptions.chart.height = this.props.chartHeight;
        finalOptions.chart.width = null;
      } else {
        if (this.props.attribute === 'resolution' || this.props.attribute === 'sensitivity' || this.props.attribute === 'stroke') {
          finalOptions.chart.height = 245;
          finalOptions.chart.width = null;
          if (this.props.embed) {
            finalOptions.chart.height = 215;
            finalOptions.chart.width = null;
          } else if (printStyles) {
            finalOptions.chart.height = 200;
            finalOptions.chart.width = null;
          } else {
            finalOptions.chart.height = 255;
            finalOptions.chart.width = null;
          }
        } else {
          if (printStyles) {
            finalOptions.chart.height = 200;
            finalOptions.chart.width = null;
          } else {
            finalOptions.chart.height = 250;
            finalOptions.chart.width = null;
          }
        }
      }
    } else {
      finalOptions = _.mergeWith(options, newOptions, customizer);
    }
    //console.log(this.props);
    //console.log(finalOptions);
    //console.log(finalOptions.chart.width);
    //console.log(finalOptions.chart.height);
    //TODO format uses wrong options, doesn't reset
    //if (this.props.format) {
    //finalOptions = _.mergeWith(options, newOptions, customizer);
    //console.log(this.props.format);
    //console.log(finalOptions);
    //console.log(!this.props.stockChart ? 'Highcharts' : 'Stockcharts');
    let stockBool = this.props.stockChart === true;
    return (
      <HighchartsReact
        key={key}
        ref={this.props.attribute}
        highcharts={stockBool ? Stockcharts : Highcharts}
        constructorType={stockBool ? 'stockChart' : 'chart'}
        options={finalOptions}
        callback={this.processCallback}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    print: state.print.print,
    format: state.format.format,
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {})(Chart);
