import React from 'react';
import { Grid, TextField, Typography, Button, DialogActions, Divider } from '@mui/material';
import CustomSelect from '../../../filters/CustomSelect';
import DateTimePicker from '../../../filters/DateTimePicker';
import withStyles from '@mui/styles/withStyles';
//import functions from '../../../../functions/functions';
import { styles } from '../../../styles';
import { connect } from 'react-redux';
import { fetchIssueTypes, updateChartSelections, addChartSelections, removeChartSelections, clearChartSelections } from '../../../../actions/index';

//!CHANGES
/*
    -Add with 0 events selected adds the default to the selections array
    -If events are already selected, those populate instead
    -Cancelling add event clears the selection array IF AND ONLY IF WAS A DEFAULT OR 0 EVENT SELECTION
    Add selection button instead of "confirming" a date box, adds a "confirmed" date box to the array for adjustment. Then only needs an "add selection button"
  */
class AddEvent extends React.Component {
  constructor(props) {
    super(props);

    let defaultEndDate =
      !this.props.loops || !this.props.loops[this.props.activeLoop] || !this.props.loops[this.props.activeLoop].dateRecent
        ? new Date()
        : new Date(this.props.loops[this.props.activeLoop].dateRecent * 1000);
    let defaultStartDiff = 7;
    let startDate = new Date(defaultEndDate);
    startDate.setDate(startDate.getDate() - defaultStartDiff);
    this.state = {
      defaultStartDiff,
      defaultEndDate,
      //startDate: null,
      //endDate: null,
      eventName: '',
      priority: 'Critical',
      //eventIssue: null,
      //eventType: null,
      //deviceName: null,
      comment: '',
      Issue: null,
      startDate: startDate,
      endDate: defaultEndDate,
      errorMessage: 'Please file in the required fields.',
    };
    //this.handleInputChange = this.handleInputChange.bind(this);
    //this.multiSelectOnChange = this.multiSelectOnChange.bind(this);
    //this.multiSelectOnClose = this.multiSelectOnClose.bind(this);
    //this.updateDateRange = this.updateDateRange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEventSubmit = this.handleEventSubmit.bind(this);
  }

  handleClose(event) {
    if (this.props.eventCount === 0 && this.props.chartSelections.length === 1) {
      this.props.removeChartSelections(0);
    }
    if (!!this.props.handleClose) {
      this.props.handleClose(event);
    }
  }
  async handleEventSubmit() {
    if (!!this.props.handleEventSubmit) {
      //console.log('hi1');
      //console.log(this.props); //this.props.chartSelections
      let loop = this.props.loops[this.props.activeLoop];
      //console.log(loop);
      //console.log(loop.devices.indexOf(this.state.Device[0]));
      //console.log(this.state.Device);
      let deviceIds = [];
      for (let i = 0; i < this.state.Device.length; i++) {
        let deviceIndex = loop.devices.indexOf(this.state.Device[i]);
        deviceIds.push(loop.device_ids[deviceIndex]);
      }
      //console.log(deviceIds);
      //console.log(this.state); //Device, comment, eventName (Label), Issue
      let events = {
        process: this.props.activeLoop,
        dates: this.props.chartSelections,
        deviceIds,
        devices: this.state.Device,
        comment: this.state.comment,
        label: this.state.eventName,
        issue: this.state.Issue,
        priority: this.state.priority,
      };
      //console.log(events);
      let submission = await this.props.handleEventSubmit(events);
      console.log(submission);
      if (submission) {
        this.setState({
          eventName: '',
          priority: 'Critical',
          comment: '',
          Issue: null,
          errorMessage: 'Please file in the required fields.',
        });
      }
    }
  }
  componentDidMount() {
    if (this.props.eventCount === 0) {
      this.addChartSelection();
    }
    let currentRow = {
      id: this.props.eventId,
      //startDate: new Date(this.props.startDate),
      //endDate: new Date(this.props.endDate),
      //eventName: this.props.eventName,
      Issue: !this.props.eventIssue || !this.props.eventIssue.issue ? 'Control Range' : [this.props.eventIssue.issue],
      'Event Type': [this.props.eventType],
      Device: !this.props.devices || !this.props.devices.deviceName ? [] : [this.props.devices.deviceName],
      comment: this.props.comment,
    };
    this.setState(currentRow);
  }
  checkTimeCollision(time) {
    const counts = {};

    for (let i = 0; i < this.props.chartSelections.length; i++) {
      counts[this.props.chartSelections[i][1]] = counts[this.props.chartSelections[i][1]] ? counts[this.props.chartSelections[i][1]] + 1 : 1;
    }
    return counts[time] > 1;
  }
  componentDidUpdate() {
    let timeCollisionFlag = false;
    for (let i = 0; i < this.props.chartSelections.length; i++) {
      timeCollisionFlag = this.checkTimeCollision(this.props.chartSelections[i][1]) || timeCollisionFlag;
    }
    let errorFlag = this.props.chartSelections.length === 0 || this.state.Device.length === 0 || this.state.eventName === '' || timeCollisionFlag;
    if (!errorFlag && this.state.errorMessage !== '') {
      //console.log('no error');
      this.setState({ errorMessage: '' });
    } else if (errorFlag && this.state.errorMessage === '') {
      //console.log('error');
      this.setState({ errorMessage: 'Please fill in required fields and ensure no time selections end at the same time.' });
    }
  }
  handleInputChange = (event) => {
    let id = event.target.name;
    //let currentRow = this.state.currentRow;
    this.setState({ [id]: event.target.value });
  };
  multiSelectOnChange = async (event, value, type) => {
    /*let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    //name = name.replace(' ', '');
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });*/
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    //console.log(name, value);
    this.setState({ [name]: value });
    //console.log('change', event, value, type);
  };
  multiSelectOnClose = async (event, value, type) => {
    /*let obj = { ...this.props.sort.filter };
    let name = !event.target.name ? event.target.id.split('-')[2] : event.target.name;
    //name = name.replace(' ', '');
    obj[name] = value;
    this.props.updateFilter(obj);
    this.setState({ changes: true });*/
    //console.log('close', event, value, type);
  };
  updateChartSelections = (date, index, index2) => {
    let utcDate = Math.floor(new Date(date).getTime() /* / 1000*/);

    this.props.updateChartSelections(utcDate, index, index2);
    //this.setState({ [type]: value });
  };
  addChartSelection = () => {
    //let startDate = new Date(this.state.startDate).getTime();
    //let endDate = new Date(this.state.endDate).getTime();

    let newStartDate = new Date(this.state.defaultEndDate);
    newStartDate.setDate(newStartDate.getDate() - this.state.defaultStartDiff);
    this.props.addChartSelections([newStartDate.getTime(), this.state.defaultEndDate.getTime()]);
    this.setState({ startDate: newStartDate, endDate: this.state.defaultEndDate });
  };
  render() {
    /*if (!!this.props.issuetypes && !!this.props.issuetypes.list) {
      console.log(this.props.issuetypes);
    }*/
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div style={{ marginTop: 16 }}>
          <Grid container spacing={2}>
            {this.props.chartSelections.map((selection, index) => {
              return (
                <Grid item key={`Selection_${index}`}>
                  <Typography variant='subtitle2' style={{ marginBottom: 12 }}>{`Selection ${index + 1}`}</Typography>
                  <Grid container spacing={2}>
                    <Grid item>
                      <DateTimePicker
                        label='Start Date'
                        //disabled={this.state.loading}
                        date={selection[0]}
                        dateFunction={(startDate) => {
                          this.updateChartSelections(startDate, index, 0 /*index in array*/);
                        }}
                      ></DateTimePicker>
                    </Grid>
                    <Grid item>
                      <DateTimePicker
                        label='End Date'
                        date={selection[1]}
                        //disabled={this.state.loading}
                        dateFunction={(endDate) => {
                          this.updateChartSelections(endDate, index, 1 /*index in array*/);
                        }}
                      ></DateTimePicker>
                      {this.checkTimeCollision(selection[1]) ? (
                        <Typography color={'error'} variant='caption' style={{ marginTop: 8, display: 'block' }}>{`Please ensure End Dates are not the same.`}</Typography>
                      ) : null}
                    </Grid>
                    <Grid item>
                      <Button onClick={() => this.props.removeChartSelections(index)} color='primary' style={{ marginTop: 10 }}>
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          {this.props.chartSelections.length === 0 ? <Typography color={'error'} variant='subtitle2' style={{ marginTop: 16 }}>{`Please add a time range selection.`}</Typography> : null}
          <React.Fragment key={`Selection_Add`}>
            <Grid container>
              <Grid item>
                <Button variant='text' style={{ marginTop: 16, marginBottom: 16, marginLeft: -6 }} disableElevation onClick={this.addChartSelection}>
                  Add New Selection
                </Button>
              </Grid>
              <Grid item>
                <Grid item>
                  {!this.props.chartSelections || this.props.chartSelections.length < 1 ? null : (
                    <Button style={{ marginTop: 16 }} variant='text' onClick={this.props.clearChartSelections}>
                      Clear Selection
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
          <Grid>
            {/*<TextField variant='standard' className={classes.textField} label='Device Name' value = {this.props.deviceName}></TextField>*/}
            <TextField
              variant='standard'
              className={classes.textField}
              label='Label'
              name='eventName'
              onChange={this.handleInputChange}
              error={!this.state.eventName || this.state.eventName === ''}
              helperText={!this.state.eventName || this.state.eventName === '' ? 'This is required!' : null}
              value={this.state.eventName}
            ></TextField>
          </Grid>
          <Grid>
            <CustomSelect
              id={null}
              single
              label='Issue'
              name='eventIssue'
              //disabled={this.state.loading}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'

              values={!this.state.Issue ? 'Control Range' : this.state.Issue}
              options={
                !this.props.issuetypes || !this.props.issuetypes.list
                  ? [
                      'Control Range',
                      'Data Validity',
                      'Loop Error',
                      'Low Data Resolution',
                      'Oscillation Amplitude',
                      'Redundant Error',
                      'Saturated Device',
                      'Saturated Valve',
                      'Signal Inconsistency',
                      'Stability',
                      'Stuck Device Values',
                      'Trend Change',
                      'Volatility',
                    ]
                  : this.props.issuetypes.list.sort()
              }
              //options={!this.props.issuetypes || !this.props.issuetypes.list ? [] : this.props.issuetypes.list.sort()}
              value={null}
              helperText={null}
            />
            <CustomSelect
              id={null}
              single
              label='Priority'
              name='priority'
              //disabled={this.state.loading}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'

              values={this.state.priority}
              options={['Good', 'Advisory', 'Critical']}
              //options={!this.props.issuetypes || !this.props.issuetypes.list ? [] : this.props.issuetypes.list.sort()}
              value={null}
              helperText={null}
            />
            {/*<CustomSelect
            id={null}
            label='Event Type'
            name='eventType'
            //disabled={this.state.loading}
            onCloseFunction={this.multiSelectOnClose}
            onChangeFunction={this.multiSelectOnChange}
            values={!this.state['Event Type'] ? [] : this.state['Event Type']}
            options={!this.props.eventType ? [] : [this.props.eventType, 'test1', 'test2', 'test3']}
            //type='unit'
            //values={!this.props.sort.filter['Device Types'] ? [] : this.props.sort.filter['Device Types']}
            //options={!this.props.devicetypes || !this.props.devicetypes.list ? [] : this.props.devicetypes.list.sort()}
            value={null}
            helperText={null}
          />*/}
            <CustomSelect
              id={null}
              label='Device'
              name='device'
              //disabled={this.state.loading}
              errorMessage={!this.state.Device || this.state.Device.length === 0 ? 'This is required!' : null}
              onCloseFunction={this.multiSelectOnClose}
              onChangeFunction={this.multiSelectOnChange}
              //type='unit'
              values={!this.state.Device || this.state.Device.length === 0 ? [] : this.state.Device}
              options={!this.props.activeLoop || !this.props.loops ? [] : this.props.loops[this.props.activeLoop].devices}
              //options={!this.props.devicetypes || !this.props.devicetypes.list ? [] : this.props.devicetypes.list.sort()}
              value={null}
              helperText={null}
            />
          </Grid>
          <TextField
            id='comment'
            label='Comment'
            name='comment'
            multiline
            fullWidth
            rows={4}
            variant='outlined'
            value={this.state.comment}
            onChange={this.handleInputChange}
            style={{ marginTop: 15 }}
            InputLabelProps={{
              classes: {
                root: classes.inputLabelFix,
              },
            }}
          />
        </div>
        <Divider className={classes.dialogDivider}></Divider>
        <DialogActions>
          {/*this.state.errorMessage !== '' ? <Typography color={'error'}>{`${this.state.errorMessage}`}</Typography> : null*/}
          <div>
            <Button onClick={this.handleClose} color='primary'>
              Cancel
            </Button>
            <Button disabled={this.state.errorMessage !== ''} onClick={this.handleEventSubmit} color='primary'>
              Submit
            </Button>
          </div>
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    issuetypes: state.issuetypes,
    chartSelections: state.chartSelections.selections,
    activeLoop: state.prioritize.activeLoop,
    loops: state.loops.loops,
    //datasets: state.datasets.datasets,
    //devices: state.devices.devices,
  };
};
export default connect(mapStateToProps, {
  fetchIssueTypes,
  updateChartSelections,
  addChartSelections,
  removeChartSelections,
  clearChartSelections,
})(withStyles(styles)(AddEvent));
