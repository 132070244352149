import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Paper } from '@mui/material';
import initializeTheme from '../theme.js';

class MaintenanceChart extends React.Component {
  render() {
    const theme = initializeTheme(this.props.colorMode);
    let options = {
      series: [{ data: [1, 2, 3, 300, 1000, 2000] }],
      time: {
        //useUTC: false,
      },
      colors: ['#E19878', '#598FD6', '#C866A7', '#48959B', '#9473C5', '#CEAD9F', '#88A5C9', '#CB8A9F', '#84AFBB', '#A796C1'],
      chart: {
        animation: false,
        //height: null,
        //spacingTop: 35,
        //zoomType: this.state.dragMode === 'zoom' || this.state.dragMode === 'select' ? 'x' : '',
        /*panning:
          this.state.dragMode === 'pan'
            ? {
                enabled: true,
                type: 'x',
              }
            : { enabled: false },*/
        backgroundColor: '',
        style: {
          fontFamily: 'roboto',
        },
        spacingBottom: 15,
        marginRight: 15,
      },
      credits: { enabled: false },
      legend: {
        align: 'left',
        enabled: true,
        squareSymbol: false,
        margin: 0,
        symbolHeight: 8,
        symbolWidth: 8,
        symbolRadius: 4,
        verticalAlign: 'top',
        useHTML: true,
        x: -6,
        y: -6,
        itemStyle: {
          color: theme.palette.text.primary,
          fontSize: '12px',
          fontWeight: '500',
          lineHeight: '18px',
        },
        itemHoverStyle: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#111',
        },
      },
      plotOptions: {
        series: {
          pointInterval: 24 * 3600 * 1000, // one day
          gapSize: 10,
          connectNulls: false,
          dataGrouping: {
            groupPixelWidth: 0.4,
            //enabled: false,
          },
          // allowPointSelect: this.state.dragMode === 'select',
          //enableMouseTracking: false,
        },
      },
      labels: {
        style: { color: this.props.colorMode === 'dark' ? '#fff' : '#333' },
      },
      navigation: {
        enabled: true,
        menuItemStyle: {
          fill: 'none',
          states: {
            hover: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
            },
            select: {
              fill: this.props.colorMode === 'dark' ? '#444' : '#e5e5e5',
            },
          },
        },
      },
      navigator: {
        enabled: true,
        //adaptToUpdatedData: false,
        height: 12,
        margin: 8,
        outlineWidth: 0,
        //series: { data: [] },
        xAxis: { gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee', labels: { enabled: false } },
      },
      scrollbar: {
        enabled: false,
      },
      title: {
        text: 'Maintenance History',
        align: 'left',
        margin: 0,
        style: {
          color: this.props.colorMode === 'dark' ? '#fff' : '#333',
          fontWeight: 500,
          fontSize: '15px',
        },
      },
      rangeSelector: {
        enabled: true,
        inputEnabled: true,
        buttons: [
          {
            type: 'day',
            count: 7,
            text: '7d',
            title: 'View 7 days',
          },
          {
            type: 'day',
            count: 14,
            text: '14d',
            title: 'View 14 days',
          },
          {
            type: 'month',
            count: 1,
            text: '1m',
            title: 'View 1 month',
          },
          {
            type: 'month',
            count: 3,
            text: '3m',
            title: 'View 3 months',
          },
          {
            type: 'month',
            count: 6,
            text: '6m',
            title: 'View 6 months',
            enabled: this.props.analysisRange === 365,
          },
          /*{
            type: 'ytd',
            text: 'YTD',
            title: 'View year to date',
          },*/
          /*{
            type: 'year',
            count: 1,
            text: '1y',
            title: 'View 1 year',
          },*/
          {
            type: 'all',
            text: 'All',
            title: 'View all',
          },
        ],
        height: 2,
        y: -50,
        buttonTheme: {
          fill: 'none',
          style: {
            color: theme.palette.text.primary,
          },
          states: {
            hover: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
              style: {
                color: this.props.colorMode === 'dark' ? '#fff' : '#000',
              },
            },
            select: {
              fill: this.props.colorMode === 'dark' ? '#333' : '#eee',
              style: {
                color: this.props.colorMode === 'dark' ? '#fff' : '#333',
              },
            },
            // disabled: { ... }
          },
        },
        inputStyle: {
          color: this.props.colorMode === 'dark' ? '#6fc2ff' : '#1879C0',
        },
        labelStyle: {
          color: '#999',
        },
      },
      xAxis: {
        type: 'datetime',
        ordinal: false,
        offset: 18,
        //minRange: dataIncrement,
        //plotBands: !plotBands ? [] : plotBands,
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        //min: !!this.props.minExtreme && this.props.minExtreme < dataMinTime ? dataMinTime : this.props.minExtreme, //Date.UTC(2017, 9, 6),
        //max: !!this.props.maxExtreme && this.props.maxExtreme > dataMaxTime ? dataMaxTime : this.props.maxExtreme, //Date.UTC(2017, 10, 30),
      },
      yAxis: {
        //min: seriesData[0].nameType !== 'Primary' ? 0 : null,
        //max: seriesData[0].nameType !== 'Primary' ? 100 : null,
        startOnTick: false,
        endOnTick: false,
        gridLineColor: this.props.colorMode === 'dark' ? '#333' : '#eee',
        labels: {
          style: {
            color: theme.palette.text.primary,
          },
        },
        title: {
          style: {
            color: theme.palette.text.primary,
          },
          x: -4,
          //text: !seriesData[0].label ? null : seriesData[0].label,
          //text: seriesData[0].title === 'Valve' ? '% Open' : seriesData[0].title === 'Flow' ? 'GPH' : seriesData[0].title === 'Pressure' ? 'PSI' : seriesData[0].title === 'Level' ? '%' :seriesData[0].title === 'Analyzer' ? '': 'Concern Score',*/
        },
        //id: seriesData[0].name,
      },
    };
    const { classes } = this.props;
    return (
      <Paper className={classes.paper} elevation={3}>
        <div className={classes.chart}>
          <HighchartsReact highcharts={Highcharts} options={options}></HighchartsReact>
        </div>
      </Paper>
    );
  }
}
MaintenanceChart.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MaintenanceChart);
