import React from 'react';
import WidgetTitle from '../../../headers/WidgetTitle';
import { Collapse, Grid, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
//import { AddBox } from '@mui/icons-material';
import TableWidgetExpandable from '../../../widgets/TableWidgetExpandable';
import functions from '../../../../functions/functions';
import { fetchSites, fetchLocations, fetchApplications, fetchClients, fetchLoopsSummary } from '../../../../actions/index';
import matchFilter from '../../../filters/functions/searchFilter';
import DatasetContent from '../content/DatasetContent';

class DatasetConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterStr: '',
      clickedRow: null,
      rowOpen: false,
      dialogOpen: false,
      rowChange: {},
      loading: false,
    };
  }
  async componentDidMount() {
    if (Object.keys(this.props.loops).length === 0) {
      this.setState({
        loading: true,
      });
      await this.props.fetchLoopsSummary();
      this.setState({
        loading: false,
      });
    }
  }
  handleRowClick = (row) => {
    let loop = this.props.loops[row];
    //console.log(loop);
    //console.log(row + ' Clicked');
    this.setState((state) => ({
      clickedRow: row,
      rowChange: {
        name: loop.processName,
        site: loop.site,
        application: loop.applicationType,
        importance: functions.importanceToString(loop.importance_max),
        applicationOrder: loop.applicationOrder,
      },
    }));
    if (this.state.rowOpen && row === this.state.clickedRow) {
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    } else {
      this.setState((state) => ({ rowOpen: false }));
      this.setState((state) => ({ rowOpen: !state.rowOpen }));
    }
  };
  renderChildRowContent = (row) => {
    const { classes } = this.props;
    //console.log(this.state);
    //console.log(this.state.clickedRow);
    return (
      <Collapse in={this.state.rowOpen && this.state.clickedRow === row.id} unmountOnExit={true} className={classes.collapseBody}>
        <DatasetContent row={row}></DatasetContent>
      </Collapse>
    );
  };
  handleClickOpen = () => {
    //console.log('Click Open');
    this.setState({
      dialogOpen: true,
      rowOpen: false,
      clickedRow: null,
      rowChange: {
        name: '',
        site: '',
        application: '',
        importance: '',
        applicationOrder: 1,
      },
      status: '',
      statusMessage: '',
    });
  };
  handleClose = () => {
    //console.log('Click Close');
    this.setState({
      dialogOpen: false,
      rowChange: {},
      status: '',
      statusMessage: '',
    });
  };
  getDeviceTypeList = (loop) => {
    let obj = {};
    let str = '';
    for (let i = 0; i < loop.deviceTypes.length; i++) {
      if (!(loop.deviceTypes[i] in obj)) {
        obj[loop.deviceTypes[i]] = 1;
      } else {
        obj[loop.deviceTypes[i]]++;
      }
    }
    let objKeys = Object.keys(obj).sort();
    for (let i = 0; i < objKeys.length; i++) {
      str += `${objKeys[i]} (${obj[objKeys[i]]})`;
      if (i + 1 !== objKeys.length) {
        str += ', ';
      }
    }
    //return loop.deviceTypesDistinct;
    return str;
  };

  render() {
    //console.log(this.state.rowChange);
    const { classes } = this.props;
    const headerArr = [
      { id: 'datasetName', label: 'Dataset Name' },
      { id: 'startDate', label: 'Start Date' },
      { id: 'endDate', label: 'End Date' },
      { id: 'recent', label: 'Recent Data' },
    ];

    let dataKeys = Object.keys(this.props.loops);
    let data = [];
    for (let i = 0; i < dataKeys.length; i++) {
      let key = dataKeys[i];
      //let loop = this.props.loops[key];
      data.push({
        id: key,
        datasetName: '2010 Data',
        startDate: '10/1/2010',
        endDate: '11/1/2010',
        recent: 'No',
      });
    }
    data = matchFilter(data, this.state.filterStr);
    //console.log(data);
    if (this.props.permission > 30) {
      //TODO Forcefull link to another section?
      return <React.Fragment>You do not have permission to view this module</React.Fragment>;
    }
    return (
      <React.Fragment>
        <WidgetTitle title='Datasets'></WidgetTitle>
        <Grid container justifyContent='space-between' className={classes.printHide}>
          <Grid item>
            <TextField
              variant='outlined'
              InputProps={{ classes: { root: classes.inlineInput } }}
              className={classes.searchField}
              placeholder='Search'
              onChange={(event) => {
                this.setState({ filterStr: event.target.value });
              }}
              value={this.state.filterStr}
            />
          </Grid>
          {/*<Grid item>
            <Button variant='outlined' size='medium' color='primary' onClick={this.handleClickOpen} className={classes.buttonFilterRight} style={{ marginBottom: 8 }}>
              <AddBox className={classes.buttonIcon}></AddBox>Add New Loop
            </Button>
            </Grid>*/}
        </Grid>
        <Typography variant='body2' style={{ marginBottom: 4 }} className={classes.printHide}>
          Click any row to expand
        </Typography>
        <TableWidgetExpandable
          data={data}
          header={headerArr}
          rows={25}
          type='deviceConfig'
          handleClick={this.handleRowClick}
          renderChildRowContent={this.renderChildRowContent}
          rowOpen={this.state.rowOpen}
          loading={this.state.loading}
        ></TableWidgetExpandable>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    realms: state.auth.realms,
    current_realm: state.auth.current_realm,
    permission: state.auth.view,
    loops: state.loops.loops,
    sites: state.sites,
    locations: state.locations,
    clients: state.clients,
    applications: state.applications,
  };
};

export default connect(mapStateToProps, {
  //fetchDeviceImprovements,
  fetchSites,
  fetchLocations,
  fetchClients,
  fetchApplications,
  fetchLoopsSummary,
})(withStyles(styles)(DatasetConfig));
