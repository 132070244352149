import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import { Grid, Typography } from '@mui/material';
import initializeTheme from '../theme';
import { connect } from 'react-redux';
import { styles } from '../styles';
import InfoPopover from '../popup/InfoPopover';
import functions from '../../functions/functions';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
//import alignmentArray from '../../arrays/optimize/alignment';

class OptimizeProgress extends React.Component {
  state = {
    completed: this.props.alignment,
    buffer: 0,
  };
  processProgress() {
    if (this.props.alignmentTarget < 0) {
      return {
        completed: (this.props.alignment / this.props.alignmentMax) * 100,
        buffer: ((this.props.alignment - this.props.alignmentShift) / this.props.alignmentMax) * 100,
        alignmentShift: this.props.alignmentShift,
      };
    } else {
      return {
        completed: (((this.props.alignment - this.props.alignmentShift) / this.props.alignmentMax) * 100) / 2,
        buffer: ((this.props.alignment / this.props.alignmentMax) * 100) / 2,
        alignmentShift: this.props.alignmentShift,
      };
    }
  }
  renderPerformance() {
    const { classes } = this.props;
    //if (typeof this.props.alignment === 'undefined') {
    if (!this.props.alignment) {
      return (
        <Typography variant='subtitle2' className={classes.alignment}>
          N/A
        </Typography>
      );
    } else {
      return (
        <Typography variant='subtitle2' className={classes.alignment}>
          {this.props.alignmentTitle}
        </Typography>
      );
    }
  }
  renderCompare() {
    const { classes } = this.props;
    if (!!this.props.compareTitle) {
      return <Typography variant='subtitle2' className={`${classes.grayText} ${classes.compare}`}>{`Compared: ${this.props.compareTitle}`}</Typography>;
    }
  }
  renderShift() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    if (this.props.alignmentShift > 0) {
      return (
        <Grid container>
          <Grid>
            <Typography variant='subtitle2' style={theme.palette.blue}>
              ({this.props.alignmentShift})
            </Typography>
          </Grid>
          <Grid>
            <TrendingUp className={classes.shiftArrow} style={theme.palette.blue}></TrendingUp>
          </Grid>
        </Grid>
      );
    } else if (this.props.alignmentShift < 0) {
      return (
        <Grid container>
          <Grid>
            <Typography variant='subtitle2' style={theme.palette.pink}>
              ({this.props.alignmentShift})
            </Typography>
          </Grid>
          <Grid>
            <TrendingDown className={classes.shiftArrow} style={theme.palette.pink}></TrendingDown>
          </Grid>
        </Grid>
      );
    } else if (this.props.alignmentShift === 0) {
      //return <Typography variant='subtitle2'>(No Shift)</Typography>;
      return;
    } else return;
    //}
  }
  renderTarget() {
    //const { classes } = this.props;
    if (!this.props.alignmentTarget || this.props.alignmentTarget === null) {
      return null;
    } else {
      return <Typography variant='subtitle2'>{this.props.alignmentTarget === null ? `` : `Target - ${this.props.alignmentTarget}`}</Typography>;
    }
  }
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    let {
      completed,
      buffer,
      alignmentShift,
      //alignment,
    } = this.processProgress(this.props);
    let barColorPrimary;

    let colorCheck = alignmentShift < 0 ? completed : buffer;
    let colorValue;
    if (colorCheck < 30) {
      barColorPrimary = classes.greenBG;
      colorValue = 'Green';
    } else if (colorCheck <= 50) {
      barColorPrimary = classes.yellowBG;
      colorValue = 'Yellow';
    } else {
      barColorPrimary = classes.redBG;
      colorValue = 'Red';
    }

    let colorPrimary;
    if (completed > 50) {
      colorPrimary = barColorPrimary;
    } else {
      colorPrimary = classes.clearBG;
    }
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <div className={classes.progressWrapper}>
        <Grid container>
          {printStyles ? null : (
            <Grid item style={{ marginTop: -3 }}>
              <InfoPopover resize title={this.props.label} usePerformanceTabs type='performance' colorValue={colorValue} id={this.props.alignmentType} />
            </Grid>
          )}
          <Grid item>
            <Typography style={theme.palette.blue} variant='subtitle2'>
              {this.props.label}
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent='space-between' className={classes.progressText}>
          <Grid item>
            <Grid container>
              <Grid item>{this.renderPerformance()}</Grid>
              <Grid item className={classes.shift}>
                {this.renderShift()}
              </Grid>
              <Grid item>{this.renderCompare()}</Grid>
            </Grid>
          </Grid>
          <Grid item>{this.renderTarget()}</Grid>
        </Grid>
        {
          // todo Make sure shift shows if the progress > 100
        }
        {isNaN(completed) ? null : (
          <LinearProgress
            //value={completed > 100 ? 100 : completed}
            value={completed > 50 ? 50 : completed}
            variant='buffer'
            //valueBuffer={buffer}
            valueBuffer={completed < 50 ? 50 : completed > 100 ? 100 : completed}
            classes={{
              bar1Buffer: classes.marker,
              bar2Buffer: classes.marker,
              barColorPrimary: barColorPrimary,
              colorPrimary: colorPrimary,
            }}
          />
        )}
        <div className={classes.guide} />
      </div>
    );
  }
}

OptimizeProgress.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(OptimizeProgress));
