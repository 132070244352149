import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Grid, Typography, Collapse, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

class FieldContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { moreInfo: false };
  }
  handleMoreInfo = () => {
    this.setState({ moreInfo: !this.state.moreInfo });
  };
  renderField(name, value) {
    const { classes } = this.props;
    return (
      <Grid container className={classes.field}>
        <Grid item xs={6} className={classes.fieldName}>
          <Typography variant='subtitle2'>{name}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.fieldValue}>
          <Typography variant='body1'>{value}</Typography>
        </Grid>
      </Grid>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' color='secondary' style={{ marginBottom: 5 }}>
          Customer Information
        </Typography>
        {this.renderField('Field Name', 'Field Value')}
        <div
          onClick={() => {
            this.handleMoreInfo();
          }}
          style={{ marginBottom: 10, marginTop: 20 }}
          className={classes.bodyHeaderSelect}
        >
          <Typography variant='subtitle1' color='secondary'>
            Additional Information
          </Typography>
          <Tooltip arrow placement='top' title={this.state.moreInfo ? 'Collapse' : 'Expand'} aria-label={this.state.moreInfo ? 'Collapse' : 'Expand'}>
            <IconButton style={{ marginTop: -1 }} size='large'>
              {this.state.moreRecommendations ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown className={classes.inactiveArrow}></KeyboardArrowDown>}
            </IconButton>
          </Tooltip>
          <Typography variant='caption'>Click to view missing or incomplete information from this report</Typography>
        </div>
        <Collapse style={{ marginLeft: 8 }} in={this.state.moreInfo}>
          {this.renderField('Field Name', 'Field Value')}
        </Collapse>
      </React.Fragment>
    );
  }
}

FieldContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FieldContent);
