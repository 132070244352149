import React from 'react';
//import Grid from '@mui/material/Grid';
import {
  Grid,
  /*Input,
  Tooltip,
  TableSortLabel,
  TableRow,
  TablePagination,
  TableHead,
  TableCell,
  TableBody,
  Table,*/
  IconButton,
  Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

class ExpandableSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  handleOpen(e) {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container onClick={(e) => this.handleOpen(e)} className={classes.expansionTab}>
          <Grid item>
            <Typography variant='subtitle2'>{this.props.title}</Typography>
            {this.state.open ? (
              <Typography variant='caption' className={classes.helperText}>
                Click to Collapse
              </Typography>
            ) : (
              <Typography variant='caption' className={classes.helperText}>
                Click to Expand
              </Typography>
            )}
          </Grid>
          <Grid item>
            <IconButton style={{ marginTop: -5 }} color='inherit' size='large'>
              {this.state.open ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown></KeyboardArrowDown>}
            </IconButton>
          </Grid>
          {/*<div className={classes.expansionTabBorder}></div>*/}
        </Grid>
        {!this.state.open ? null : this.props.content}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ExpandableSection);
