import api from '../apis/api';
//import functions from '../functions/functions';
import {
  API_ERROR,
  APPROVE_IMPROVEMENT,
  DELETE_IMPROVEMENT,
  CLEAR_STATE,
  CLEAR_DEVICE,
  CLEAR_DEVICE_ISSUES_IMPROVEMENTS,
  OPTIMIZE_SET_ACTIVE_TAB,
  OPTIMIZE_SET_ACTIVE_LOOP,
  OPTIMIZE_SET_ACTIVE_DEVICE,
  OPTIMIZE_SET_ACTIVE_TEST,
  OPTIMIZE_SET_COMPARE_DEVICE,
  OPTIMIZE_SET_COMPARE_FLAG,
  OPTIMIZE_SET_COMPARE_TEST,
  FETCH_APPLICATIONS,
  FETCH_IMPORTANCES,
  FETCH_ISSUE_TYPES,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
  FETCH_DATASETS,
  FETCH_SITES,
  FETCH_CLIENTS,
  FETCH_DEVICE_TYPES,
  DELETE_DEVICES,
  DELETE_LOOPS,
  FETCH_LOOP,
  FETCH_LOOPS_ARCHIVE,
  //FETCH_LOOP_DEVICES,
  FETCH_LOOPS,
  FETCH_USER_REALMS,
  FETCH_USER_PROFILE,
  UPDATE_PRESET,
  //FETCH_VARIABLES,
  FETCH_DEVICES,
  FETCH_DEVICE_SPECS,
  SET_DEVICE_SUMMARY_STATUS,
  SET_LOOP_SUMMARY_STATUS,
  //FETCH_DEVICE_COMPARE_TESTS,
  FETCH_DEVICE_IMPROVEMENTS,
  FETCH_DEVICE_ISSUES,
  SET_DEVICE_ISSUE_LOAD,
  FETCH_DEVICE_HISTORY,
  FETCH_ACTIONS,
  FETCH_INVESTIGATIONS,
  FETCH_ISSUES_DETAILS,
  FETCH_TEST,
  //FETCH_PRIORITIZE_VARIABLE,
  //SET_LOOP_VARIABLE_STATUS,
  FETCH_DIAGNOSTICS,
  FETCH_PRIORITIZE_DIAGNOSTICS,
  SET_TEST_STATUS,
  UPDATE_LOOP_COUNT,
  //UPDATE_LOOP_SUMMARY,
  UPDATE_DEVICE_COUNT,
  FETCH_SORT,
  UPDATE_SORT,
  UPDATE_CONCERN,
  UPDATE_DATE_RANGE,
  UPDATE_ACTION_RANGE,
  UPDATE_ANALYSIS_RANGE,
  UPDATE_FILTER,
  SET_TABLE_OFFSET,
  SET_TABLE_PAGE_SIZE,
  SET_TABLE_PAGE,
  FETCH_ACTION_ITEMS,
  FETCH_EVENTS,
  FETCH_SHUTDOWNS,
  SET_MODULE,
  PRIORITIZE_SET_ACTIVE_TAB,
  PRIORITIZE_SET_ACTIVE_LOOP,
  PRIORITIZE_SET_ACTIVE_DEVICE,
  PRIORITIZE_TOGGLE_ACTIVE_VARIABLE,
  PRIORITIZE_CLEAR_ACTIVE_VARIABLES,
  PRIORITIZE_ADD_ACTIVE_VARIABLE,
  PRIORITIZE_REMOVE_ACTIVE_VARIABLE,
  RELIABILITY_SET_ACTIVE_TAB,
  RELIABILITY_SET_ACTIVE_LOOP,
  SIGN_IN,
  SIGN_OUT,
  SET_AUTH_STATE,
  REPORTS_SET_ACTIVE_TAB,
  REPORTS_SET_ACTIVE_REPORT,
  ROADMAP_SET_ACTIVE_TAB,
  CONFIG_SET_ACTIVE_TAB,
  SUPPORT_SET_ACTIVE_TAB,
  SUPPORT_SET_ACTIVE_OPTIMIZE,
  SUPPORT_SET_ACTIVE_PRIORITIZE,
  SET_ACTIVE_NAV,
  GET_IMPROVEMENT_REPORT,
  GET_SUMMARY_REPORT,
  GET_PORTFOLIO_REPORT,
  GET_PRIORITIZE_SUMMARY_REPORT,
  SET_COMPARATOR_REPORT,
  SET_PRINT,
  SET_FORMAT,
  CLEAR_FILTER,
  UPLOAD_SET_ACTIVE_TAB,
  SET_COLOR_MODE,
  ADD_CHART_SELECTIONS,
  REMOVE_CHART_SELECTIONS,
  REPLACE_CHART_SELECTIONS,
  CLEAR_CHART_SELECTIONS,
  UPDATE_CHART_SELECTION,
  CHANGE_EVENT_SELECTIONS,
  CHANGE_EVENT_SELECTIONS_CONSOLIDATED,
} from './types';

// TODO ERROR HANDLING FOR RESPONSE TYPES [CURRENTLY 401 UNAUTHORIZED] - HANDLE SERVER SIDE? - CLEAN UP JWT STRAT RESPONSE

// TODO NEED TO DO REDUCER TO RESET STATE ON LOGOUT - https://alligator.io/redux/reset-state-redux/
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store

export const signIn = (data) => (dispatch) => {
  //const ignoreAuth = true;
  const ignoreAuth = false;
  if (ignoreAuth) {
    dispatch({ type: SIGN_IN, payload: 'test_user' });
  } else {
    api
      .post('auth', data)
      .then((res) => {
        dispatch({ type: SET_COLOR_MODE, payload: res.data.color_mode });
        dispatch({ type: SIGN_IN, payload: res.data });
        dispatch({ type: SET_AUTH_STATE, payload: true });
      })
      .catch((err) => {
        //console.log(err.response);
        if (err.response.status === 401) {
          dispatch({ type: SET_AUTH_STATE, payload: false });
        }
        //  console.log('require sign in!');
        dispatch({ type: API_ERROR, payload: err });
        //history.push('/login');
        //}
      });
    ///await api.post('auth', data);
  }
};

export const verifyAuth = (data) => (dispatch) => {
  api
    .get('verifyAuth')
    .then((res) => {
      dispatch({ type: SIGN_IN, payload: res.data });
      dispatch({ type: SET_COLOR_MODE, payload: res.data.color_mode });
    })
    .catch((err) => {
      //TODO FIX THIS SO THAT API ERRORS ONLY THROW WHEN API IS IN ERROR
      if (!err || (err.response && err.response.status === 401)) {
        //Failed Authorization, not signed in previously
        dispatch({ type: SIGN_OUT }); //sign out sets the isSignedIn to False
      }
      //console.log(err);
      //Do Nothing because you are simply trying to sign in on the users behalf, an error is outcome if not logged in.
      dispatch({ type: API_ERROR, payload: !err ? 'Unknown' : err });
    });
  ///await api.post('auth', data);
};

export const signOut = () => async (dispatch) => {
  //const response = await api.get('logout');
  await api.get('logout');
  // if (response.status === 200) {
  dispatch({
    type: SIGN_OUT,
  });
  // } else {
  //   console.log(response);
  //   console.log(JSON.stringify(response));
  // }
};

export const setModule = (current_module) => (dispatch) => {
  dispatch({ type: SET_MODULE, payload: current_module });
};

export const setActiveNav = (activeNav) => (dispatch) => {
  dispatch({ type: SET_ACTIVE_NAV, payload: activeNav });
};

export const fetchLocation = (id, module) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    const response = await api.get(`/${currentModule}/locations/${id}`);
    // if (response.status === 200) {
    dispatch({ type: FETCH_LOCATION, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLocations = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/locations?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_LOCATIONS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchApplications = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/applications?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_APPLICATIONS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchImportances = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/importances?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_IMPORTANCES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchIssueTypes = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/issuetypes?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_ISSUE_TYPES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDatasets = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/datasets?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_DATASETS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchClients = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/clients?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_CLIENTS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchSites = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/sites?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_SITES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceTypes = (module, filters) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (getState().currentModule.currentModule === 'Prioritize' && !module) {
      currentModule = 'prioritize';
    } else if ((getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') && !module) {
      currentModule = 'optimize';
    }
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/devicetypes?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICE_TYPES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchEvents = () => async (dispatch) => {
  try {
    const url = `/prioritize/events/`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_EVENTS, payload: response.data.events });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopActionItems = (loopId, filters, timestamp) => async (dispatch) => {
  try {
    //TODO
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = !loopId ? `/prioritize/actions/summary?${suffix}${timestampUrl}` : `/prioritize/actions/${loopId}?${timestampUrl}`;
    //const url = `/prioritize/actions/summary`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_ACTION_ITEMS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopEvents = (loopId, filters, timestamp) => async (dispatch) => {
  try {
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/events/${loopId}?${suffix}${timestampUrl}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_EVENTS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopShutdowns = (loopId, filters, timestamp) => async (dispatch) => {
  try {
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/shutdowns/${loopId}?${suffix}${timestampUrl}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_SHUTDOWNS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceShutdowns = (deviceId) => async (dispatch) => {
  try {
    const url = `/prioritize/shutdowns/device/${deviceId}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_SHUTDOWNS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoop = (loopId, params, timestamp) => async (dispatch) => {
  //console.log(loopId);
  try {
    params = !params ? {} : params;
    let suffix = Object.keys(params)
      .map((key, index) => {
        return !params[key] || typeof params[key] === 'undefined' || params[key] === '' || params[key].length === 0
          ? ``
          : `${index === 0 ? `` : '&'}${key.toLowerCase().replace(' ', '')}=${params[key]}`;
      })
      .join('');
    /*const url = `/${currentModule}/loops?offset=${offset}&perPage=${rowsPerPage}${
        typeof sort === 'undefined' || sort === '' ? `` : `&sortBy=${sort}`
      }${suffix}`;*/
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/loops/${loopId}?${suffix}${timestampUrl}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_LOOP, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopDevices = (loopId, params, timestamp) => async (dispatch) => {
  //console.log(loopId);
  try {
    params = !params ? {} : params;
    let suffix = Object.keys(params)
      .map((key, index) => {
        return !params[key] || typeof params[key] === 'undefined' || params[key] === '' || params[key].length === 0
          ? ``
          : `${index === 0 ? `` : '&'}${key.toLowerCase().replace(' ', '')}=${params[key]}`;
      })
      .join('');
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/loops/${loopId}/devices?${suffix}${timestampUrl}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopDevicesSummary = (params) => async (dispatch) => {
  try {
    params = !params ? {} : params;
    let suffix = Object.keys(params)
      .map((key, index) => {
        return !params[key] || typeof params[key] === 'undefined' || params[key] === '' || params[key].length === 0
          ? ``
          : `${index === 0 ? `` : '&'}${key.toLowerCase().replace(' ', '')}=${params[key]}`;
      })
      .join('');
    const url = `/prioritize/devices/summary?${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const setLoopsSummaryStatus = (status) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_LOOP_SUMMARY_STATUS, payload: status });
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopsSummary = (module, params, sort) => async (dispatch, getState) => {
  try {
    let currentModule = !module ? 'prioritize' : module;
    if (!module) {
      if (getState().currentModule.currentModule === 'Prioritize') {
        currentModule = 'prioritize';
      } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
        currentModule = 'optimize';
      }
    }
    params = !params ? {} : params;
    let suffix = Object.keys(params)
      .map((key, index) => {
        return !params[key] || typeof params[key] === 'undefined' || params[key] === '' || params[key].length === 0
          ? ``
          : `${index === 0 ? `` : '&'}${key.toLowerCase().replace(' ', '')}=${params[key]}`;
      })
      .join('');
    const url = `/${currentModule}/loops?${typeof sort === 'undefined' || sort === '' ? `` : `&sortBy=${sort}`}&${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_LOOPS, payload: response.data.loops });
    //dispatch({ type: UPDATE_LOOP_COUNT, payload: response.data.count });
    dispatch({ type: SET_LOOP_SUMMARY_STATUS, payload: 'loaded' });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
    dispatch({ type: SET_LOOP_SUMMARY_STATUS, payload: 'error' });
  }
};

export const fetchLoops = (offset, rowsPerPage, sort, filters, timestamp, archive) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log(sort);
    //console.log(filters);
    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/${currentModule}/loops?offset=${offset}&perPage=${rowsPerPage}${typeof sort === 'undefined' || sort === '' ? `` : `&sortBy=${sort}`}${suffix}${timestampUrl}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    if (archive) {
      dispatch({ type: FETCH_LOOPS_ARCHIVE, payload: response.data.loops });
    } else {
      dispatch({ type: FETCH_LOOPS, payload: response.data.loops });
      dispatch({ type: UPDATE_LOOP_COUNT, payload: response.data.count });
    }
    //dispatch({ type: UPDATE_LOOP_SUMMARY, payload: response.data.summary });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const deleteLoops = () => (dispatch) => {
  dispatch({ type: DELETE_LOOPS, payload: {} });
};

export const deleteDevices = () => (dispatch) => {
  dispatch({ type: DELETE_DEVICES, payload: {} });
};

//FETCH VARIABLES
/*export const fetchVariables = (processId, deviceId) => async dispatch => {
  try {
    console.log('variables');
    const url = `/devices/${processId}/${deviceId}/variables`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    console.log(response);

    //if (response.status === 200) {
    //REMOVE dispatch({ type: FETCH_VARIABLES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};*/

export const fetchOptimizeDevices = (offset, rowsPerPage, sort, filters) => async (dispatch, getState) => {
  //console.log('FETCHING DEVICES', filters);
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }

    filters = !filters ? {} : filters;
    let suffix = Object.keys(filters)
      .map((key) => {
        if (key.toLowerCase() === 'range') {
          return `&startDate=${filters[key].startDate}&endDate=${filters[key].endDate}`;
        }
        return !filters[key] || typeof filters[key] === 'undefined' || filters[key] === '' || filters[key].length === 0 ? `` : `&${key.toLowerCase().replace(' ', '')}=${filters[key]}`;
      })
      .join('');
    const url = `/${currentModule}/devices?offset=${offset}&perPage=${rowsPerPage}${typeof sort === 'undefined' || sort === '' ? `` : `&sortBy=${sort}`}${suffix}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICES, payload: response.data.devices });
    dispatch({ type: UPDATE_DEVICE_COUNT, payload: response.data.count });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceByTest = (processId, testId) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/test/${testId}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;

    const response = await api.get(url);
    dispatch({ type: CLEAR_DEVICE_ISSUES_IMPROVEMENTS, payload: processId });
    //await functions.delay(1000);

    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {

    dispatch({ type: FETCH_DEVICES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchRealms = () => async (dispatch, getState) => {
  try {
    const url = `getrealms`;

    const response = await api.get(url);

    dispatch({ type: FETCH_USER_REALMS, payload: response.data });
  } catch (err) {
    if (err.status === 429) {
      //do nothing for now
    } else {
      console.log(err);
    }
  }
};

export const setRealm = (realm) => async (dispatch, getState) => {
  try {
    let currentModule = '';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    const url = `setrealm`;

    await api.post(url, { current_realm: realm });

    await dispatch({ type: CLEAR_STATE, payload: null });
    window.location.href = `/${currentModule}`;
    //relative to domain
  } catch (err) {
    console.log(err);
  }
};

/*export const fetchDeviceCompareTests = (processId, testId) => async (
  dispatch,
  getState
) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' ||
    getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/test/${testId}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICE_COMPARE_TESTS, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};*/

export const fetchUserProfile = (userId) => async (dispatch, getState) => {
  try {
    const url = `/user`;
    const response = await api.get(url);
    //console.log(response.data);
    dispatch({ type: FETCH_USER_PROFILE, payload: response.data });
  } catch (err) {
    console.log(err);
  }
};

export const fetchDevices = (processId, moduleOverride) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (!!moduleOverride) {
      currentModule = moduleOverride;
    } else if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'FSR' || getState().reports.activeTab === 'FSR') {
      currentModule = 'FSR';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICES, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDevicesSummary = (module) => async (dispatch, getState) => {
  try {
    //dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: 'loading' });
    //TODO FIX REPORTS DEFAULT MODULE
    let currentModule = 'optimize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'FSR' || getState().reports.activeTab === 'FSR') {
      currentModule = 'FSR';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    currentModule = !module ? currentModule : module;
    //console.log('devices sum');
    const url = `/${currentModule}/devices/summary/`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    //console.log('sending');
    const response = await api.get(url);
    //console.log(response === 'Unauthorized');
    //console.log(response.response);
    // TODO FETECH VARIABLES CREATION
    // if (response.status === 200) {
    dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: true });
    //} else {

    //}
    dispatch({ type: FETCH_DEVICES, payload: response.data.devices });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: 'error' });
    console.log(err);
  }
};

export const fetchDevicesSummaryVerbose = (module) => async (dispatch, getState) => {
  try {
    //dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: 'loading' });
    //TODO FIX REPORTS DEFAULT MODULE
    let currentModule = 'optimize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    currentModule = !module ? currentModule : module;
    //console.log('devices sum');
    const url = `/${currentModule}/devices/summary/verbose`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    //console.log('sending');
    const response = await api.get(url);
    //console.log(response === 'Unauthorized');
    //console.log(response.response);
    // TODO FETECH VARIABLES CREATION
    // if (response.status === 200) {
    dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: true });
    //} else {

    //}
    dispatch({ type: FETCH_DEVICES, payload: response.data.devices });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: 'error' });
    console.log(err);
  }
};
export const setDevicesSummaryStatus = (status) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_DEVICE_SUMMARY_STATUS, payload: status });
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceSpecs = (processId) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/specs`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICE_SPECS, payload: response.data });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

//visually change the redux data to match what went to server
export const approveImprovement =
  (improvementId, action, contact, email, finalType, improvementType = 'Technician', loop) =>
  async (dispatch, getState) => {
    try {
      let currentModule = 'prioritize';
      if (getState().currentModule.currentModule === 'Prioritize') {
        currentModule = 'prioritize';
      } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
        currentModule = 'optimize';
      }
      const devices = currentModule === 'optimize' ? getState().devices.devices : getState().loops.loops;
      const deviceId = currentModule === 'optimize' ? getState().optimize.activeDevice : getState().prioritize.activeLoop;
      let device = !deviceId ? devices[loop] : devices[deviceId];
      let improvements = currentModule === 'optimize' ? device.improvements[improvementType] : device.improvements;
      /*let date = new Date();
    let mmddyyyy =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear();*/
      for (let i = 0; i < improvements.length; i++) {
        if (improvements[i].id === improvementId) {
          if (action) {
            improvements[i][`${finalType}By`] = contact;
            improvements[i][`${finalType}ByEmail`] = email;

            improvements[i][`${finalType}ByUser`] = getState().auth.userId;
            improvements[i][`${finalType}`] = 1;
            improvements[i][`${finalType}ByDate`] = Math.floor(new Date().getTime() / 1000);
          } else {
            //improvements[i][`${finalType}By`] = null;
            //improvements[i][`${finalType}ByUser`] = null;
            improvements[i][`${finalType}`] = null;
            //improvements[i][`${finalType}ByDate`] = null;
          }
        }
      }
      if (currentModule === 'optimize') {
        device.improvements[improvementType] = improvements;
      } else {
        device.improvements = improvements;
      }
      //console.log(device);
      dispatch({ type: APPROVE_IMPROVEMENT, payload: { device, deviceId } });
    } catch (err) {
      console.log(err);
    }
  };

export const deleteImprovement =
  (improvementId, improvementType = 'Technician') =>
  async (dispatch, getState) => {
    try {
      let currentModule = 'prioritize';
      if (getState().currentModule.currentModule === 'Prioritize') {
        currentModule = 'prioritize';
      } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
        currentModule = 'optimize';
      }
      const devices = currentModule === 'optimize' ? getState().devices.devices : getState().loops.loops;
      const deviceId = currentModule === 'optimize' ? getState().optimize.activeDevice : getState().prioritize.activeLoop;
      let device = devices[deviceId];
      let improvements = currentModule === 'optimize' ? device.improvements[improvementType] : device.improvements;

      /*let date = new Date();
    let mmddyyyy =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '/' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      '/' +
      date.getFullYear();*/
      for (let i = 0; i < improvements.length; i++) {
        if (improvements[i].id === improvementId) {
          improvements.splice(i, 1);
        }
      }
      if (currentModule === 'optimize') {
        device.improvements[improvementType] = improvements;
      } else {
        device.improvements = improvements;
      }
      //console.log(device);
      dispatch({ type: DELETE_IMPROVEMENT, payload: { device, deviceId } });
    } catch (err) {
      console.log(err);
    }
  };

export const fetchDeviceImprovements = (processId, type) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    let issue_type = !type ? (currentModule === 'optimize' ? 'Technician' : 'Prioritize') : type;
    const url = `/${currentModule}/devices/${processId}/improvements/${issue_type}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({
      type: FETCH_DEVICE_IMPROVEMENTS,
      payload: { type, data: response.data },
    });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceIssues = (processId, testId, type) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/issues/${testId}/${type}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({
      type: FETCH_DEVICE_ISSUES,
      payload: { type, deviceId: processId, data: response.data },
    });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceIssuesByName = (processId, testId, type, name) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/issues/${testId}/${type}/${name}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    let issueData = response.data;
    dispatch({
      type: FETCH_DEVICE_ISSUES,
      payload: { type, deviceId: processId, data: issueData },
    });
    dispatch({
      type: SET_DEVICE_ISSUE_LOAD,
      payload: { type, deviceId: processId, name: name, status: response.data.length === 0 ? 'empty' : 'loaded' },
    });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchActions = (actions) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/actions`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.post(url, { data: { actions } });
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_ACTIONS, payload: response.data });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchInvestigations = (investigations) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/investigations`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.post(url, { data: { investigations } });
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_INVESTIGATIONS, payload: response.data });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};
export const fetchIssueDetails = (issues) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/issues/`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.post(url, { data: { issues } });
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_ISSUES_DETAILS, payload: response.data });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchDeviceHistory = (processId) => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/devices/${processId}/history`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    dispatch({ type: FETCH_DEVICE_HISTORY, payload: response.data });

    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const fetchTest = (testId, type) => async (dispatch) => {
  try {
    //console.log('devices');

    const url = `/optimize/tests/${testId}/data/${!type ? '' : type}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    const response = await api.get(url);
    //console.log(response);
    // TODO FETECH VARIABLES CREATION
    //if (response.status === 200) {
    //console.log(response.data);
    //console.log(response.data);
    dispatch({
      type: FETCH_TEST,
      payload: response.data,
    });
    var obj = { type, status: 'loaded', testId };
    //obj[testId] = 'Loaded';
    dispatch({ type: SET_TEST_STATUS, payload: obj });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
    let correctedType = type === 'IP' ? 'i/p' : type.toLowerCase();
    var badResponseData = { testId, type, [correctedType]: { [testId]: { data: [], testId } } };
    //console.log(badResponseData);
    dispatch({
      type: FETCH_TEST,
      payload: badResponseData,
    });
    var objError = { type, status: 'loaded', testId }; // !should be status error in future versions
    //obj[testId] = 'Loaded';
    //console.log('t');
    dispatch({ type: SET_TEST_STATUS, payload: objError });
  }
};

export const fetchLoopVariable = (testId, variable, params, timestamp) => async (dispatch) => {
  try {
    //console.log('devices');
    params = !params ? {} : params;
    let suffix = Object.keys(params)
      .map((key, index) => {
        return !params[key] || typeof params[key] === 'undefined' || params[key] === '' || params[key].length === 0
          ? ``
          : `${index === 0 ? `` : '&'}${key.toLowerCase().replace(' ', '')}=${params[key]}`;
      })
      .join('');
    dispatch({
      type: SET_TEST_STATUS,
      payload: { type: variable, status: 'loading', testId: testId },
    });
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/loop/${testId}/variable/${variable}?${suffix}${timestampUrl}`;
    //const url = `/devices/Olin1000258/CHL7-AI-0902-09/variables`;
    // const url = `/devices`;
    //let obj = { type: variable, status: 'loading', testId: testId };
    //dispatch({ type: SET_TEST_STATUS, payload: obj });
    //const startTime = new Date().getTime();
    //console.log(startTime);
    const response = await api.get(url);
    //console.log(new Date().getTime() - startTime);
    //console.log(response.data);
    // TODO THIS GOES TO TEST CONTROLLER ATM, NEED TO RENAME IT ALL TO VARIABLE
    //match formating to optimize formatting
    let dispatchDataObj = [];
    let dispatchStatusObj = [];
    if (Object.keys(response.data).length === 0 && response.status === 200) {
      let obj = {
        type: variable,
        status: 'loaded',
        testId: testId,
      };
      dispatchStatusObj.push(obj);
      dispatch({ type: SET_TEST_STATUS, payload: dispatchStatusObj });
    }
    for (let loopId in response.data) {
      let dataObj = { testId: loopId, type: variable };
      dataObj[variable] = {};
      dataObj[variable][loopId] = response.data[loopId][variable];
      let obj = {
        type: variable,
        status: 'loaded',
        testId: testId,
      };
      dispatchDataObj.push(dataObj);
      dispatchStatusObj.push(obj);
      /*dispatch({
        type: FETCH_TEST,
        payload: dataObj,
      });
      

      dispatch({ type: SET_TEST_STATUS, payload: obj });*/
      /*dispatch(
        {
          type: FETCH_TEST,
          payload: dataObj,
        },
        { type: SET_TEST_STATUS, payload: obj }
      );*/
    }
    //console.log(dispatchStatusObj);
    dispatch({
      type: FETCH_TEST,
      payload: dispatchDataObj,
    });
    //TODO NO LONGER RECORD INDIVIDUAL TEST STATUS?
    dispatch({ type: SET_TEST_STATUS, payload: dispatchStatusObj });
  } catch (err) {
    console.log(err);
  }
};

export const setTestStatus = (type, testId, status) => (dispatch) => {
  var obj = { type, status, testId };
  //console.log(obj);
  dispatch({ type: SET_TEST_STATUS, payload: obj });
};

export const fetchDiagnostics = (testId) => async (dispatch) => {
  try {
    const url = `/optimize/tests/${testId}/diagnostics/`;
    const response = await api.get(url);
    dispatch({
      type: FETCH_DIAGNOSTICS,
      payload: response.data,
    });
    var obj = { type: 'diagnostics', status: 'loaded', testId };
    //obj[testId] = 'Loaded';
    dispatch({ type: SET_TEST_STATUS, payload: obj });
  } catch (err) {
    console.log(err);
  }
};

export const fetchLoopDiagnostics = (loopId, range, timestamp) => async (dispatch) => {
  try {
    let timestampUrl = !timestamp ? `` : `&timestamp=${timestamp}`;
    const url = `/prioritize/loops/${loopId}/diagnostics/${range}?${timestampUrl}`;

    const response = await api.get(url);
    dispatch({
      type: FETCH_PRIORITIZE_DIAGNOSTICS,
      payload: response.data,
    });
    var obj = { type: 'diagnostics', status: 'loaded', testId: loopId };
    //obj[testId] = 'Loaded';
    dispatch({ type: SET_TEST_STATUS, payload: obj });
  } catch (err) {
    console.log(err);
  }
};

export const fetchSort = (sort) => async (dispatch) => {
  try {
    const url = `/prioritize/sort/${sort}`;
    const response = await api.get(url);
    //if (response.status === 200) {
    dispatch({ type: FETCH_SORT, payload: response.data });
    // } else {
    //   console.log(response);
    //   console.log(JSON.stringify(response));
    // }
  } catch (err) {
    console.log(err);
  }
};

export const getImprovementsReport = () => async (dispatch, getState) => {
  try {
    //TODO FIX REPORTS DEFAULT MODULE
    let currentModule = 'optimize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/reports/improvements/`;
    const response = await api.get(url);
    dispatch({ type: GET_IMPROVEMENT_REPORT, payload: response.data });
  } catch (err) {
    console.log(err);
  }
};

export const getSummaryReport = () => async (dispatch, getState) => {
  try {
    //TODO FIX REPORTS DEFAULT MODULE
    let currentModule = 'optimize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/reports/summary/`;
    const response = await api.get(url);
    dispatch({ type: GET_SUMMARY_REPORT, payload: response.data });
  } catch (err) {
    console.log(err);
  }
};

export const getPortfolioReport = (filters) => async (dispatch, getState) => {
  try {
    //TODO FIX REPORTS DEFAULT MODULE
    let currentModule = 'optimize';
    if (getState().currentModule.currentModule === 'Prioritize') {
      currentModule = 'prioritize';
    } else if (getState().currentModule.currentModule === 'IBA' || getState().reports.activeTab === 'IBA') {
      currentModule = 'IBA';
    } else if (getState().currentModule.currentModule === 'Optimize' || getState().reports.activeTab === 'Optimize') {
      currentModule = 'optimize';
    }
    //console.log('devices');
    const url = `/${currentModule}/reports/portfolio/`;
    const response = await api.post(url, filters);
    dispatch({ type: GET_PORTFOLIO_REPORT, payload: response.data });
  } catch (err) {
    console.log(err);
  }
};

export const getPrioritizeSummaryReport = () => async (dispatch, getState) => {
  try {
    let currentModule = 'prioritize';
    if (getState().currentModule.currentModule === 'Optimize') {
      currentModule = 'optimize';
    } else if (getState().currentModule.currentModule === 'Prioritize' || getState().reports.activeTab === 'Prioritize') {
      currentModule = 'prioritize';
    }
    //console.log('devices');
    const url = `/${currentModule}/reports/summary/`;
    const response = await api.get(url);
    dispatch({ type: GET_PRIORITIZE_SUMMARY_REPORT, payload: response.data });
  } catch (err) {
    console.log(err);
  }
};

export const setComparatorReport = (devices) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_COMPARATOR_REPORT, payload: devices });
  } catch (err) {
    console.log(err);
  }
};
export const updatePreset = (preset) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRESET, payload: preset });
  } catch (err) {
    console.log(err);
  }
};

export const updateSort = (sort) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_SORT, payload: sort });
  } catch (err) {
    console.log(err);
  }
};

export const updateConcern = (concern) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_CONCERN, payload: concern });
  } catch (err) {
    console.log(err);
  }
};

export const updateDateRange = (range) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_DATE_RANGE, payload: { Range: range } });
  } catch (err) {
    console.log(err);
  }
};

export const updateAnalysisRange = (range) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ANALYSIS_RANGE,
      payload: { AnalysisRange: range },
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateActionRange = (range) => (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ACTION_RANGE,
      payload: { ActionRange: range },
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateFilter = (filter) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_FILTER, payload: filter });
  } catch (err) {
    console.log(err);
  }
};

export const clearState = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_STATE, payload: [] });
  } catch (err) {
    console.log(err);
  }
};

export const clearDevice = (device_id) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_DEVICE, payload: device_id });
  } catch (err) {
    console.log(err);
  }
};

export const clearDeviceIssuesImprovements = (device_id) => (dispatch) => {
  try {
    dispatch({ type: CLEAR_DEVICE_ISSUES_IMPROVEMENTS, payload: device_id });
  } catch (err) {
    console.log(err);
  }
};

export const setTableOffset = (offset) => (dispatch) => {
  dispatch({ type: SET_TABLE_OFFSET, payload: offset });
};
export const setTablePageSize = (size) => (dispatch) => {
  dispatch({ type: SET_TABLE_PAGE_SIZE, payload: size });
};
export const setTablePage = (page) => (dispatch) => {
  dispatch({ type: SET_TABLE_PAGE, payload: page });
};

export const optimizeSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_ACTIVE_TAB, payload: tab });
};

export const optimizeSetActiveLoop = (loop) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_ACTIVE_LOOP, payload: loop });
};
export const optimizeSetActiveDevice = (device) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_ACTIVE_DEVICE, payload: device });
};
export const optimizeSetActiveTest = (test) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_ACTIVE_TEST, payload: test });
};
export const optimizeSetCompareDevice = (device) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_COMPARE_DEVICE, payload: device });
};
export const optimizeSetCompareTest = (test) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_COMPARE_TEST, payload: test });
};
export const optimizeSetCompareFlag = (bool) => (dispatch) => {
  dispatch({ type: OPTIMIZE_SET_COMPARE_FLAG, payload: bool });
};

export const prioritizeSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: PRIORITIZE_SET_ACTIVE_TAB, payload: tab });
};

export const prioritizeSetActiveLoop = (loop) => (dispatch) => {
  dispatch({ type: PRIORITIZE_SET_ACTIVE_LOOP, payload: loop });
};

export const prioritizeSetActiveDevice = (device) => (dispatch) => {
  dispatch({ type: PRIORITIZE_SET_ACTIVE_DEVICE, payload: device });
};
export const prioritizeToggleActiveVariable = (variables) => (dispatch) => {
  dispatch({ type: PRIORITIZE_TOGGLE_ACTIVE_VARIABLE, payload: variables });
};
export const prioritizeClearActiveVariables = () => (dispatch) => {
  dispatch({ type: PRIORITIZE_CLEAR_ACTIVE_VARIABLES, payload: {} });
};
export const prioritizeAddActiveVariable = (variables) => (dispatch) => {
  dispatch({ type: PRIORITIZE_ADD_ACTIVE_VARIABLE, payload: variables });
};
export const prioritizeRemoveActiveVariable = (variables) => (dispatch) => {
  dispatch({ type: PRIORITIZE_REMOVE_ACTIVE_VARIABLE, payload: variables });
};
export const reliabilitySetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: RELIABILITY_SET_ACTIVE_TAB, payload: tab });
};
export const reliabilitySetActiveLoop = (loop) => (dispatch) => {
  dispatch({ type: RELIABILITY_SET_ACTIVE_LOOP, payload: loop });
};
export const reportsSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: REPORTS_SET_ACTIVE_TAB, payload: tab });
};

export const reportsSetActiveReport = (report) => (dispatch) => {
  dispatch({ type: REPORTS_SET_ACTIVE_REPORT, payload: report });
};

export const roadmapSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: ROADMAP_SET_ACTIVE_TAB, payload: tab });
};

export const configSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: CONFIG_SET_ACTIVE_TAB, payload: tab });
};

export const supportSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: SUPPORT_SET_ACTIVE_TAB, payload: tab });
};

export const uploadSetActiveTab = (tab) => (dispatch) => {
  dispatch({ type: UPLOAD_SET_ACTIVE_TAB, payload: tab });
};

export const supportSetActiveOptimize = (supportTab) => (dispatch) => {
  dispatch({ type: SUPPORT_SET_ACTIVE_OPTIMIZE, payload: supportTab });
};
export const supportSetActivePrioritize = (supportTab) => (dispatch) => {
  dispatch({ type: SUPPORT_SET_ACTIVE_PRIORITIZE, payload: supportTab });
};
export const setPrint = (bool) => (dispatch) => {
  dispatch({ type: SET_PRINT, payload: bool });
};

export const setFormat = (bool) => (dispatch) => {
  dispatch({ type: SET_FORMAT, payload: bool });
};

export const clearFilter = () => (dispatch) => {
  dispatch({ type: CLEAR_FILTER });
};

export const setColorMode = (colorMode) => async (dispatch) => {
  try {
    const url = `setColorMode/`;
    /*const response =*/ await api.post(url, { color_mode: colorMode });
    dispatch({ type: SET_COLOR_MODE, payload: colorMode });
  } catch (err) {
    console.log(err);
  }
};

export const addChartSelections = (selections) => (dispatch) => {
  try {
    dispatch({ type: ADD_CHART_SELECTIONS, payload: selections });
  } catch (err) {
    console.log(err);
  }
};

export const removeChartSelections = (selections) => (dispatch) => {
  try {
    dispatch({ type: REMOVE_CHART_SELECTIONS, payload: selections });
  } catch (err) {
    console.log(err);
  }
};

export const replaceChartSelections = (selections) => (dispatch) => {
  try {
    dispatch({ type: REPLACE_CHART_SELECTIONS, payload: selections });
  } catch (err) {
    console.log(err);
  }
};

export const clearChartSelections = () => (dispatch) => {
  try {
    dispatch({ type: CLEAR_CHART_SELECTIONS, payload: [] });
  } catch (err) {
    console.log(err);
  }
};

export const updateChartSelections = (utcDate, index, index2) => (dispatch) => {
  try {
    dispatch({ type: UPDATE_CHART_SELECTION, payload: { utcDate, index, index2 } });
  } catch (err) {
    console.log(err);
  }
};

export const changeEventSelections = (id, indexes, value) => (dispatch) => {
  try {
    dispatch({ type: CHANGE_EVENT_SELECTIONS, payload: { id, indexes, value } });
  } catch (err) {
    console.log(err);
  }
};

export const changeEventSelectionsConsolidated = (id, indexes, value, selectedConsolidated) => (dispatch) => {
  try {
    dispatch({ type: CHANGE_EVENT_SELECTIONS_CONSOLIDATED, payload: { id, indexes, value, selectedConsolidated } });
  } catch (err) {
    console.log(err);
  }
};
