import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { InputLabel, ListItemText, MenuItem, FormControl, Select } from '@mui/material';
import { styles } from '../styles';
import { connect } from 'react-redux';
import { fetchSort, updateSort, updateFilter, setTablePage, fetchLoops, deleteLoops } from '../../actions/index';
//TODO FIX REPETITION OF OPTION SWITCH
class SingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: !props.options ? 'Loading...' : !this.props.selectedOption ? props.options[0] : this.props.selectedOption,
    };
  }

  handleChange(e) {
    if (!!this.props.handleChange) {
      this.props.handleChange(e.target.value);
    }
    this.setState({ value: e.target.value });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    //if the value doesn't match an available option defaults to the 0th option
    if (this.props.options.indexOf(this.state.value) === -1 && !!this.state.value && this.props.options.length !== 0) {
      this.setState({ value: this.props.options[0] });
    }
  }
  componentDidMount() {
    // do nothing
    if (!!this.props.mountFunction) {
      this.props.mountFunction(this.props.mountParams);
    }
  }
  renderPrefix() {
    if (this.props.prefix) {
      return this.props.label + ': ';
    } else {
      return '';
    }
  }
  render() {
    const { classes } = this.props;
    let options = !this.props.options || this.props.options.length === 0 ? ['No Options Provided for this dropdown'] : this.props.options;
    return (
      <FormControl
        variant='standard'
        className={this.props.short ? classes.formSelectShort : this.props.inline ? classes.buttonFilter : classes.formSelect}
        disabled={this.props.disabled}
        style={this.props.right ? { marginRight: 0 } : null}
      >
        {this.props.variant === 'outlined' ? null : <InputLabel htmlFor='select-label'>{this.props.label}</InputLabel>}
        <Select
          name={this.props.label}
          variant={!this.props.variant ? 'standard' : this.props.variant}
          value={!this.props.value ? (this.props.options.indexOf(this.state.value) === -1 ? '' : this.state.value) : this.props.value} //if the value doesn't match an available option defaults to no option selection until the setState triggers and defaults to the 0th option, if value is submitted as a prop it will use that instead
          onChange={this.handleChange.bind(this)}
          renderValue={(selected) => {
            return this.renderPrefix() + selected;
          }}
          style={this.props.variant === 'outlined' && this.props.inline ? { height: 37 } : null}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemText primary={`${option}${!this.props.suffix ? '' : this.props.suffix}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

SingleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    sort: state.sort,
    table: state.table,
  };
};

export default connect(mapStateToProps, {
  fetchSort,
  updateSort,
  updateFilter,
  setTablePage,
  fetchLoops,
  deleteLoops,
})(withStyles(styles)(SingleSelect));
