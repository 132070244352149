import React from 'react';
import { Typography, Grid, List } from '@mui/material';
import configDetails from '../../arrays/optimize/configDetails';
import makeStyles from '@mui/styles/makeStyles';
import { styles } from '../styles';

const useStyles = makeStyles(styles);

function ConfigDetails(props) {
  const classes = useStyles();
  const id = props.id;
  if (!configDetails[id]) {
    return <Typography style={{ marginRight: 40 }}>Loading...</Typography>;
  }
  let content = (
    <Grid container spacing={0} justifyContent='space-between'>
      <Grid item>
        {props.activeTab === 'intro' ? (
          <React.Fragment>
            <Typography variant='subtitle1'>Description</Typography>
            <Typography variant='body1' className={classes.body}>
              {!configDetails[id].description ? `Description Not Found!` : configDetails[id].description}
            </Typography>
          </React.Fragment>
        ) : null}
        {props.activeTab === 'importance' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Recommendations
            </Typography>
            <List>
              {!configDetails[id].importance
                ? `Importance Not Found!`
                : Object.keys(configDetails[id].importance).map((importanceItem) => {
                    const labelId = `${importanceItem.replace(/\s/g, '')}`;
                    return (
                      (<Typography key={`${importanceItem.replace(/\s/g, '')}_item`} variant='body1' id={labelId} className={classes.body} style={{ marginBottom: 20 }}>
                        <span className={classes.bold}>
                          {labelId}
                          {labelId === 'Standard' || labelId === 'Important' ? null : ' Important'}:{' '}
                        </span>
                        {configDetails[id].importance[importanceItem]}
                      </Typography>)
                    );
                  })}
            </List>
          </div>
        ) : null}
        {props.activeTab === 'recommendations' ? (
          <div className={classes.helperSection}>
            <Typography variant='subtitle1' className={classes.bodyHeader}>
              Recommendations
            </Typography>
            <List>
              {!configDetails[id].recommendations
                ? `Recommendations Not Found!`
                : Object.keys(configDetails[id].recommendations).map((recommendation, index) => {
                    const labelId = `checkbox-list-label-${recommendation.replace(/\s/g, '')}`;
                    var recommendationLabel = recommendation.slice(3, 4);
                    if (labelId.includes('Indent')) {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} style={{ paddingLeft: 25 }} id={labelId}>
                          {configDetails[id].recommendations[recommendation]}
                        </Typography>)
                      );
                    } else {
                      return (
                        (<Typography key={`${recommendation.replace(/\s/g, '')}_item`} role={undefined} className={classes.bodyList} id={labelId}>
                          {recommendationLabel}. {configDetails[id].recommendations[recommendation]}
                        </Typography>)
                      );
                    }
                  })}
            </List>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
  return content;
}

export default ConfigDetails;
