import React from 'react';
import initializeTheme from '../theme';
import { Checkbox } from '@mui/material';
import { connect } from 'react-redux';
import {
  updateConcern,
  fetchLoops,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
  clearFilter,
} from '../../actions/index';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { Grid, Typography } from '@mui/material';

class Legend extends React.Component {
  constructor(props) {
    super(props);
    //this.state = {};
    this.updateConcern = this.updateConcern.bind(this);
  }

  updateConcern = async (event) => {
    let concern = {
      [event.target.name]: !this.props.concern[event.target.name],
    };

    //99.9% SAME AS MULTISELECTONCLOSE
    //console.log('Closed');
    let obj = { ...this.props.sort.filter };
    obj[`SpecificDevice`] = event.target.checked;
    this.props.updateFilter(obj);
    let filters = {
      Application: this.props.sort.filter.Application,
      Site: this.props.sort.filter.Site,
      Location: this.props.sort.filter.Location,
      Disruption: this.props.sort.filter.Disruption,
      Range: JSON.stringify(this.props.sort.filter.Range),
      Devicetypes: this.props.sort.filter['Device Types'],
      Issuetypes: this.props.sort.filter['Issue Types'],
      Importance: this.props.sort.filter.Importance,
      SpecificDevice: event.target.checked,
      Concerns: JSON.stringify(Object.assign(this.props.concern, concern)),
    };
    if (
      !(
        !filters.Application &&
        !filters.Disruption &&
        !filters.Devicetypes &&
        !filters.Issuetypes &&
        !filters.Importance &&
        !filters.Location &&
        !filters.Site &&
        !filters.Concerns &&
        this.props.auth.require_filter
      )
    ) {
      this.props.deleteDevices();
      this.props.deleteLoops();
      //TODO Finish with update of page
      this.props.updateConcern(concern);
      //console.log(filters);
      //console.log(this.props.sort.filter);
      //this.setState({ loading: true });
      this.props.setTablePage(0);
      //await this.props.fetchDevicesSummary();
      //console.log(filters);
      this.props.fetchLoops(0, this.props.table.pageSize, this.props.sort.sort, filters, this.props.sort.filter.Dataset);
    }
  };
  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle2' className={classes.concernLegend}>
          {this.props.type} Legend
        </Typography>
        <Grid container justifyContent='flex-start'>
          <Grid item xs={12} sm='auto' className={classes.legendItem}>
            <Typography style={theme.palette.green} variant='subtitle2'>
              <Checkbox
                name='Good'
                checked={!this.props.concern ? true : this.props.concern.Good}
                disabled={!this.props.concern}
                onClick={this.updateConcern}
                className={classes.checkboxLegend}
                style={theme.palette.green}
              />
              {this.props.type !== 'Reliability' ? null : 'xx'} <span style={{ marginTop: 2 }}>Good {this.props.type !== 'Concern' ? null : '0 - 30'}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm='auto' className={classes.legendItem}>
            <Typography style={theme.palette.yellow} variant='subtitle2'>
              <Checkbox
                name='Advisory'
                checked={!this.props.concern ? true : this.props.concern.Advisory}
                disabled={!this.props.concern}
                onClick={this.updateConcern}
                className={classes.checkboxLegend}
                style={theme.palette.yellow}
              />
              {/*<SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>*/}
              {this.props.type !== 'Reliability' ? null : 'xx'} Advisory {this.props.type !== 'Concern' ? null : '31 - 60'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm='auto' className={classes.legendItem}>
            <Typography style={theme.palette.red} variant='subtitle2'>
              <Checkbox
                name='Critical'
                checked={!this.props.concern ? true : this.props.concern.Critical}
                disabled={!this.props.concern}
                onClick={this.updateConcern}
                className={classes.checkboxLegend}
                style={theme.palette.red}
              />
              {/*<SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>*/}
              {this.props.type !== 'Reliability' ? null : 'xx'} Critical {this.props.type !== 'Concern' ? null : '61 - 100'}
            </Typography>
          </Grid>
          {this.props.type !== 'Reliability' ? null : (
            <React.Fragment>
              <Grid item xs={12} sm='auto' className={classes.legendItem}>
                <Typography style={theme.palette.blue} variant='subtitle2'>
                  <Checkbox
                    name='Critical'
                    checked={!this.props.concern ? true : this.props.concern.Critical}
                    disabled={!this.props.concern}
                    onClick={this.updateConcern}
                    className={classes.checkboxLegend}
                    style={theme.palette.blue}
                  />
                  {/*<SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>*/}
                  xx Calibrations Due
                </Typography>
              </Grid>
              <Grid item xs={12} sm='auto' className={classes.legendItem}>
                <Typography style={theme.palette.purple} variant='subtitle2'>
                  <Checkbox
                    name='Critical'
                    checked={!this.props.concern ? true : this.props.concern.Critical}
                    disabled={!this.props.concern}
                    onClick={this.updateConcern}
                    className={classes.checkboxLegend}
                    style={theme.palette.purple}
                  />
                  {/*<SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>*/}
                  xx Calibrations Late
                </Typography>
              </Grid>
            </React.Fragment>
          )}
          {/*<Grid item>
            <Typography style={theme.palette.orange} variant='subtitle2'>
              <SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>
              Warning 50%-75%
            </Typography>
          </Grid>*/}
          {/*<Grid item>
            <Typography style={theme.palette.pink} variant='subtitle2'>
              <SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>
              Increasing Shift
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.blueLightText} variant='subtitle2'>
              <SvgIcon className={classes.legendIcon}>
                <circle cx='12' cy='12' r='12' />
              </SvgIcon>
              Decreasing Shift
            </Typography>
          </Grid>*/}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    concern: state.sort.concern,
    sort: state.sort,
    table: state.table,
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {
  updateConcern,
  clearFilter,
  fetchLoops,
  fetchSites,
  fetchDeviceTypes,
  fetchApplications,
  fetchLocations,
  fetchImportances,
  fetchEvents,
  fetchIssueTypes,
  setTableOffset,
  setTablePageSize,
  setTablePage,
  updateSort,
  updateFilter,
  deleteLoops,
  deleteDevices,
  setModule,
  prioritizeSetActiveLoop,
})(withStyles(styles, { withTheme: true })(Legend));
