import React from 'react';
import TextDisplay from '../../../filters/TextDisplay';
import { Grid } from '@mui/material';

class MaintenanceContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rowOpen: true };
  }
  render() {
    //const row = this.props.row;
    //console.log(this.props.clickedRow, row.id);
    return (
      <Grid container spacing={2}>
        <Grid item>
          <TextDisplay id={null} label='Slope' text={'xx'} type='large' />
        </Grid>
        <Grid item>
          <TextDisplay id={null} label='Zero' text={'xx'} type='large' />
        </Grid>
        <Grid item>
          <TextDisplay id={null} label='Order Code' text={'xx'} type='large' />
        </Grid>
        <Grid item>
          <TextDisplay id={null} label='Cycle' text={'xx'} type='large' />
        </Grid>
        <Grid item>
          <TextDisplay id={null} label='Contact' text={'xx'} type='large' />
        </Grid>
        <Grid item>
          <TextDisplay id={null} label='Comment' text={'xx'} type='large' />
        </Grid>
      </Grid>
    );
  }
}
export default MaintenanceContent;
