import {
  REPORTS_SET_ACTIVE_TAB,
  REPORTS_SET_ACTIVE_REPORT,
  GET_IMPROVEMENT_REPORT,
  GET_SUMMARY_REPORT,
  GET_PRIORITIZE_SUMMARY_REPORT,
  SET_COMPARATOR_REPORT,
  GET_PORTFOLIO_REPORT,
} from '../actions/types';

const INITIAL_STATE = {
  activeTab: null,
  activeReport: null,
  report_data: {
    summary: [],
    performance: [],
    improvements: [],
    comparator: [],
    portfolio: [],
  },
};

const reportsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPORTS_SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case REPORTS_SET_ACTIVE_REPORT:
      return { ...state, activeReport: action.payload };
    case GET_IMPROVEMENT_REPORT: {
      let reportData = Object.assign({}, state.report_data);
      reportData.improvements = action.payload.results;
      return { ...state, report_data: reportData };
    }
    case GET_SUMMARY_REPORT: {
      let reportData = Object.assign({}, state.report_data);
      reportData.summary = action.payload.results;
      return { ...state, report_data: reportData };
    }
    case GET_PORTFOLIO_REPORT: {
      let reportData = Object.assign({}, state.report_data);
      reportData.portfolio = action.payload.results;
      return { ...state, report_data: reportData };
    }
    case GET_PRIORITIZE_SUMMARY_REPORT: {
      let reportData = Object.assign({}, state.report_data);
      reportData.summary = action.payload.results;
      return { ...state, report_data: reportData };
    }
    case SET_COMPARATOR_REPORT: {
      let reportData = Object.assign({}, state.report_data);
      reportData.comparator = action.payload;
      return { ...state, report_data: reportData };
    }
    default:
      return state;
  }
};

export default reportsReducer;
