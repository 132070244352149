import React from 'react';
import { Button, Grid, TextField, Paper, Typography, MenuItem, Checkbox, Tooltip, IconButton, Popper, ClickAwayListener, Link } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import OptimizeSpecs from '../../widgets/OptimizeSpecs';
import OptimizeDiagnosticsWidget from '../../widgets/OptimizeDiagnosticsWidget';
import RecommendationsWidget from '../../widgets/RecommendationsWidget';
import ValveChartWidget from '../../widgets/ValveChartWidget';
import WidgetTitle from '../../headers/WidgetTitle';
import CustomSelect from '../../filters/CustomSelect';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import Alert from '@mui/material/Alert';
//import { Compare } from '@mui/icons-material';
import {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setDevicesSummaryStatus /*, fetchDeviceEvents*/,
  clearDeviceIssuesImprovements,
} from '../../../actions/index';
import PerformanceWidgets from './PerformanceWidgets';
import ImprovementsWidgets from './ImprovementsWidgets';
import AlignmentWidgets from './AlignmentWidgets';
import InfoPopover from '../../popup/InfoPopover.js';
import OptimizeContent from '../../widgetGroups/config/content/OptimizeContent';
import AcknowledgeContent from '../../widgetGroups/config/content/AcknowledgeContent';
import { AssignmentTurnedIn, Merge, Update, Settings, MoreVert, Speed } from '@mui/icons-material';
import OptimizeTestMerge from '../../widgetGroups/config/content/OptimizeTestMerge';
import OptimizeTestState from '../../widgetGroups/config/content/OptimizeTestState';
import PrintHeader from '../../headers/PrintHeader';
import functions from '../../../functions/functions';
import CustomDialog from '../../popup/CustomDialog';

class DiagnosticsWidgets extends React.Component {
  constructor(props) {
    super(props);
    this.compareTest = this.compareTest.bind(this);
  }
  state = {
    performanceCheck: false,
    improvementsCheck: false,
    alignmentCheck: false,
    performanceToggle: false,
    improvementsToggle: false,
    alignmentToggle: false,
    selectMenu: false,
    selectMenuAnchor: null,
    message: '',
  };
  handlePerformanceCheck = () => {
    this.setState({
      performanceCheck: !this.state.performanceCheck,
    });
  };
  handleImprovementsCheck = () => {
    this.setState({
      improvementsCheck: !this.state.improvementsCheck,
    });
  };
  handleAlignmentCheck = () => {
    this.setState({
      alignmentCheck: !this.state.alignmentCheck,
    });
  };
  processState(state) {
    switch (parseInt(state)) {
      case 1:
        return ' - As Left';
      case 3:
        return ' - As Found';
      default:
        return '';
    }
  }
  processTimestampArray(arr, stateArr) {
    let result = [];
    if (!!arr) {
      for (let i = 0; i < arr.length; i++) {
        const timestamp = new Date(parseInt(arr[i]) * 1000).toLocaleString();

        result.push(`${timestamp}${this.processState(stateArr[i])}`);
      }
    }
    return result;
  }
  selectActiveTest = (date) => {
    const timestampArr =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory ? this.props.devices[this.props.activeDevice].datetimeHistory : [];
    const stateArr = !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].stateHistory ? this.props.devices[this.props.activeDevice].stateHistory : [];
    //let indexNum = -1;
    let newActiveTestId = -1;
    for (let i = 0; i < timestampArr.length; i++) {
      const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
      //result.push(timestamp);

      if (timestamp === date) {
        //indexNum = i;
        newActiveTestId = this.props.devices[this.props.activeDevice].testIdHistory[i];
        //this.props.clearDeviceIssuesImprovements(this.props.activeDevice);  //superflous added to fetchDeviceByTest
        this.props.fetchDeviceByTest(this.props.activeDevice, newActiveTestId);
      }
    }
  };
  compareTest(date) {
    this.setState({ message: '' });
    if (date === 'No Comparison') {
      this.props.optimizeSetCompareFlag(false);
      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      this.props.optimizeSetCompareTest('');
    } else {
      const timestampArr = !!this.props.devices[this.props.optimize.compareDevice] ? this.props.devices[this.props.optimize.compareDevice].datetimeHistory : [];
      const stateArr =
        !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].stateHistory
          ? this.props.devices[this.props.optimize.compareDevice].stateHistory
          : [];
      //let indexNum = -1;
      let newCompareTestId = -1;
      for (let i = 0; i < timestampArr.length; i++) {
        const timestamp = `${new Date(parseInt(timestampArr[i]) * 1000).toLocaleString()}${this.processState(stateArr[i])}`;
        //result.push(timestamp);

        if (timestamp === date) {
          //indexNum = i;
          newCompareTestId = this.props.devices[this.props.optimize.compareDevice].testIdHistory[i];
          if (parseInt(newCompareTestId) !== this.props.devices[this.props.activeDevice].activeTestId || this.props.optimize.compareDevice.deviceId !== this.props.activeDevice) {
            // console.log('true');
            this.props.optimizeSetCompareFlag(true);
            //this.props.optimizeSetCompareDevice(this.props.activeDevice);
            this.props.optimizeSetCompareTest(newCompareTestId);
            this.props.fetchDevices(newCompareTestId);
          } else {
            //console.log('false');
            this.props.optimizeSetCompareFlag(false);
            //this.props.optimizeSetCompareDevice(this.props.);
            this.props.optimizeSetCompareTest('');
            this.setState({ message: 'No Diagnostic Data to Compare!' });
          }
        } else {
          /*this.props.optimizeSetCompareFlag(false);
        this.props.optimizeSetCompareDevice('');
        this.props.optimizeSetCompareTest('');*/
        }
      }
    }

    //this.props.fetchDeviceCompareTests(16, 30);
  }
  componentDidMount() {
    if (!this.props.optimize.compareFlag) {
      this.props.optimizeSetCompareDevice(this.props.activeDevice); //defaults comparison to the current device
    }
    if (
      !this.props.devices[this.props.activeDevice] ||
      !this.props.devices[this.props.activeDevice].resolutionApp ||
      !this.props.devices[this.props.activeDevice].sensitivityApp ||
      !this.props.devices[this.props.activeDevice].sensitivityResult ||
      !this.props.devices[this.props.activeDevice].specBool
    ) {
      if (!this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].activeTestId) {
        this.props.fetchDevices(this.props.activeDevice);
        this.props.fetchDeviceSpecs(this.props.activeDevice);
      }
      if (this.props.optimize.compareDevice !== '' && this.props.optimize.compareDevice !== this.props.activeDevice) {
        this.props.fetchDevices(this.props.optimize.compareDevice);
      }
      if (this.props.deviceSummary === false) {
        this.props.setDevicesSummaryStatus(true);
        this.props.fetchDevicesSummary();
      }

      //this.props.optimizeSetCompareDevice(this.props.activeDevice);
      //this.props.fetchLoopEvents(this.props.activeLoop);
    }
  }
  renderPerformanceWidgets() {
    const { classes } = this.props;
    if (this.state.performanceToggle) {
      return (
        <div className={`${classes.pageBreak} ${classes.avoidBreak}`}>
          <PerformanceWidgets diagnosticSpecs moreSpecs toggledWidget></PerformanceWidgets>
        </div>
      );
    }
  }
  renderImprovementsWidgets() {
    const { classes } = this.props;
    if (this.state.improvementsToggle) {
      return (
        <div className={classes.pageBreak}>
          <ImprovementsWidgets toggledWidget></ImprovementsWidgets>
        </div>
      );
    }
  }
  renderAlignmentWidgets() {
    const { classes } = this.props;
    if (this.state.alignmentToggle) {
      return (
        <div className={classes.pageBreak}>
          <AlignmentWidgets toggledWidget></AlignmentWidgets>
        </div>
      );
    }
  }
  togglePdfDialog = () => {
    this.setState({
      pdfDialog: !this.state.pdfDialog,
    });
  };
  renderPdfDialog() {
    return (
      <CustomDialog
        center
        maxWidth='md'
        open={this.state.pdfDialog}
        onClose={this.togglePdfDialog}
        title='View Raw PDF'
        titleIcon='Folder'
        content={
          <div style={{ marginTop: 16 }}>
            <Link>View Valve Test 1</Link>
            <Link>Save as</Link>
          </div>
        }
        actions={
          <React.Fragment>
            <Button onClick={this.togglePdfDialog} color='primary'>
              Cancel
            </Button>
          </React.Fragment>
        }
      ></CustomDialog>
    );
  }
  render() {
    const { classes } = this.props;
    const device = this.props.devices[this.props.activeDevice];
    const compareDevice = this.props.devices[this.props.optimize.compareDevice];
    const testOptions =
      !!this.props.devices[this.props.activeDevice] && !!this.props.devices[this.props.activeDevice].datetimeHistory
        ? this.processTimestampArray(this.props.devices[this.props.activeDevice].datetimeHistory, this.props.devices[this.props.activeDevice].stateHistory)
        : ['Loading'];
    //console.log(compareDevice);
    //Prevent comparison to self
    let adjustedDatetimeHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.datetimeHistory.slice();
    let adjustedStateHistory = !compareDevice || !compareDevice.datetimeHistory ? [] : compareDevice.stateHistory.slice();
    //console.log(adjustedDatetimeHistory);
    //console.log(compareDevice);
    const index = !compareDevice || !compareDevice.datetimeHistory ? -1 : compareDevice.datetimeHistory.indexOf(device.dateTime.toString());
    //console.log(!device ? 'loading' : device.dateTime);
    //console.log(index);
    if (index !== -1) {
      adjustedDatetimeHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"

      adjustedStateHistory.splice(index, 1); //removed the current datatime so as to not allow "comparison to self"
    }
    const testOptionsCompare =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? this.processTimestampArray(adjustedDatetimeHistory, adjustedStateHistory)
        : ['No Tests Found'];
    const testOptionsCompareIndex =
      !!this.props.devices[this.props.optimize.compareDevice] && !!this.props.devices[this.props.optimize.compareDevice].datetimeHistory
        ? adjustedDatetimeHistory.indexOf(this.props.optimize.compareTest) + 1
        : 0;
    //console.log(testOptionsCompare[testOptionsCompareIndex]);

    const row = !device
      ? {}
      : {
          id: device.deviceId,
          name: device.name,
          serial: device.serial,
          site: device.site,
          application: device.application,
          description: device.description,
          bodySize: device.bodySize,
          bodyType: device.bodyType,
          importance: device.importance,
          resApp: !device.appRes ? null : device.appRes,
          senApp: !device.appSen ? null : device.appSen,
          strokeApp: !device.appStroke ? null : device.appStroke,
          effectiveTargetDate: new Date().getTime() / 1000,
          speedImportance: device.speedImportance,
          accuracyImportance: device.accuracyImportance,
          performanceSatisfaction: device.performanceSatisfaction,
          currentSerial: device.serial,
          deviceId: device.deviceId,
        };
    const handleClick = (event) => {
      this.setState({
        selectMenu: !this.state.selectMenu,
        selectMenuAnchor: event.currentTarget,
      });
    };
    const handleClose = () => {
      this.setState({ selectMenu: false });
    };
    const handleSubmit = () => {
      this.setState({ selectMenu: false, performanceToggle: this.state.performanceCheck, improvementsToggle: this.state.improvementsCheck, alignmentToggle: this.state.alignmentCheck });
    };
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    //TODO Fix error coming from undefined value when transfering from History
    if (this.props.devices[this.props.activeDevice]) {
      return (
        <React.Fragment>
          <Grid container justifyContent='space-between' style={{ marginBottom: this.props.activeTab === 'Certification' || this.props.toggledWidget ? null : -42 }} className={classes.printHide}>
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1}>
                {!this.props.toggledWidget ? (
                  <React.Fragment>
                    {!printStyles ? (
                      <Grid item>
                        <Tooltip arrow placement='top' title='Create Performance Certificate in a New Tab' aria-label='Create Performance Certificate in a New Tab'>
                          <Link href={`/optimize/${this.props.activeDevice}/Certification`} target='_blank'>
                            <IconButton className={classes.textColor} aria-label='more' style={{ marginLeft: -10 }} size='large'>
                              <Speed />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {!printStyles ? (
                      <Grid item>
                        <InfoPopover
                          center
                          tooltip='Acknowledge Checklist Alarms'
                          title='Acknowledge Checklist Alarms'
                          titleIcon='Acknowledge'
                          style={{ marginBottom: -15 }}
                          icon={<AssignmentTurnedIn />}
                          content={<AcknowledgeContent />}
                          maxWidth='xs'
                        />
                      </Grid>
                    ) : null}
                    {!printStyles ? (
                      <Grid item style={{ marginBottom: -15 }}>
                        <InfoPopover center tooltip='Set As Found/As Left' title='Set As Found/As Left' titleIcon='Update' large icon={<Update />} content={<OptimizeTestState />} maxWidth='xs' />
                      </Grid>
                    ) : null}
                  </React.Fragment>
                ) : null}
                {!printStyles &&
                !this.props.toggledWidget &&
                !!this.props.devices &&
                !!this.props.devices[this.props.activeDevice] &&
                !!this.props.devices[this.props.activeDevice].testIdHistory &&
                this.props.devices[this.props.activeDevice].testIdHistory.length > 1 ? (
                  <Grid item style={{ marginBottom: -15 }}>
                    <InfoPopover center tooltip='Merge Tests' title='Merge Tests' titleIcon='Merge' icon={<Merge />} content={<OptimizeTestMerge />} maxWidth='sm' />
                  </Grid>
                ) : null}
                {!this.props.toggledWidget ? (
                  <React.Fragment>
                    {!printStyles ? (
                      <Grid item>
                        <InfoPopover
                          center
                          tooltip='Configure'
                          resize
                          style={{ marginBottom: -15 }}
                          icon={<Settings />}
                          title='Configure'
                          titleIcon='Settings'
                          content={<OptimizeContent row={row} clickedRow={this.props.activeDevice} rowOpen={true} />}
                        />
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Tooltip arrow placement='top' title='Show More' aria-label='Show More'>
                        <IconButton color='inherit' aria-label='more' onClick={handleClick} style={{ marginLeft: -10 }} size='large'>
                          <MoreVert />
                        </IconButton>
                      </Tooltip>
                      <Popper open={this.state.selectMenu} onClose={handleClose} anchorEl={this.state.selectMenuAnchor} placement='bottom-start'>
                        <ClickAwayListener onClickAway={handleClose}>
                          <Paper>
                            <Typography
                              variant='caption'
                              style={{
                                padding: '10px 12px 6px 14px',
                                display: 'block',
                              }}
                            >
                              Select to show more info
                            </Typography>
                            <MenuItem onClick={this.handlePerformanceCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.performanceCheck} color='secondary' />
                              Performance
                            </MenuItem>
                            <MenuItem onClick={this.handleImprovementsCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.improvementsCheck} color='secondary' />
                              Improvement Paths
                            </MenuItem>
                            <MenuItem onClick={this.handleAlignmentCheck}>
                              <Checkbox className={classes.menuCheckbox} checked={this.state.alignmentCheck} color='secondary' />
                              Alignment
                            </MenuItem>
                            <Grid container justifyContent='space-between'>
                              <Grid item></Grid>
                              <Grid item>
                                <Button variant='outlined' disableElevation size='small' color='primary' aria-label='submit' onClick={handleSubmit} style={{ margin: 10, display: 'block' }}>
                                  Submit
                                </Button>
                              </Grid>
                            </Grid>
                          </Paper>
                        </ClickAwayListener>
                      </Popper>
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <div>
            {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
            <WidgetTitle title={`Diagnostics - ${!device || !device.name ? 'Device Name Not Found' : device.name}`} />
            {!!this.props.toggledWidget ? null : (
              <OptimizeSpecs
                device={this.props.devices[this.props.activeDevice]}
                compareDevice={this.props.devices[this.props.optimize.compareDevice]}
                compareTo={['No Comparison'].concat(testOptionsCompare)[testOptionsCompareIndex]}
                useTitle
                title='Device Details'
                toggledWidget={this.props.toggledWidget}
                moreSpecs
              />
            )}
            {!!this.props.toggledWidget ? null : (
              <Grid container className={`${classes.printHide} ${classes.testSelect}`}>
                <Grid item>
                  {!!this.props.toggledWidget || !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime ? null : (
                    <CustomSelect
                      single
                      id={null}
                      label='Test'
                      type='Test'
                      disabled={this.state.loading}
                      //onCloseFunction={this.multiSelectOnClose}
                      onChangeFunction={(event, value) => {
                        this.selectActiveTest(value);
                      }}
                      //type='unit'
                      values={
                        !this.props.devices || !this.props.devices[this.props.activeDevice] || !this.props.devices[this.props.activeDevice].dateTime
                          ? 'Loading'
                          : `${new Date(this.props.devices[this.props.activeDevice].dateTime * 1000).toLocaleString()}${this.processState(this.props.devices[this.props.activeDevice].state)}`
                      }
                      options={testOptions}
                      //value={null}
                      helperText={null}
                      width={260}
                    />
                  )}
                </Grid>
                {!!this.props.toggledWidget ||
                !this.props.devices[this.props.activeDevice] ||
                !this.props.devices[this.props.activeDevice].datetimeHistory ||
                this.props.devices[this.props.activeDevice].datetimeHistory.length < 0 ? null : (
                  <React.Fragment>
                    <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                      <Autocomplete
                        className={classes.formSelect}
                        id='combo-box-demo'
                        options={Object.keys(this.props.devices).map((key) => {
                          return {
                            name: this.props.devices[key].name,
                            deviceId: this.props.devices[key].deviceId,
                          };
                        })}
                        value={
                          !this.props.optimize.compareDevice || !this.props.devices[this.props.optimize.compareDevice]
                            ? null
                            : {
                                name: this.props.devices[this.props.optimize.compareDevice].name,
                                deviceId: this.props.devices[this.props.optimize.compareDevice].deviceId,
                              }
                        }
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                          //console.log(option);
                          //console.log(value);
                          return option.name === value.name && option.deviceId === value.deviceId;
                          //return false;
                        }}
                        disableClearable={true}
                        onChange={(e, value) => {
                          this.props.optimizeSetCompareFlag(false);
                          this.props.optimizeSetCompareTest('');
                          this.props.optimizeSetCompareDevice(value.deviceId);
                          this.props.fetchDevices(value.deviceId);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Comparison Device' /*variant="outlined"*/
                            InputLabelProps={{
                              className: classes.printHide,
                            }}
                            variant='standard'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item className={!this.props.optimize.compareFlag ? classes.printHide : null}>
                      <CustomSelect
                        single
                        id={null}
                        label='Compare to'
                        type='Compare'
                        disabled={this.state.loading || testOptionsCompare.length < 1}
                        onChangeFunction={(event, value) => {
                          this.compareTest(value);
                        }}
                        values={!testOptionsCompare || !testOptionsCompare[testOptionsCompareIndex] || !this.props.optimize.compareFlag ? 'No Comparison' : testOptionsCompare[testOptionsCompareIndex]}
                        options={['No Comparison'].concat(testOptionsCompare)}
                        helperText={null}
                        width={260}
                      />
                    </Grid>

                    {this.state.message !== '' ? (
                      <Grid item>
                        <Alert severity='warning'>{this.state.message}</Alert>
                      </Grid>
                    ) : null}
                  </React.Fragment>
                )}
              </Grid>
            )}
            <Grid container className={classes.containerSpacingFix}>
              <Grid item lg={printStyles ? 12 : 3} md={12} sm={12} className={classes.widgetSpacing}>
                <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
                  <OptimizeDiagnosticsWidget device={this.props.devices[this.props.activeDevice]} />
                </Paper>
              </Grid>
              <Grid item lg={printStyles ? 12 : 9} md={12}>
                <Grid container>
                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'Travel'}
                  />
                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'Air'}
                  />

                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'Actuator'}
                  />
                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'Friction'}
                  />
                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'I/P'}
                  />
                  <ValveChartWidget
                    className={classes.widgetSpacing}
                    xs={12}
                    initialWidth={6}
                    type='valve'
                    valveColumns={1}
                    progress={false}
                    testId={this.props.devices[this.props.activeDevice].activeProfileId}
                    attribute={'Seat'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
          {this.props.activeTab === 'Certification' ? null : <RecommendationsWidget device={this.props.devices[this.props.activeDevice]} toggledWidget />}
          {this.renderPdfDialog()}
          {this.renderPerformanceWidgets()}
          {this.renderImprovementsWidgets()}
          {this.renderAlignmentWidgets()}
          {!this.props.toggledWidget ? (
            <Grid className={classes.printHide}>
              <Button variant='outlined' size='medium' color='primary' aria-label='more' onClick={handleClick} style={{ marginTop: 4 }}>
                <MoreVert className={classes.buttonIcon}></MoreVert>Show More
              </Button>
            </Grid>
          ) : null}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span className={classes.printHide}>
            <WidgetTitle title='Diagnostics' className={classes.printHide} />
          </span>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    optimize: state.optimize,
    user: state.auth.userId,
    device_summary: state.devices.summary,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, {
  fetchDevices,
  fetchDevicesSummary,
  fetchDeviceByTest,
  fetchDeviceSpecs,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  optimizeSetCompareDevice,
  setDevicesSummaryStatus /*, fetchDeviceEvents */,
  clearDeviceIssuesImprovements,
})(withStyles(styles)(DiagnosticsWidgets));
