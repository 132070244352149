import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { connect } from 'react-redux';
import { uploadSetActiveTab, setModule } from '../../actions';
//import { Redirect } from 'react-router-dom';
import functions from '../../functions/functions';
import UploadTabs from '../menus/UploadTabs';
import OptimizeUpload from '../uploadUtilities/OptimizeUpload';
import PrioritizeUpload from '../uploadUtilities/PrioritizeUpload';

class Upload extends React.Component {
  componentDidMount() {
    if (this.props.match.params.tab === 'null' || this.props.match.params.tab === null || !this.props.match.params.tab || this.props.match.params.tab === 'User') {
      this.props.uploadSetActiveTab('Optimize');
      this.props.setModule('Optimize');
    } else {
      this.props.uploadSetActiveTab(this.props.match.params.tab);
      this.props.setModule(this.props.match.params.tab);
    }
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {this.props.format ? null : <UploadTabs />}
        <div className={printStyles ? null : classes.detailsMin}>
          {this.props.activeTab === 'Prioritize' && <PrioritizeUpload></PrioritizeUpload>}
          {this.props.activeTab === 'Optimize' && <OptimizeUpload></OptimizeUpload>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.upload.activeTab,
    format: state.format.format,
  };
};

export default connect(mapStateToProps, {
  uploadSetActiveTab,
  setModule,
})(withStyles(styles, { withTheme: true })(Upload));
