const presets = {
  noPreset: {
    preset: 'No Preset',
    filter: {
      sort: 'Importance',
      AnalysisRange: '90',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  stability: {
    preset: 'Long Term System and Asset Stability',
    filter: {
      sort: 'Importance',
      AnalysisRange: '180',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  outage: {
    preset: 'High Risk of Sudden Outage',
    filter: {
      sort: 'Importance',
      AnalysisRange: '90',
      concern: { Good: false, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  frequentMaintenance: {
    preset: 'High Frequency Maintenance',
    filter: {
      sort: 'Concern',
      AnalysisRange: '30',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  wetMaintenance: {
    preset: 'Wet Analytical Maintenance',
    filter: {
      sort: 'Concern',
      AnalysisRange: '30',
      concern: { Good: true, Advisory: true, Critical: true },
      //TODO set wet analytics devices
      'Device Types': [],
      'Issue Types': [],
    },
  },
  turnaround: {
    preset: 'Turnaround Projects',
    filter: {
      sort: 'Importance',
      AnalysisRange: '365',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  saturation: {
    preset: 'Critical Saturation',
    filter: {
      sort: 'Concern',
      AnalysisRange: '180',
      concern: { Good: false, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': ['Saturated Instr.', 'Saturated Valve', 'Loop Error'],
    },
  },
  corrosion: {
    preset: 'Fighting Corrosion, Erosion, and Coating',
    filter: {
      sort: 'Importance',
      AnalysisRange: '180',
      concern: { Good: false, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  technology: {
    preset: 'Technology Review by Asset Type',
    filter: {
      sort: 'Importance',
      AnalysisRange: '180',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
  dcs: {
    preset: 'DCS Timing and Tuning',
    filter: {
      sort: 'Importance',
      AnalysisRange: '90',
      concern: { Good: true, Advisory: true, Critical: true },
      'Device Types': [],
      'Issue Types': [],
    },
  },
};

export default presets;
