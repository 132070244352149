import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../../styles';
import { Button, Grid, Checkbox, ListItem, ListItemText, Typography, Divider } from '@mui/material';
import ChecklistIndicator from '../../../filters/ChecklistIndicator';
import { fetchDiagnostics, setTestStatus } from '../../../../actions/index';
import diagnosticsArray from '../../../../arrays/optimize/diagnostics';
import api from '../../../../apis/api';
import functions from '../../../../functions/functions';

class AcknowledgeContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      acknowledgements: {},
      diagnostics: {},
      status: '',
      statusMessage: '',
    };
  }
  handleSubmit = async (event, acknowledge) => {
    event.preventDefault();
    this.setState({
      loading: true,
      status: 'Success',
      statusMessage: 'Processing...',
    });
    try {
      const deviceId = this.props.activeDevice;
      const profileId = this.props.devices[deviceId].activeProfileId;
      const url = `optimize/devices/${deviceId}/${profileId}/acknowledge`;

      await api.post(url, {
        diagnostics: acknowledge ? this.state.diagnostics : this.state.acknowledgements,
        acknowledge,
      });
      this.props.setTestStatus('diagnostics', profileId, 'loading');
      await this.props.fetchDiagnostics(profileId);

      this.setState({
        acknowledgements: !acknowledge ? {} : this.state.acknowledgements,
        diagnostics: acknowledge ? {} : this.state.diagnostics,
        loading: false,
        status: 'Success',
        statusMessage: 'Changes Saved',
      });

      /*
      
      //console.log('t');
      const url = `optimize/devices/${this.props.row.id}/acknowledge`;
      //console.log(this.state.rowChange);
      //console.log(url);
      await api.post(url, this.state.rowChange);
      //console.log(response);
      this.props.fetchDevicesSummary();
      this.props.fetchDevices(this.props.row.id);
      //this.setState((state) => ({ rowOpen: false }));
      this.setState({ status: 'Success', statusMessage: 'Success!' });
      /*if (!!this.props.bubbleRowOpen) {
        this.props.bubbleRowOpen();
      }*/
    } catch (err) {
      this.setState({ status: 'Failure', statusMessage: 'Failure!' });
      console.log(err);
    }
  };
  handleChangeAcknowledge = (event) => {
    let acknowledgements = this.state.acknowledgements;
    if (!acknowledgements[event.target.name]) {
      acknowledgements[event.target.name] = event.target.checked;
    } else {
      delete acknowledgements[event.target.name];
    }
    this.setState(acknowledgements);
  };
  handleChange = (event) => {
    let diagnostics = this.state.diagnostics;
    if (!diagnostics[event.target.name]) {
      diagnostics[event.target.name] = event.target.checked;
    } else {
      delete diagnostics[event.target.name];
    }

    this.setState(diagnostics);
  };

  createListItem = (activeTest, check, acknowledgeBool) => {
    const { classes } = this.props;
    const labelId = `checkbox-list-label-${check.subtitle.replace(/\s/g, '')}`;
    return (
      (<ListItem key={`${check.check.replace(/\s/g, '')}_item`} role={undefined} dense style={{ marginTop: 5 }} className={classes.checklistItem}>
        <ChecklistIndicator
          color={functions.numberToColor(!activeTest ? -1 : activeTest[check.check])}
          tooltip={functions.numberToOptimizeTooltip(!activeTest ? -1 : activeTest[check.check])}
        ></ChecklistIndicator>
        <ListItemText id={labelId} primary={`${check.subtitle}`} />
        <Checkbox
          checked={!acknowledgeBool ? !!this.state.diagnostics[check.check] : !!this.state.acknowledgements[check.check]}
          size={'small'}
          name={check.check}
          onClick={(e) => {
            //console.log(activeTest);
            //console.log(e, activeTest[check.check]);
            activeTest[check.check] < -2 ? this.handleChangeAcknowledge(e) : this.handleChange(e);
          }}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          color='secondary'
        />
      </ListItem>)
    );
  };

  renderChildRowContent = () => {
    const color = this.state.status === 'Failure' ? 'error' : 'secondary';
    const tests = this.props.tests;
    const profileId = this.props.devices[this.props.activeDevice].activeProfileId;
    const activeTest = !tests.diagnostics ? {} : tests['diagnostics'][profileId];
    let criticalList = [];
    let warningList = [];
    let acknowledgementList = [];
    const { classes } = this.props;
    diagnosticsArray.map((diagnosticSet) => {
      if (!!profileId || !!activeTest) {
        return diagnosticSet.checks.map((check) => {
          if (activeTest[check.check] === 2) {
            criticalList.push(check);
          } else if (activeTest[check.check] === 1) {
            warningList.push(check);
          } else if (activeTest[check.check] < -2) {
            acknowledgementList.push(check);
          }
          return true;
        });
      } else {
        return true;
      }
    });
    let content = (
      <React.Fragment>
        {criticalList.length > 0 || warningList.length > 0 || acknowledgementList.length > 0 ? (
          <Typography variant='subtitle1'>Please select the items you wish to acknowledge</Typography>
        ) : (
          <Typography style={{ marginTop: 10, marginBottom: 10 }}>No Eligible Diagnostics Found.</Typography>
        )}
        {criticalList.length > 0 ? (
          <React.Fragment>
            <Typography variant='subtitle1' style={{ marginTop: 10, marginBottom: 10 }}>
              Criticals
            </Typography>
            {criticalList.map((check) => {
              return this.createListItem(activeTest, check);
            })}
          </React.Fragment>
        ) : null}
        {warningList.length > 0 ? (
          <React.Fragment>
            <Typography variant='subtitle1' style={{ marginTop: 10, marginBottom: 10 }}>
              Warnings
            </Typography>
            {warningList.map((check) => {
              return this.createListItem(activeTest, check);
            })}
          </React.Fragment>
        ) : null}
        <Divider className={`${classes.dialogDivider} ${classes.accordionDialogDivider}`} style={{ paddingTop: 10 }}></Divider>
        <Grid container justifyContent='space-between' style={{ marginBottom: -5 }}>
          <Grid item>
            {this.state.status !== '' && acknowledgementList.length <= 0 ? (
              <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                {`${this.state.statusMessage}`}
              </Typography>
            ) : null}
          </Grid>
          {criticalList.length > 0 || warningList.length > 0 ? (
            <Grid item>
              <Button
                disabled={this.state.loading || Object.keys(this.state.diagnostics).length === 0}
                onClick={(event) => {
                  this.handleSubmit(event, true);
                }}
                color='secondary'
                type='text'
                value='Submit'
              >
                Acknowledge
              </Button>
            </Grid>
          ) : null}
        </Grid>
        {acknowledgementList.length > 0 ? (
          <React.Fragment>
            <Divider className={classes.accordionDialogDivider} style={{ borderColor: 'rgba(0, 0, 0, 0.06)' }}></Divider>
            <Grid container>
              <Typography variant='subtitle1' style={{ marginTop: 10, marginBottom: 10 }}>
                Acknowledgements
              </Typography>
              {acknowledgementList.map((check) => {
                return this.createListItem(activeTest, check, true);
              })}
            </Grid>
            <Divider className={classes.accordionDialogDivider} style={{ borderColor: 'rgba(0, 0, 0, 0.06)', paddingTop: 10 }}></Divider>
            <Grid container justifyContent='space-between' style={{ marginBottom: -5 }}>
              <Grid item>
                {this.state.status !== '' ? (
                  <Typography color={color} variant='subtitle1' style={{ marginTop: 3 }}>
                    {`${this.state.statusMessage}`}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  disabled={this.state.loading || Object.keys(this.state.acknowledgements).length === 0}
                  onClick={(event) => {
                    this.handleSubmit(event, false);
                  }}
                  color='secondary'
                  type='text'
                  value='Submit'
                >
                  Undo Acknowledge
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
    return content;
  };

  render() {
    //console.log(this.props.row.id, this.props.clickedRow);
    //console.log();

    return <React.Fragment>{this.renderChildRowContent()}</React.Fragment>;
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    activeTest: state.optimize.activeTest,
    devices: state.devices.devices,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    optimize: state.optimize,
    currentModule: state.currentModule.currentModule,
  };
};

export default connect(mapStateToProps, {
  fetchDiagnostics,
  setTestStatus,
})(withStyles(styles)(AcknowledgeContent));
