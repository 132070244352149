import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
import { Link } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ComparatorReport from './optimize/ComparatorReport';
import ImprovementsReport from './optimize/ImprovementsReport';
import PerformanceReport from './optimize/PerformanceReport';
import PortfolioReport from './optimize/PortfolioReport';
import RoiReport from './optimize/RoiReport';
import { getImprovementsReport, reportsSetActiveReport } from '../../../actions/index';
import functions from '../../../functions/functions';

class OptimizeWidgets extends React.Component {
  componentDidMount() {
    let url = window.location.href.toLowerCase();
    url.includes('improvements')
      ? this.props.reportsSetActiveReport('improvements')
      : url.includes('comparator')
      ? this.props.reportsSetActiveReport('comparator')
      : url.includes('performance')
      ? this.props.reportsSetActiveReport('performance')
      : this.props.reportsSetActiveReport('portfolio');
  }
  handleReport(event, value) {
    this.props.reportsSetActiveReport(value);
    if (!value) {
      this.props.reportsSetActiveReport('portfolio');
    }
    //console.log(value);
  }
  //TODO fix wrapper buttons onclick
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        <ToggleButtonGroup
          value={!this.props.activeReport ? 'performance' : this.props.activeReport}
          exclusive
          onChange={(event, value) => this.handleReport(event, value)}
          aria-label='text alignment'
          className={`${classes.toggleButtons} ${classes.printHide}`}
          style={printStyles ? { display: 'none' } : null}
        >
          {/*<ToggleButton
              value="summary"
              text="Summary"
              component={Link}
              to={`Summary`}
            >
              Summary
            </ToggleButton>*/}
          {
            // TODO CORRECT PERMISSIONS FOR THIS FOR FULL RELEASE
            this.props.view === 1 ? (
              <ToggleButton value='portfolio' text='Portfolio' component={Link} to={`/reports/Optimize/Portfolio`}>
                Portfolio
              </ToggleButton>
            ) : null
          }
          <ToggleButton value='improvements' text='Improvements' component={Link} to={`/reports/Optimize/Improvements`}>
            Improvement Paths
          </ToggleButton>
          <ToggleButton value='comparator' text='Comparator' component={Link} to={`/reports/Optimize/Comparator`}>
            Valve Comparison
          </ToggleButton>
          <ToggleButton value='performance' text='Performance' component={Link} to={`/reports/Optimize/Performance`}>
            Performance History
          </ToggleButton>
          {/*<ToggleButton
              value='roi'
              text='Roi'
              component={Link}
              to={`/reports/Optimize/Roi`}
            >
              ROI
            </ToggleButton>*/}
        </ToggleButtonGroup>
        <div className={classes.dividerXl}></div>
        {!this.props.activeReport ? null : this.props.activeReport.toLowerCase() === 'improvements' ? (
          <ImprovementsReport />
        ) : this.props.activeReport.toLowerCase() === 'performance' ? (
          <PerformanceReport />
        ) : this.props.activeReport.toLowerCase() === 'comparator' ? (
          <ComparatorReport />
        ) : this.props.activeReport.toLowerCase() === 'portfolio' || this.props.activeReport.toLowerCase() === 'print' ? (
          <PortfolioReport />
        ) : this.props.activeReport.toLowerCase() === 'roi' ? (
          <RoiReport />
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    reports: state.reports.report_data,
    activeReport: state.reports.activeReport,
    view: state.auth.view,
  };
};

export default connect(mapStateToProps, {
  getImprovementsReport,
  reportsSetActiveReport,
})(withStyles(styles)(OptimizeWidgets));
