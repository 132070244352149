import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../../styles';
//import WidgetTitle from '../../headers/WidgetTitle';
import { supportSetActiveTab } from '../../../actions';
import { supportSetActiveOptimize } from '../../../actions';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import UploadDetails from '../../helpers/UploadDetails';
import { Link } from 'react-router-dom';
import search from '../../../images/screenshots_FAQ/search_ss.PNG';
import sort from '../../../images/screenshots_FAQ/sort_ss.PNG';
import tiles from '../../../images/screenshots_FAQ/tiles_ss.PNG';
import tile from '../../../images/screenshots_FAQ/tile_ss.PNG';
import filters from '../../../images/screenshots_FAQ/filters_ss.PNG';
import print from '../../../images/screenshots_FAQ/print_ss.PNG';
import tabs from '../../../images/screenshots_FAQ/tabs_ss.PNG';
import alignment from '../../../images/screenshots_FAQ/alignment_ss.PNG';
import compare from '../../../images/screenshots_FAQ/compare_ss.PNG';
import diag from '../../../images/screenshots_FAQ/diag_ss.PNG';
import diagPaths from '../../../images/screenshots_FAQ/diagPaths_ss.PNG';
import history from '../../../images/screenshots_FAQ/history_ss.PNG';
import improvement from '../../../images/screenshots_FAQ/improvement_ss.PNG';
import improvements from '../../../images/screenshots_FAQ/improvements_ss.PNG';
import info from '../../../images/screenshots_FAQ/info_ss.png';
import printSettings from '../../../images/screenshots_FAQ/printSettings_ss.png';
import performanceHistory from '../../../images/screenshots_FAQ/performanceHistory_ss.PNG';
import compare1 from '../../../images/screenshots_FAQ/compare1_ss.PNG';
import compare2 from '../../../images/screenshots_FAQ/compare2_ss.PNG';
import improvementsReport from '../../../images/screenshots_FAQ/improvementsReport_ss.PNG';
import noTests from '../../../images/screenshots_FAQ/noTests_ss.PNG';
import merge1 from '../../../images/screenshots_FAQ/merge1_ss.png';
import merge2 from '../../../images/screenshots_FAQ/merge2_ss.PNG';

class FAQ extends React.Component {
  renderSection1() {
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. Getting your valve tests on to Optimize is quick and easy as long as you know where your test files are. Let’s look at a general scenario.
        </Typography>
        <UploadDetails hideTitle></UploadDetails>
      </React.Fragment>
    );
  }
  renderSection2() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. There can be a few reasons why you do not see your tests. Please look for the appropriate answer below:
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>I don’t see any tests at all:</span> If you don’t see any tests, there are likely 2 reasons. First, if you are brand new to using Optimize, it’s possible that
          tests have not yet been uploaded to the system. Please confirm with your appropriate service provider that your tests have been uploaded. The uploading process is very fast (a matter of
          minutes for most cases), so there should be no prolonged delay between upload time and you being able to see your tests.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Your Tests should look something like this when you click Optimize on the left hand menu.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tiles} alt='tiles screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          You may see this if there are no tests in the system.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={noTests} alt='tiles screenshot' className={classes.screenshot}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          If you have confirmed that your tests have been uploaded, then there is likely a problem with your user credentials. Please contact Ten One with your relevant company information so that we
          can resolve the issue.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>I do see some tests, just not the ones I want: </span>
          If you do see some tests, there are a variety of reasons why the tests you are specifically looking for are not being displayed the way you expect. First, it is important to confirm with
          your service provider that the tests you expect to see have been uploaded. The uploading process is very fast (a matter of minutes for most cases), so there should be no prolonged delay
          between upload time and you being able to see your tests.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          The quickest and easiest way to find a specific test is to use the “Search” bar at the top of the screen. If you know your device ID, type it in, then click on it from the drop down menu.
          This will take you to the device page.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          The Search Bar
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={search} alt='search screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          If you have confirmed that your tests have been uploaded and would prefer to browse through the Test Index, the most likely culprit is that your tests are not sorted in the way you would
          like them to be. Optimize has a number of options to sort your Test Index. Optimize will default to showing devices sorted by the most recent date of each test for every site, location, and
          application. However, throughout use, these settings can be altered, so it is good to check if changes have been made. For example, you may be looking for a device tested from a previous
          year, but you’ve limited your date range to the “Last Quarter”. Your device would not show up until the date range was expanded.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          The Sort Filter
        </Typography>
        <img src={sort} alt='sort screenshot' className={classes.screenshot}></img>
        <Typography style={{ marginBottom: 20 }}>
          Another aspect to keep in mind about dates is that Optimize will show the most recent test date and results in a given valve. If you are looking for information from an older test from a
          device, please click any valve to naviagte into the device page where you can choose which test you want.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Selecting a Valve
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tile} alt='tile screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 500 }}></img>
          </Grid>
        </Grid>
        <Typography>
          So, you’ve confirmed your test was uploaded, you’ve made sure all your sorting options would include the test you want, and you’ve even used the search bar to look for your device directly,
          but you still don’t see your test. It’s not your fault; it’s likely ours and we apologize for this inconvenience. The issue probably has to do with how your user credentials were set up.
          Please contact Ten One with your relevant company information and we will help resolve the issue as soon as possible.
        </Typography>
        <Typography variant='subtitle1' style={{ marginTop: 20 }}>
          I can select a valve, but some of the tests I uploaded for that valve aren’t displaying
        </Typography>
        <Typography style={{ marginTop: 10 }}>
          The most common reason this can occur is because the tests have been automatically grouped at different timestamps. You can check to see if all of your tests are available by reviewing the
          tests available in the highlighted dropdown.
        </Typography>
        <Typography style={{ marginTop: 10 }}>
          If the tests aren’t grouped in a manner you would like, you can check out our FAQ question about test groupings below to learn how to adjust these groupings.
        </Typography>
        <Typography style={{ marginTop: 10 }}>
          In the event a specific subtest isn’t visible, you should attempt to upload it again. Be sure to check that the test is a valid test and successfully uploads. If that doesn’t work, you
          should <span className={classes.bold}>contact us</span> and we’ll be happy to work with you to troubleshoot.
        </Typography>
      </React.Fragment>
    );
  }
  renderSection3() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. Navigating through Optimize may seem intimidating at first, but the following will give you a quick breakdown so that you can start reviewing your data ASAP:
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Valves:</span> You’ve already noticed that there are boxes on your screen filled with information. In Optimize, these are Valves. Each represents a specific
          device and displays key information from the most recent test performed. The 3 bars that you see are representations of the target performance for the tests listed. A bar will be green if
          the valve has performed better than the target accuracy, orange if it is within 25% of target accuracy, and red if it does not meet target accuracy. A more thorough explanation of each test
          can be found by clicking the information “i” next to each test name. You’ll also notice each bar shows what the target was, as well as the actual test result. Below the bars is “Improvement
          Paths”. This area shows a count of each type of service that needs to be performed on the valve. The valve itself also acts as a button, which when clicked will take you to the specific
          device page. From there you can explore your tests in more detail.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          The Valve Index
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tiles} alt='tiles screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Navigation:</span> Now that you know what the valves are for and how to read them, it’s important to know how to find which valves you want. The screen that
          contains all of the valves we simply call the
          <span className={classes.bold}> Valve Index</span>.You’ll notice that there is a “Search” bar at the top of the page. Using this is the quickest and easiest way to find a specific test. If
          you know your device ID, type it in there, and then click on it from the drop down menu. This will take you to the device page. However, you may not want to see a specific device test right
          away and would prefer to browse through your devices. Browsing allows for a comparative view and gives you options such as the ability to see what tests have been uploaded recently, or check
          to see which valves are performing the best/worst. To do this, you will use the various “sort” options at the top of the page below the search bar. These will allow you to sort and filter
          your results so that you can see the devices you want, in the order you want. As an example, you could choose to see your devices that were tested most recently, but in a specific location
          and application.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Filters on the Valve Index
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={filters} alt='filters screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          The Valve Index will be your launching point towards getting your test results. While it does give a broad overview of your most recent tests, if you’d like to dig deeper into your results
          and improvement paths, please click on a Valve.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Selecting a Valve
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tile} alt='tile screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 500 }}></img>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  renderSection4() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. The Device Page is where you can really dig into the details of your tests. The following will explain the various graphs and options so that you can get the most out of your results.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Navigation:</span> You’ll notice at the top of the page there are buttons (Performance, Diagnostics, Improvement Paths, History) which will display certain
          information when clicked. Let’s go through each one so you’ll know how to find the data you want.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Click a tab to navigate
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={tabs} alt='tabs screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Performance:</span> The Performance tab can be used as a way to see your basic device data and test results. However, it can also be the primary place for test
          analysis. We’ve provided both options to best suit you. You’ll see in the top section that certain essential information is displayed about your valve. In some cases this is all you’ll need,
          but if you’d like to get a more detailed look at the valve, click the down arrow below the device name. This will expand this section showing all of the identification data provided.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Click the small arrow to expand device details
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={info} alt='info screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 600 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          Below, you will see a row of 3 options. The first option is to choose which test you want to see, which can be selected by date. Next to that you’ll see “Comparison Device” and “Compare to”.
          These options exist to allow you to compare your currently displayed test results to other tests. “Comparison Device” defaults to the device you are currently looking at. The reason for this
          is that “Compare to” is a drop down menu of tests by date. So, say you want to compare your most recent test results for this device to previous test results. You would leave “Comparison
          Device” as it’s default, and then select which test you want from “Compare to”. If you want to compare your current devices’ tests to a different device, you would simply change “Comparison
          Device” to whichever other device you would like. Either way, when you compare 2 tests, you will see one superimposed over the other in the following.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Choose a test, then choose a test to compare
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare} alt='compare screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 400 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          Below, you will see 3 graphs. You’ll recognize that these graphs have the same names and bars as the tests listed on a Valve. The graphs are your actual test results, visualized. If you want
          to explore these tests in more detail, you can expand them by clicking the “expand” button in the top right corner of each graph. Once expanded, you can zoom in to a specific area by
          clicking and dragging your mouse over that area. You will see a blue highlight to know that it is working. To reset your zoom, simply click the “reset zoom” button. To set your graph back to
          its original size, click the “expand” button again.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Performance Widgets
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={alignment} alt='alignment screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          Below the basic tests, you will see a button that says “Show Diagnostics”. You might say to yourself “Wait a second, there’s a Diagnostics button at the top of the page too!”. You are
          correct. We’ve left the option open to you as to how you’d like to view all of your data. If you would like to see it all on a single tab to scroll up and down on, you can. If you would like
          to just see your more specific diagnostic information and not have the basic tests there as well, you can click the Diagnostics tab at the top.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Diagnostics:</span> The Diagnostics tab is where an analyst will go to get into the specific information acquired from your valve tests in order to determine
          what service (if any) needs to be done on your valve to get it working optimally. On the left, you’ll see a list of “Issues” Each one of these issues has a color associated with it based on
          the results of your test. To learn more about each issue, please click the information “i”. To the right, you will see your diagnostic test results in graphical form. The graphs are
          expandable and zoomable like those in the Performance tab. An analyst will be able to look at the diagnostic tests in conjunction with the issues in order to come up with your Improvement
          options.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Diagnostics widgets
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={diag} alt='diagnostics screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          Below, you will see a section called “Advisory Improvement Paths”. This section will populate based on whatever issues are triggered as orange or red from above. While these paths are not
          definitive solutions to the issues presented, they give a basis for how to think about the issues and what options might exist to resolve them.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Advisory and Critical Improvement Paths auto generate
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={diagPaths} alt='improvement paths screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Improvement Paths:</span> The Improvement Paths tab is where an analyst will enter the improvement paths necessary for your team to service your device. If any
          improvement paths exist, they will be listed here. If you wish to add an improvement path, click “Add Improvement Path” From there you will fill in the necessary information. These
          improvement paths will show up in a count on your valve in the Valve Index.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Improvement Paths Table
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvements} alt='improvement paths screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          After Clicking 'Add Improvement Path'
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvement} alt='improvement path screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 300 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>History:</span> The History tab shows some key information from the tests done on your device. You can see the results of the basic tests, the number of
          repairs that are done, and whether the test was “As Found” or “As Left”
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Performance History Table
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={history} alt='history screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography>
          Hopefully this brief explanation of the Device Page will serve as a sufficient primer for you to be able to start digging into your valve tests in a meaningful way. However, if there are
          additional questions that do not have answers here, please contact either your service provider or Ten One and we would be happy to help you get the most out of Optimize.
        </Typography>
      </React.Fragment>
    );
  }
  renderSection5() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. It is important to format any page before you print. Make sure to double-check your print settings, and take note of your chosen web browser.
        </Typography>
        <Typography variant='subtitle1' style={{ marginBottom: 5 }}>
          To format the page, press the button at the top right corner of the screen that looks like a sheet of paper. This helps your browser properly render charts to fit the print window.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={print} alt='print screenshot' className={classes.screenshot}></img>
          </Grid>
        </Grid>
        <Typography variant='subtitle1' style={{ marginBottom: 20 }}>
          Then, press Ctrl + P to print or select print using your web browser. Some browsers have print in different locations.
        </Typography>
        <Typography variant='subtitle2'>Google Chrome and Microsoft Edge</Typography>
        <Typography>Right click the page and select print or click the menu in the top right of the browser and select print</Typography>
        <Typography style={{ marginTop: 20 }} variant='subtitle2'>
          Mozilla Firefox
        </Typography>
        <Typography>Click the menu in the top right of the browser and select print</Typography>
        <Typography style={{ marginTop: 20 }} variant='subtitle2'>
          Safari
        </Typography>
        <Typography>Choose file - print</Typography>
        <Typography>On a mac, the shortcut is command + P</Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5, marginTop: 30 }}>
          Example print settings in Google Chrome
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={printSettings} alt='print settings screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 300 }}></img>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  renderSection6() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. Yes! You can find summary information as well as historical data in the Reports tab. Let’s go over what you’ll find in them.
        </Typography>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Performance History:</span> The Performance History Report will show you a device’s change in performance over time. When you select a device, you will see 3
          graphs showing changes in Resolution, Sensitivity, and Stroke Speed. In each graph, there is a target line to show how your test results compare to the desired performance of a given device.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Performance History Report
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={performanceHistory} alt='performance history screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Improvement Paths:</span> The Improvement Paths Report will show you a summary checklist of all the improvement paths entered into the system. You’ll see some
          key information as well as links to the tests themselves if you need to reference something about them more closely. This report is particularly useful if you just want to see what
          improvement paths have been approved so you know what work has been done.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Improvement Paths Report
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={improvementsReport} alt='imporovement paths report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 20 }}>
          <span className={classes.bold}>Valve Comparison:</span> The Valve Comparison Report allows you to compare devices to each other. First you need to select which devices you’d like to check.
          Once selected, you’ll be able to see 3 graphs, Resolution, Sensitivity, and Stroke Speed, much like you would in the Performance tab of the Device Page. Below the graphs you will see the
          same issues list that you would find in the Diagnostic tab of the Device Page. This report provides a quick way for you to look at multiple devices on one screen.
        </Typography>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Valve Comparison Report Performance
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare1} alt='valve comparison alignment report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
        <Typography variant='subtitle2' style={{ marginBottom: 5 }}>
          Valve Comparison Report Diagnostics
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={compare2} alt='valve comparison diagnostics report screenshot' className={classes.screenshot} style={{ width: '100%', maxWidth: 800 }}></img>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  renderSection7() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. Groupings of individual tests (ex: profile, resolution, sensitivity, stroke) that are run within 23 minutes of each other are automatically grouped for you. In the event that multiples of
          the same test type (ex: two resolution tests) are within 23 minutes of each other, the last one is automatically used. This saves you the trouble of having to specify or worry about tests
          you re-do due to issues with the first run. The use of 23 minutes is based on an analysis of the time taken to run tests in the field, allowing for redo tests.
        </Typography>
        <Typography style={{ marginBottom: 10 }}>
          Tests that are run more than 23 minutes apart will be initially grouped into different test-groupings on the same tag. This is to ensure tests that are run ‘as found’ vs ‘as left' are
          properly cataloged. This may not always be how you want your tests grouped, so to assist with proper grouping of tests we have incorporated a ‘merge test’ feature. You can find it in the
          upper right of the alignment page or the diagnostic page if your tag has more than one test associated with it.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={merge1} alt='merge screenshot 1' className={classes.screenshot} style={{ width: '100%', maxWidth: 500 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 10 }}>
          The ‘source’ test is the test you wish to merge tests ‘from’ and the ‘target’ test is the test you wish to merge tests ‘to’. You cannot merge tests further than one week apart. This feature
          should be used to group sets of profile, resolution, sensitivity, and stroke tests that should be treated as one ‘state’ of the valve. For example, the valve’s performance before
          tuning-adjustments are made and the valve’s performance after tuning adjustments and repairs are made.
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <img src={merge2} alt='merge screenshot 2' className={classes.screenshot} style={{ width: '100%', maxWidth: 500 }}></img>
          </Grid>
        </Grid>
        <Typography style={{ marginBottom: 10 }}>
          If you’d like to ensure tests are consistently and correctly grouped, we recommend using the sequencer in your valve diagnostic system. You should use the sequencer to run the last set of
          tests you wish to run to capture the current ‘state’ of the valve. For example, if you wish to run sensitivity tests to explore the effects of tuning adjustments, you can run as many of
          these as you like over as long a period of time as you like. Just be sure to run a sequencer of the four core tests at the end. If you do, you can upload all tests you have run and the
          system will automatically display your last group appropriately, as well as store the intermediate tests to show the customer the difference between the sensitivity when you started vs your
          ‘as left’ state.
        </Typography>
      </React.Fragment>
    );
  }
  renderSection8() {
    //const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='h6' style={{ marginBottom: 20 }}>
          A. If your location doesn't have its own particular SOPs, we recommend following the process outlined in the getting started section: The Optimize Process: Step by Step.
        </Typography>
      </React.Fragment>
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='body1' style={{ marginTop: 20, marginBottom: 10 }}>
          If you have any further questions, you can always{' '}
          <Link
            onClick={() => {
              this.props.supportSetActiveOptimize('contact');
            }}
            to='/support/Optimize/Contact'
            className={classes.textLink}
          >
            contact us
          </Link>
          .
        </Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q: I’ve done all my testing. How do I get my valve tests on to Optimize?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection1()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. Help! I don’t see my tests! Where are they?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection2()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. I’ve logged in to Optimize and see a bunch of boxes filled with colored bars. What does this all mean?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection3()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>
              Q. Alright, so I’ve got a handle on the Valve Index, I've selected a valve, and now I want to explore a device page. I see a lot of graphs and options, what do I do?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection4()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. I’m trying to print from the website, but it just doesn’t look right.</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection5()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. I don’t want to look at my devices one by one. Is there anywhere I can find summary information?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection6()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. How does Optimize decide to group tests together? What if I want to have a different grouping?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection7()}</AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>Q. Do you have a recommended Workflow for using Optimize?</Typography>
          </AccordionSummary>
          <AccordionDetails>{this.renderSection8()}</AccordionDetails>
        </Accordion>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    activeTab: state.support.activeTab,
    activeOptimize: state.support.activeOptimize,
  };
};

export default connect(mapStateToProps, {
  supportSetActiveTab,
  supportSetActiveOptimize,
})(withStyles(styles, { withTheme: true })(FAQ));
