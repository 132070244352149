import React from 'react';
import { Typography, Grid, Collapse, Tooltip, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { styles } from '../styles';
//import investigationDetails from '../../arrays/optimize/investigationDetails';
import { connect } from 'react-redux';

class InvestigateDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Investigate',
    };
  }
  inactiveStepsOpen = {};
  handleOpenInactiveStep = (index) => {
    this.inactiveStepsOpen[index].open = !this.inactiveStepsOpen[index].open;
    this.setState({ state: this.state });
  };
  renderInvestigation() {
    //console.log(this.props.issue)
    let activeInvestigations = this.props.issue.investigation_list_active;
    let inactiveInvestigations = this.props.issue.investigation_list_inactive;
    const { classes } = this.props;
    let investigationDetails = this.props.recommendations.investigations;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' className={classes.bodyHeader}>
          Step by Step Investigation
        </Typography>
        {activeInvestigations.length < 1 ? (
          <Typography>No active investigation steps for this issue</Typography>
        ) : (
          activeInvestigations.map((investigation, index) => {
            return (
              <div key={`investigation_index_${index}`} style={{ marginBottom: 20, marginTop: 15 }}>
                <Grid container>
                  <Grid item>
                    <Typography variant='subtitle1'>{`Step ${index + 1}`}</Typography>
                    <Typography variant='subtitle2' color='secondary' className={classes.stepLabel}>
                      {!investigationDetails[investigation] || !investigationDetails[investigation].investigation_name
                        ? 'Investigation name not found!'
                        : investigationDetails[investigation].investigation_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant='body1' className={classes.body}>
                  {!investigationDetails[investigation] || !investigationDetails[investigation].description ? 'Investigation description not found!' : investigationDetails[investigation].description}
                </Typography>
              </div>
            );
          })
        )}
        {inactiveInvestigations.length < 1 ? null : (
          <React.Fragment>
            <Typography variant='subtitle1' className={classes.bodyHeader} style={{ marginTop: 20 }}>
              Inactive Investigation Steps
            </Typography>
            {inactiveInvestigations.map((investigation, index) => {
              if (typeof this.inactiveStepsOpen[index] === 'undefined') {
                this.inactiveStepsOpen[index] = { open: false };
              }
              return (
                <React.Fragment key={`investigation_index_${index}`}>
                  <Typography
                    variant='subtitle1'
                    className={!!this.inactiveStepsOpen[index].open ? classes.inactiveStepOpen : classes.inactiveStep}
                    onClick={() => {
                      this.handleOpenInactiveStep(index);
                    }}
                  >
                    {!investigationDetails || !investigationDetails[investigation] || !investigationDetails[investigation].investigation_name
                      ? 'Investigation name not found!'
                      : investigationDetails[investigation].investigation_name}
                    <Tooltip arrow placement='top' title={!!this.inactiveStepsOpen[index].open ? 'Collapse' : 'Expand'} aria-label={!!this.inactiveStepsOpen[index].open ? 'Collapse' : 'Expand'}>
                      <IconButton style={{ marginTop: -1 }} size='large'>
                        {!!this.inactiveStepsOpen[index].open ? <KeyboardArrowUp></KeyboardArrowUp> : <KeyboardArrowDown className={classes.inactiveArrow}></KeyboardArrowDown>}
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Collapse in={this.inactiveStepsOpen[index].open}>
                    <Typography variant='body1' className={classes.body}>
                      {!investigationDetails || !investigationDetails[investigation] || !investigationDetails[investigation].description
                        ? 'Investigation description not found!'
                        : investigationDetails[investigation].description}
                    </Typography>
                  </Collapse>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderExamples() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant='subtitle1' className={classes.bodyHeader}>
          Examples
        </Typography>
        <Grid /*key={example}*/ container style={{ marginTop: 10, marginBottom: 30 }}>
          <Typography variant='body1' className={classes.body}>
            <span style={{ fontWeight: 500 }}>Example {/*index + 1*/}.</span> {/*diagnosticsGraphDetails[id].examples[example].caption*/}
          </Typography>

          <img
            //key={`img-${diagnosticsGraphDetails[id].examples[example].src}`}
            //src={imagesArray[diagnosticsGraphDetails[id].examples[example].src]}
            //alt={diagnosticsGraphDetails[id].examples[example].caption}
            alt='temp'
            className={classes.helperScreenshot}
            style={
              {
                //maxWidth: diagnosticsGraphDetails[id].examples[example].size === 'small' ? 600 : 1000,
              }
            }
          />
          <Grid
            container
            justifyContent='space-between'
            style={
              {
                //maxWidth: diagnosticsGraphDetails[id].examples[example].size === 'small' ? 600 : 1000,
              }
            }
          >
            <Grid item></Grid>
            <Grid item>
              <Typography variant='caption' style={{ fontStyle: 'italic' }}>
                Example {/*index + 1*/} image
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
  render() {
    //const { classes } = this.props;
    let content = (
      <Grid container spacing={0} justifyContent='space-between'>
        <Grid item>
          <React.Fragment>
            {this.props.activeTab === 'intro' && this.renderInvestigation()}
            {this.props.activeTab === 'examples' && this.renderExamples()}
          </React.Fragment>
        </Grid>
      </Grid>
    );
    return content;
  }
}

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    devices: state.devices.devices,
    currentModule: state.currentModule.currentModule,
    recommendations: state.recommendations,
    // userId: state.auth.userId,
    // clientPermission: state.auth.clientPermission,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(InvestigateDetails));
