//import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid, Paper, Typography } from '@mui/material';
import { styles } from '../styles';
import {
  fetchSort,
  updateSort,
  updateFilter,
  setTablePage,
  fetchLoops,
  deleteLoops,
  //fetchVariables,
  fetchTest,
  fetchDevices,
  prioritizeSetActiveDevice,
} from '../../actions/index';
import functions from '../../functions/functions';
import { connect } from 'react-redux';

class ChartWidget extends React.Component {
  componentDidMount() {
    // do nothing
    // TODO CORRECT FOR WHEN DATA ALREADY EXISTS
    //console.log(this.props);
    //this.props.fetchTest(this.props.testId);
  }
  processTestSummaryResult(device, type, unit) {
    const { classes } = this.props;
    if (!device) return null;
    //!FOR TIM: MAY WANT TO MAKE THIS RETURN A TYPOGRAPHY WITH COLORS APPLIED
    let target = device[`${type}App`];
    let result = device[`${type}Result`];
    let calc = Math.round((result - target) * 100) / 100;
    let color = calc > 0 ? classes.redText : calc < 0 ? classes.greenText : null;
    //let prefix = ![`${type}Prefix`] ? device[`${type}Prefix`] : `${device[`${type}Prefix`]} `;
    //add space to prefix if not ''
    let sign = calc > 0 ? `+` : ``; //- already exists if negative. //! FOR TIM: CAN REMOVE THIS TO DROP +
    return (
      <Typography className={color} variant='h6' style={{ fontSize: calc === 0 ? 16 : 18 }}>
        {calc === 0 ? null : sign}
        {calc === 0 ? 'No Change' : calc}
        {calc === 0 ? null : unit}
      </Typography>
    );
  }
  processPrefix(device, type) {
    if (!device) return null;
    let prefix = ![`${type}Prefix`] ? device[`${type}Prefix`] : `${device[`${type}Prefix`]} `;
    if (!prefix || prefix === ' ' || prefix === '' || prefix === 'null' || prefix === 'null ') {
      return null;
    } else {
      return (
        <React.Fragment>
          {prefix} {prefix === 'Over ' || prefix === 'Under ' || prefix === 'Over' || prefix === 'Under' ? 'Target' : null}
        </React.Fragment>
      );
    }
  }
  processAdditionalSuffixes(device) {
    if (!device) return null;
    let testTypeArr = ['resolution', 'sensitivity', 'stroke'];
    let uniqueSuff = [];
    for (let i = 0; i < testTypeArr.length; i++) {
      if (uniqueSuff.indexOf(device[`${testTypeArr[i]}Postfix`]) === -1 && !!device[`${testTypeArr[i]}Postfix`]) {
        uniqueSuff.push(device[`${testTypeArr[i]}Postfix`]);
      }
    }
    return uniqueSuff.toString();
  }
  render() {
    const { classes } = this.props;
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    const device = this.props.devices[this.props.activeDevice];
    let resolution = this.processTestSummaryResult(device, 'resolution', '%');
    let sensitivity = this.processTestSummaryResult(device, 'sensitivity', '%');
    let stroke = this.processTestSummaryResult(device, 'stroke', 's');
    //suffix
    let additionalIssues = this.processAdditionalSuffixes(device);
    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg}>
        <Paper className={printStyles ? classes.printPaper : classes.paper} elevation={3}>
          {!device ? (
            'Loading'
          ) : (
            <Grid container justifyContent='space-between' alignContent='center' style={{ textAlign: 'center' }}>
              <Grid item sm={12}>
                <Typography variant='subtitle2' color='secondary'>
                  Performance vs. Target
                </Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant='subtitle2' color='secondary'>
                  Resolution
                </Typography>
                {resolution}
                <Typography variant='subtitle2'>{this.processPrefix(device, 'resolution')}</Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant='subtitle2' color='secondary'>
                  Sensitivity
                </Typography>
                {sensitivity}
                <Typography variant='subtitle2'>{this.processPrefix(device, 'sensitivity')}</Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant='subtitle2' color='secondary'>
                  Stroke
                </Typography>
                {stroke}
                <Typography variant='subtitle2'>{this.processPrefix(device, 'stroke')}</Typography>
              </Grid>
              <Grid item sm={3}>
                <Typography variant='subtitle2' color='secondary'>
                  New Conditions
                </Typography>
                <Typography variant='h6'>{!additionalIssues ? 'None' : additionalIssues}</Typography>
              </Grid>
            </Grid>
          )}
        </Paper>
      </Grid>
    );
  }
}

ChartWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    //sort: state.sort,
    //table: state.table,
    //loops: state.loops.loops,
    //prioritize: state.prioritize,
    //tests: state.tests.tests,
    //optimize: state.optimize,
    devices: state.devices.devices,
    activeTab: state.optimize.activeTab,
    activeDevice: state.optimize.activeDevice,
  };
};

//export default withStyles(styles)(ChartWidget);
export default connect(mapStateToProps, {
  fetchSort,
  updateSort,
  updateFilter,
  setTablePage,
  fetchLoops,
  deleteLoops,
  //fetchVariables,
  fetchTest,
  fetchDevices,
  prioritizeSetActiveDevice,
})(withStyles(styles)(ChartWidget));
