import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { /*ThemeProvider, */ StyledEngineProvider } from '@mui/material';
import { Router, matchPath } from 'react-router-dom';
import history from './history/history';
/*import initializeTheme from './components/theme';
const theme from './components/theme';*/
import { Provider } from 'react-redux';
//import { createStore, applyMiddleware, compose } from 'redux';
import store from './stores/store.js';
//import reduxThunk from 'redux-thunk';

import App from './components/App';
//import reducers from './reducers';

let baseEnvironment = 'production';
if (typeof window !== 'undefined' && (window.location.origin === 'http://localhost:3000' || window.location.origin === 'https://localhost:3000')) {
  baseEnvironment = 'development-local';
}
if (
  typeof window !== 'undefined' &&
  (window.location.origin === 'http://ten-one.io' ||
    window.location.origin === 'https://ten-one.io' ||
    window.location.origin === 'http://www.ten-one.io' ||
    window.location.origin === 'https://www.ten-one.io')
) {
  baseEnvironment = 'development-live';
}
//implementation of history for sentry to feed on react router
// Array of Route Config Objects
const routes = [
  { path: '/calendar' },
  { path: '/prioritize' },
  { path: '/optimize/:id/Performance' },
  { path: '/optimize/:id/Diagnostics' },
  { path: '/optimize/:id/Improvements' },
  { path: '/optimize/:id/History' },
  { path: '/optimize/:id/Certification' },
  { path: '/optimize/:id/:tab' },
  { path: '/reports/:tab/:report?' },
  { path: '/roadmap/:tab' },
  { path: '/optimize' },
  { path: '/upload' },
  { path: '/upload/:tab' },
  { path: '/profile' },
  { path: '/configure/:tab' },
  { path: '/support/:tab' },
  { path: '/support/:tab/:supportTab' },
  { path: '/login' },
  { path: '/signup' },
  { path: '/' },
];
//!DISABLE IN FIREFOX LOCALLY TO USE CONSOLE FOR DEBUGGING
if (!(baseEnvironment === 'development-local' && navigator.userAgent.toLowerCase().includes('firefox'))) {
  Sentry.init({
    dsn: 'https://44a0c66bf2164b9ea87f7c09bf41f447@o62457.ingest.sentry.io/5384474',
    integrations: [
      new Integrations.BrowserTracing({
        // Can also use reactRouterV4Instrumentation
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
      }),
    ],
    //normalizeDepth: 10, // Or however deep you want your state context to be.
    environment: baseEnvironment,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: baseEnvironment === 'Production' ? 0.2 : 1.0,
  });
}

//Let's us use redux devTools
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/*const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk))
);*/
/*const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});*/

/*const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk), sentryReduxEnhancer)
);*/

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
    <StyledEngineProvider injectFirst>
      <Router history={history}>
        <Provider store={store}>
          <App />
        </Provider>
      </Router>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>,
  document.querySelector('#root')
);
