import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Paper } from '@mui/material';
import { fetchDiagnostics, setTestStatus } from '../../actions/index';
import { connect } from 'react-redux';
import { styles } from '../styles';
import diagnosticsArray from '../../arrays/optimize/diagnostics';
import DiagnosticDetailsConcise from '../helpers/DiagnosticDetailsConcise';
import WidgetTitle from '../headers/WidgetTitle';
import PrintHeader from '../headers/PrintHeader';
import functions from '../../functions/functions';

class RecommendationsWidget extends React.Component {
  constructor() {
    super();
    //this.getCompareTestId = this.getCompareTestId.bind(this);
    this.state = {
      loading: false,
      hasBorder: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {}
  componentDidMount() {
    const device = this.props.device;
    if (
      !!device.activeProfileId &&
      (!this.props.tests_status ||
        !this.props.tests_status['diagnostics'] ||
        !(this.props.tests_status['diagnostics'][device.activeProfileId] === 'loading' || this.props.tests_status['diagnostics'][device.activeProfileId] === 'loaded'))
    ) {
      this.props.fetchDiagnostics(device.activeProfileId);
      this.props.setTestStatus('diagnostics', device.activeProfileId, 'loading');
    }
  }
  renderContent(alertLevel, showRecommendations) {
    //const { classes } = this.props;
    const tests = this.props.tests;
    const profileId = this.props.device.activeProfileId;
    const activeTest = !tests.diagnostics ? {} : tests['diagnostics'][profileId];
    const content = !activeTest
      ? null
      : diagnosticsArray.map((diagnosticSet) => {
          let count = 0;
          let diagnosticContent = (
            <React.Fragment key={diagnosticSet.title}>
              {diagnosticSet.checks.map((check) => {
                //if (activeTest[check.check] === alertLevel) console.log(check.subtitle);
                if (activeTest[check.check] === alertLevel) count++;
                return activeTest[check.check] === alertLevel ? (
                  <div key={`${diagnosticSet.title}_${alertLevel}_${count}`} style={{ marginBottom: 20 }}>
                    {<DiagnosticDetailsConcise id={check.check} issue_name={check.subtitle} checklistColor={functions.numberToColor(alertLevel)} showRecommendations />}
                  </div>
                ) : null;
              })}
            </React.Fragment>
          );
          //Only show sections that have content
          return count > 0 ? diagnosticContent : null;
        });

    return content;
  }
  //TODO resolution,sensitivity etc driven from 'concern' prop, cleaner to add optimize variables to state of OptimizeProgress.js
  render() {
    const { classes } = this.props;
    const hasBorder = this.state.hasBorder;
    const showRecommendations = true;
    const superCriticalContent = this.renderContent(3, showRecommendations);
    const criticalContent = this.renderContent(2, showRecommendations);
    const nullResultCritical = criticalContent === null ? 0 : criticalContent.filter((x) => x == null).length;
    const warningContent = this.renderContent(1, showRecommendations);
    const nullResultWarning = warningContent === null ? 0 : warningContent.filter((x) => x == null).length;
    const criticalContentLength = criticalContent === null ? 0 : criticalContent.length;
    const warningContentLength = warningContent === null ? 0 : warningContent.length;
    const device = this.props.devices[this.props.activeDevice];
    const printStyles = functions.setPrintStyles(this.props.activeTab);
    return (
      <React.Fragment>
        {criticalContentLength === nullResultCritical ? null : (
          <div className={`${classes.widgetMargin} ${classes.pageBreak}`} style={{ marginTop: printStyles ? 60 : 2 }}>
            {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
            <WidgetTitle title={`Critical Diagnostics - ${!device || !device.name ? 'Device Name Not Found' : device.name}`}></WidgetTitle>
            <Paper className={printStyles ? classes.printDiagnosticsPaper : classes.diagnosticsPaper} elevation={3} style={{ border: hasBorder ? '2px solid' : '' }}>
              {superCriticalContent}
              {criticalContent}
            </Paper>
          </div>
        )}
        {warningContentLength === nullResultWarning ? null : (
          <div className={`${classes.widgetMargin} ${classes.pageBreak}`} style={{ marginTop: printStyles ? 60 : 2 }}>
            {!!this.props.toggledWidget ? <PrintHeader></PrintHeader> : null}
            <WidgetTitle title={`Advisory Diagnostics - ${!device || !device.name ? 'Device Name Not Found' : device.name}`}></WidgetTitle>
            <Paper className={printStyles ? classes.printDiagnosticsPaper : classes.diagnosticsPaper} elevation={3} style={{ border: hasBorder ? '2px solid' : '' }}>
              {warningContent}
            </Paper>
          </div>
        )}
      </React.Fragment>
    );
  }
}

RecommendationsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    activeTest: state.optimize.activeTest,
    devices: state.devices.devices,
    tests: state.tests.tests,
    tests_status: state.tests.status,
    optimize: state.optimize,
    currentModule: state.currentModule.currentModule,
    activeTab: state.optimize.activeTab,
  };
};

export default connect(mapStateToProps, { fetchDiagnostics, setTestStatus })(withStyles(styles)(RecommendationsWidget));
