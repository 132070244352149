import React from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import SearchIcon from '@mui/icons-material/Search';
//import InputBase from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { styles } from '../styles';
import { connect } from 'react-redux';
import {
  fetchDevicesSummary,
  setDevicesSummaryStatus,
  setLoopsSummaryStatus,
  optimizeSetActiveDevice,
  optimizeSetCompareDevice,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  fetchDevices,
  fetchDeviceSpecs,
  optimizeSetActiveTab,
  fetchLoopsSummary,
  prioritizeSetActiveLoop,
  fetchLoopDevices,
  fetchLoopEvents,
  fetchLoopShutdowns,
} from '../../actions/index';
import { ArrowDropDown } from '@mui/icons-material';
//import { Typography } from '@mui/material';
import matchFilter from '../filters/functions/searchFilter';
//require('string_score'); // USE REQUIRE TO AVOID 'no use of declared item'. // TODO replace with better search package

//const SearchBar = (props) => {
class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      open: false,
      currentAnalysisRange: null,
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSelect = this.onSearchSelect.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    //this.handleClose = this.handleClose.bind(this);
  }
  onSearchChange = (e, value) => {
    this.setState({ value: value });
  };
  onSearchSelect = (e, value) => {
    if (value !== null && (this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.configModule === 'Optimize')) {
      this.props.optimizeSetActiveTab('Performance');
      this.props.optimizeSetActiveDevice(value.deviceId);
      if (!this.props.optimize.compareFlag) {
        this.props.optimizeSetCompareDevice(value.deviceId); //defaults comparison to the current device
      }
      if (
        !this.props.devices[value.deviceId] ||
        !this.props.devices[value.deviceId].resolutionApp ||
        !this.props.devices[value.deviceId].sensitivityApp ||
        !this.props.devices[value.deviceId].strokeApp ||
        !this.props.devices[value.deviceId].specBool
      ) {
        this.props.fetchDevices(value.deviceId);
        if (this.props.optimize.compareDevice !== value.deviceId) {
          this.props.fetchDevices(this.props.optimize.compareDevice);
        }

        this.props.fetchDeviceSpecs(value.deviceId);
        //this.props.optimizeSetCompareDevice(this.props.activeDevice);
        //this.props.fetchLoopEvents(this.props.activeLoop);
      }
    }
    //this.props.history.push(`/${e.target.value.deviceId}/Performance`);
    //return <Route to={`/${value.deviceId}/Performance`} />;
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);

    const analysisRange = urlParams.get('analysisrange');
    this.setState({ currentAnalysisRange: analysisRange });
  }

  componentDidUpdate() {
    //console.log(this.props.currentModule);
    //console.log(this.props.devices.summary);
    //console.log(this.props.loopSummary);
    //console.log(this.state);
    //console.log(this.props.analysisRange);
    if ((this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.configModule === 'Optimize') && this.props.deviceSummary === false) {
      //console.log(this.props.devices);
      //console.log(this.props.deviceCount);
      //this.props.setDevicesSummaryStatus(true);
      //this.props.fetchDevicesSummary();
      this.props.setDevicesSummaryStatus('loading').then(this.props.fetchDevicesSummary('optimize'));
    } else if (
      (this.props.currentModule === 'Prioritize' || this.props.reportModule === 'Prioritize' || this.props.configModule === 'Prioritize') &&
      this.props.loopSummary === false /*|| this.state.currentAnalysisRange !== this.props.analysisRange*/ //TODO add loop summary status check (see lines 80-81)
    ) {
      //const analysisRange =   componentDidMount(){
      const urlParams = new URLSearchParams(window.location.search);

      const analysisRange = urlParams.get('analysisrange');
      if (this.state.currentAnalysisRange !== this.props.analysisRange) {
        this.setState({ currentAnalysisRange: this.props.analysisRange });
      }
      //}
      const params = {
        analysisRange: !this.props.analysisRange ? analysisRange : this.props.analysisRange,
      };

      this.props.setLoopsSummaryStatus('loading').then(this.props.fetchLoopsSummary('prioritize', params, this.props.sort.sort));
      //console.log('TODO: CALL FOR LOOP SUMMARY'); //TODO CALL FOR LOOP SUMMARY
    }
  }

  getOptions = () => {
    let options = [];

    if (this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.configModule === 'Optimize') {
      // console.log(this.props.devices);
      const deviceKeys = Object.keys(this.props.devices);
      options = deviceKeys.map((key) => {
        const device = this.props.devices[key];
        //console.log(device);
        //console.log(device.datetimeHistory);
        return {
          serial: device.serial,
          name: device.name,
          device: device.site,
          application: device.application,
          deviceId: device.deviceId,
        };
      });
    } else if (
      this.props.currentModule === 'Prioritize' ||
      this.props.reportModule === 'Prioritize' ||
      this.props.configModule === 'Prioritize'
      //&& !this.props.loopSummary  //TODO add loop summary status check (see lines 80-81)
    ) {
      const loopKeys = Object.keys(this.props.loops);
      options = loopKeys.map((key) => {
        const loop = this.props.loops[key];
        //console.log(device);
        //console.log(device.datetimeHistory);
        return {
          //serial: device.serial,
          name: loop.processName,
          devices: !loop.devices ? '' : loop.devices.join(', '),
          application: loop.applicationType,
          processId: loop.processId,
          site: loop.site,
        };
      });
    }

    return options;
  };
  handleOpen() {
    if (!this.state.open) {
      this.setState({ open: true });
    }
  }
  renderOption = (
    props,
    option // design of options/select lines goes here
  ) => {
    const { classes } = this.props;
    if (this.props.currentModule === 'Optimize' || this.props.reportModule === 'Optimize' || this.props.configModule === 'Optimize') {
      return (
        <Link
          to={option.name.indexOf('please refine search') === -1 ? `/optimize/${option.deviceId}/Performance` : '#'}
          key={`${option.deviceId}_${option.name}`}
          className={classes.autoCompleteListItem}
          style={{ fontSize: 14 }}
          onClick={() => {
            if (option.name.indexOf('please refine search') === -1) {
              this.props.optimizeSetCompareFlag(false);
              //this.props.optimizeSetCompareDevice(this.props.activeDevice);
              this.props.optimizeSetCompareTest('');
              this.props.fetchDevices(option.deviceId);
              this.props.fetchDeviceSpecs(option.deviceId);
              this.setState({ open: false });
            }
          }}
        >
          {option.name ? <span>{option.name}</span> : null}
          <br></br>
          {option.site ? <span style={{ marginRight: 10 }}>Site: {option.site}</span> : null}
          {option.application ? <span style={{ marginRight: 10 }}>Application: {option.application}</span> : null}
          {option.serial ? <span>Serial: {option.serial}</span> : null}
        </Link>
      );
    } else if (
      this.props.currentModule === 'Prioritize' ||
      this.props.reportModule === 'Prioritize' ||
      this.props.configModule === 'Prioritize'
      //&& !this.props.loopSummary //TODO add loop summary status check (see lines 80-81)
    ) {
      return (
        <Link
          to={option.name.indexOf('please refine search') === -1 ? `/prioritize/${option.processId}/Workbench` : '#'}
          key={`${option.processId}_${option.name}`}
          className={classes.autoCompleteListItem}
          style={{ fontSize: 14 }}
          onClick={() => {
            if (option.name.indexOf('please refine search') === -1) {
              this.props.prioritizeSetActiveLoop(option.processId);
              this.props.fetchLoopDevices(option.processId);
              this.props.fetchLoopEvents(option.processId);
              this.props.fetchLoopShutdowns(option.processId);
              this.setState({ open: false });
            }
          }}
        >
          {option.name ? <span>{option.name}</span> : null}
          <br></br>
          {option.site ? <span style={{ marginRight: 10 }}>Site: {option.site}</span> : null}
          {option.application ? <span style={{ marginRight: 10 }}>Application: {option.application}</span> : null}
          {/*option.devices ? <span>Devices: {option.devices}</span> : null*/}
        </Link>
      );
    }
  };
  render() {
    const { classes } = this.props;
    const options = this.getOptions();
    if (!options || options.length === 0) {
      return null;
    }
    return (
      <div className={classes.search}>
        <Autocomplete
          size='small'
          options={options}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
          classes={{
            option: classes.option,
            inputRoot: classes.searchInput,
            // ASSIGN ZINDEX FIX
            popper: classes.autoCompletePopper,
          }}
          autoHighlight
          getOptionLabel={(option) => option.name.toString()}
          /*getOptionLabel={(option) => {
            return this.matchFilter(option);
          }}*/
          filterOptions={(options) => {
            const limit = 400;
            return matchFilter(options, this.state.value, null, limit);
          }}
          renderOption={this.renderOption}
          onInputChange={this.onSearchChange}
          onChange={this.onSearchSelect}
          popupIcon={<ArrowDropDown style={{ color: '#e0e0e0' }} />}
          renderInput={(
            params // design of box goes here?
          ) => (
            <div>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <TextField
                {...params}
                placeholder='Search'
                variant='outlined'
                onClick={this.handleOpen}
                inputProps={{
                  classes: { root: classes.searchInnerInput },
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            </div>
          )}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    activeDevice: state.optimize.activeDevice,
    auth: state.auth,
    devices: state.devices.devices,
    loops: state.loops.loops,
    deviceSummary: state.devices.summary,
    loopSummary: state.loops.summary,
    deviceCount: state.devices.count,
    currentModule: state.currentModule.currentModule,
    optimize: state.optimize,
    reportModule: state.reports.activeTab,
    configModule: state.config.activeTab,
    analysisRange: state.sort.filter.AnalysisRange,
    sort: state.sort,
  };
};

export default connect(mapStateToProps, {
  fetchDevicesSummary,
  setDevicesSummaryStatus,
  setLoopsSummaryStatus,
  optimizeSetActiveDevice,
  optimizeSetCompareDevice,
  optimizeSetCompareTest,
  optimizeSetCompareFlag,
  fetchDevices,
  fetchDeviceSpecs,
  optimizeSetActiveTab,
  fetchLoopsSummary,
  prioritizeSetActiveLoop,
  fetchLoopDevices,
  fetchLoopEvents,
  fetchLoopShutdowns,
})(withStyles(styles, { withTheme: true })(SearchBar));
