import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { styles } from '../styles';
import { CreateNewFolder } from '@mui/icons-material';
import { Typography } from '@mui/material';
import initializeTheme from '../theme.js';
import { connect } from 'react-redux';

class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
  }

  onDragOver(evt) {
    evt.preventDefault();

    if (this.props.disabled) return;

    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();

    if (this.props.disabled) return;

    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const theme = initializeTheme(this.props.colorMode);
    const { classes } = this.props;
    return (
      <div
        className={`${classes.dropzone} ${this.state.hightlight ? classes.highlight : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
      >
        <input id={!this.props.id ? 'upload_file_id' : this.props.id} ref={this.fileInputRef} className={classes.fileInput} type='file' multiple onChange={this.onFilesAdded} />
        <CreateNewFolder color='primary' style={{ color: theme.palette.primary.main }}></CreateNewFolder>
        <Typography variant='subtitle2' color='primary'>
          Select Files
        </Typography>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    colorMode: state.colorMode.colorMode,
  };
};

export default connect(mapStateToProps, {})(withStyles(styles, { withTheme: true })(Dropzone));
