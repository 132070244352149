import { createTheme } from '@mui/material';
//import { adaptV4Theme } from '@mui/material/styles';
//import store from '../stores/store.js';

/*const getColorTheme = () => {
  const state = store.getState();
  return state.colorMode.colorMode;
};*/

//import reducers from './reducers';
const getPaletteColorMode = (colorMode) => {
  if (colorMode === 'dark') {
    return {
      mode: 'dark',
      background: {
        default: '#0d0d0d',
      },
      primary: {
        main: '#fcfcfc',
        light: '#6f94af',
        dark: '#34556c',
        hover: '#b2c7d5',
      },
      secondary: {
        main: '#6fc2ff',
        light: '#1597ff',
        dark: '#135183',
      },
      blue: {
        color: '#6fc2ff',
        dark: '#296C9D',
        light: '#72A6E1',
        transparent: 'rgba(30,80,120,.05)',
      },
      border: {
        color: '#333',
      },
      gray: {
        color: '#aaa',
        dark: '#eee',
        light: '#999',
        ultraLight: '#222',
      },
      green: {
        color: '#79cc83',
        dark: '#379141',
        light: '#7FBF86',
      },
      orange: {
        color: '#E17C4F',
        dark: '#CC6E43',
        light: '#E99671',
      },
      pink: {
        color: '#ED5183',
      },
      purple: {
        color: '#795BA6',
      },
      red: {
        color: '#c33c4d',
        dark: '#AE3333',
        light: '#E87171',
      },
      text: {
        primary: '#eee',
        secondary: '#eee',
      },
      tile: {
        primary: '#0f0f0f',
        hover: '#161616',
        border: '#333',
      },
      yellow: {
        color: '#f8c249',
        dark: '#C77232',
        light: '#EBAD7D',
      },
      appBar: {
        color: '#191c25',
      },
      list: {
        hover: '#333',
      },
    };
  } else {
    return {
      mode: !colorMode ? 'light' : colorMode,
      background: {
        default: '#f5f5f5',
      },
      primary: {
        main: '#184568',
        light: '#6f94af',
        dark: '#34556c',
        hover: '#b2c7d5',
      },
      secondary: {
        main: '#1c75bc',
        light: '#1597ff',
        dark: '#135183',
      },
      blue: {
        color: '#1879C0',
        dark: '#296C9D',
        light: '#72A6E1',
        transparent: 'rgba(30,80,120,.05)',
      },
      border: {
        color: '#ddd',
      },
      gray: {
        color: '#616161',
        dark: '#464749',
        light: '#999',
        ultraLight: '#eee',
      },
      green: {
        color: '#308D3B',
        dark: '#379141',
        light: '#7FBF86',
      },
      orange: {
        color: '#E17C4F',
        dark: '#CC6E43',
        light: '#E99671',
      },
      pink: {
        color: '#ED5183',
      },
      purple: {
        color: '#795BA6',
      },
      red: {
        color: '#D3001B',
        dark: '#AE3333',
        light: '#E87171',
      },
      text: {
        primary: '#464749',
        secondary: '#464749',
      },
      tile: {
        primary: '#fff',
        hover: '#EEF6FC',
        border: '#ccc',
      },
      yellow: {
        color: '#e3a61b',
        dark: '#C77232',
        light: '#EBAD7D',
      },
      appBar: {
        color: '#184568',
      },
      list: {
        hover: '#eee',
      },
    };
  }
};

const initializeTheme = (colorMode) => {
  //const colorMode = getColorTheme();
  return createTheme(
    /*adaptV4Theme(*/ {
      breakpoints: {
        values: {
          xs: 0,
          sm: 450,
          md: 900,
          lg: 1280,
          xl: 1920,
        },
      },
      typography: {
        useNextVariants: true,
        body1: {
          '@media print': {
            fontSize: 13,
          },
        },
        subtitle1: {
          '@media print': {
            fontSize: 14,
          },
        },
        subtitle2: {
          fontSize: 15,
        },
        h6: {
          fontSize: 16,
          '@media print': { fontSize: 14 },
        },
      },
      palette: getPaletteColorMode(colorMode),
      components: {
        MuiAccordion: {
          styleOverrides: {
            root: {
              boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 40%)',
              marginBottom: '4px !important',
              borderRadius: 8,
              '&:before': {
                display: 'none',
              },
            },
          },
        },
        MuiAlert: {
          styleOverrides: {
            root: {
              border: colorMode === 'dark' ? '1px solid #222' : '1px solid #ddd',
            },
          },
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              border: 'none',
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            input: { cursor: 'pointer' },
            popper: { zIndex: 2000 },
          },
        },
        MuiBackdrop: {
          styleOverrides: {
            root: { zIndex: -1 },
          },
        },
        MuiButton: {
          styleOverrides: {
            containedPrimary: {
              '&:hover': {
                backgroundColor: colorMode === 'dark' ? '#0f0f0f' : '#3a6a8f',
              },
            },
            containedSecondary: {
              '&:hover': {
                backgroundColor: colorMode === 'dark' ? '#0f0f0f' : '#038fff',
              },
            },
            root: {
              textTransform: 'none',
            },
            outlined: {
              padding: '6px 12px 4px 12px',
              backgroundColor: colorMode === 'dark' ? '#0f0f0f' : '#fcfcfc',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              height: 25,
            },
            deleteIcon: {
              margin: '0px 1px 0px -6px',
            },
            label: {
              paddingLeft: 8,
              paddingRight: 8,
            },
          },
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              margin: 8,
            },
            scrollPaper: {
              display: 'block',
            },
          },
        },
        MuiDialogContent: {
          styleOverrides: {
            root: {
              padding: '18px',
            },
          },
        },
        MuiDialogContentText: {
          styleOverrides: {
            root: {
              marginBottom: 4,
            },
          },
        },
        MuiDialogTitle: {
          styleOverrides: {
            root: {
              padding: '10px 18px',
            },
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: { border: 'none', borderRight: colorMode === 'light' ? '1px solid #e0e0e0' : '1px solid #333', backgroundColor: colorMode === 'light' ? null : '#0f0f0f' },
          },
        },
        MuiExpansionPanelSummary: {
          styleOverrides: {
            root: {
              padding: '0px 16px 0px 0px',
            },
          },
        },
        MuiFormControl: {
          styleOverrides: {
            root: {
              marginTop: 0,
            },
            marginNormal: {
              marginTop: null,
              marginBottom: null,
            },
          },
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: { marginRight: 0, marginLeft: 0 },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              padding: 4,
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: colorMode === 'dark' ? '0 0 0 100px #0a2335 inset' : 'none',
                WebkitTextFillColor: colorMode === 'dark' ? '#fcfcfc !important' : '#184568',
              },
            },
            formControl: {
              marginTop: 8,
              '@media print': {
                'label + &': {
                  marginTop: 10,
                },
              },
            },
          },
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              width: 30,
              color: colorMode === 'dark' ? '#eee' : '#464749',
            },
          },
        },
        MuiInputLabel: {
          defaultProps: { shrink: true },
          styleOverrides: {
            root: {
              color: colorMode === 'dark' ? '#ccc' : '#464749',
            },
            formControl: {
              '@media print': {
                transform: 'translate(0, 0px) scale(.75)',
              },
            },
          },
        },
        MuiLinearProgress: {
          styleOverrides: {
            dashed: {
              animation: 'none',
            },
            dashedColorPrimary: {
              backgroundImage: 'none',
              backgroundColor: colorMode === 'dark' ? '#333' : '#ddd',
            },
            dashedColorSecondary: {
              backgroundImage: 'none',
              backgroundColor: colorMode === 'dark' ? '#333' : '#ddd',
            },
          },
        },
        MuiLink: {
          styleOverrides: {
            root: { textDecoration: 'none' },
          },
        },
        MuiList: {
          styleOverrides: {
            padding: {
              paddingTop: 2,
              paddingBottom: 2,
              '@media print': {
                paddingTop: 2,
                paddingBottom: 2,
              },
            },
          },
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              paddingTop: 5,
              '&$selected': {
                backgroundColor: 'rgba(24,69,104,.1)',
                '&:hover': {
                  backgroundColor: 'rgba(24,121,192,.1)',
                },
              },
            },
            button: {
              '&:hover': {
                backgroundColor: 'rgba(24,121,192,.1)',
              },
            },
            gutters: {
              '@media print': {
                paddingLeft: 6,
                paddingRight: 6,
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: '45px',
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              marginTop: 2,
              marginBottom: 0,
            },
          },
        },
        MuiMenu: {
          styleOverrides: {
            paper: {
              padding: 0,
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              color: colorMode === 'dark' ? '#fcfcfc' : '#184568',
            },
            notchedOutline: {
              border: colorMode === 'dark' ? '1px solid rgba(252, 252, 252, 0.5)' : '1px solid rgba(24, 69, 104, 0.5)',
            },
            input: {
              '&:-webkit-autofill': {
                WebkitBoxShadow: colorMode === 'dark' ? '0 0 0 100px #0a2335 inset' : 'none',
              },
            },
          },
        },
        MuiPagination: {
          styleOverrides: {
            selectRoot: {
              marginRight: 8,
              paddingLeft: 0,
            },
            spacer: {
              flex: null,
            },
            toolbar: {
              height: 30,
              minHeight: 30,
              marginBottom: 5,
              paddingLeft: null,
              '@media only screen and (max-width: 550px)': {
                height: 15,
                minHeight: 15,
              },
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              //backgroundColor: '#fcfcfc',
              outline: colorMode === 'dark' ? '.5px solid #333' : null,
              backgroundImage: 'none',
              /*'@media print': {
              boxShadow: 'none',
              border: '1px solid #ddd',
              backgroundColor: 'transparent',
            },*/
            },
          },
        },
        MuiPopover: {
          styleOverrides: {
            paper: {
              overflowY: 'auto',
              maxWidth: 'calc(80% - 32px)',
              maxHeight: 'calc(80% - 32px)',

              '@media only screen and (max-width: 800px)': {
                maxWidth: 'calc(100% - 32px)',
                maxHeight: 'calc(100% - 32px)',
              },
            },
          },
        },
        MuiSelect: {
          styleOverrides: {
            icon: {
              //color: 'inherit',
            },
            outlined: {
              backgroundColor: colorMode === 'dark' ? '#eee' : '#fcfcfc',
            },
          },
        },
        MuiSvgIcon: {
          styleOverrides: {
            root: {
              width: '.9em',
              height: '.9em',
            },
            colorPrimary: {
              color: colorMode === 'dark' ? '#fcfcfc' : '#184568',
            },
          },
        },
        MuiTab: {
          styleOverrides: {
            root: {
              textTransform: 'none',
              cursor: 'pointer',
              '@media only screen and (max-width: 960px)': {
                padding: '10px 0px',
              },
              '@media (min-width: 960px)': {
                minWidth: 72,
              },
              '&.Mui-selected': {
                stroke: colorMode === 'dark' ? '#519bd1' : '#1c75bc',
              },
            },
            labelIcon: {
              '@media (max-width: 550px)': {
                paddingTop: 14,
              },
            },
          },
        },
        MuiTabs: {
          styleOverrides: {
            root: {
              minHeight: null,
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            body: {
              fontWeight: 500,
            },
            root: {
              padding: 6,
              borderBottom: colorMode === 'dark' ? '1px solid #333' : '1px solid #d5d5d5',
              '&:first-of-type': {
                paddingLeft: 16,
                '@media (max-width: 550px)': {
                  paddingLeft: 6,
                },
              },
              '@media print': {
                borderBottom: colorMode === 'dark' ? '.5px solid #333' : '.5px solid #eee',
              },
            },
            head: {
              color: colorMode === 'dark' ? '#80a7c4' : '#35688A',
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            actions: { marginLeft: 4 },
            select: { marginLeft: 4, marginRight: 4, lineHeight: null },
            toolbar: {
              paddingLeft: '0px !important',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            root: {
              //'&$selected': { backgroundColor: 'rgba(30,80,120,.02)' },
            },
          },
        },
        MuiTableSortLabel: {
          styleOverrides: {
            root: {
              '&:hover': { color: colorMode === 'dark' ? '#eee' : '#464749' },
            },
          },
        },
        MuiToggleButton: {
          styleOverrides: {
            root: {
              '@media only screen and (max-width: 550px)': {
                padding: '8px 12px',
                fontSize: 12,
                textAlign: 'center',
              },
              '&$selected': {
                color: '#1879C0',
                backgroundColor: 'rgba(24,69,104,.1)',
                '&:hover': {
                  backgroundColor: 'rgba(24,121,192,.1)',
                },
              },
              '&:hover': { backgroundColor: 'rgba(130,162,192,.1)' },
            },
          },
        },
        MuiTypography: {
          styleOverrides: {
            body1: {
              '@media print': {
                lineHeight: 1.2,
              },
            },
            caption: {
              fontWeight: 500,
            },
            subtitle1: {
              fontWeight: 500,
            },
            subtitle2: {
              fontWeight: 500,
            },
          },
        },
      },
    } /*)*/
  );
};

//const theme = store.subscribe(initializeTheme);
//const theme = initializeTheme(this.props.colorMode);;

export default initializeTheme;
